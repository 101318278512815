import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import tinycolor from 'tinycolor2';
import { ColorWrap, Saturation, Alpha, Hue } from 'react-color/lib/components/common';

import ColorType from './ColorType';
import { autofillColor, setDefaultColor } from './helpers';
import { Input } from '@dealroadshow/uikit';
import ColorPickerPanel from './ColorPickerPanel';
import ColorPickerPresetColors from './ColorPickerPresetColors';
import SelectedColor from './SelectedColor';

import styles from './colorPicker.scss';

interface IProps {
  input: any,
  containerClassName: string,
  label: string,
  onChange: () => void,
  onSwatchHover: () => void,
  disableAlpha?: boolean,
  enableAutofill?: boolean,
  preSetColor: string,
  defaultColor: string,
  enablePresetColors?: boolean,
  withoutTextInput?: boolean,
  forceColorPickerVisible?: boolean,
  presetColors?: string[] | { color: string, title: string }[],
  renderers: any,
  className: string,
  dataTest?: string,
  width: number,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const ColorPicker = (
  {
    input,
    containerClassName,
    className,
    label,
    width,
    onChange,
    onSwatchHover,
    disableAlpha = true,
    enableAutofill = false,
    enablePresetColors = false,
    defaultColor,
    preSetColor,
    presetColors = ColorType.getDefaultPresetColors(),
    renderers,
    withoutTextInput = false,
    forceColorPickerVisible = false,
    dataTest = '',
    ...otherProps
  }: IProps,
) => {
  const [color, setColor] = useState(null);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const colorPickerRef = useRef(null);

  useEffect(() => {
    if (enableAutofill) {
      autofillColor(input, preSetColor);
    }

    if (defaultColor) {
      setDefaultColor(input, defaultColor);
    }

    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const color = tinycolor(input.value);
    setColor({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
      rgb: color.toRgb(),
    });
  }, [input.value]);

  const handleClickOutside = (event) => {
    if (!colorPickerRef.current.contains(event.target)) {
      hideColorPicker();
    }
  };

  const showColorPicker = () => setIsColorPickerVisible(true);

  const hideColorPicker = () => setIsColorPickerVisible(false);

  const handleChange = ({ source, ...type }) => {
    const colorCode = ColorType.isHexType(source) ? type.hex : type;
    const color = tinycolor(colorCode);
    input.onChange(`#${ color.toHex() }`);
  };

  return (
    <div
      className={ cn(styles.inputContainer, containerClassName) }
      onClick={ showColorPicker }
      ref={ colorPickerRef }
      data-test={ dataTest }
    >
      { !withoutTextInput && (
        // @ts-ignore
        <Input
          input={ input }
          type="text"
          minLength={ 4 }
          maxLength={ 7 }
          appendComponent={ <SelectedColor color={ input.value } /> }
          label={ label }
          { ...otherProps }
          isClearable={ false }
          dataTest="colorPickerInput"
        />
      ) }
      { (isColorPickerVisible || forceColorPickerVisible) && (
        <div
          className={ cn(
            styles.colorPickerContainer,
            className, {
              [styles.noLabel]: !label,
              [styles.colorPickerAlphaWrp]: !disableAlpha,
            },
          ) }
        >
          <div className={ styles.saturation }>
            <Saturation
              className={ styles.saturationWrp }
              hex={ color.hex }
              hsl={ color.hsl }
              hsv={ color.hsv }
              pointer={ () => <div className={ styles.pointer } /> }
              onChange={ handleChange }
            />
          </div>
          <div className={ cn(styles.controls, styles.colorFlexboxFix) }>
            <div className={ styles.sliders }>
              <div className={ styles.hue }>
                <Hue
                  className={ styles.hueWrp }
                  hsl={ color.hsl }
                  onChange={ handleChange }
                  pointer={ () => <div className={ styles.huePointer } /> }
                />
              </div>
              <div className={ cn(styles.alpha, { [styles.noAlpha]: disableAlpha }) }>
                <Alpha
                  className={ styles.alphaWrp }
                  rgb={ color.rgb }
                  hsl={ color.hsl }
                  renderers={ renderers }
                  onChange={ handleChange }
                  pointer={ () => <div className={ cn(styles.huePointer) } /> }
                />
              </div>
            </div>
          </div>
          <ColorPickerPanel
            rgb={ color.rgb }
            hsl={ color.hsl }
            hex={ color.hex }
            onChange={ handleChange }
            disableAlpha={ disableAlpha }
          />
          {
            enablePresetColors && (
              <ColorPickerPresetColors
                hex={ color.hex }
                colors={ presetColors }
                onClick={ handleChange }
                onSwatchHover={ onSwatchHover }
              />
            )
          }
        </div>
      ) }
    </div>
  );
};

export default ColorWrap(ColorPicker);
