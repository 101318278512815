import React from 'react';
import { Input } from '@dealroadshow/uikit';
import { convertNumberWithCurrencyToNumber } from '@/Framework/dataHelpers/convertTypes';

import styles from './AccountProfile/accountProfile.scss';

interface IProps {
  fieldComponent: React.ElementType,
  label: string,
  name: string,
  placeholder: string,
  className: string,
  dataTest: string,
  currentValue: number,
}

const AumField = ({
  fieldComponent: Field,
  currentValue,
  ...otherProps
}:IProps) => {
  const formatAum = (value) => {
    if (!value) {
      return value === 0 ? value : (value || '');
    }

    const strValue = (value).toString();

    return convertNumberWithCurrencyToNumber(strValue);
  };

  return (
    <Field
      component={ Input }
      formFieldClassName={ styles.aumInput }
      { ...otherProps }
    >
      { ({ input, ...props }) => (
        /* @ts-ignore */
        <Input
          { ...props }
          input={ {
            ...input,
            value: formatAum(input.value),
          } }
        />
        ) }
    </Field>
  );
};

export default AumField;
