import { connect, batch } from 'react-redux';
import debounce from 'lodash/debounce';
import { getContactsByFilter, setContactsSearchQuery, paginate } from '@/condor/application/actions/contacts/collection/actions';
import styles from '@/condor/ui/components/AccountsAndContacts/Contacts/ContactsList/contactsList.scss';
import selectors from '@/condor/application/actions/contacts/collection/selectors';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';
import { resetContactItem } from '@/condor/application/actions/contacts/item/actions';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
    className: styles.searchFilterInput,
    placeholder: 'Filter by Email, Name, Phone or Account',
    isNarrow: true,
    isClearable: true,
  }
);

const mapDispatchToProps = (dispatch) => {
  const searchDebounced = debounce(() => dispatch(getContactsByFilter()), 500);
  return {
    onChange: (e) => {
      batch(() => {
        dispatch(setContactsSearchQuery(e.target.value));
        dispatch(paginate(1));
        dispatch(resetContactItem());
      });
      searchDebounced();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
