import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLE_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import ArticleProfileFields from './ArticleProfileFields';
import {
  useArticleProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import validate from './validate';
import { getStatus } from '@/condor/ui/components/Finsight/CreditFlowResearch/Articles/ArticleProfile/helpers';

const ArticleProfile = () => {
  const {
    createOrUpdateArticle,
    initialFormData,
    isInitialFetching,
    isEdit,
    isActive,
  } = useArticleProfileContext();

  return (
    <ProfileWrapper
      isSpinnerVisible={ isInitialFetching }
      status={ isEdit ? getStatus(isActive) : null }
    >
      <FinalForm<ArticleFormData>
        name={ CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLE_PROFILE_FORM_NAME }
        dataTest={ CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLE_PROFILE_FORM_NAME }
        onSubmit={ createOrUpdateArticle }
        render={ () => <ArticleProfileFields /> }
        validate={ validate }
        initialValues={ initialFormData }
        fetchInitialData={ isInitialFetching }
      />
    </ProfileWrapper>
  );
};

export default ArticleProfile;
