import { useState, useEffect } from 'react';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { useDIContext } from '@/Framework/DI/DIContext';
import TraceRepository from '@/finsight/infrastructure/repository/TraceRepository';
import { DEFAULT_TRACE_DATA, DEFAULT_TRACE_PARAMS } from './constants';
import { ArchiveByOptions, ITracePayload, ITraceResponse, SubProductType } from '@/condor/domain/vo/types';
import { useMapTrade } from './useMapTrade';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import useRouter from '@/Framework/hooks/useNextRouter';
import * as constants from '@/condor/ui/common/constants';

export const useTraceData = () => {
  const traceRepository = useDIContext().container.get<TraceRepository>(TraceRepository);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [traceData, setTraceData] = useState<ITraceResponse>(DEFAULT_TRACE_DATA);
  const [params, setParams] = useState<ITracePayload>(DEFAULT_TRACE_PARAMS);
  const { asPath, query: { any } } = useRouter();

  const handlePageChange = (value: { selected: number }) => setParams(
      (prevParams) => ({ ...prevParams, page: value.selected }));

  const handleSortChange = (sortBy: string, sortOrder: string) => setParams(
      (prevParams) => ({ ...prevParams, sortBy, sortOrder }));

  const handleItemsPerPageChange = (perPage: { value: number, label: string }) => setParams(
      (prevParams) => ({ ...prevParams, page: 1, perPage: perPage.value }));

  const onSearch = (query: string) => setParams((prevParams) => ({ ...prevParams, query }));

  const onCheckFilters = (filterField: string) => setParams((prevParams) => ({
    ...prevParams,
    filters: {
      ...prevParams.filters,
      [filterField]: !prevParams.filters[filterField],
    },
  }));

  const getTraceListItems = async () => {
    setIsFetching(true);
    try {
      const response = await traceRepository.getTradesRequest(params);
      setTraceData(response);
      setIsFetching(false);
    } catch (error) {
      if (!Dispatcher.isAbortError(error)) {
        NotificationManager.error(getErrorMessage(error));
        setIsFetching(false);
      }
    }
  };

  const archiveTrade = async (cusip: string | null, archiveBy: ArchiveByOptions, issuerName: string | null) => {
    setIsFetching(true);
    try {
      await traceRepository.archiveTrade({ cusip, archiveBy, issuerName });
      const message = archiveBy === ArchiveByOptions.Cusip
          ? getMessage(messageCodes.TRACE_TRADE_ARCHIVED_BY_CUSIP_SUCCESS, { cusip })
          : getMessage(messageCodes.TRACE_TRADE_ARCHIVED_BY_ISSUER_SUCCESS, { issuerName });
      NotificationManager.success(message);
      getTraceListItems();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const restoreTrade = async (cusip: string) => {
    setIsFetching(true);
    try {
      await traceRepository.restoreTrade({ cusip });
      NotificationManager.success(getMessage(messageCodes.TRACE_TRADE_RESTORED_SUCCESS, { cusip }));
      getTraceListItems();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const isArchived = asPath.includes(constants.CONDOR_FINSIGHT_TRADES_ARCHIVED_BASE_URL);

    if (params.filters.includedArchived !== isArchived) {
      setParams((prevState) => ({
        ...prevState,
        filters: {
          ...params.filters,
          includedArchived: isArchived,
          includedNotArchived: !isArchived,
          spdType: isArchived ? null : prevState.filters.spdType,
        },
      }));
    }
  }, [asPath]);

  useEffect(() => {
    if (any) {
      const subProductType = any[4];
      if (subProductType) {
        setParams((prevState) => ({
          ...prevState,
          filters: {
            ...params.filters,
            includedArchived: false,
            includedNotArchived: true,
            spdType: subProductType as SubProductType,
          },
        }));
      }
    }
  }, [any]);

  useEffect(() => {
    getTraceListItems();
  }, [JSON.stringify(params)]);

  const mapTradeDataAndActions = useMapTrade(getTraceListItems);

  return {
    ...params,
    traceData,
    isFetching,
    onSearch,
    handlePageChange,
    handleSortChange,
    handleItemsPerPageChange,
    getTraceListItems,
    onCheckFilters,
    archiveTrade,
    restoreTrade,
    ...mapTradeDataAndActions,
  };
};
