import React from 'react';
import { format, isSameDay, toDate } from 'date-fns';
import memoize from '@/Framework/dataHelpers/memoize';
import { Link } from 'react-router-dom';
import Status from '@/condor/ui/common/Layout/Status';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { MONTH_DAY_YEAR_FORMAT, TIME_FORMAT, TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

/**
 * @param {Object} cellProps
 * @return {String}
 */
export function commonCellCallback(cellProps) {
  if (!cellProps.row[cellProps.cellName]) {
    return '-';
  }
  return cellProps.row[cellProps.cellName];
}

interface IopenProfileCellWrapperParams {
  tooltipTitle?: string,
  href: string,
  className?: string,
  children: React.ReactNode,
}

export function openProfileCellWrapper({ tooltipTitle, href, className, children }: IopenProfileCellWrapperParams) {
  return (
    <Tooltip content={ tooltipTitle || 'Open Profile' }>
      <Link
        to={ href }
        className={ className }
      >
        { children }
      </Link>
    </Tooltip>
  );
}

/**
 * @param {Object} status
 */
export function statusCellWrapper(status) {
  return (
    <Status
      className={ alignStyles.alignCenter }
      status={ status }
    />
  );
}

const dateToMonthDayYearOrTime = (
    { timeZone = TIMEZONE_DEFAULT, dateTime }:
        { timeZone?: string, dateTime: Date },
) => {
  const time = utcToZonedDate(timeZone, new Date(dateTime));

  const isToday = isSameDay(time, utcToZonedDate(timeZone));
  if (isToday) {
    return `${ format(time, TIME_FORMAT) } ET`;
  }

  return format(time, MONTH_DAY_YEAR_FORMAT);
};

export const getCellDisplayDateFromNumber = (date) => {
  if (date) {
    const dateValue = new Date(date * 1000);

    return dateToMonthDayYearOrTime({ dateTime: dateValue });
  }

  return '-';
};

export const getCellDisplayDateFromString = (date) => {
  if (date) {
    const dateValue = toDate(new Date(date));

    return dateToMonthDayYearOrTime({ dateTime: dateValue });
  }

  return '-';
};

/**
 * @param {Object} item
 */
export const createOption = (item) => ({
  label: item.name,
  value: item.id,
});

/**
 * @param {Array} options
 * @param {Function} callBack
 */
export const mapOptions = (options, callBack = createOption) => options.map(callBack);

export const mapOptionsMemoized = memoize(mapOptions);

/**
 * @param arrowText
 * @param iconType
 */
export const panelArrow = (arrowText = '', iconType) => (
  <div>
    { (typeof arrowText === 'string') && (
      <span className="panelGroupHeaderArrowText">{ arrowText }</span>
    ) }
    <span className="panelGroupHeaderArrowIcon">
      <Icon type={ iconType } />
    </span>
  </div>
);

/**
 * @param arrowText
 */
export const panelArrowCollapsed = (arrowText) => panelArrow(arrowText, IconType.plus);

/**
 * @param arrowText
 */
export const panelArrowExpanded = (arrowText) => panelArrow(arrowText, IconType.minus);

/**
 @param {object} cellProps
 @return {String}
 */
export const getFormattingNumberCellCallback = (cellProps) => {
  if (cellProps.cellData) {
    return formatNumber(cellProps.cellData);
  }

  return '-';
};
