import React, { memo } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import cn from 'classnames';
import Route from '@/Framework/Router/ReactRouter/Route';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';

import Requests from './Requests';
import Subscribers from './Subscribers';

import {
  CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_REQUESTS_URL,
  CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_SUBSCRIBERS_URL,
} from '@/condor/ui/common/constants';

import floatStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/floats.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

const ManagePlans = () => {
  return (
    <PageWrapper
      label="Manage Plans"
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_REQUESTS_URL }
            >
              Requests
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_SUBSCRIBERS_URL }
            >
              Subscribers
            </NavLink>
          </div>
        </div>
      ) }
    >
      <Switch>
        <Route
          path={ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_REQUESTS_URL }
          component={ Requests }
        />
        <Route
          path={ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_SUBSCRIBERS_URL }
          component={ Subscribers }
        />
      </Switch>
    </PageWrapper>
  );
};

export default memo(ManagePlans);
