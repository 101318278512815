import React from 'react';
import { Field } from 'redux-form';
import { format } from 'date-fns';
import { openProfileCellWrapper } from '@/condor/ui/common/Layout/helpers';
import { MONTH_DAY_YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import CompanySelectField from '@/condor/ui/components/Finsight/Companies/CompanySelectField';
import { PARENT } from '@/finsight/application/actions/pageFilters/constants';
import IndustryOrSectorField from './IndustryOrSectorField';
import ProductFieldContainer from './ProductField';
import RegionField from './RegionField';
import IssuerField from './IssuerField';
import IndustryOrSectorCell from './IndustryOrSectorCell';
import ActionsCell from './ActionsCell';
import {
  CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL,
} from '@/condor/ui/common/constants';
import { APPLICATION_WRP_ID } from '@/Framework/UI/Templates/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';
import { Input } from '@dealroadshow/uikit';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';

import linkStyles from '@dealroadshow/uikit/dist/lib/styles/links.scss';
import spaceStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import tableStyles from './dealsTable.scss';

/**
 * @param {Object} cellProps
 */
export const seriesNameCallback = (cellProps) => openProfileCellWrapper({
  href: `${ CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/${ cellProps.row.id }`,
  className: linkStyles.link,
  children: cellProps.row.seriesName,
  tooltipTitle: 'Open Deal',
});

/**
 * @param {Object} cellProps
 */
export const regionCellCallback = (cellProps) => (
  <span>{ cellProps.row.country.region.abbreviation }</span>
);

/**
 * @param {Object} cellProps
 */
export const regionEditableFieldCallback = (cellProps) => (
  <RegionField { ...cellProps } />
);

/**
 * @param {Object} cellProps
 */
export const productCellCallback = (cellProps) => (
  <span>{ cellProps.cellData.abbreviation }</span>
);

/**
 * @param {Object} cellProps
 */
export const tickerCellCallback = (cellProps) => (
  <span>{ cellProps.row.primaryIssuer.issuerTicker }</span>
);

export const editableTickerCallback = () => (
  <Field
    name="issuerTicker"
    component={ Input }
    label=""
    placeholder=""
    disabled
    isNarrow
    inputClassName={ tableStyles.tickerInput }
    className={ tableStyles.tickerFieldWrp }
    dataTest="dealsTableIssuerTickerInput"
  />
);

/**
 * @param {Object} cellProps
 */
export const productEditableFieldCallback = (cellProps) => (
  <ProductFieldContainer { ...cellProps } />
);
/**
 * @param {Object} cellProps
 */
export const industryOrSectorCell = (cellProps) => (
  <IndustryOrSectorCell { ...cellProps } />
);

/**
 * @param {Object} cellProps
 */
export const parentCell = (cellProps) => (
  <span>{ cellProps.row.parent.name }</span>
);

/**
 * @param {Object} cellProps
 */
export const actionsCell = (cellProps) => (
  <ActionsCell row={ cellProps.row } />
);

/**
 * @param {Object} cellProps
 */
export const industrySectorEditableFieldCallback = (cellProps) => (
  <IndustryOrSectorField { ...cellProps } />
);

/**
 * @param {Object} cellProps
 */
export const pricingDateCellCallback = ({ cellData }) => (
  cellData?.dateTime
  ? (
    <span>
      { format(utcToZonedDate(cellData.timezone.timeZone, new Date(cellData.dateTime)), MONTH_DAY_YEAR_FORMAT) }
    </span>
  )
  : <span>-</span>);

/**
 * @param {Object} cellProps
 */
export const pricingDateEditableFieldCallback = (cellProps) => (
  <Field
    className={ tableStyles.pricingDate }
    name="pricingDate"
    label=""
    component={ CondorDatePicker }
    isNarrow
    usePortal
    iconClassName={ spaceStyles.mtn }
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);

/**
 * @param {Object} cellProps
 */
export const parentSelect = (cellProps) => (
  <Field
    name="parent"
    isSelect
    usePortal
    role={ PARENT }
    component={ CompanySelectField }
    withoutExcludedIds
    isNarrow
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);

/**
 * @param {Object} cellProps
 */
export const issuerCell = (cellProps) => (
  <span>{ cellProps.row.primaryIssuer.name }</span>
);

/**
 * @param {Object} cellProps
 */
export const issuerSelect = (cellProps) => (
  <IssuerField tableRef={ cellProps.tableRef } />
);
