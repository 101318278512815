import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import * as condorConstants from '../constants';
import { IconType } from '@dealroadshow/uikit';
import CondorNavigationItem from '@/condor/ui/common/Navigation/CondorNavigationItem';
import * as sidebarSelectors from '@/condor/application/actions/sidebar/selectors';

import FinsightManager from './FinsightManager';
import Counter from '@/condor/ui/common/Counter';

import textStyles from '@dealroadshow/uikit/dist/lib/styles/text.scss';
import navigationStyles from './navigationItem.scss';
import styles from '@/dmPortal/ui/common/Layout/Navigation/navigation.scss';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/condor/application/config/featureToggle';

interface INavigationItemsProps {
  isSidebarCollapsed: boolean,
  toggleMobileMenu: () => void,
  changeSidebarColor: (color: string) => void,
}

interface INavigationItem {
  id: string,
  title: string,
  href?: string,
  iconType?: IconType,
  shortTitle?: string,
  parent?: string,
  color?: string,
  type?: string,
  isExternal?: boolean,
}

const NavigationItems = (props: INavigationItemsProps) => {
  const [isFinsightTabOpen, setIsFinsightTabOpen] = useState<boolean>(false);
  const navigationItems = useSelector(sidebarSelectors.getNavigationItems) as INavigationItem[];

  useEffect(() => {
    if (!isFinsightTabOpen && window.location.pathname.includes(condorConstants.CONDOR_FINSIGHT_BASE_URL)) {
      setIsFinsightTabOpen(true);
    }
  });

  const getNavigationSubItemsByParentId = (id: string) => navigationItems
    .filter((item) => item.parent === id);

  const getNavigationParentItems = useMemo(() => navigationItems.filter((item) => !item.parent), [navigationItems]);

  const renderNavLabel = (item: INavigationItem) => {
    const appsTitleCls = cn(
      textStyles.smallTitle,
      styles.navSectionTitle,
      { [styles.isSidebarCollapsed]: props.isSidebarCollapsed },
    );

    return (
      <div className={ appsTitleCls } key={ item.id }>
        { props.isSidebarCollapsed ? item.shortTitle : item.title }
      </div>
    );
  };

  const renderNavItem = (item: INavigationItem) => {
    // TODO Need to replace with AppNavigationItem after implemented next/router in Condor
    return (
      <CondorNavigationItem
        key={ item.id }
        isSubItem
        isSidebarCollapsed={ props.isSidebarCollapsed }
        onClick={ props.toggleMobileMenu }
        isExternal={ item.isExternal }
        iconType={ item.iconType }
        id={ item.id }
        name={ item.title }
        uri={ item.href }
      />
    );
  };

  const renderParentItem = (item: INavigationItem) => {
    const subItems = getNavigationSubItemsByParentId(item.id);
    const isFinsightTab = item.id === 'finsight';
    const isResearchRoomTab = item.id === 'researchroom';
    const isManageTab = item.id === 'finsight_manager';
    const isLabel = item.type === 'label';

    let onClick = () => {
      setIsFinsightTabOpen(false);
      props.toggleMobileMenu();
      props.changeSidebarColor(item.color);
    };

    if (isLabel) {
      return renderNavLabel(item);
    }

    if (isFinsightTab) {
      onClick = () => {
        setIsFinsightTabOpen(!isFinsightTabOpen);
      };
    }

    if (isManageTab) {
      return (
        <FinsightManager
          key={ item.id }
          navContent={ ({ requestsCount }) => (
            // TODO Need to replace with AppNavigationItem after implemented next/router in Condor
            <CondorNavigationItem
              key={ item.id }
              isSubItem={ false }
              isSidebarCollapsed={ props.isSidebarCollapsed }
              iconType={ item.iconType }
              id={ item.id }
              name={ item.title }
              uri={ item.href }
              onClick={ onClick }
              isActive={ isFinsightTab ? isFinsightTabOpen : undefined }
              nameCallback={ () => {
                return (
                  <div className={ styles.navItemTextWrapper }>
                    <span className={ cn(
                      navigationStyles.navItemText,
                      { [navigationStyles.textHidden]: props.isSidebarCollapsed },
                    ) }
                    >
                      { item.title }
                    </span>
                    { requestsCount > 0 && (
                    <Counter
                      value={ requestsCount }
                      className={ cn(navigationStyles.navItemCounter, {
                        [navigationStyles.navItemCounterCollapsed]: props.isSidebarCollapsed,
                      }) }
                    />
                  ) }
                  </div>
              );
            } }
            >
              { subItems.map(renderNavItem) }
            </CondorNavigationItem>
          ) }
        />
      );
    }

    if (isResearchRoomTab) {
      return (
        <FeatureToggle
          featureName={ RESEARCHROOM_FEATURE }
          key={ item.id }
        >
          <CondorNavigationItem
            isSubItem={ false }
            isSidebarCollapsed={ props.isSidebarCollapsed }
            iconType={ item.iconType }
            id={ item.id }
            name={ item.title }
            uri={ item.href }
            onClick={ onClick }
            isActive={ isFinsightTab ? isFinsightTabOpen : undefined }
          >
            { subItems.map(renderNavItem) }
          </CondorNavigationItem>
        </FeatureToggle>
      );
    }

    // TODO Need to replace with AppNavigationItem after implemented next/router in Condor
    return (
      <CondorNavigationItem
        key={ item.id }
        isSubItem={ false }
        isSidebarCollapsed={ props.isSidebarCollapsed }
        iconType={ item.iconType }
        id={ item.id }
        name={ item.title }
        uri={ item.href }
        onClick={ onClick }
        isActive={ isFinsightTab ? isFinsightTabOpen : undefined }
      >
        { subItems.map(renderNavItem) }
      </CondorNavigationItem>
    );
  };

  return getNavigationParentItems.map(renderParentItem);
};

export default NavigationItems;
