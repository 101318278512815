import { IUserPlanResponse } from '@/users/domain/vo/IUserPlanResponse';
import { UserPlanType } from '@/users/domain/UserPlan';

export enum UserPlanRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}

export enum UserPlanFeature {
  XlsExport = 'xlsExport',
}

export interface IUserPlanState extends IUserPlanResponse {
  requests: IUserPlanRequestsState,
  features: UserPlanFeature[],
  hasServerError?: boolean,
}

export const initialRequests: IUserPlanRequestsState = {
  [UserPlanType.UNAUTHORIZED]: {
    plan: UserPlanType.UNAUTHORIZED,
    status: UserPlanRequestStatus.Denied,
  },
};

export const initUserPlan = {
  plan: UserPlanType.UNAUTHORIZED,
  features: [],
  requests: initialRequests,
};

export type IUserPlanRequestsState = {
  [key in UserPlanType]?: {
    plan: UserPlanType,
    status: UserPlanRequestStatus,
  }
};

export interface IUserPlanContext extends IUserPlanState {
  getUserPlan: () => void,
  isUnauthorized: boolean,
  isStarter: boolean,
  isPremium: boolean,
  isPro: boolean,
}

export const USER_PLAN_ACCESS_LEVEL_MAP = {
  [UserPlanType.UNAUTHORIZED]: 0,
  [UserPlanType.STARTER]: 1,
  [UserPlanType.PREMIUM]: 2,
  [UserPlanType.PRO]: 3,
};

export const mapUserPlanToSubscribeDescription = {
  [UserPlanType.STARTER]: 'Starter, Premium and Professional plans',
  [UserPlanType.PREMIUM]: 'Premium and Professional plans',
  [UserPlanType.PRO]: 'Professional plan',
};

export const mapUserPlanToSubscribeDescriptionWithHighlighting = {
  [UserPlanType.STARTER]: '<strong>Starter</strong>, <strong>Premium</strong> and <strong>Professional</strong> plans',
  [UserPlanType.PREMIUM]: '<strong>Premium</strong> and <strong>Professional</strong> plans',
  [UserPlanType.PRO]: '<strong>Professional</strong> plan',
};

export type TUserPlanTypes = (keyof typeof UserPlanType)[];

export const checkUserPlanMatchRequired = (plan, requiredUserPlan) => {
  return USER_PLAN_ACCESS_LEVEL_MAP[plan] >= USER_PLAN_ACCESS_LEVEL_MAP[requiredUserPlan];
};
