import React from 'react';

import { Field, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { Input, CheckboxInput } from '@dealroadshow/uikit';
import CustomSectionSubfieldsTable from './CustomSectionSubfieldsTable';
import { DetailsSection } from '@/evercall/domain/vo/DetailsSection';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

import styles from './detailsSections.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IDetailsSection {
  id: DetailsSection,
  renderSection: (values: ICustomRegistration) => React.ReactNode,
}

export const DETAILS_SECTIONS: IDetailsSection[] = [
  {
    id: DetailsSection.Event,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableEventDetailsSection"
          dataTest="isEnableEventDetailsSection"
          label="Enable Event Details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.Roadshow,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableRoadshowDetailsSection"
          dataTest="isEnableRoadshowDetailsSection"
          label="Enable Presentation Details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.DialIn,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableDialInDetailsSection"
          dataTest="isEnableDialInDetailsSection"
          label="Enable Dial-in details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.Custom,
    renderSection: ({ isEnableCustomDetailsSection }) => (
      <>
        <div className={ styles.sectionContainer }>
          <Field
            isNarrow
            name="isEnableCustomDetailsSection"
            dataTest="isEnableCustomDetailsSection"
            label="Enable Custom section"
            component={ CheckboxInput }
            type="checkbox"
          />
        </div>
        {
          isEnableCustomDetailsSection && (
            <>
              <div className={ styles.customSectionContainer }>
                <Field
                  component={ Input }
                  containerClassName={ formStyles.formInput }
                  name="customDetailsSectionName"
                  label="Section Name"
                  dataTest="customDetailsSectionNameInput"
                />
                <div className={ styles.provideDetailsLabel }>Provide Custom Section details</div>
              </div>
              <FieldArray
                name="customDetailsSectionSubfields"
                component={ CustomSectionSubfieldsTable }
              />
            </>
          )
        }
      </>
    ),
  },
];
