import { IGroupFormValues, IGroupEventsItemFormValue } from './interfaces';

export const GROUP_PROFILE_FORM_NAME = 'groupProfileForm';

export const GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM: IGroupEventsItemFormValue = {
  id: null,
  useCustomization: false,
};

export const GROUP_FOOTER_TEXT_MAX_LENGTH = 5000;

export const GROUP_PROFILE_FORM_INITIAL_VALUES: IGroupFormValues = {
  events: [GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM],
  name: '',
  path: '',
  footerText: '',
};
