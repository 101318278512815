import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { IValidationError } from './interfaces/ValidationError';
import { IExternalLinksParams } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ExternalLinksParams';

export const validateExternalLinkName = (params: IExternalLinksParams): IValidationError => {
  if (!params.allValue.url) {
    return null;
  }
  const required = validateRequiredField(params);
  if (required) {
    return required;
  }
  const minLengthResponse = validateMinLength({ ...params, length: params.length.min });

  if (minLengthResponse) {
    return minLengthResponse;
  }
  const maxLengthResponse = validateMaxLength({ ...params, length: params.length.max });

  if (maxLengthResponse) {
    return maxLengthResponse;
  }
  return null;
};
