import React, { memo } from 'react';
import cn from 'classnames';
import { CONDOR_CREATE_ACCOUNT_BASE_URL } from '@/condor/ui/common/constants';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { Icon, IconType } from '@dealroadshow/uikit';
import CheckboxFilters from './CheckboxFilters';
import SearchInputContainer from './SearchInputContainer';
import AccountsTable from './AccountsTable';
import AccountsTablePaginator from './AccountsTable/AccountsTablePaginatorContainer';
import DeleteModal from './DeleteModal';
import BulkDeleteIcon from './BulkDeleteIcon';

import styles from './accountsList.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import btnStyles from '@/Framework/UI/Atoms/Button/button.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const AccountsList = (props: IProps) => {
  const { mll, plxl, prxl } = spacesStyles;
  return (
    <PageWrapper
      label="Accounts"
      headerComponent={ (
        <div className={ styles.headerComponentContainer }>
          <CheckboxFilters />
          <a
            href={ CONDOR_CREATE_ACCOUNT_BASE_URL }
            target="_blank"
            className={ cn(btnStyles.action, btnStyles.btnIcon, plxl, prxl) }
            data-test="createAccount"
          >
            <span>Create Account</span>
            <Icon
              type={ IconType.plusBold }
              className={ mll }
            />
          </a>
        </div>
      ) }
      onMount={ props.init }
      onUnmount={ props.reset }
    >
      <TableWrapper
        label="Accounts"
        labelClassName={ styles.header }
        toolbar={ <BulkDeleteIcon /> }
        filterComponent={ <SearchInputContainer /> }
      >
        { /* @ts-ignore */ }
        <AccountsTable />
        <AccountsTablePaginator className={ cardStyles.cardInner } dataTest="accountsTable" />
        <DeleteModal />
      </TableWrapper>
    </PageWrapper>
  );
};

export default memo(AccountsList);
