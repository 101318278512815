import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { IDataroomDetails } from '@/dataroom/domain/vo/condor/IDataroomDetails';
import CondorRepository from '@/dataroom/infrastructure/repository/CondorRepository';

export default function useDataroomDetails(dataroomId: number) {
  const [isFetching, setIsFetching] = useState(false);
  const [dataroomDetails, setDataroomDetails] = useState<IDataroomDetails>(null);
  const { container } = useDIContext();
  const { tenant } = useDataroomTenantContext();
  const { push } = useHistory();

  const getDataroomDetails = async () => {
    setIsFetching(true);
    try {
      const condorRepository = container.get<CondorRepository>(CondorRepository);
      const response = await condorRepository.getDataroomById({ dataroomId });

      setDataroomDetails(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      push(`/core/${ tenant }`);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    dataroomId,
    isFetching,
    dataroomDetails,
    getDataroomDetails,
  };
}
