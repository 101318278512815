import {
  ElementType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { Transforms, Editor, Node } from 'slate';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  useFocused,
  useSlate,
} from 'slate-react';

import {
  Button,
  Icon,
  IconType,
  Popover,
  Tooltip,
} from '@dealroadshow/uikit';
import {
  Element,
  Leaf,
  Toolbar,
} from '@/Framework/UI/Molecules/Form/RichTextInput/components/index';

import { TToolbarPosition } from '../interfaces';

import styles from '@/Framework/UI/Molecules/Form/RichTextInput/richTextInput.scss';

interface IProps {
  toolbarPosition: TToolbarPosition,
  ToolbarComponent: ElementType,
  value: string,
  placeholder: string,
  onKeyDown: (event: React.KeyboardEvent) => void,
  onPaste: (event: React.ClipboardEvent) => void,
  editorStyles: React.CSSProperties,
  isValidationFeedback: boolean,
  editableWrpCls: string,
  className: string,
}

const TextEditor = ({
  toolbarPosition = 'top',
  ToolbarComponent = Toolbar,
  value,
  placeholder,
  onKeyDown,
  onPaste,
  editorStyles = {},
  isValidationFeedback,
  editableWrpCls,
  className,
}: IProps) => {
  const popoverRef = useRef(null);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const inFocus = useFocused();
  const editor = useSlate();
  const hasContent = Node.string(editor) !== '';

  useEffect(() => {
    if (toolbarPosition === 'popover' && inFocus) {
      popoverRef.current.show();
      if (hasContent) {
        setIsClearButtonVisible(true);
      }
    }
  }, [hasContent, inFocus]);

  const renderElement = useCallback(
    ({ children, ...otherProps }: RenderElementProps) => <Element { ...otherProps }>{ children }</Element>,
    [],
  );
  const renderLeaf = useCallback(
    ({ children, ...otherProps }: RenderLeafProps) => <Leaf { ...otherProps }>{ children }</Leaf>,
    [],
  );

  const editableInputWrpCls = cn(styles.editableWrp, editableWrpCls, {
    [styles.editableWrpValidation]: isValidationFeedback,
  });

  const handleClearText = () => {
    Transforms.select(editor, { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) });
    Transforms.delete(editor);
    setIsClearButtonVisible(false);
    popoverRef.current.hide();
  };

  const editableInput = (
    <div className={ editableInputWrpCls }>
      <Editable
        placeholder={ placeholder }
        renderElement={ renderElement }
        renderLeaf={ renderLeaf }
        onKeyDown={ onKeyDown }
        onPaste={ onPaste }
        style={ editorStyles }
        className={ cn(styles.editable, className) }
        data-test="editableInput"
      />
      { isClearButtonVisible && (
        <Button
          className={ styles.clearButton }
          dataTest="clearButton"
          onClick={ handleClearText }
        >
          <Icon type={ IconType.cancel } />
        </Button>
      ) }
    </div>
  );

  switch (toolbarPosition) {
    case 'bottom':
      return (
        <>
          { editableInput }
          { ToolbarComponent && (<ToolbarComponent value={ value } position="bottom" />) }
        </>
      );
    case 'popover':
      return (
        <Popover
          placement="top-start"
          content={ <ToolbarComponent value={ value } position="popover" /> }
          arrowClassName={ styles.arrow }
          contentClassName={ styles.popoverWrapper }
          ref={ popoverRef }
          triggerEvent={ false }
          afterClose={ () => setIsClearButtonVisible(false) }
        >
          <Tooltip
            disabled={ !isValidationFeedback }
            content="Display Text may not contain less than 3 characters"
            containerClassName={ styles.tooltip }
            type={ Tooltip.CONSTANTS.ERROR }
          >
            { editableInput }
          </Tooltip>
        </Popover>
      );
    default:
      return (
        <>
          { ToolbarComponent && (<ToolbarComponent value={ value } position="top" />) }
          { editableInput }
        </>
      );
  }
};

export default TextEditor;
