import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { IGroupFormValues } from './interfaces';
import { GROUP_FOOTER_TEXT_MAX_LENGTH } from './constants';

const groupNameField = { fieldName: 'name', fieldCaption: 'Group name' };
const footerTextField = { fieldName: 'footerText', fieldCaption: 'Footer text' };

const validateNameToSlug = (values: IGroupFormValues) => {
  if (values.name?.length >= 3 && values.path?.length < 3) {
    return { name: 'Group name must form a valid URL path of at least 3 characters' };
  }
  return null;
};

const validateGroupName = (values: IGroupFormValues) => (
  groupValidators(
    validateRequiredField({ ...groupNameField, value: values.name }),
    validateMinLength({ ...groupNameField, length: 3, value: values.name }),
    validateMaxLength({ ...groupNameField, length: 128, value: values.name }),
    validateNameToSlug(values),
  )
);

export default (values: IGroupFormValues) => groupValidators(
  validateGroupName(values),
  validateMinLength({ ...footerTextField, length: 3, value: values.footerText }),
  validateMaxLength({ ...footerTextField, length: GROUP_FOOTER_TEXT_MAX_LENGTH, value: values.footerText }),
);
