import React, { useMemo } from 'react';
import { Checkbox } from '@dealroadshow/uikit';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FilterItem from '@/Framework/UI/Organisms/Filters/components/FilterItem';
import FilterColumns from '@/Framework/UI/Organisms/Filters/components/FilterColumns';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';
import { ICommonSectionProps } from '../types';
import { IEnhancedTaggingFiltersOption } from '@/condor/domain/vo/EnhancedTagging/EnhancedTaggingFilters';

interface IProps extends ICommonSectionProps<string[]> {
  options: IEnhancedTaggingFiltersOption[],
  columnsCount?: number,
}

const CheckboxSection = ({
  isVisible,
  onSectionToggle,
  setFieldValue,
  sectionId,
  filterName,
  sectionTitle,
  fieldComponent: Field,
  selected,
  options,
  columnsCount = 2,
}: IProps) => {
  const indeterminateState = useMemo(() => getIndeterminateState(options, selected), [options, selected]);
  const allOptions = useMemo(() => options.map((option) => option.value), [options]);

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(event.target.checked ? allOptions : []);
    };

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        { ...indeterminateState }
      />
    );
  }, [sectionId, sectionTitle, indeterminateState, allOptions]);

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns columnsCount={ columnsCount }>
        { options.map(({ value }) => (
          <FilterItem key={ value }>
            <Field
              type="checkbox"
              name={ filterName }
              component={ ({ meta, input }) => (
                <Checkbox
                  { ...input }
                  meta={ meta }
                  dataTest={ `${ filterName } ${ value } checkbox` }
                  value={ value }
                  label={ value }
                  checked={ selected?.includes(value) }
                  onChange={ (event) => {
                    const { checked } = event.target;
                    if (checked) {
                      setFieldValue([...selected, value]);
                    } else {
                      setFieldValue(selected.filter((selectedValue) => selectedValue !== value));
                    }
                  } }
                />
              ) }
            />
          </FilterItem>
        )) }
      </FilterColumns>
    </FilterSection>
  );
};

export default CheckboxSection;
