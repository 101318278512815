import cn from 'classnames';
import noop from 'lodash/noop';

import { Input, IconType } from '@dealroadshow/uikit';
import OpenMarketTabsList from './OpenMarketTabsList';

import styles from './openMarket.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';

const OpenMarketHeader = () => {
  return (
    <div className={ spacesStyles.pbn }>
      <div className={ cn(cardStyles.cardHeader, styles.cardHeader) }>
        <div className={ cn(cardStyles.cardHeaderTitle, styles.cardHeaderTitle) }>
          <h3>OpenMarket Profiles & Events</h3>
        </div>
        <div className={ cardStyles.cardHeaderActions }>
          { /* @ts-ignore */ }
          <Input
            name="search"
            formFieldClassName={ styles.searchInputWrapper }
            iconType={ IconType.filtering }
            placeholder="Filter by Profile Name, Event Name or ID"
            isNarrow
            dataTest="searchInput"
            value=""
            onChange={ (event) => noop(event.target.value) }
            disabled
          />
        </div>
      </div>
      <OpenMarketTabsList />
    </div>
  );
};

export default OpenMarketHeader;
