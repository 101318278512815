import React, { createContext, PropsWithChildren, useContext } from 'react';
import useGroupProfile from './useGroupProfile';
import { ICreateGroupProps } from './interfaces';

export const GroupProfileContext = createContext<ReturnType<typeof useGroupProfile>>(null);

export const useGroupProfileContext = () => {
  const context = useContext(GroupProfileContext);
  if (!context) {
    throw new Error('useGroupProfileContext must be used within the GroupProfileContext');
  }
  return context;
};

const GroupProfileContextProvider = ({ children, ...props }: PropsWithChildren<ICreateGroupProps>) => (
  <GroupProfileContext.Provider value={ useGroupProfile(props) }>{ children }</GroupProfileContext.Provider>
);

export default GroupProfileContextProvider;
