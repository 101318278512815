import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import RpcRequest from '@/Framework/api/Rpc/Request';
import BaseFinsightRepository from '@/finsight/infrastructure/repository/BaseFinsightRepository';
import IDictionaries from '@/finsight/domain/vo/dictionaries/IDictionaries';

@Dependencies(RpcDispatcher)
class HeaderRepository extends BaseFinsightRepository {
  constructor(protected rpc: typeof RpcDispatcher) {
    super(rpc);
  }

  getDictionaries = async (): Promise<IDictionaries> => {
    const request = new RpcRequest('finsight_web.frontend.finsight.get_dictionaries', {});
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload as any;
  };

  /**
   * @param {String} searchString
   * @return {Promise}
   */
  search = async (searchString) => this.abortableCall(new RpcRequest('finsight_web.frontend.finsight.get_search', {
    searchString,
  }));

  /**
   * @param {String} searchString
   * @return {Promise}
   */
  searchDeals = async (searchString) => this.abortableCall(new RpcRequest('finsight_web.market.search.deals', {
    searchString,
  }));
}

export default HeaderRepository;
