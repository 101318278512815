import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';
import {
  IEventPayload,
  IRanking,
  ILinkToRoadshow,
  IRoadshowData,
  IEvent,
  IListEvent,
} from '@/openMarket/domain/Condor/vo/IEvent';

@injectable()
class EventRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  getRankings = async (): Promise<IRanking[]> => {
    const request = new Request('open_market.event.get_rankings');
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getRoadshowsList = async (value: string): Promise<ILinkToRoadshow[]> => {
    const request = new Request('open_market.roadshow.list', { search: value });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getRoadshowData = async (id: string): Promise<IRoadshowData> => {
    const request = new Request('open_market.roadshow.get', { id });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getEvents = async (payload: IFetchCollectionPayload): Promise<IFetchCollectionResponse<IListEvent>> => {
    const request = new Request('open_market.event.list', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  deleteEvent = async (id: string) => {
    const requestuest = new Request('open_market.event.delete', {
      eventId: id,
    });
    const response = await this.rpc.call(requestuest);

    return response.getResult().payload;
  };

  createEvent = async (payload: IEventPayload): Promise<void> => {
    const request = new Request('open_market.event.create', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  updateEvent = async (payload: IEventPayload): Promise<void> => {
    const request = new Request('open_market.event.update', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getEventById = async (eventId: string): Promise<IEvent> => {
    const request = new Request('open_market.event.get_by_id', { eventId });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };
}

export default EventRepository;
