import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';

import styles from './common.scss';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

const NoNotificationOverlay = ({ isNotificationEnabled }: { isNotificationEnabled: boolean }) => {
  if (isNotificationEnabled) {
    return null;
  }

  return (
    <div className={ styles.overlay }>
      <div className={ alignStyles.flexColumnCenter }>
        <Icon type={ IconType.noBell } />
        <p>User(s) will not receive any
          <br />
          notifications by email.
        </p>
      </div>
    </div>
    );
};

export default NoNotificationOverlay;
