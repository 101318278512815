import React, { useCallback, memo } from 'react';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';

import { Spinner, Modal, DataTable } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

import useDealChangesList from '../helpers/useDealChangesList';
import { TRANCHES_CHANGES_LIST_COLUMNS } from '../constants';

import styles from './submitDealModal.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

interface IProps {
  onClose: () => void,
  isVisible: boolean,
  formMeta: any,
  formValues: any,
  initialValues: any,
  ratingAgencies: any[],
  reduxFormSubmit: () => void,
  isCreating: boolean,
  isUpdating: boolean,
  isMapping: boolean,
  isSecFiling: boolean,
}

const SubmitDealModal = (
  {
    onClose,
    isVisible,
    formMeta,
    formValues,
    initialValues,
    ratingAgencies,
    reduxFormSubmit,
    isCreating,
    isUpdating,
    isMapping,
    isSecFiling,
  }: IProps,
) => {
  const isSpinnerVisible = isCreating || isUpdating || isMapping;

  const {
    dealChangesList,
    tranchesChangesList,
  } = useDealChangesList(
    {
      formMeta,
      formValues,
      initialValues,
      isModalVisible: isVisible,
      ratingAgencies,
    },
  );

  const renderDealChange = useCallback(
    (dealChange, i) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <div
        key={ i }
        className={ spacesStyles.pbl }
      >
        <span className={ cn(styles.boldText, spacesStyles.mrs) }>{ dealChange.label }:</span>
        <span>{ dealChange.value }</span>
      </div>
    ),
    [],
  );

  return (
    <Modal
      hideOnOverlayClicked
      className={ styles.modal }
      isVisible={ isVisible }
      title="Change Confirmation"
      onCloseClicked={ onClose }
      dataTest="submitDealModal"
      footer={ !isSpinnerVisible && (
        <>
          <Button
            variant={ variantTypes.action }
            title="Confirm"
            onClick={ reduxFormSubmit }
            dataTest="submitDealModalConfirmButton"
          />
          <Button
            variant={ variantTypes.text }
            onClick={ onClose }
            title="Cancel"
            dataTest="submitDealModalCancelButton"
          />
        </>
      ) }
    >
      { !isSpinnerVisible && (
        <>
          <div className={ spacesStyles.mbxl }>Please review the below changes for accuracy before continuing.</div>
          {
            !!dealChangesList.length && (
              <div className={ styles.dealChangesListContainer }>
                { dealChangesList.map(renderDealChange) }
              </div>
            )
          }
          {
            !!tranchesChangesList.length && !isSecFiling && (
              <div className={ styles.tranchesChangesListContainer }>
                <div className={ cn(styles.boldText, spacesStyles.mbm) }>Tranche Edits:</div>
                <DataTable
                  data={ tranchesChangesList }
                  dataTest="submitDealModalDataTable"
                  columns={ TRANCHES_CHANGES_LIST_COLUMNS }
                  containerClassName={ styles.tranchesChangesTableContainer }
                />
              </div>
            )
          }
        </>
      ) }
      <Spinner isVisible={ isSpinnerVisible } />
    </Modal>
  );
};

/**
 * SubmitDealModal receives formMeta, formValues and initialValues props that change quiet often.
 * In order to avoid extra re-renders, we subscribe only to boolean props change.
 *
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areBooleanPropsEqual = (prevProps, nextProps) => (
  isEqual(prevProps.isVisible, nextProps.isVisible) &&
  isEqual(prevProps.isCreating, nextProps.isCreating) &&
  isEqual(prevProps.isUpdating, nextProps.isUpdating)
);

export default memo(SubmitDealModal, areBooleanPropsEqual);
