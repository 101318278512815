import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CountryRepository from '@/dealroadshow/infrastructure/repository/country/CountryRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import Country from '@/dealroadshow/domain/vo/Country';

const useCountries = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<[Country?]>([]);

  async function getCountriesList() {
    setIsFetching(true);

    try {
      const countryRepository = container.get<CountryRepository>(CountryRepository);
      const response = await countryRepository.getCountries();
      setCollection(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getCountriesList,
  };
};

export default useCountries;
