import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import * as actionTypes from './actionTypes';
import bwicSelectors from './selectors';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {String} sortBy
 * @param {String} sortOrder
 * @return {Object}
 */
export const sort = (sortBy, sortOrder) => createAction(actionTypes.CONDOR_BWIC_SORT_CHANGE, { sortBy, sortOrder });

/**
 * @param {Number} page
 * @return {Object}
 */
export const paginate = (page) => createAction(actionTypes.CONDOR_BWIC_PAGE_CHANGE, page);

/**
 * @param {Number} perPage
 * @return {Object}
 */
export const itemsPerPage = (perPage) => createAction(actionTypes.CONDOR_BWIC_PER_PAGE_CHANGE, perPage);

/**
 * Reset state except some fields (perPage) when asset class has changed
 * @return {{type: string}}
 */
export const partialStateReset = () => createAction(actionTypes.CONDOR_BWIC_RESET_PARTIAL);

/**
 * @return {{type: string}}
 */
export const resetState = () => createAction(actionTypes.CONDOR_BWIC_RESET);

/**
 * @return {Function}
 */
 export const fetchBwic = () => async (dispatch, getState) => {
  const state = getState();
  let payload = {
    filter: {
      includedAssetClassId: metadataSelectors.getActiveAssetClassId(state),
    },
    sortBy: bwicSelectors.getSortBy(state),
    sortOrder: bwicSelectors.getSortOrder(state),
    page: bwicSelectors.getPage(state),
    perPage: bwicSelectors.getPerPage(state),
    search: metadataSelectors.getSearch(state),
  };
  const bondsRepository = getState().container.get(BondsRepository);

  dispatch(createAsyncRequest(
    {
      payload,
      method: bondsRepository.getCondorBwicList,
      types: [
        actionTypes.GET_CONDOR_BWIC_LIST,
        actionTypes.GET_CONDOR_BWIC_LIST_SUCCESS,
        actionTypes.GET_CONDOR_BWIC_LIST_FAILURE,
      ],
      callbacks: {
        onError: ({ error }) => {
          if (Dispatcher.isAbortError(error)) {
            return;
          }
          NotificationManager.error(getErrorMessage(error));
          throw error;
        },
      },
    },
  ));
};
