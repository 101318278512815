import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import isServer from '@/Framework/Router/Next/isServer';

export default class BaseFinsightRepository {
  protected constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  protected async abortableCall(request: Request, tags: string = '') {
    if (isServer()) {
      const response = await this.rpc.call<RpcSuccess>(request);
      return response.getResult().payload;
    }

    if (tags) {
      tags = request.method.concat(tags);
    } else {
      tags = request.method;
    }
    this.rpc.abort(tags);

    const response = await this.rpc.call<RpcSuccess>(request, { tags });
    return response.getResult().payload;
  }
}
