import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { INPUT_MIN_LENGTH } from '@/Framework/UI/Organisms/EnhancedTagging/Field/Underwriters/Rows/Rows';

// Form level validation doesn't work properly with FieldArrays.
// https://github.com/final-form/react-final-form/issues/382s

const field = { fieldName: 'name', fieldCaption: 'Underwriters Name' };

export default (value) => groupValidators(
  validateMaxLength({
    ...field,
    length: 128,
    value,
  }),
  validateMinLength({
    ...field,
    length: INPUT_MIN_LENGTH,
    value,
  }),
)?.[field.fieldName];
