import { extractDomainsAndEmailsFromString } from '@/Framework/dataHelpers/string/extractDomainsAndEmailsFromString';
import { emailRegexp } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import { domainRegexp } from '@/Framework/UI/Organisms/FinalForm/validators/validateDomain';
import { IWhitelistFull } from '../interfaces';

const getInvalidDomainsAndEmails = (domainsAndEmails: ReturnType<typeof extractDomainsAndEmailsFromString>) => {
  return domainsAndEmails
    .filter(({ emailOrDomain }) => {
      const isInvalidEmail = !emailRegexp.test(emailOrDomain);
      const isInvalidDomain = !domainRegexp.test(emailOrDomain);

      return isInvalidEmail && isInvalidDomain;
    })
    .map(({ emailOrDomain }) => emailOrDomain);
};

const getAlreadyAddedDomainsAndEmails = (
  domainsAndEmails: ReturnType<typeof extractDomainsAndEmailsFromString>,
  existWhitelist: IWhitelistFull[],
) => {
  return domainsAndEmails
    .filter(({ emailOrDomain }) => {
      const lowerCaseEmailOrDomain = emailOrDomain.toLowerCase();

      return existWhitelist.find((whitelistItem) => {
        return lowerCaseEmailOrDomain === whitelistItem.emailOrDomain.toLowerCase();
      });
    })
    .map(({ emailOrDomain }) => emailOrDomain);
};

export const validateInput = ({
  whitelist,
  value,
  fieldName,
}: {
  whitelist: IWhitelistFull[],
  value?: string,
  fieldName: string,
}) => {
  if (!value) {
    return null;
  }

  const domainsAndEmails = extractDomainsAndEmailsFromString(value);

  if (!domainsAndEmails.length) {
    return null;
  }

  const invalidDomainsAndEmails = getInvalidDomainsAndEmails(domainsAndEmails);

  if (invalidDomainsAndEmails.length > 0) {
    return {
      [fieldName]: `Invalid records entered: ${ invalidDomainsAndEmails.join(', ') }`,
    };
  }

  const alreadyAddedDomainsAndEmails = getAlreadyAddedDomainsAndEmails(domainsAndEmails, whitelist);

  if (alreadyAddedDomainsAndEmails.length > 0) {
    return {
      [fieldName]: `Records already added to list: ${ alreadyAddedDomainsAndEmails.join(', ') }`,
    };
  }

  return null;
};

export default (value: string, whitelist: IWhitelistFull[]) => {
  return validateInput({
    value,
    whitelist,
    fieldName: 'input',
  });
};
