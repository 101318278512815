import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import validateDetails from './sections/Details/validation';
import validateLoginAndDisclaimer from './sections/LoginAndDisclaimer/validation';
import validateCustomization from './sections/Customization/validation';
import { IFormValues } from './interfaces';

export default (values: IFormValues) => {
  return groupValidators(
    validateDetails(values),
    validateLoginAndDisclaimer(values),
    validateCustomization(values),
  );
};
