import { createContext, useContext, useState } from 'react';
import ProfileRepository from '@/openMarket/infrastructure/repository/Condor/ProfileRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IListProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useOpenMarketListContext } from '../../../OpenMarketList/OpenMarketListContext';

const useProfilesList = () => {
  const { container } = useDIContext();
  const profileRepository = container.get<ProfileRepository>(ProfileRepository);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingProfile, setDeletingProfile] = useState<IListProfile | null>(null);

  const { getCounters } = useOpenMarketListContext();
  const activity = useFetchCollection(profileRepository.getProfilesList, {});

  const deleteProfile = async (id: string) => {
    try {
      setIsDeleting(true);

      await profileRepository.deleteProfile(id);
      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      activity.getCollection();
      getCounters();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      closeDeleteModal();
      setIsDeleting(false);
    }
  };

  const openDeleteModal = (profile: IListProfile) => setDeletingProfile(profile);
  const closeDeleteModal = () => setDeletingProfile(null);

  return {
    activity,
    deleteProfile,
    isDeleting,
    deletingProfile,
    openDeleteModal,
    closeDeleteModal,
  };
};

export const ProfilesListContext = createContext<ReturnType<typeof useProfilesList>>(null);

export const useProfilesListContext = () => {
  const context = useContext(ProfilesListContext);
  if (!context) {
    throw new Error('useProfilesListContext must be used within a ProfilesListContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ProfilesListContextProvider = ({ children }: IProps) => (
  <ProfilesListContext.Provider value={ useProfilesList() }>{ children }</ProfilesListContext.Provider>
);

export default ProfilesListContextProvider;
