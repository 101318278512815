import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { Spinner, IconType } from '@dealroadshow/uikit';

import styles from '../dealsTable.scss';

interface IProps {
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  isDownloading: boolean,
  inlineEditFormState: any,
  onSubmit: () => void,
  onCancel: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
  exportToExcel: () => void,
}

const ActionsCell = ({
  isSelectedForEditing,
  isUpdating,
  isDownloading,
  inlineEditFormState,
  onSubmit,
  onCancel,
  onEditClick,
  onDeleteClick,
  exportToExcel,
}: IProps) => {
  const actions = isSelectedForEditing ?
    [
      {
        tooltipContent: 'Save',
        iconType: IconType.check,
        onClick: onSubmit,
        disabled: inlineEditFormState.submitting ||
                  inlineEditFormState.pristine ||
                  inlineEditFormState.invalid,
        className: styles.actionContainer,
        dataTest: 'saveButton',
      },
      {
        tooltipContent: 'Cancel',
        iconType: IconType.cancel,
        onClick: onCancel,
        dataTest: 'cancelButton',
      },
    ] : [
      {
        tooltipContent: 'Edit',
        iconType: IconType.pencil,
        onClick: onEditClick,
        className: styles.actionContainer,
        dataTest: 'editButton',
      },
      {
        tooltipContent: 'Export to Excel',
        iconType: IconType.fileXls,
        onClick: exportToExcel,
        className: styles.actionContainer,
        dataTest: 'exportButton',
      },
      {
        tooltipContent: 'Delete',
        iconType: IconType.trash,
        onClick: onDeleteClick,
        dataTest: 'deleteButton',
      },
    ];

  const isLoading = isUpdating || isDownloading;
  const isVisibleSpinner = isSelectedForEditing || isDownloading;

  return (
    <LastTableCell
      showHoverContent={ isVisibleSpinner }
      childrenToShowOnHover={
        !isLoading
          ? <TableRowActions actions={ actions } />
          : (
            <Spinner
              size="small"
              isVisible={ isVisibleSpinner }
            />
          )
      }
    />
  );
};

export default ActionsCell;
