import React, { useState } from 'react';
import { ArchiveByOptions } from '@/condor/domain/vo/types';
import { Modal, RadioGroup, Radio, Spinner } from '@dealroadshow/uikit';
import { Button } from '@/Framework/UI/Atoms/Button/Button';
import { variantTypes } from '@/Framework/UI/Atoms/Button';

import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './styles.scss';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';

const ArchiveByLabels = {
  [ArchiveByOptions.Cusip]:
      'All existing and future trades with this CUSIP will be automatically archived',
  [ArchiveByOptions.Issuer]:
      'All existing and future trades with any CUSIP of this Issuer will be automatically archived',
};

const ArchiveModal = ({ archived, submitClick, cancelClick, isEmptyIssuerName }) => {
  const { isFetching } = useTraceContext();
  const [archiveBy, setArchiveBy] = useState(ArchiveByOptions.Cusip);

  return (
    <Modal
      title={ `${ archived ? 'Restore' : 'Archive' } Trade` }
      isVisible
      handleCloseClick={ cancelClick }
      dataTest="traceManagerTraceTableArchiveModal"
      modalBodyClassName={ styles.modalBody }
      className={ styles.modalWrapper }
      footer={ (
        <>
          <Button
            dataTest="traceManagerTraceTableArchiveModalSubmit"
            onClick={ () => submitClick(archiveBy) }
            variant={ variantTypes.action }
            title={ archived ? 'Restore' : 'Archive' }
            disabled={ isFetching || (isEmptyIssuerName && archiveBy === ArchiveByOptions.Issuer) }
          />
          <Button
            dataTest="traceManagerTraceTableArchiveModalClose"
            onClick={ cancelClick }
            variant={ variantTypes.text }
            title="Cancel"
          />
        </>
      ) }
    >
      { isFetching && <Spinner overlay /> }
      { archived ? (
        <p>Are you sure you want to restore this trade? This action will
          restore all existing TRACE trades with this CUSIP. Additionally,
          future trades with this CUSIP will no longer be archived.
        </p>
      ) : (
        <>
          <p>Are you sure you want to archive this trade?
            You may archive this trade and all future TRACE trades with this CUSIP or with this exact Issuer.
          </p>
          <RadioGroup selectedValue={ archiveBy } onChange={ setArchiveBy } className={ spacesStyles.pbn }>
            { Object.values(ArchiveByOptions).map((option) => (
              <Radio
                key={ option }
                value={ option }
                dataTest={ option }
                label={ ArchiveByLabels[option] }
                className={ spacesStyles.pbm }
              />
            )) }
          </RadioGroup>
        </>
      ) }
    </Modal>
  );
};

export default ArchiveModal;
