import React, { useCallback, useEffect } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';

import { checkUserPlanMatchRequired } from '@/finsight/application/User/UserPlan/helpers';
import { UserPlanType } from '@/users/domain/UserPlan';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Spinner, CheckboxInput } from '@dealroadshow/uikit';
import { CreatableSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import CheckEmailsSubscriptionContextProvider, {
  useCheckEmailsSubscriptionContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';
import {
  setEmailsFromChecked,
} from '@/condor/ui/components/FinsightManager/ManagePlans/Subscribers/AddSubscribersModal/helpers';
import { SubscriptionsData } from '@/condor/domain/vo/Finsight/Subscriptions/SubscriptionsData';
import SubscriptionSubGroup from './SubscriptionSubGroup';

import { emailRegexp } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';

import styles from './SubscriptionsForm.scss';

interface IProps {
  isSubmitting: boolean,
  submitForm: (values) => void,
  validate: (values, subscriptionsData) => void,
  formName: string,
  initialValues: object,
  subscriptionsData: SubscriptionsData[],
  withUnsubscribe: boolean,
  onCancel: () => void,
  withEmailSelect: boolean,
  submitButtonTitle: string,
  email?: string,
}

const SubscriptionsForm = ({
  isSubmitting,
  submitForm,
  initialValues,
  subscriptionsData,
  validate,
  formName,
  withUnsubscribe = true,
  onCancel = noop,
  submitButtonTitle = 'Save Preferences',
  withEmailSelect = false,
  email,
}: IProps) => {
  const { checkNewEmails, checkedEmails, isChecking } = useCheckEmailsSubscriptionContext();

  useEffect(() => {
    if (email) {
      checkNewEmails([{ isValid: true, label: email, value: email }]);
    }
  }, [email]);

  const validateWithData = useCallback((values) => {
    if (values?.subscribersListSelect) {
      const emails = values?.subscribersListSelect.map((email) => ({ ...email, isValid: true }));
      checkNewEmails(emails);
    }

    validate(values, subscriptionsData);
  }, [subscriptionsData]);

  const renderFields = useCallback(
    ({ values, invalid, pristine }, { Field }) => {
      const selectedEmails = setEmailsFromChecked(email
        ? [{ isValid: true, label: email, value: email }]
        : values?.subscribersListSelect, checkedEmails);
      const selectedEmailsPlans = selectedEmails.map(({ userPlan }) => userPlan || UserPlanType.STARTER);

      return (
        <>
          <Spinner
            overlay
            isAbsolute
            isVisible={ isSubmitting || isChecking }
          />
          { withEmailSelect && (
            <Field
              name="subscribersListSelect"
              component={ CreatableSelect }
              placeholder="Type Email address here (required)"
              noOptionsMessage={ () => null }
              formatCreateLabel={ (label) => `Create a "${ label }" by Enter` }
              isValidNewOption={ (label) => emailRegexp.test(label) }
              components={ { DropdownIndicator: () => null } }
              isMulti
              dataTest="subscribersListReduxFormSelect"
            />
          ) }
          {
            subscriptionsData.map((dataItem) => (
              <div
                key={ dataItem.groupId || dataItem.id }
                className={ cn(styles.subscriptionsBlock, {
                  [styles.overlayVisible]: values.unsubscribeFromAll,
                }) }
              >
                <h3 className={ styles.heading }>{ dataItem.groupName || dataItem.name }</h3>
                {
                  dataItem.subgroups.map((subgroup) => (
                    <SubscriptionSubGroup
                      key={ subgroup.id }
                      subgroup={ subgroup }
                      isDisabled={
                        email
                          ? !checkUserPlanMatchRequired(selectedEmailsPlans, dataItem.groupRequiredPlan)
                          : selectedEmailsPlans.some(
                            (plan) => !checkUserPlanMatchRequired(plan, dataItem.requiredPlan),
                          )
                      }
                    />
                  ))
                }
              </div>
            ))
          }
          { withUnsubscribe && (
            <div>
              <h3 className={ styles.heading }>Unsubscribe All</h3>
              <div className={ styles.tileRow }>
                <Field
                  type="checkbox"
                  name="unsubscribeFromAll"
                  dataTest="unsubscribeFromAll"
                  label="Unsubscribe me from all FINSIGHT communications"
                  className={ styles.checkboxItem }
                  component={ CheckboxInput }
                />
              </div>
            </div>
          ) }
          <div className={ styles.footer }>
            <Button
              type="submit"
              title={ submitButtonTitle }
              variant={ variantTypes.action }
              disabled={ invalid || pristine || isSubmitting }
              dataTest="submitButton"
            />
            <Button
              type="button"
              title="Cancel"
              dataTest="cancelButton"
              variant={ variantTypes.link }
              onClick={ onCancel }
              className={ styles.cancelBtn }
              disabled={ isSubmitting }
            />
          </div>
        </>
      );
    }, [subscriptionsData, isSubmitting, checkedEmails, email, isChecking],
  );

  return (
    <div className={ styles.content }>
      <FinalForm
        dataTest={ formName }
        onSubmit={ submitForm }
        render={ renderFields }
        initialValues={ initialValues }
        validate={ validateWithData }
        name={ formName }
      />
    </div>
  );
};

export default (props) => (
  <CheckEmailsSubscriptionContextProvider>
    <SubscriptionsForm { ...props } />
  </CheckEmailsSubscriptionContextProvider>
);
