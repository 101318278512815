import getImageDimensions from '@/Framework/dom/getImageDimensions';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';

export interface IParams {
  url: string,
  fieldName: string,
  fieldCaption?: string,
  minWidth?: number,
  minHeight?: number,
  maxWidth?: number,
  maxHeight?: number,
}

export const validateImageDimensions = async (params: IParams): Promise<IValidationError> => {
  const {
    url,
    fieldName,
    fieldCaption,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  } = params;

  const dimensions = await getImageDimensions(url);

  if (minWidth && (dimensions.width < minWidth)) {
    return { [fieldName]: `${ fieldCaption || 'Image' } width must be more than ${ minWidth }px` };
  }

  if (minHeight && (dimensions.height < minHeight)) {
    return { [fieldName]: `${ fieldCaption || 'Image' } height must be more than ${ minHeight }px` };
  }

  if (maxWidth && (dimensions.width > maxWidth)) {
    return { [fieldName]: `${ fieldCaption || 'Image' } width must be less than ${ maxWidth }px` };
  }

  if (maxHeight && (dimensions.height > maxHeight)) {
    return { [fieldName]: `${ fieldCaption || 'Image' } height must be less than ${ maxHeight }px` };
  }

  return null;
};
