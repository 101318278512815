import { connect } from 'react-redux';

import selectors from '@/condor/application/actions/accounts/mappings/contacts/selectors';
import {
  itemsPerPage,
  getMappingsContactsByFilter,
  paginate,
  sort,
  resetMappingsContactsCollection,
} from '@/condor/application/actions/accounts/mappings/contacts/actions';

import BaseMappingsPanel from '../BaseMappingsPanel';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import { contactNameCellCallback } from '../cellCallbacks/contactNameCellCallback';

const columns = [
  {
    name: 'firstName',
    title: 'Name',
    cellCallback: contactNameCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 320,
  },
  {
    name: 'email',
    title: 'Email',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
  },
];

const mapStateToProps = (state) => (
  {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    totalCount: selectors.getTotalCount(state),
    columns,
    dataTableDataTest: 'contactsMappingsTable',
    paginatorDataTest: 'contactsMappingsTablePaginator',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    getCollection: () => {
      dispatch(getMappingsContactsByFilter());
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getMappingsContactsByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getMappingsContactsByFilter());
    },
    reset: () => {
      dispatch(resetMappingsContactsCollection());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getMappingsContactsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseMappingsPanel);
