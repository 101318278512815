import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { ConnectedProps } from 'react-redux';
import { LAYOUT_CONTENT_WRP_ID, NAV_COLORS_CLS } from '@/Framework/UI/Templates/AppNavigation/constants';
import AppNavigationLogo from '@/Framework/UI/Templates/AppNavigation/AppNavigationLogo';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import Avatar from '@/Framework/UI/Organisms/ProfileWidget/Avatar';
import Menu from '@/Framework/UI/Organisms/ProfileWidget/Menu';
import { IUser } from '@/users/domain/vo/User';
import isDefined from '@/Framework/dataHelpers/isDefined';
import { LogoProduct, PlacementTypes, Icon, IconType } from '@dealroadshow/uikit';
import styles from './appNavigation.scss';
import { isScreenS } from '@dealroadshow/uikit/dist/lib/styles/screen/screen';
import throttle from 'lodash/throttle';
import screenVariables from '@dealroadshow/uikit/dist/lib/styles/screen/screen.scss';

interface IProps {
  children: React.ReactNode,
  logoComponent?: React.ComponentType,
  logoProduct?: LogoProduct,
  mobileLogoComponent?: React.ComponentType,
  contentComponent: React.ReactElement | ConnectedProps<any>,
  footerComponent?: React.ReactElement,
  toggleSidebar?: () => void,
  toggleMobileMenu?: () => void,
  isSidebarCollapsed?: boolean,
  isMobileMenuOpen?: boolean,
  onSidebarChange?: () => void,
  onMobileMenuChange?: () => void,
  currentUser: IUser,
  logoutUrl?: string,
  userMenuPlacement?: PlacementTypes,
  logoutAction?: () => void,
  isNext?: boolean,
}
const RESIZE_THROTTLE_DELAY = 100;

const parseGradient = (gradientString) => {
  const gradientValues = gradientString.substring(gradientString.indexOf('(') + 1, gradientString.lastIndexOf(')'));

  const [uncle, primaryColor, secondaryColor] = gradientValues.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/);

  return { uncle, primaryColor: primaryColor?.split(' ')[1], secondaryColor: secondaryColor?.split(' ')[1] };
};

const AppNavigation = (
  {
    children,
    logoComponent: LogoComponent,
    mobileLogoComponent: MobileLogoComponent,
    contentComponent: ContentComponent,
    footerComponent: FooterComponent,
    toggleSidebar,
    toggleMobileMenu,
    isSidebarCollapsed,
    isMobileMenuOpen,
    logoProduct = LogoProduct.FINSIGHT,
    onSidebarChange = noop,
    onMobileMenuChange = noop,
    currentUser,
    logoutUrl,
    userMenuPlacement,
    logoutAction,
    isNext,
  }: IProps,
) => {
  const checkScreenToCollapse = () => window.innerWidth <= parseInt(screenVariables.screen1126);
  const [isMobile, setIsMobile] = useState(isScreenS());
  const [isSidebarCollapsedState, setSidebarCollapsedState] = useState<boolean>(checkScreenToCollapse());
  const [isMobileMenuOpenState, setMobileMenuOpenState] = useState<boolean>(false);
  const [backgroundGradient, setBackgroundGradient] = useState<string>(styles.defaultBackgroundColor);
  const [renderedBackgroundGradient, setRenderedBackgroundGradient] = useState<string>(styles.defaultBackgroundColor);
  const [isGradientChanged, setIsGradientChanged] = useState(false);

  const resizeHandler = useCallback(
    throttle(() => {
      setIsMobile(isScreenS());
      setSidebarCollapsedState(checkScreenToCollapse());
    }, RESIZE_THROTTLE_DELAY),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [checkScreenToCollapse]);

  useEffect(() => {
    isDefined(isSidebarCollapsed) && setSidebarCollapsedState(isSidebarCollapsed);
  }, [isSidebarCollapsed]);

  useEffect(() => {
    isDefined(isMobileMenuOpen) && setMobileMenuOpenState(isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const toggleCurrentSidebar = () => {
    onSidebarChange();
    if (!isDefined(isSidebarCollapsed) && toggleSidebar) {
      toggleSidebar();
    } else {
      setSidebarCollapsedState(!isSidebarCollapsedState);
    }
  };

  const toggleCurrentMobileMenu = () => {
    onMobileMenuChange();
    if (!isDefined(isMobileMenuOpen) && toggleMobileMenu) {
      toggleMobileMenu();
    } else {
      setMobileMenuOpenState(!isMobileMenuOpenState);
    }
  };

  const handleChangeBackgroundColor = (gradient) => {
    const selectedGradient = gradient || styles.defaultBackgroundColor;

    if (selectedGradient === backgroundGradient) return;
    setIsGradientChanged(false);

    const { uncle, primaryColor, secondaryColor } = parseGradient(selectedGradient);
    const {
      primaryColor: previousPrimaryColor,
      secondaryColor: previousSecondaryColor,
    } = parseGradient(backgroundGradient);

    setRenderedBackgroundGradient(`
      linear-gradient(
      ${ uncle },
      ${ previousPrimaryColor },
      ${ previousSecondaryColor },
      ${ primaryColor },
      ${ secondaryColor }
    )`);
    setBackgroundGradient(selectedGradient);

    setTimeout(() => {
      setIsGradientChanged(true);
    }, 100);
  };

  return (
    <>
      <div dangerouslySetInnerHTML={
        { __html: `<style>.${ NAV_COLORS_CLS } { background: ${ renderedBackgroundGradient } 100% 50%; background-size: 400% 400%; }</style>` }
      }
      />
      <div
        data-test="appNavigation"
        className={ cn(
        styles.container,
        NAV_COLORS_CLS,
        { [styles.isCollapsed]: isSidebarCollapsedState },
        { [styles.isMobileMenuOpen]: isMobileMenuOpenState },
        { [styles.gradientChanged]: isGradientChanged },
      ) }
      >
        <div className={ cn(
          styles.header,
          { [NAV_COLORS_CLS]: isMobile },
          styles.headerMobile,
          { [styles.gradientChanged]: isGradientChanged },
        ) }
        >
          <AppNavigationLogo
            LogoComponent={ LogoComponent }
            MobileLogoComponent={ MobileLogoComponent }
            logoProduct={ logoProduct }
          />
          <button
            type="button"
            className={ cn(styles.headerButton, styles.toggleMobileMenuButton) }
            onClick={ toggleCurrentMobileMenu }
            data-test="appNavigationToggleMobileMenuButton"
          >
            <Icon type={ isMobileMenuOpenState ? IconType.close : IconType.hamburgerMenu } />
          </button>
          <div className={ styles.collapsePosition }>
            <button
              type="button"
              className={ cn(styles.headerButton, styles.collapseButton) }
              onClick={ toggleCurrentSidebar }
              data-test="appNavigationToggleSidebarButton"
            >
              <Icon
                type={ isSidebarCollapsedState ? IconType.doubleArrowsRight : IconType.doubleArrowsLeft }
                className={ styles.collapseIcon }
              />
            </button>
          </div>
        </div>
        <div className={ cn(styles.sidebarItemsContainer, { [styles.isMobileMenuOpen]: isMobileMenuOpenState }) }>
          <div className={ styles.navigationItemsContainer }>
            { ContentComponent && (
              // @ts-ignore
              <ContentComponent
                isSidebarCollapsed={ isSidebarCollapsedState }
                isMobileMenuOpen={ isMobileMenuOpenState }
                changeSidebarColor={ handleChangeBackgroundColor }
                toggleSidebar={ toggleCurrentSidebar }
                toggleMobileMenu={ toggleCurrentMobileMenu }
              />
            ) }
          </div>
        </div>
        <div
          className={ cn(styles.userProfileWrapper, {
            [styles.withoutText]: isSidebarCollapsedState,
          }) }
        >
          {
            FooterComponent &&
            (
              // @ts-ignore
              <FooterComponent
                isSidebarCollapsed={ isSidebarCollapsedState }
                isMobileMenuOpen={ isMobileMenuOpenState }
                toggleSidebar={ toggleCurrentSidebar }
                toggleMobileMenu={ toggleCurrentMobileMenu }
              />
            )
          }
          {
            !FooterComponent && currentUser &&
            (
              <Menu
                logoutUrl={ logoutUrl }
                placement={ userMenuPlacement }
                logoutAction={ logoutAction }
                popoverContentClassName={ styles.menuPopoverContent }
                arrowClassName={ styles.menuArrowPopover }
                isNext={ isNext }
              >
                <Avatar
                  // @ts-ignore
                  email={ currentUser.email }
                  firstName={ currentUser.firstName }
                  lastName={ currentUser.lastName }
                  nameClass={ styles.avatarName }
                />
              </Menu>
            )
          }
        </div>
      </div>
      { children && (
        <ContentWrp className={ cn(
          styles.pageContent,
          { [styles.isCollapsed]: isSidebarCollapsedState },
        ) }
        >
          <div id={ LAYOUT_CONTENT_WRP_ID }>
            { children }
          </div>
        </ContentWrp>
      ) }
    </>
  );
};

export default React.memo(AppNavigation);
