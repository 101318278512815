import React from 'react';
import cn from 'classnames';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import headerStyles from '@dealroadshow/uikit/dist/lib/styles/headers.scss';

interface IProps {
  label?: string,
  className?: string,
  labelCls?: string,
  headerCls?: string,
  contentCls?: string,
  headerComponent?: React.ReactNode,
  navigationComponent?: React.ReactNode,
  children: React.ReactNode,
}

const PageWrapper = (
  {
    className,
    label,
    headerComponent,
    headerCls,
    labelCls,
    navigationComponent,
    contentCls,
    children,
  }: IProps,
) => (
  <div className={ className }>
    {
      (label || headerComponent) && (
        <div className={ cn(pageStyles.pageHeader, spacesStyles.mbl, headerCls) }>
          <div
            className={ cn(pageStyles.pageHeaderTitle, headerStyles.isH2, labelCls) }
            data-test="pageHeaderTitle"
          >
            { label }
          </div>
          { headerComponent && (headerComponent) }
        </div>
      )
    }
    { navigationComponent && navigationComponent }
    <div className={ cn(cardStyles.cardContainer, spacesStyles.mbn, contentCls) }>
      { children }
    </div>
  </div>
);

export default PageWrapper;
