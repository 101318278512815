import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { components as ReactSelectComponents, OptionProps } from 'react-select';
import { AsyncSelect, Tooltip } from '@dealroadshow/uikit';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import {
  DealsContextProvider,
  useDealsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { IOptionDeal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

import styles from './styles.scss';

const DealSelect = ({
  input,
  meta,
  ...otherProps
}: IFinalFormFieldInputComponentProps) => {
  const { products } = useRegionsProductsContext();
  const { loadDealsOptions, currentDealsOptions, isDisabled } = useDealsContext();
  const productMap = useMemo(() => keyBy(products, 'id'), [products]);
  const textTooltip = 'Input is disabled until a theme is selected. Please note that some deals may not appear ' +
                      'in the list if they do not match the selected product or region.';

  const Option = ({ children, ...props }: OptionProps<IOptionDeal>) => (
    <ReactSelectComponents.Option { ...props } className={ styles.dealOptionContainer }>
      <span className={ styles.badge }>{ productMap[props.data.productId].abbreviation }</span>
      { children }
    </ReactSelectComponents.Option>
  );

  return (
    <Tooltip content={ textTooltip } disabled={ !isDisabled } containerClassName={ styles.dealTooltipContainer }>
      <AsyncSelect
        { ...otherProps }
        name={ input.name }
        value={ input.value }
        onBlur={ input.onBlur }
        onChange={ input.onChange }
        meta={ meta }
        label="Deals"
        dataTest="deals"
        placeholder="Select related deals"
        loadOptions={ (query, callback) => {
          loadDealsOptions(query, callback);
        } }
        defaultOptions={ currentDealsOptions }
        components={ { Option } }
        hideSelectedOptions={ false }
        closeMenuOnSelect={ false }
        isDisabled={ isDisabled }
        isMulti
        isUsePortal
        isNarrow={ isDisabled }
        filterOption={ () => true }
        noOptionsMessage={ () => 'You need to enter at least 3 letters' }
      />
    </Tooltip>
  );
};

export default (props: IFinalFormFieldInputComponentProps) => (
  <DealsContextProvider>
    <DealSelect { ...props } />
  </DealsContextProvider>
);
