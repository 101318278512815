import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { isSameDay } from 'date-fns';
import { getDisplayDate } from '@/Framework/UI/Molecules/Tables/CellCallbacks/LastLoginAt/helpers';
import { IDatetime } from '@/Framework/DateTime/Datetime';

export function getCellDisplayDate({ timezone, dateTime }: IDatetime) {
  const dateMoment = utcToZonedDate(timezone.timeZone, new Date(dateTime));
  const isToday = isSameDay(new Date(dateTime), utcToZonedDate(timezone.timeZone));
  const displayDate = getDisplayDate(dateMoment, timezone.timeZone);

  return isToday ? displayDate.today : displayDate.day;
}
