import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import styles from '../filings.scss';
import { setSearchQuery, getFilingsByFilter, paginate } from '@/condor/application/actions/finsight/filings/collection/actions';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';

import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
    formClassName: styles.searchInput,
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const searchDebounced = debounce(() => dispatch(getFilingsByFilter(ownProps.types)), 200);

  return {
    onChange: ({ target }) => {
      dispatch(setSearchQuery(target.value));
      searchDebounced();
      dispatch(paginate(1));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
