import React from 'react';
import { Field } from 'redux-form';
import { Input } from '@dealroadshow/uikit';
import Product from '@/finsight/domain/Product';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import { APPLICATION_WRP_ID } from '@/Framework/UI/Templates/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';

interface IProps {
  sectorsOptions: any[],
  tableRef: any,
  currentProduct: any,
}

const IndustryOrSectorField = ({ currentProduct, sectorsOptions, tableRef }: IProps) => {
  if (!Product.isAbsProductId(currentProduct?.value)) {
    return (
      <Field
        name="industry"
        disabled
        isNarrow
        component={ Input }
        dataTest="industrySectorInput"
      />
    );
  }
  return (
    <Field
      name="sector"
      isNarrow
      usePortal
      component={ ReduxFormSelect }
      options={ sectorsOptions }
      bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
      scrollableParentElements={ [tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
      dataTest="sectorSectorInput"
    />
  );
};

export default IndustryOrSectorField;
