import React from 'react';
import { NavLink } from 'react-router-dom';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import { Tooltip, StatusIndicator, StatusIndicatorType } from '@dealroadshow/uikit';
import DateCell from '@/Framework/UI/Molecules/Tables/CellCallbacks/DateCell';
import byteConverter from '@/Framework/dataHelpers/formatters/byteConverter';
import styles from './dataroomsList.scss';

export const columns = (tenant) => ([
  {
    name: 'status',
    title: 'Status',
    className: alignStyles.alignCenter,
    width: 30,
    cellCallback: (cellProps) => (
      <Tooltip
        placement="bottom"
        content={ cellProps.row.isActive ? 'Live' : 'Deactivated' }
        className={ styles.hideTooltipOnMobile }
      >
        <StatusIndicator status={ cellProps.row.isActive ? StatusIndicatorType.live : StatusIndicatorType.expired } />
      </Tooltip>
      ),
  },
  {
    name: 'displayName',
    title: 'Data Room Name',
    sortable: true,
    cellCallback: ({ row }) => (
      <NavLink to={ `/core/${ tenant }/${ row.id }/details` }>
        { row.displayName }
      </NavLink>
    ),
  },
  {
    name: 'accounts',
    className: alignStyles.alignRight,
    sortable: true,
    title: 'Accounts',
    width: 107,
  },
  {
    name: 'contacts',
    className: alignStyles.alignRight,
    sortable: true,
    title: 'Contacts',
    width: 92,
  },
  {
    name: 'size',
    className: alignStyles.alignRight,
    sortable: true,
    title: 'Size',
    width: 99,
    cellCallback: ({ cellData }) => byteConverter(cellData),
  },
  {
    name: 'createdAt',
    sortable: true,
    className: alignStyles.alignRight,
    title: 'Date Created',
    width: 110,
    cellCallback: ({ row }) => (
      <DateCell
        date={ row.createdAt }
        withoutFormat
        withoutAbbr
        withoutTime
        isUtc
      />
    ),
  },
]);
