import {
  useArticlesActionsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesList/ArticlesActionsContext';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import ArticleActionModal from '../../ArticleActionModal';

const ArticlesTableModals = () => {
  const {
    isModalOpen,
    article,
    modalType,
    closeModal,
    isRequestPerforming,
    handleDeleteArticle,
    handleSendEmail,
  } = useArticlesActionsContext();

  if (!isModalOpen || !modalType) {
    return null;
  }

  const isNeedToShowEmailHistory = ActionModalType.RESEND_EMAIL === modalType && !!article.mailingRequests.length;
  const handleAction = modalType === ActionModalType.DELETE ? handleDeleteArticle : handleSendEmail;

  return (
    <ArticleActionModal
      isModalOpen={ isModalOpen }
      modalType={ modalType }
      article={ article }
      isNeedToShowEmailHistory={ isNeedToShowEmailHistory }
      isRequestPerforming={ isRequestPerforming }
      closeModal={ closeModal }
      handleAction={ handleAction }
    />
  );
};

export default ArticlesTableModals;
