import cn from 'classnames';
import {
  TextEditor,
  ITextEditorProps,
  FormField,
  FormError,
  IFinalFormFieldInputComponentProps,
} from '@dealroadshow/uikit';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import styles from './styles.scss';

export interface ITextEditorFieldProps extends ITextEditorProps {
  dataTest: string,
  label?: string,
  formFieldClassName?: string,
  className?: string,
  meta?: {
    dirty?: boolean,
    touched?: boolean,
    error?: string,
    submitError?: boolean,
  },
  isNarrow?: boolean,
  isErrorMessage?: boolean,
}

type TTextEditorFieldProps = ITextEditorFieldProps & IFinalFormFieldInputComponentProps<string>;

const TextEditorField = ({
  input,
  meta,
  label,
  formFieldClassName,
  className,
  isNarrow = false,
  isErrorMessage = true,
  dataTest,
  ...otherProps
}: TTextEditorFieldProps) => {
  return (
    <FormField
      className={ cn(styles.textEditorFieldContainer, formFieldClassName) }
      isNarrow={ isNarrow }
      isValidationFeedback={ !!(meta && meta.touched && meta.error) }
      dataTest={ dataTest }
    >
      <div className={ className }>
        { label && (
          <label className={ formStyles.formLabel } htmlFor={ input.name }>
            { label }
          </label>
        ) }
        <TextEditor
          onChange={ input.onChange }
          fieldName={ input.name }
          value={ input.value }
          containerClassName={ styles.textEditorContainer }
          { ...otherProps }
        />
        { isErrorMessage && <FormError { ...meta } /> }
      </div>
    </FormField>
  );
};

export default TextEditorField;
