import { History } from '@/Framework/DI/Providers/History';
import createAction from '@/Framework/State/Redux/createAction';
import { editUser } from '@/users/application/actions/data/actions';
import { isPhoneEntered } from '@/Framework/UI/Organisms/FinalForm/validators/user/validatePhoneNumber';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';
import { CONDOR_CONTACTS_LIST_BASE_URL } from '@/condor/ui/common/constants';

export const {
  setCurrentItem: setCurrentContactItem,
  reset: resetContactItem,
} = getItemActions(actionTypes);

export const submitContactProfile = (formData) => async (dispatch, getState) => {
  const history = getState().container.get(History);

  const payload = {
    activated: formData.activated,
    banned: formData.banned,
    companyId: (typeof formData.companyId === 'object') && formData.companyId ? formData.companyId.value : formData.companyId,
    emailVerified: formData.emailVerified,
    firstName: formData.firstName,
    id: formData.id,
    lastName: formData.lastName,
    titleId: (typeof formData.titleId === 'object') && formData.titleId ? formData.titleId.value : formData.titleId,
    workPhone: isPhoneEntered(formData.officePhone) ? formData.officePhone?.value : null,
    personalPhone: isPhoneEntered(formData.personalPhone) ? formData.personalPhone?.value : null,
    jurisdictionCountryId: formData.jurisdiction.value,
    bloombergEmail: formData.bloombergEmail,
    linkedinProfile: formData.linkedinProfile,
  };

  const callbacks = {
    onSuccess: () => history.push(CONDOR_CONTACTS_LIST_BASE_URL),
  };

  const editContacts = editUser(payload, callbacks);
  await dispatch(editContacts(dispatch, getState));
};

export const setCondorContactsIsInlineEditing = (payload) => createAction(
  actionTypes.SET_CONDOR_CONTACTS_IS_INLINE_EDITING,
  payload,
);
