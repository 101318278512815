import React from 'react';
import cn from 'classnames';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { Spinner, DataTable, dataTableStyles, Paginator } from '@dealroadshow/uikit';
import Filters from './Filters';
import columns from './columns';
import SubscribersContextProvider, { useSubscribersContext } from '@/condor/application/FinsightManager/Subscribers';
import ManageSubscriptionModal from './ManageSubscriptionModal';
import AddSubscribersModal from './AddSubscribersModal';
import {
  ManageSubscriptionFormContextProvider,
} from '@/condor/application/FinsightManager/ManagePlans/ManageSubscriptionFormContext';
import AddSubscribersContextProvider from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm';
import CheckEmailsSubscriptionContextProvider
  from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';

import commonStyles from '../common/common.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import styles from './subscribers.scss';

const Subscribers = () => {
  const {
    collection,
    paginate,
    setItemsPerPage,
    page,
    perPage,
    totalCount,
    sortOrder,
    sortBy,
    sort,
    isFetching,
  } = useSubscribersContext();

  return (
    <>
      <TableWrapper
        label="Subscribers"
        filterComponent={ <Filters /> }
        contentClassName={ commonStyles.tableWrapper }
      >
        <DataTable
          isFetching={ isFetching }
          loadingComponent={ Spinner }
          className={ cn(dataTableStyles.isHoverable, styles.subscribersTable) }
          columns={ columns }
          data={ collection }
          dataTest="finsightManagePlansSubscribersTable"
          sortBy={ sortBy }
          sortOrder={ sortOrder }
          onSortChange={ sort }
        />
        <Paginator
          page={ page }
          onPageChange={ (page) => paginate(page.selected) }
          perPage={ perPage }
          onItemsPerPageChange={ (perPage) => setItemsPerPage(perPage.value) }
          totalCount={ totalCount }
          className={ cardStyles.cardInner }
          isItemsPerPageVisible
          dataTest="finsightManagePlansSubscribersPaginator"
        />
      </TableWrapper>
      <ManageSubscriptionModal />
      <AddSubscribersModal />
    </>
  );
};

export default () => (
  <SubscribersContextProvider>
    <ManageSubscriptionFormContextProvider>
      <CheckEmailsSubscriptionContextProvider>
        <AddSubscribersContextProvider>
          <Subscribers />
        </AddSubscribersContextProvider>
      </CheckEmailsSubscriptionContextProvider>
    </ManageSubscriptionFormContextProvider>
  </SubscribersContextProvider>
);
