import { FormField, IconType, Input } from '@dealroadshow/uikit';
import getEndOfDay from 'date-fns/endOfDay';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import DatePicker from '@/Framework/UI/Molecules/Form/DatePicker/DatePicker';
import { MONTH_DAY_YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { useEventFormContext } from '@/openMarket/ui/components/Condor/Events/EventForm/EventFormContext';
import { FieldsLabel, FieldsNames } from '../../constants';

const defaultTimezone = 'America/New_York';

const PricingDateField = () => {
  const { event } = useEventFormContext();

  const timezone = event?.expectedPricingDate?.timezone.timeZone ?? defaultTimezone;
  const minDate = utcToZonedDate(timezone, new Date());

  return (
    <Field
      name={ FieldsNames.EXPECTED_PRICING_DATE }
      component={ ({ meta, input }) => {
        const value = input.value ? utcToZonedDate(timezone, input.value) : null;

        return (
          <FormField>
            <DatePicker
              placeholderText="Select Date"
              dateFormat={ MONTH_DAY_YEAR_FORMAT }
              minDate={ minDate }
              selected={ value }
              onChange={ (date: Date | null) => {
                if (!date) {
                  return input.onChange(null);
                }

                const endOfDay = getEndOfDay(date);
                const zonedDate = getZonedUTCDate(timezone, endOfDay);

                return input.onChange(zonedDate);
              } }
              dataTest="datepicker"
              onBlur={ input.onBlur }
              customInput={ (
                <Input
                  label={ FieldsLabel.EXPECTED_PRICING_DATE }
                  // @ts-ignore
                  input={ { name: input.name } }
                  meta={ meta }
                  iconType={ IconType.calendar }
                  iconPosition="right"
                  isReadOnly
                  isClearable
                  isNarrow
                  dataTest="pricingDateField"
                />
              ) }
            />
          </FormField>
        );
      } }
    />
  );
};

export default PricingDateField;
