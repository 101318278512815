import React from 'react';
import UserPlanTag from '@/Framework/UI/Atoms/UserPlanTag';
import {
  useManageSubscriptionFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ManageSubscriptionFormContext';

import styles from './styles.scss';

const UserPanel = () => {
  const { data } = useManageSubscriptionFormContext();
  const { firstName, lastName, email, account, plan } = data;

  return (
    <div className={ styles.userPanelContainer }>
      <div className={ styles.userPanelItem }>
        <span className={ styles.userPanelFieldName }>Subscriber</span>
        <span className={ styles.userPanelFieldValue }>{ (firstName || lastName) ? `${ firstName || '' } ${ lastName || '' }` : '-' }</span>
      </div>
      <div className={ styles.userPanelItem }>
        <span className={ styles.userPanelFieldName }>Email</span>
        <span className={ styles.userPanelFieldValue }>{ email }</span>
      </div>
      <div className={ styles.userPanelItem }>
        <span className={ styles.userPanelFieldName }>Account</span>
        <span className={ styles.userPanelFieldValue }>{ account || '-' }</span>
      </div>
      <div className={ styles.userPanelItem }>
        <span className={ styles.userPanelFieldName }>Current plan</span>
        <span>
          <UserPlanTag userPlan={ plan } />
        </span>
      </div>
    </div>
  );
};

export default UserPanel;
