import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { Filter, Search } from '@/condor/ui/common/FilterGroup';
import { TIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { format } from 'date-fns';
import TimeZoneAbbrContainer from './TimeZoneAbbrContainer';
import { Spinner, Modal, DataTable, Paginator, SortOrder } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import {
  TableFilterInterface,
} from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealFiles/reducers/tableFilter';
import { ITimeZone } from '@/evercall/domain/vo/TimeZone';
import { IAnalyticItem } from '@/condor/domain/vo/Dealroadshow/Analytics';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import textStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/text.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';

interface IProps {
  timeZone: ITimeZone,
  match: {
    params: {
      roadshowId: string,
    },
  },
  setAnalyticsTableFilterSearch: ({ value }: { value: string }) => void,
  setAnalyticsTableFilterPagination: ({ value }: { value: string }) => void,
  setAnalyticsTableFilterPerPage: ({ value }: { value: string }) => void,
  setAnalyticsTableSort: ({ sortBy, sortOrder }: { sortBy: string, sortOrder: SortOrder }) => void,
  getAnalytics: (id: string) => void,
  roadshowClearStats: ({ roadshowId, contactId }: { roadshowId: string, contactId: number }) => void,
  resetAnalytics: () => void,
  tableFilter: TableFilterInterface,
  analytics: IAnalyticItem[],
  isFetching: boolean,
  totalCount: number,
}

interface IState {
  deleteModalVisibleRowId: number | null,
}

class Analytics extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalVisibleRowId: null,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTableSortChange = this.handleTableSortChange.bind(this);
    this.cellActionCallback = this.cellActionCallback.bind(this);
    this.applyModal = this.applyModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
  }

  componentDidMount() {
    this.getAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.tableFilter, prevProps.tableFilter)) {
      this.getAnalytics();
    }
  }

  componentWillUnmount() {
    this.props.resetAnalytics();
  }

  getAnalytics() {
    this.props.getAnalytics(this.props.match.params.roadshowId);
  }

  getModal() {
    let { contactTitle } = this.props.analytics
      .filter((contact) => contact.contactId === this.state.deleteModalVisibleRowId)[0];
    let title = `Delete ${ contactTitle } from Analytics?`;
    return (
      <Modal
        onCloseClicked={ () => this.hideModal() }
        isVisible={ !!this.state.deleteModalVisibleRowId }
        title={ title }
        dataTest="deleteFromAnalyticsModal"
        footer={ (
          <div>
            <Button
              variant={ variantTypes.success }
              onClick={ () => this.applyModal() }
              title="Delete"
              dataTest="analyticsDeleteButton"
            />
            <Button
              variant={ variantTypes.secondary }
              onClick={ () => this.hideModal() }
              title="Cancel"
              dataTest="analyticsCancelButton"
            />
          </div>
        ) }
      />
    );
  }

  handleFilterChange(type, value) {
    switch (type) {
      case 'search':
        this.props.setAnalyticsTableFilterSearch({ value });
        break;
      default:
        break;
    }
  }

  handleTableSortChange(sortBy, sortOrder) {
    this.props.setAnalyticsTableSort({ sortBy, sortOrder });
  }

  cellActionCallback(cellProps) {
    return (
      <Button
        variant={ variantTypes.action }
        onClick={ () => this.showModal(cellProps.row.contactId) }
        title="Delete"
        dataTest="analyticsShowModalButton"
      />
    );
  }

  showModal(contactId) {
    this.setState({
      deleteModalVisibleRowId: contactId,
    });
  }

  hideModal() {
    this.setState({
      deleteModalVisibleRowId: null,
    });
  }

  applyModal() {
    let contact = this.props.analytics
      .filter((contact) => contact.contactId === this.state.deleteModalVisibleRowId)[0];
    this.props.roadshowClearStats({
      roadshowId: this.props.match.params.roadshowId,
      contactId: contact.contactId,
    });
    this.hideModal();
  }

  /**
   * @param {Object} page
   */
  handlePageChange(page) {
    this.props.setAnalyticsTableFilterPagination({ value: page.selected });
  }

  /**
   * @param {Object} perPage
   */
  handleItemsPerPageChange(perPage) {
    this.props.setAnalyticsTableFilterPerPage({ value: perPage.value });
  }

  render() {
    const tableColumns = [
      {
        name: 'accountTitle',
        title: 'Company',
        sortable: true,
      },
      {
        name: 'contactTitle',
        title: 'Name',
        sortable: true,
      },
      {
        name: 'contactEmail',
        title: 'Corporate Email',
        sortable: true,
      },
      {
        name: 'lastLoginAt',
        title: (
          <span>Last Login <TimeZoneAbbrContainer /></span>
        ),
        width: 115,
        className: textStyles.nowrap,
        cellCallback: ({ cellData }) => {
          const lastLoginAt = utcToZonedDate(this.props.timeZone.timeZone, new Date(cellData.slice(0, -4)));
          const date = format(lastLoginAt, 'dd/MM/yyyy');
          const time = format(lastLoginAt, TIME_FORMAT);
          return (
            <div>
              <div>{ date }</div>
              <span className={ textStyles.smallText }>{ time }</span>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: 'action',
        title: 'Actions',
        className: alignStyles.alignCenter,
        cellCallback: this.cellActionCallback,
      },
    ];
    const tableFilter = {
      search: {
        placeholder: 'Filter by Company, Name or Email',
        debounceValue: 500,
      },
      paginationGroup: {
        perPage: {
          list: [50, 100, 200],
        },
      },
    };
    return (
      <PageWrapper>
        <TableWrapper
          label="Analytics"
          filterComponent={ (
            <Filter
              config={ tableFilter }
              state={ this.props.tableFilter }
              onChange={ this.handleFilterChange }
            >
              <Search />
            </Filter>
          ) }
        >
          <DataTable
            columns={ tableColumns }
            dataTest="roadshowAnalyticsDataTable"
            data={ this.props.analytics }
            loadingComponent={ Spinner }
            isFetching={ this.props.isFetching }
            sortBy={ this.props.tableFilter.sort.sortBy }
            sortOrder={ this.props.tableFilter.sort.sortOrder }
            onSortChange={ this.handleTableSortChange }
          />
          <Paginator
            className={ cardStyles.cardInner }
            totalCount={ this.props.totalCount }
            page={ this.props.tableFilter.paginationGroup.activePage }
            perPage={ this.props.tableFilter.paginationGroup.perPage }
            onItemsPerPageChange={ this.handleItemsPerPageChange }
            onPageChange={ this.handlePageChange }
            dataTest="dealRoadShowAnalyticsPaginator"
          />
        </TableWrapper>
        { this.state.deleteModalVisibleRowId && this.getModal() }
      </PageWrapper>
    );
  }
}

export default Analytics;
