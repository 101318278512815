import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { CheckboxInput, FormError, FormField, InputLabel, Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import { FieldsLabel, FieldsNames } from '../../constants';

import styles from './displayOnFields.scss';

const DisplayOnFields = () => {
  return (
    <Field
      name={ FieldsNames.DISPLAY_ON }
      render={ ({ meta }) => (
        <FormField>
          <InputLabel>{ FieldsLabel.DISPLAY_ON }</InputLabel>
          <div className={ styles.checkboxWrapper }>
            <Field
              name={ `${ FieldsNames.DISPLAY_ON }.finsight` }
              dataTest="displayOnFinsight"
              label={ (
                <span className={ styles.optionLabel }>
                  Display Event on Finsight.com
                  <Tooltip
                    wrpClassName={ styles.tooltip }
                    content="Enabling this option will display this event on the Profiles selected above on Finsight.com."
                  >
                    <Icon type={ IconType.info } className={ styles.infoIcon } />
                  </Tooltip>
                </span>
              ) }
              type="checkbox"
              component={ CheckboxInput }
            />
            <Field
              name={ `${ FieldsNames.DISPLAY_ON }.openMarket` }
              dataTest="displayOnOpenmarket"
              label={ (
                <span className={ styles.optionLabel }>
                  Display Event on OpenMarket Profile Pages
                  <Tooltip
                    wrpClassName={ styles.tooltip }
                    content="Enabling this option will display this event on the standalone OpenMarket Profile Pages for the Profiles selected above."
                  >
                    <Icon type={ IconType.info } className={ styles.infoIcon } />
                  </Tooltip>
                </span>
              ) }
              type="checkbox"
              component={ CheckboxInput }
            />
          </div>
          { meta.error && <FormError className={ styles.error } error={ meta.error } touched /> }
        </FormField>
      ) }
    />
  );
};

export default DisplayOnFields;
