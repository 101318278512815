import { Modal, Spinner } from '@dealroadshow/uikit';
import ArticlePreviewContextProvider, { useArticlePreviewContext }
  from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/ArticlePreviewContext';
import SectorIndustryLabel from '@/condor/ui/components/Finsight/CreditFlowResearch/common/SectorIndustryLabel';
import PreviewModalFooter from './PreviewModalFooter';
import AttachmentItem from './AttachmentItem';
import { getAuthor, getPreviewDate, getThemes } from './helpers';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';

import styles from './styles.scss';

const PreviewModal = () => {
  const { isEdit } = useArticleProfileContext();
  const {
    isFetching,
    previewData,
    closePreviewModal,
  } = useArticlePreviewContext();

  if (isFetching || !previewData) {
    return <Spinner isFixed isVisible overlay />;
  }

  const {
    sectorOrIndustry,
    subsectorOrSubindustry,
    headline,
    description,
    author,
    date,
    themes,
    attachments,
  } = previewData;
  return (
    <Modal
      className={ styles.previewModal }
      onCloseClicked={ closePreviewModal }
      isVisible
      title={ isEdit ? 'Updating Post' : 'Publishing New Post' }
      footer={ (<PreviewModalFooter />) }
    >
      <div className={ styles.articleMetadata }>
        <SectorIndustryLabel
          sectorOrIndustry={ sectorOrIndustry }
          subsectorOrSubindustry={ subsectorOrSubindustry }
        />
        { getAuthor(author) }
        { getPreviewDate(date) }
      </div>
      <p className={ styles.headline }>{ headline }</p>
      { getThemes(themes) }
      <div className={ styles.description }>
        <div dangerouslySetInnerHTML={ { __html: description } } />
      </div>
      { attachments.length > 0 && (
        <div>
          <p className={ styles.attachmentLabel }>Attachments</p>
          {
            attachments.map((attachment) => (
              <AttachmentItem key={ attachment.uploadId } attachment={ attachment } />
            ))
          }
        </div>
      ) }
    </Modal>
  );
};

export default ({ setIsPreviewOpen }) => (
  <ArticlePreviewContextProvider setIsPreviewOpen={ setIsPreviewOpen }>
    <PreviewModal />
  </ArticlePreviewContextProvider>
);
