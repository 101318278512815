import { History } from '@/Framework/DI/Providers/History';
import sleep from '@/Framework/dataHelpers/sleep';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import { CONDOR_ACCOUNT_TYPES_LIST_BASE_URL } from '@/condor/ui/common/constants';
import AccountTypeRepository from '@/users/infrastructure/repository/AccountTypeRepository';
import actionTypes from './actionTypes';

export const openAccountTypeListPage = () => (dispatch, getState) => {
  const history = getState().container.get(History);

  history.push(CONDOR_ACCOUNT_TYPES_LIST_BASE_URL);
};

export const {
  getItem: getAccountTypeById,
  createItem: createAccountType,
  updateItem: updateAccountType,
  reset: resetAccountType,
} = getItemActions(
  actionTypes,
  {
    get: {
      method: (payload, dispatch, getState) => {
        const accountTypeRepository: AccountTypeRepository = getState().container.get(AccountTypeRepository);
        return accountTypeRepository.getById(payload);
      },
    },
    create: {
      method: async (payload, dispatch, getState) => {
        const accountTypeRepository: AccountTypeRepository = getState().container.get(AccountTypeRepository);
        await accountTypeRepository.create(payload);
        await sleep(2500);
      },
      callbacks: {
        onSuccess: ({ dispatch }) => dispatch(openAccountTypeListPage()),
      },
    },
    update: {
      method: async (payload, dispatch, getState) => {
        const accountTypeRepository: AccountTypeRepository = getState().container.get(AccountTypeRepository);
        await accountTypeRepository.update(payload);
        await sleep(2500);
      },
      callbacks: {
        onSuccess: ({ dispatch }) => dispatch(openAccountTypeListPage()),
      },
    },
  },
);

/**
 * @param {Object} formData
 */
export const submitAccountTypeForm = (formData) => async (dispatch) => {
  if (formData.id) {
    await dispatch(updateAccountType(formData));
  } else {
    await dispatch(createAccountType(formData));
  }
};
