import React from 'react';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import MapTradeSearchInput from './MapTradeSearchInput';
import MapTradeItems from './MapTradeItems';
import { Button } from '@/Framework/UI/Atoms/Button/Button';
import { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Spinner, Modal, DataTable } from '@dealroadshow/uikit';
import columns from './mapTradeTablecolumns';

import styles from './styles.scss';

const MapTradeModal = () => {
  const { tradeToMap, setTradeToMap } = useTraceContext();

  if (tradeToMap) {
    return (
      <Modal
        title="Map Trade"
        isVisible
        handleCloseClick={ () => setTradeToMap(null) }
        dataTest="traceManagerTraceTableMapModal"
        modalBodyClassName={ styles.modalBody }
        footer={ (
          <Button
            dataTest="traceManagerTraceTableMapModalClose"
            onClick={ () => setTradeToMap(null) }
            variant={ variantTypes.action }
            title="Cancel"
          />
        ) }
      >
        <DataTable
          loadingComponent={ Spinner }
          dataTest="traceModalMapTable"
          columns={ columns }
          className={ styles.mapTableTrade }
          data={ [tradeToMap] }
        />
        <MapTradeSearchInput />
        <MapTradeItems />
      </Modal>
    );
  }

  return null;
};

export default MapTradeModal;
