import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateFieldsArrayForFinalForm } from '@/Framework/UI/Organisms/FinalForm/validators/validateFieldsArrayForFinalForm';
import { getRules as getDisplayTabsRules } from '../../fields/DisplayTabs/validation';
import { IFormValues } from '../../interfaces';
import { FormFieldName } from '../../constants';

export default (values: IFormValues) => {
  return groupValidators(
    validateRequiredField({
      fieldName: FormFieldName.HEADER_LOGO,
      fieldCaption: 'Header logo',
      value: values.headerLogo?.url,
    }),
    validateRequiredField({
      fieldName: FormFieldName.HEADER_DESCRIPTION,
      fieldCaption: 'Header description',
      value: values.headerDescription,
    }),
    validateMinLength({
      fieldName: FormFieldName.HEADER_DESCRIPTION,
      fieldCaption: 'Header description',
      value: values.headerDescription,
      length: 3,
    }),
    validateMaxLength({
      fieldName: FormFieldName.HEADER_DESCRIPTION,
      fieldCaption: 'Header description',
      value: values.headerDescription,
      length: 1000,
    }),
    validateFieldsArrayForFinalForm(FormFieldName.DISPLAY_TABS, getDisplayTabsRules(values), {
      collection: values.displayTabs,
    }),
  );
};
