import React from 'react';
import useColumns from './useColumns';
import { DataTable, dataTableStyles, Icon, IconType, Button, ButtonVariantType } from '@dealroadshow/uikit';
import { GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM } from '../constants';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IGroupEventsItemFormValue, IGroupEventTableRowItem } from '../interfaces';
import styles from './eventsTable.scss';

const EventsTable = ({ fields }: IFinalFormFieldArrayInputComponentProps<IGroupEventsItemFormValue>) => {
  const tableData = fields.map<IGroupEventTableRowItem>((fieldName, fieldIndex) => (
    {
      fieldName,
      fieldIndex,
      ...fields.value[fieldIndex],
    }
  ));

  const onAddClick = () => fields.push(GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM);

  return (
    <>
      <DataTable
        isDraggable
        containerClassName={ styles.tableContainer }
        className={ dataTableStyles.isSecondaryTable }
        data={ tableData }
        columns={ useColumns(fields) }
        dataTest="eventsTable"
        orderCallback={ fields.move }
      />
      <Button
        dataTest="eventAddButton"
        variant={ ButtonVariantType.text }
        className={ styles.addButton }
        onClick={ onAddClick }
      >
        <Icon
          type={ IconType.add }
          className={ styles.addIcon }
        />
        Add Event
      </Button>
    </>
  );
};

export default EventsTable;
