import React from 'react';
import { AutocompleteInput } from '@dealroadshow/uikit';

type TRestProps = {
  [key in string]: any
};

interface IProps {
  companies: any,
}

const Company = ({ companies, ...otherProps }: IProps & TRestProps) => {
  const { verifiedCompanies, searchCompanies } = companies;

  const onSuggestionSelected = (_val, suggestion) => {
    if (otherProps.input && otherProps.onSuggestionSelected && suggestion.id) {
      const fieldName = `${ otherProps.input.name }Id`;
      otherProps.onSuggestionSelected(suggestion.id, fieldName);
    }
  };

  return (
    <AutocompleteInput
      { ...otherProps }
      useAsyncSuggestions
      fetchAsyncSuggestions={ (value) => searchCompanies({
        query: value,
        limit: 100,
      }) }
      suggestions={ verifiedCompanies }
      onSuggestionSelected={ onSuggestionSelected }
      isClearable
    />
  );
};

export default Company;
