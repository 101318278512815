import { batch } from 'react-redux';
import createAction from '@/Framework/State/Redux/createAction';
import { regenerateUserActivationCode } from '@/users/application/actions/data/actions';
import * as userSelectors from '@/users/application/actions/data/selectors';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { NotificationManager } from '@/Framework/Notification';
import * as actionTypes from './actionTypes';

export const resetUserLinks = () => createAction(actionTypes.RESET_USER_LINKS_STATE);

export function generateUserPasswordResetLink() {
  return async (dispatch, getState) => {
    const state = getState();
    const userRepository: UserRepository = state.container.get(UserRepository);
    const user = userSelectors.getUser(state);

    dispatch(createAction(actionTypes.GENERATE_USER_PASSWORD_RESET_LINK));
    try {
      const response = await userRepository.regenerateForgotPasswordUrl(
        user.id,
        state.user.links.passwordReset.application.value,
        state.user.links.passwordReset.application.callbackUrl,
      );

      dispatch(createAction(actionTypes.GENERATE_USER_PASSWORD_RESET_LINK_SUCCESS, response));
    } catch (e) {
      dispatch(createAction(actionTypes.GENERATE_USER_PASSWORD_RESET_LINK_FAILURE, e));
      NotificationManager.error(getErrorMessage(e));
    }
  };
}

export function invalidateUserPassword() {
  return async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.getUser(state);

    dispatch(createAction(actionTypes.INVALIDATE_USER_PASSWORD));
    try {
      const {
        id,
        email,
        firstName,
        lastName,
      } = user;

      let userRepository = state.container.get(UserRepository);
      const response = await userRepository.regeneratePassword(id);

      batch(() => {
        dispatch(createAction(actionTypes.INVALIDATE_USER_PASSWORD_SUCCESS, response));
        dispatch(setUserInvalidatePasswordModalIsVisible({ isVisible: false }));
      });

      let userNameWithEmail = email;

      if (firstName || lastName) {
        userNameWithEmail = `${ firstName ? `${ firstName } ` : '' }${ lastName ? `${ lastName } ` : '' }(${ email })`;
      }

      NotificationManager.success(getMessage(messageCodes.USER_INVALIDATE_PASSWORD_SUCCESS, { userNameWithEmail }));
    } catch (e) {
      dispatch(createAction(actionTypes.INVALIDATE_USER_PASSWORD_FAILURE, e));
      NotificationManager.error(getErrorMessage(e));
    }
  };
}

export function generateUserActivationLink() {
  return async (dispatch, getState) => {
    const state = getState();
    const userRepository = getState().container.get(UserRepository);
    await dispatch(regenerateUserActivationCode());

    const user = userSelectors.getUser(state);

    dispatch(createAction(actionTypes.GENERATE_USER_ACTIVATION_LINK));
    try {
      const response = await userRepository.getActivationUrl(
        user.email,
        state.user.links.activation.application.value,
        state.user.links.activation.application.callbackUrl,
      );

      dispatch(createAction(actionTypes.GENERATE_USER_ACTIVATION_LINK_SUCCESS, response));
    } catch (e) {
      dispatch(createAction(actionTypes.GENERATE_USER_ACTIVATION_LINK_FAILURE, e));
      NotificationManager.error(getErrorMessage(e));
    }
  };
}

/**
 * @param {String} sectionName
 * @param {Object} value
 */
export const setUserLinksSectionApplication = (sectionName, value) => createAction(
  actionTypes.SET_USER_LINKS_SECTION_APPLICATION,
  { sectionName, value },
);

/**
 * @param {Object} payload
 */
export const setUserInvalidatePasswordModalIsVisible = (payload) => createAction(
  actionTypes.SET_USER_INVALIDATE_PASSWORD_MODAL_IS_VISIBLE,
  payload,
);
