import { IOption } from './interfaces';

export const getOptionsMapper = (options: IOption[]) => options.reduce((acc, option) => {
  acc[option.value] = option.groups;
  return acc;
}, {});

export const getToolbarOptions = (options: IOption[]) => options.map((option) => ({
  label: option.label,
  value: option.value,
  isDisable: option.isDisable || false,
}));
