import React from 'react';
import { IconType } from '@dealroadshow/uikit';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import LastTableCell from '@/Framework/UI/Molecules/Tables/TableWrapper/LastTableCell';
import { IAdminCallListItem } from '@/evercall/domain/vo/call/admin/AdminCallListItem';
import { useCallsCollectionContext } from '@/evercall/application/condor/call/collection/CallsCollectionContext';

interface IProps {
  row: IAdminCallListItem,
  cellData: string,
}

const CallDateCell = (
  {
    cellData,
    row,
  }: IProps,
) => {
  const {
    toggleCallIsDeleted,
    toggleCallIsHidden,
    toggleCallIsSupported,
  } = useCallsCollectionContext();
  return (
    <LastTableCell
      childrenToShowOnHover={
        (
          <TableRowActions
            actions={
              [
                {
                  tooltipContent: row.isSupported
                    ? 'Remove Support as Deal Manager'
                    : 'Add Support as Deal Manager',
                  onClick: () => toggleCallIsSupported({ id: row.id }),
                  iconType: row.isSupported ? IconType.unlock : IconType.lock,
                },
                {
                  tooltipContent: row.isHidden ? 'Move to Active Filter' : 'Move to Hidden Filter',
                  onClick: () => toggleCallIsHidden({ id: row.id }),
                  iconType: row.isHidden ? IconType.notDisplay : IconType.display,
                },
                {
                  tooltipContent: row.isDeleted ? 'Restore Evercall' : 'Delete Evercall',
                  onClick: () => toggleCallIsDeleted({ id: row.id }),
                  iconType: row.isDeleted ? IconType.refresh : IconType.trash,
                },
              ]
            }
          />
        )
      }
    >
      { cellData }
    </LastTableCell>
  );
};

export default CallDateCell;
