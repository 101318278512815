import React, { useState } from 'react';
import cn from 'classnames';
import { IInputTag } from '@/Framework/UI/Molecules/Form/InputTags/InputTags';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';

import styles from './tagsInput.scss';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';

const submitEnteredTagKeys = ['Enter', 'Tab', ',', ' ', ';'];
const removeLastEnteredTagKeys = ['Backspace'];

const defaultNormalizeChange = (value: string) => value.trim().replace(/, ?/g, ' ').split(' ');

interface IProps {
  inputRef: React.MutableRefObject<HTMLInputElement>,
  tags: IInputTag[],
  isDisabled: boolean,
  isFetching: boolean,
  placeholder: string,
  onAppendTags: (tags: IInputTag[]) => void,
  onRemoveTag: () => void,
  onSubmitTags: () => void,
  onFocus: () => void,
  onBlur: () => void,
  validate: (value: string) => IValidationError | null,
  normalize: (value: string) => string,
  normalizeChange?: (value: string) => string[],
  dataTest: string,
  autoFocus: boolean,
}

const TagsInput = ({
  inputRef,
  tags,
  isDisabled,
  isFetching,
  placeholder,
  autoFocus,
  onAppendTags,
  onRemoveTag,
  onSubmitTags,
  onFocus,
  onBlur,
  validate,
  normalize,
  normalizeChange = defaultNormalizeChange,
  dataTest,
}: IProps) => {
  const [value, setValue] = useState('');

  const handleInputChange = (e) => {
    if (isFetching) {
      e.preventDefault();
      return;
    }

    const { value: inputValue } = e.target;

    const values = normalizeChange(inputValue);

    if (values.length > 1) {
      appendTags(values);
    } else {
      setValue(values[0]);
    }
  };

  const handleInputKeyDown = (e) => {
    if (isFetching) {
      e.preventDefault();
      return;
    }

    if (!value && isEnterKey(e)) {
      onSubmitTags();
      return;
    }

    if (value && submitEnteredTagKeys.includes(e.key)) {
      e.preventDefault();
      appendTags([value]);
    }

    if (!value && removeLastEnteredTagKeys.includes(e.key)) {
      onRemoveTag();
    }
  };

  const handleInputBlur = () => {
    appendTags([value]);
    onBlur();
  };

  const appendTags = (tagsList) => {
    const uniqueTagsList = getUniqueTags(tagsList);

    if (uniqueTagsList.length) {
      onAppendTags(uniqueTagsList);
    }

    setValue('');
  };

  const isTagAlreadyExists = (tags, tag) => !!tags.find(
    (existingTag) => existingTag.toLowerCase() === tag.toLowerCase(),
  );

  const getUniqueTags = (tagsList) => {
    const existingTags = tags.map((tag) => tag.value);

    return tagsList.reduce((acc, tag) => {
      const normalizedTag = normalize ? normalize(tag) : tag;

      if (
        normalizedTag &&
        !isTagAlreadyExists(existingTags, normalizedTag) &&
        !isTagAlreadyExists(acc, normalizedTag)
      ) {
        acc.push(normalizedTag);
      }

      return acc;
    }, []).map((tag) => ({
      value: tag,
      isValid: validate ? !validate(tag) : true,
    }));
  };

  return (
    <div
      className={ cn(styles.tagsInputWrapper, {
        [styles.isDisabled]: isDisabled,
      }) }
      data-test="inputTags"
    >
      <input
        className={ styles.tagsInput }
        value={ value }
        onKeyDown={ handleInputKeyDown }
        onChange={ handleInputChange }
        onBlur={ handleInputBlur }
        onFocus={ onFocus }
        placeholder={ placeholder }
        ref={ inputRef }
        disabled={ isDisabled }
        data-test={ dataTest }
        autoFocus={ autoFocus }
      />
    </div>
  );
};

export default React.forwardRef((props: IProps, ref: React.MutableRefObject<HTMLInputElement>) => (
  <TagsInput
    { ...props }
    inputRef={ ref }
  />
));
