import { NAME_MIN_LENGTH } from '@/users/domain/User';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateAum } from '@/Framework/UI/Organisms/FinalForm/validators/validateAum';

export default (values) => groupValidators(
  validateMinLength({ fieldName: 'name', fieldCaption: 'Account Name', value: values.name, length: NAME_MIN_LENGTH }),
  validateMaxLength({ fieldName: 'name', fieldCaption: 'Account Name', value: values.name, length: 128 }),
  validateMaxLength({ fieldName: 'abbreviation', fieldCaption: 'Abbreviation', value: values.abbreviation, length: 256 }),
  validateRequiredFields([
    { fieldName: 'name', fieldCaption: 'Account Name' },
    { fieldName: 'accountTypeId', fieldCaption: 'Type' },
  ], values),
  values?.aum !== '' ? validateAum({
    fieldName: 'aum',
    fieldCaption: 'Aum',
    value: values.aum,
  }) : null,
);
