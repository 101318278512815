import { useState, useEffect } from 'react';

import { useDIContext } from '@/Framework/DI/DIContext';
import EntityRepository from '@/openMarket/infrastructure/repository/Condor/EntityRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useOpenMarketList = () => {
  const { container } = useDIContext();
  const entityRepository = container.get<EntityRepository>(EntityRepository);

  const [counters, setCounters] = useState({
    profiles: 0,
    events: 0,
  });

  const getCounters = async () => {
    try {
      const response = await entityRepository.getCounters();
      setCounters(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    getCounters();
  }, []);

  return {
    counters,
    getCounters,
  };
};

export default useOpenMarketList;
