import { useParams } from 'react-router';
import ArticleProfile from '../ArticleProfile';

const EditArticleProfile = () => {
  const articleId = useParams().articleId as string;

  return <ArticleProfile articleId={ articleId } />;
};

export default EditArticleProfile;
