import React, { useEffect } from 'react';
import { Input, Textarea } from '@dealroadshow/uikit';
import { Field, useForm, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import EventsTable from './EventsTable';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import { toSlug } from '@/Framework/url/helpers';
import {
  GROUP_FOOTER_TEXT_MAX_LENGTH,
  GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM,
  GROUP_PROFILE_FORM_INITIAL_VALUES,
} from '@/evercall/ui/components/condor/GroupProfile/constants';
import { IGroupFormValues } from './interfaces';
import { IGroup } from '@/evercall/domain/vo/call/admin/Group';
import { useGroupProfileContext } from '@/evercall/application/condor/group/profile/GroupProfileContext';
import useFormReinitializeAfterSubmit from '@/evercall/ui/components/condor/CallProfile/useFormReinitializeAfterSubmit';
import styles from './groupProfile.scss';

const GroupProfileFormFields = () => {
  const form = useForm<IGroupFormValues>();
  const { values } = form.getState();

  const {
    state: {
      isInitialized,
      isUpdating,
      isUpdateSucceeded,
      item,
      isCreateGroup,
    },
  } = useGroupProfileContext();

  const getInitialValues = (payload: IGroup): IGroupFormValues => (
    {
      ...payload,
      events: payload.events.length ? payload.events.map((item) => (
        {
          id: {
            value: item.id,
            label: item.name,
          },
          useCustomization: item.useCustomization,
        }
      )) : [GROUP_PROFILE_FORM_EMPTY_EVENTS_ITEM],
    }
  );

  useEffect(
    () => {
      if (isInitialized) {
        if (isCreateGroup) {
          form.initialize(GROUP_PROFILE_FORM_INITIAL_VALUES);
        } else {
          form.initialize(getInitialValues(item));
        }
      }
    },
    [isInitialized],
  );

  useEffect(() => {
    if (!values.name) {
      form.change('path', null);
    } else {
      form.change('path', toSlug(values.name.replaceAll('\'', '') || ''));
    }
  }, [values.name]);

  useFormReinitializeAfterSubmit(() => {
    if (isUpdateSucceeded) {
      form.restart(getInitialValues(item));
    }
  }, isUpdating);

  return (
    <>
      <Field
        name="name"
        render={ ({ input, meta }) => (
          <div className={ styles.groupNameInputContainer }>
            <Input
              isNarrow
              className={ styles.inputField }
              name={ input.name }
              label="Group Name"
              placeholder="Enter group name"
              dataTest="groupNameInput"
              meta={ meta }
              input={ input }
            />
            { values.name && (
              <div className={ styles.urlLabel }>
                { evercallUrl.getUrl(`/events/${ values.path }`) }
              </div>
            ) }
          </div>
        ) }
      />
      <Field
        name="footerText"
        label="Footer Text"
        textareaClassName={ styles.footerTextarea }
        component={ Textarea }
        dataTest="footerTextArea"
        placeholder="Enter Footer Text"
        maxLength={ GROUP_FOOTER_TEXT_MAX_LENGTH }
      />
      <FieldArray
        name="events"
        component={ EventsTable }
      />
    </>
  );
};

export default GroupProfileFormFields;
