import { connect, batch } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  getAccountsByFilter,
  setCondorAccountsSearchQuery,
  paginate,
} from '@/condor/application/actions/accounts/collection/actions';
import { resetCondorAccountsItem } from '@/condor/application/actions/accounts/item/actions';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';
import selectors from '@/condor/application/actions/accounts/collection/selectors';
import styles from './accountsList.scss';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
    className: styles.searchFilterInput,
    placeholder: 'Filter by Name, Type, Web or Email Domain',
    isNarrow: true,
    isClearable: true,
    dataTest: 'accountsListSearchInput',
  }
);

const mapDispatchToProps = (dispatch) => {
  const searchDebounced = debounce(() => dispatch(getAccountsByFilter()), 500);
  return {
    onChange: (e) => {
      batch(() => {
        dispatch(setCondorAccountsSearchQuery(e.target.value));
        dispatch(paginate(1));
        dispatch(resetCondorAccountsItem());
      });
      searchDebounced();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
