import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';

const field = { fieldName: 'displayName', fieldCaption: 'Data Room Name' };

export default (values) => groupValidators(
  validateRequiredField({ ...field, value: values.displayName?.trim() }),
  validateMinLength({ ...field, value: values.displayName?.trim(), length: 2 }),
  validateMaxLength({ ...field, value: values.displayName?.trim(), length: 128 }),
);
