import React from 'react';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import isEmpty from 'lodash/isEmpty';
import {
  isValidHRegSCusip,
} from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/MapTrade/MapTradeExpandableRowContent/CellItems/helpers';

import styles from './styles.scss';

const CusipCell = ({ row: tranche, cusipType }) => {
  const { tradeToMap } = useTraceContext();

  const cusips = tranche[cusipType] || [];
  const isConflictTooltipVisible = isValidHRegSCusip(tradeToMap.cusip)
    ? !isEmpty(tranche.cusipsRegs)
    : !isEmpty(tranche.cusips);

  return (
    <>
      {
        cusips.map((cusip: string) => (
          <div key={ cusip }>
            { cusip }
            {
              isConflictTooltipVisible && (
                <Tooltip content="Due to CUSIP conflict, mapping this trade to this tranche requires manual CUSIP removal">
                  <Icon type={ IconType.warning } className={ styles.conflictIcon } />
                </Tooltip>
              )
            }
          </div>
        ))
      }
      { !cusips.length && '-' }
    </>
  );
};

export default CusipCell;
