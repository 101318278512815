import React from 'react';
import cn from 'classnames';
import { Field } from 'redux-form';

import { Textarea, Input } from '@dealroadshow/uikit';

import { DEAL_PROFILE_FIELDS } from '../constants';
import withTooltipOnInputLabel from '@/Framework/UI/Molecules/Form/withTooltipOnInputLabel';

import styles from '../dealProfile.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const InputWithTooltipOnLabel = withTooltipOnInputLabel(Input);
const TextAreaWithTooltipOnLabel = withTooltipOnInputLabel(Textarea);

interface IProps {
  isProductAbs: boolean,
}

const DealComments = ({ isProductAbs }: IProps) => (
  <div className={ styles.panelContent }>
    <div className={ styles.dealCommentsCol }>
      <Field
        { ...DEAL_PROFILE_FIELDS.COLLATERAL }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
      />
      <Field
        { ...DEAL_PROFILE_FIELDS.COMMENTS }
        placeholder="type text here..."
        rows={ 5 }
        textareaClassName={ cn(layoutStyles.inputField, styles.textArea) }
        component={ TextAreaWithTooltipOnLabel }
      />
      <Field
        { ...DEAL_PROFILE_FIELDS.GUARANTOR }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
        isNarrow
      />
    </div>
    <div className={ styles.dealCommentsCol }>
      { isProductAbs && (
        <>
          <Field
            { ...DEAL_PROFILE_FIELDS.ORIGINATOR }
            className={ layoutStyles.inputField }
            component={ InputWithTooltipOnLabel }
          />
          <Field
            { ...DEAL_PROFILE_FIELDS.RISK_RETENTION }
            className={ layoutStyles.inputField }
            component={ InputWithTooltipOnLabel }
          />
        </>
      ) }
      <Field
        { ...DEAL_PROFILE_FIELDS.USE_OF_PROCEEDS }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
      />
      { isProductAbs && (
        <Field
          { ...DEAL_PROFILE_FIELDS.VOLCKER_COMPLIANCE }
          className={ layoutStyles.inputField }
          component={ InputWithTooltipOnLabel }
          isNarrow
        />
      ) }
    </div>
  </div>
);

export default DealComments;
