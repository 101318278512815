import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { mapOptionsMemoized } from '@/condor/ui/common/Layout/helpers';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import * as userTitleSelectors from '@/users/application/actions/title/selectors';

const mapStateToProps = (state, { titles, isProfile, ...restProps }) => ({
  name: 'titleId',
  component: ReduxFormSelect,
  placeholder: 'Select Title',
  backspaceRemovesValue: false,
  options: mapOptionsMemoized(isProfile ? titles : userTitleSelectors.getTitles(state)),
  className: layoutStyles.inputField,
  dataTest: 'titleId',
  ...restProps,
});

export default connect(
  mapStateToProps,
)(Field);
