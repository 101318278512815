import React, { useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';

import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { getAcceptanceTimestampCell } from '../../helpers';

import { IconType } from '@dealroadshow/uikit';

import { FILINGS_STATUS } from '../../constants';

import styles from './updateStatusButtonsCell.scss';

interface IProps {
  filing: any,
  setIsSendEmailModalVisible: () => void,
  setIsResendEmailModalVisible: () => void,
  setIsDisableModalVisible: () => void,
}

const UpdateStatusButtonsCell = (
  {
    filing,
    setIsSendEmailModalVisible,
    setIsResendEmailModalVisible,
    setIsDisableModalVisible,
  }: IProps,
) => {
  const actions = useMemo(() => {
    if (filing.row.status === FILINGS_STATUS.PENDING) {
      return [{
        iconType: IconType.deny,
        onClick: setIsDisableModalVisible,
        iconClassName: styles.icon,
        tooltipContent: 'Disable Email',
      }];
    }

    if (filing.row.status === FILINGS_STATUS.SENT) {
      return [{
        iconType: IconType.arrowCircle,
        onClick: setIsResendEmailModalVisible,
        iconClassName: styles.icon,
        tooltipContent: 'Resend Email',
      }];
    }

    if (filing.row.status === FILINGS_STATUS.DISABLE) {
      return [{
        iconType: IconType.sendEmail,
        onClick: setIsSendEmailModalVisible,
        iconClassName: styles.icon,
        tooltipContent: 'Send Email',
      }];
    }

    return [];
  }, [filing.row]);

  return (
    <LastTableCell
      childrenToShowOnHover={ (
        <TableRowActions
          actions={ actions }
        />
      ) }
    >
      { getAcceptanceTimestampCell(filing) }
    </LastTableCell>
  );
};

export default memo(UpdateStatusButtonsCell, isEqual);
