import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import SectorRepository from '@/dealroadshow/infrastructure/repository/sector/sectorRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export default function useSectors() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState([]);

  async function getSectorsList() {
    setIsFetching(true);

    try {
      const sectorRepository = container.get<SectorRepository>(SectorRepository);
      const response = await sectorRepository.list();
      setCollection(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getSectorsList,
  };
}
