import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { IField } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/Field';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateRegExp } from '@/Framework/UI/Organisms/FinalForm/validators/validateRegExp';

export const validateDashboardId = (dashboardIdField: IField & { value: string }) => {
  return groupValidators(
    validateMinLength({ ...dashboardIdField, length: 3 }),
    validateMaxLength({ ...dashboardIdField, length: 32 }),
    validateRegExp({ ...dashboardIdField, expression: '^[a-zA-Z0-9_-]+$' }),
  );
};
