import { useParams } from 'react-router';
import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { messageCodes } from '@/Framework/Message/messages';

interface IProps {
  setIsUpdating: (payload: boolean) => void,
  setIsFetching: (payload: boolean) => void,
  setSettings: (payload: ICustomRegistration) => void,
  setDefaultSettings: (payload: ICustomRegistration) => void,
}

export default (
  {
    setIsUpdating,
    setIsFetching,
    setSettings,
    setDefaultSettings,
  }: IProps,
) => {
  const { container } = useDIContext();
  const evercallAdminRepository = container.get<EvercallAdminRepository>(EvercallAdminRepository);

  const { callId: callIdFromUrl } = useParams();

  const getCustomRegistrationSettings = () => {
    const request = createAsyncRequest<typeof evercallAdminRepository.getCallCustomRegistrationSettings>(
      {
        method: evercallAdminRepository.getCallCustomRegistrationSettings,
        callbacks: {
          onSuccess: ({ response }) => {
            setSettings(response);
          },
        },
      },
    );
    return request({ callId: callIdFromUrl });
  };

  const getCustomRegistrationDefaultSettings = () => {
    const request = createAsyncRequest<typeof evercallAdminRepository.getCallCustomRegistrationDefaultSettings>(
      {
        method: evercallAdminRepository.getCallCustomRegistrationDefaultSettings,
        callbacks: {
          onSuccess: ({ response }) => {
            setDefaultSettings(response);
          },
        },
      },
    );
    return request();
  };

  const updateSettings = async (payload: ICustomRegistration) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.updateCallCustomRegistrationSettings>(
      {
        method: evercallAdminRepository.updateCallCustomRegistrationSettings,
        callbacks: {
          onSuccess: ({ response }) => {
            setSettings(response);
            setIsUpdating(false);
            NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
          },
        },
      },
    );
    setIsUpdating(true);
    return request({ ...payload, callId: callIdFromUrl });
  };

  const initCustomRegistration = () => {
    setIsFetching(true);
    Promise.all(
      [
        getCustomRegistrationSettings(),
        getCustomRegistrationDefaultSettings(),
      ],
    ).then(() => {
      setIsFetching(false);
    });
  };

  return {
    initCustomRegistration,
    updateSettings,
  };
};
