import React from 'react';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { useEnhancedTaggingCondorContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { TruncateTextWithTooltip, ICellProps } from '@dealroadshow/uikit';

import spaces from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

const CurrencyCallback = ({ row: { id, currencyId }, rowIndex }: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { currenciesOptions } = useEnhancedTaggingCondorContext();
  const { editableRowId } = useEnhancedTaggingRoadshowsContext();

  if (editableRowId === id) {
    return (
      <Field
        name={ `${ fieldRowName }.currencyId` }
        placeholder="Deal Currency"
        formFieldClassName={ spaces.mbn }
        // @ts-ignore
        component={ FinalFormSelect }
        simpleValue
        asterisk
        options={ currenciesOptions }
        dataTest={ `currency[${ rowIndex }]` }
      />
    );
  }

  if (!currencyId) {
    return '-';
  }

  const currencyName = currenciesOptions.find((item) => item.value === currencyId)?.label;

  return <TruncateTextWithTooltip value={ currencyName } />;
};

export default CurrencyCallback;
