import { useEffect, useState } from 'react';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import TraceRepository from '@/finsight/infrastructure/repository/TraceRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { DealsResponse } from '@/condor/domain/vo/Trace/DealsResponse';
import { IMapTradedParams, IMapTradePayload, ITradeItem } from '@/condor/domain/vo/types';
import { DEALS_REQUEST_DEFAULT_PARAMS } from './constants';

const traceMapInitialState = {
  collection: [],
  totalCount: 0,
};

export const useMapTrade = (getTraceListItems) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [tradeToMap, setTradeToMap] = useState<ITradeItem>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [traceMapData, setTraceMapData] = useState<DealsResponse>(traceMapInitialState);
  const [params, setParams] = useState<IMapTradedParams>(DEALS_REQUEST_DEFAULT_PARAMS);
  const { container } = useDIContext();
  const traceRepository = container.get<TraceRepository>(TraceRepository);

  const handlePageChange = (value: { selected: number }) => {
    setParams((prevParams) => ({
      ...prevParams, page: value.selected,
    }));
  };

  const handleItemsPerPageChange = (perPage: { value: number, label: string }) => {
    setParams((prevParams) => ({
      ...prevParams, page: 1, perPage: perPage.value,
    }));
  };

  const searchTraceMapListItems = async (query?: string) => {
    if (query?.length) {
      setIsFetching(true);
      const payload = { ...params, query };
      try {
        const res = await traceRepository.getDealsRequest(payload);
        return setTraceMapData(res);
      } catch (error) {
        return !Dispatcher.isAbortError(error) && NotificationManager.error(getErrorMessage(error));
      } finally {
        setIsFetching(false);
      }
    } else {
      return setTraceMapData(traceMapInitialState);
    }
  };

  const mapTrade = async (value: IMapTradePayload) => {
    setIsFetching(true);
    try {
      const res = await traceRepository.mapTradeRequest(value);
      NotificationManager.success('The tranche was successfully mapped!');
      return res;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return error;
    } finally {
      setIsFetching(false);
      getTraceListItems();
      setTradeToMap(null);
    }
  };

  useEffect(() => {
    searchTraceMapListItems(searchValue);
  }, [params, searchValue]);

  return {
    tradeToMap,
    setTradeToMap,
    traceMapData,
    searchTraceMapListItems,
    mapTradeFetching: isFetching,
    mapTrade,
    searchValue,
    setSearchValue,
    mapTradeData: {
      ...params,
      handlePageChange,
      handleItemsPerPageChange,
    },
  };
};
