import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { IconType } from '@dealroadshow/uikit';
import { getImportTimestamp } from '@/condor/ui/components/Finsight/Import/helpers';

interface IProps {
  cellProps: any,
  onDelete: () => void,
}

const DateOfImportCell = (props: IProps) => (
  <LastTableCell
    childrenToShowOnHover={ (
      <TableRowActions actions={ [
        {
          tooltipContent: 'Delete import',
          iconType: IconType.trash,
          onClick: props.onDelete,
          disabled: !!props.cellProps.row.deletedAt,
        },
      ] }
      />
      ) }
  >
    { getImportTimestamp(props.cellProps) }
  </LastTableCell>
);

export default DateOfImportCell;
