import React from 'react';
import { useSlate } from 'slate-react';
import { insertLink } from '@/Framework/UI/Molecules/Form/RichTextInput/helpers/links';
import { isBlockActive } from './helpers';
import Button from './Button';
import { Icon, IconType } from '@dealroadshow/uikit';

interface IProps {
  className?: string,
  activeClassName?: string,
}

const LinkButton = ({ className, activeClassName }: IProps) => {
  const editor = useSlate();
  const handleInsertLink = () => {
    // eslint-disable-next-line no-alert
    const url = prompt('Enter URL');
    if (!url) {
      return;
    }
    insertLink(editor, url);
  };
  return (
    <Button
      activeClassName={ activeClassName }
      active={ isBlockActive(editor, 'link') }
      format="link"
      onClick={ handleInsertLink }
      className={ className }
    >
      <Icon type={ IconType.linkText } />
    </Button>
  );
};

export default LinkButton;
