import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateFieldsArrayForFinalForm } from '@/Framework/UI/Organisms/FinalForm/validators/validateFieldsArrayForFinalForm';
import { validateDealSize } from '@/Framework/UI/Organisms/FinalForm/validators/validateDealSize';

const editableTableParams = [
  {
    fieldName: 'dealSize',
    fieldCaption: 'Deal Size',
    functionValidation: [
      {
        name: validateDealSize,
        params: {},
      },
    ],
  },
];

export default (values) => groupValidators(
  validateFieldsArrayForFinalForm(
    'editableTableArray',
    editableTableParams,
    { collection: values.editableTableArray },
  ),
);
