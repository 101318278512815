import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import useItemFactory from '@/Framework/State/useItemFactory';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';
import { INITIAL_CALL_ITEM_STATE } from './constants';
import getActions from './getActions';
import { IGroup } from '@/evercall/domain/vo/call/admin/Group';
import { ICreateGroupProps } from './interfaces';

export default ({ isCreateGroup }: ICreateGroupProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSucceeded, setIsUpdateSucceeded] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const history = useHistory();
  const { groupId: groupIdFromUrl } = useParams();

  const {
    state: itemState,
    getItemData,
    setItem,
  } = useItemFactory<IGroup, typeof evercallAdminRepository.getGroupData>(
    {
      initialItemState: INITIAL_CALL_ITEM_STATE,
      getRequestConfig: {
        method: evercallAdminRepository.getGroupData,
        callbacks: {
          onError: () => {
            history.push(CONDOR_EVERCALL_LIST);
          },
        },
      },
    },
  );

  return {
    state: {
      ...itemState,
      isInitialized,
      isCreating,
      isUpdating,
      isUpdateSucceeded,
      isDeleting,
      isCreateGroup,
    },
    initCallProfile: () => getItemData({ id: groupIdFromUrl }).then(() => setIsInitialized(true)),
    setIsInitialized,
    ...getActions(
      {
        setIsCreating,
        setIsUpdating,
        setIsUpdateSucceeded,
        setIsDeleting,
        setItem,
      },
    ),
  };
};
