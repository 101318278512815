import React from 'react';
import cn from 'classnames';
import UserPlan, { UserPlanType } from '@/users/domain/UserPlan';

import styles from './styles.scss';

interface IProps {
  userPlan: UserPlanType,
  className?: string,
}

const UserPlanTag = ({ userPlan, className }: IProps) => {
  const tagClasses = cn(
    styles.userPlanTag,
    className,
    {
      [styles.userPlanStarter]: UserPlan.isStarter(userPlan),
      [styles.userPlanPremium]: UserPlan.isPremium(userPlan),
      [styles.userPlanPro]: UserPlan.isPro(userPlan),
    },
  );

  return (
    <div className={ tagClasses }>{ userPlan }</div>
  );
};

export default UserPlanTag;
