import { createContext, ReactNode, useContext } from 'react';
import useThemes from './useThemes';

type ThemesContextType = ReturnType<typeof useThemes>;

const ThemesContext = createContext<ThemesContextType>(null);

export const useThemesContext = () => {
  const context = useContext(ThemesContext);

  if (!context) {
    throw new Error('useThemesContext must be used within the ThemesContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
}

export const ThemesContextProvider = ({ children }: IProps) => (
  <ThemesContext.Provider value={ useThemes() }>
    { children }
  </ThemesContext.Provider>
);
