import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { isPhoneEntered } from '@/Framework/UI/Organisms/FinalForm/validators/user/validatePhoneNumber';
import { IValidationError } from '../interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
  allValue: {
    url: string,
    phone: {
      value: string,
      code: string,
    },
    lead: string,
  },
  length: {
    min: number,
    max: number,
  },
  isShortErrorText: boolean,
  isPlural: boolean,
}

export const validateUnderwritersName = (params: IParams): IValidationError => {
  if (params.allValue.url || isPhoneEntered(params.allValue.phone) || params.allValue.lead) {
    const required = validateRequiredField(params);
    if (required) {
      return required;
    }
  }
  const minLengthResponse = validateMinLength({ ...params, length: params.length.min });

  if (minLengthResponse) {
    return minLengthResponse;
  }
  const maxLengthResponse = validateMaxLength({ ...params, length: params.length.max });

  if (maxLengthResponse) {
    return maxLengthResponse;
  }
  return null;
};
