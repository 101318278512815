import React from 'react';
import { Logo, LogoProduct } from '@dealroadshow/uikit';
import styles from './appNavigation.scss';

interface IAppNavigationLogoProps {
  logoProduct: LogoProduct,
  LogoComponent: React.ComponentType,
  MobileLogoComponent: React.ComponentType,
}

const AppNavigationLogo = ({ LogoComponent, MobileLogoComponent, logoProduct }: IAppNavigationLogoProps) => (
  <>
    <div className={ styles.logo }>
      { LogoComponent
        ? <LogoComponent />
        : (
          <Logo
            type={ Logo.TYPE.STANDART }
            product={ logoProduct }
            colorType={ Logo.COLOR_TYPE.WHITE }
            className={ styles.logo }
          />
        ) }
    </div>
    <div className={ styles.mobileLogo }>
      { MobileLogoComponent
        ? <MobileLogoComponent />
        : (
          <Logo
            type={ Logo.TYPE.TEXT }
            product={ logoProduct }
            colorType={ Logo.COLOR_TYPE.WHITE }
            className={ styles.mobileLogo }
          />
      ) }
    </div>
  </>
);

export default AppNavigationLogo;
