import React from 'react';
import cn from 'classnames';
import { NavLink, Switch, useLocation } from 'react-router-dom';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Route from '@/Framework/Router/ReactRouter/Route';

import FilingsFilter from './FilingsFilter';
import SearchInputContainer from './FilingsTables/SearchInputContainer';
import Abs15G from './FilingsTables/Abs15G';
import AbsEE from './FilingsTables/AbsEE';

import Filing from '@/finsight/domain/Filing';
import {
  CONDOR_FINSIGHT_FILINGS_15G_BASE_URL,
  CONDOR_FINSIGHT_FILINGS_EE_BASE_URL,
} from '@/condor/ui/common/constants';

import pageStyles from '@/Framework/GlobalStyles/page.scss';
import floatStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/floats.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './filings.scss';

const Filings = () => {
  const location = useLocation().pathname;
  const types = Filing.getFilingTypesArray(location);
  const isAbs15G = Filing.isAbs15G(location);

  return (
    <PageWrapper
      label="SEC Filings"
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_FILINGS_15G_BASE_URL }
            >
              ABS 15G
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_FILINGS_EE_BASE_URL }
            >
              ABS EE
            </NavLink>
          </div>
        </div>
      ) }
    >
      <TableWrapper
        className={ spacesStyles.pl }
        label={ isAbs15G ? 'ABS 15G' : 'ABS EE' }
        filterComponent={ (
          <div className={ styles.inputsContainer }>
            <FilingsFilter types={ types } />
            <SearchInputContainer
              placeholder="Filter by Issuer Name or Asset Class"
              types={ types }
              isNarrow
              isClearable
              inputClassName={ styles.searchInput }
              dataTest="filingsListSearchInput"
            />
          </div>
        ) }
      >
        <Switch>
          <Route
            path={ CONDOR_FINSIGHT_FILINGS_15G_BASE_URL }
            component={ Abs15G }
          />
          <Route
            path={ CONDOR_FINSIGHT_FILINGS_EE_BASE_URL }
            component={ AbsEE }
          />
        </Switch>
      </TableWrapper>
    </PageWrapper>
  );
};

export default Filings;
