import cn from 'classnames';
import { useFieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { Icon, IconType, DataTable, dataTableStyles, Spinner, IColumn, Tooltip } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

import DisplayTabsContextProvider, { useDisplayTabsContext } from './DisplayTabsContext';
import { FormFieldName } from '../../constants';
import { IDisplayTab } from './interfaces';
import { DisplayTabFormFieldName } from './constants';
import { createNewTab } from './helpers';
import {
  TabNameCell,
  DisplayCell,
  TransactionTypeCell,
  RegionsCell,
  WhitelistCell,
  ActionCell,
  EmailCell,
} from './cellCallbacks';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import styles from './displayTabs.scss';

const columns: IColumn[] = [
  {
    name: 'dragAndDrop',
    isDraggable: true,
    className: cn(dataTableStyles.draggableCol, styles.draggableCol),
    title: '',
    width: 45,
  },
  {
    name: DisplayTabFormFieldName.DISPLAY,
    title: 'Display',
    width: 50,
    maxWidth: 50,
    className: alignStyles.alignCenter,
    cellCallback: DisplayCell,
  },
  {
    name: DisplayTabFormFieldName.NAME,
    minWidth: 200,
    width: 278,
    title: 'Tab name',
    cellCallback: TabNameCell,
  },
  {
    name: DisplayTabFormFieldName.TRANSACTION_TYPES,
    title: 'Transaction type(s)',
    minWidth: 300,
    width: 400,
    className: styles.top,
    cellCallback: TransactionTypeCell,
  },
  {
    name: DisplayTabFormFieldName.REGIONS,
    title: 'Regions',
    minWidth: 200,
    width: 252,
    className: styles.top,
    cellCallback: RegionsCell,
  },
  {
    name: DisplayTabFormFieldName.EMAIL,
    title: (
      <span className={ styles.cellTitleWithTooltip }>
        <span>Email</span>
        <Tooltip content="Set email, to which user can request an access.">
          <Icon type={ IconType.question } />
        </Tooltip>
      </span>
    ),
    minWidth: 200,
    width: 252,
    cellCallback: EmailCell,
  },
  {
    name: DisplayTabFormFieldName.WHITELIST,
    title: 'Whitelist',
    minWidth: 195,
    width: 195,
    cellCallback: WhitelistCell,
  },
  {
    name: 'action',
    title: '',
    cellCallback: ActionCell,
  },
];

const DisplayTabs = () => {
  const { fields } = useFieldArray<IDisplayTab>(FormFieldName.DISPLAY_TABS);
  const { isTransactionTypesLoading, isRegionsLoading, tableRef } = useDisplayTabsContext();
  const addNewTab = () => fields.push(createNewTab());

  return (
    <>
      <DataTable
        ref={ tableRef }
        isFetching={ isTransactionTypesLoading || isRegionsLoading }
        loadingComponent={ Spinner }
        isDraggable
        dataTest="displayTabsTable"
        containerClassName={ styles.dataTableWrapper }
        className={ cn(dataTableStyles.isSecondaryTable, styles.dataTable) }
        isUsePureUpdateComponent
        isScrollable
        columns={ columns }
        data={ fields.value }
        orderCallback={ fields.move }
        showEmptyStateMessage
        showEmptyStateMessageFirst
        emptyMessageClassName={ styles.emptyMessage }
        emptyMessage="There is no display tabs"
      />
      <Button dataTest="addNewButton" variant={ variantTypes.link } className={ styles.addButton } onClick={ addNewTab }>
        <Icon type={ IconType.add } />
        Add Tab
      </Button>
    </>
  );
};

export default () => (
  <DisplayTabsContextProvider>
    <DisplayTabs />
  </DisplayTabsContextProvider>
);
