import React from 'react';
import { Tooltip } from '@dealroadshow/uikit';

const AgingCell = ({ cellData }) => (
  Number.isInteger(cellData) ? (
    <Tooltip content={ `${ cellData } ${ cellData === 1 ? 'day' : 'days' }` }>
      { cellData }
    </Tooltip>
  ) : '-'
);

export default AgingCell;
