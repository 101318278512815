import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { TToolbarItemFormat } from '@/Framework/UI/Molecules/Form/RichTextInput/components/Toolbar/interfaces';

import styles from './button.scss';

interface IProps {
  format: TToolbarItemFormat,
  active: boolean,
  className?: string,
  activeClassName?: string,
  [key: string]: unknown,
}

const Button = (
  {
    children,
    format,
    active,
    className,
    activeClassName,
    ...rest
  }: PropsWithChildren<IProps>,
) => (
  <span
    className={ cn(styles.iconsTool, { [styles.active]: active }, className, { [activeClassName]: active }) }
    title={ format }
    data-test={ `${ format }Button` }
    { ...rest }
  >
    { children }
  </span>
);

export default Button;
