import cn from 'classnames';
import { useState } from 'react';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import { RegionsProductsContextProvider } from '@/condor/application/Dictionary/RegionsProducts';
import {
  ArticleProfileContextProvider,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import ArticleProfile from './ArticleProfile';

import styles from './styles.scss';

interface IArticleProfilePageProps {
  fromArticle?: string,
  articleId?: string,
}

const ArticleProfilePage = (props: IArticleProfilePageProps) => {
  const [title, setTitle] = useState(props.articleId ? '' : 'Create New Post');

  return (
    <PageWrapper
      label={ title }
      className={ cn({ [styles.pageWrapper]: !title }) }
    >
      <RegionsProductsContextProvider>
        <ArticleProfileContextProvider { ...props } setTitle={ setTitle }>
          <ArticleProfile />
        </ArticleProfileContextProvider>
      </RegionsProductsContextProvider>
    </PageWrapper>
  );
};

export default ArticleProfilePage;
