import React, { memo, useEffect } from 'react';
import BondUploadOverlayContainer from './BondUploadProcessContainer';
import UploadWrapperContainer from './UploadWrapperContainer';
import ValidationTable from './ValidationTable';
import PotentialErrorsTable from './PotentialErrorsTable';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';

interface IProps {
  showAreYouSureModalIfNeeded: boolean,
  validationTableVisible: boolean,
  potentialErrorsVisible: boolean,
  onTemplateDownload: () => void,
  resetAll: () => void,
}

const BondUpload = (
  {
    showAreYouSureModalIfNeeded,
    validationTableVisible,
    onTemplateDownload,
    potentialErrorsVisible,
    resetAll,
  }: IProps,
) => {
  useEffect(() => resetAll, []);
  return (
    <>
      { !(validationTableVisible || potentialErrorsVisible) && (
        <BondUploadOverlayContainer />
      ) }
      { !(validationTableVisible || potentialErrorsVisible) && (
        <UploadWrapperContainer onTemplateDownload={ onTemplateDownload } />
      ) }
      { validationTableVisible && (
        <ValidationTable />
      ) }
      { potentialErrorsVisible && (
        <PotentialErrorsTable />
      ) }
      <AreYouSureModal
        when={ showAreYouSureModalIfNeeded }
        disableNative={ false }
        text="Are you sure you want to leave this upload? Any changes you made will be lost."
      />
    </>
  );
};

export default memo(BondUpload);
