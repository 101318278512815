import { ReactNode } from 'react';
import { components as SelectComponents, MultiValueProps } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';

interface IMultiValueProps<
  SOption,
  IsMulti extends boolean,
  Group extends GroupBase<SOption>,
> {
  formatValue?: (data: SOption) => ReactNode,
  ownProps: MultiValueProps<SOption, IsMulti, Group>,
}

const MultiValue = <SOption, IsMulti extends boolean, Group extends GroupBase<SOption>>({
  formatValue,
  ownProps: { children, ...otherProps },
}: IMultiValueProps<SOption, IsMulti, Group>) => (
  <SelectComponents.MultiValue { ...otherProps }>
    { formatValue ? formatValue(otherProps.data) : children }
  </SelectComponents.MultiValue>
);

export default MultiValue;
