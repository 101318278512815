import { useEffect, useMemo } from 'react';
import flatten from 'lodash/flatten';
import { useEnhancedTaggingContext } from '@/Framework/UI/Organisms/EnhancedTagging/EnhancedTaggingContext';
import { OtherExcludedAnalyticsAbbr, OtherExcludedAnalyticsId } from './constants';

interface IOption {
  value: string,
  label: string,
}

const useEnhancedTaggingCondor = () => {
  const {
    countries,
    sectors,
    industries,
    currencies,
    transactionTypes,
    companies,
  } = useEnhancedTaggingContext();

  const {
    isFetching: isFetchingCountries,
    getCountriesList,
    collection: countriesCollection,
  } = countries;
  const {
    isFetching: isFetchingCurrencies,
    getCurrenciesList,
    collection: currenciesCollection,
  } = currencies;
  const {
    isFetching: isFetchingSectors,
    getSectorsList,
    collection: sectorCollection,
  } = sectors;
  const {
    isFetching: isFetchingIndustries,
    getIndustriesList,
    collection: industryCollection,
  } = industries;
  const {
    isFetching: isFetchingTransaction,
    getTransactionTypes,
    collection: transactionCollection,
  } = transactionTypes;

  useEffect(() => {
    getIndustriesList();
    getTransactionTypes();
    getSectorsList();
    getCountriesList();
    getCurrenciesList();
  }, []);

  const transactionTypeOptions: IOption[] = useMemo(() => (
    transactionCollection.map((option) => {
      if (option.id === OtherExcludedAnalyticsId) {
        return {
          value: option.id,
          label: OtherExcludedAnalyticsAbbr,
        };
      }
      return {
        value: option.id,
        label: option.abbreviation,
      };
    })
  ), [transactionCollection.length]);

  const sectorOptions: IOption[] = useMemo(() => (
    flatten(
      sectorCollection.map((sector) => sector.subsectorList.map((subSector) => ({
        value: subSector.id,
        label: `${ sector.name } - ${ subSector.name }`,
      }))),
    )
  ), [sectorCollection.length]);

  const industryOptions: IOption[] = useMemo(() => (
    flatten(
      industryCollection.map((industry) => industry.subindustryList.map((subIndustry) => ({
        value: subIndustry.id,
        label: `${ industry.name } - ${ subIndustry.name }`,
      }))),
    )
  ), [industryCollection.length]);

  const countriesOptions: IOption[] = useMemo(() => (
    countriesCollection.map((option) => ({
      value: option.id,
      label: option.name,
    }))
  ), [countriesCollection.length]);

  const currenciesOptions: IOption[] = useMemo(() => (
    currenciesCollection.map((option) => ({
      value: option.id,
      label: `${ option.name } (${ option.displayName })`,
    }))
  ), [currenciesCollection.length]);

  return {
    isFetchingCountries,
    isFetchingCurrencies,
    isFetchingIndustries,
    isFetchingTransaction,
    isFetchingSectors,
    companies,
    transactionCollection,
    countriesCollection,
    currenciesCollection,
    sectorCollection,
    industryCollection,
    transactionTypeOptions,
    sectorOptions,
    industryOptions,
    countriesOptions,
    currenciesOptions,
  };
};

export default useEnhancedTaggingCondor;
