import { useLocation } from 'react-router-dom';
import ArticleProfile from '../ArticleProfile';
import { getQueryStringParams } from '@/Framework/url/helpers';

const CreateArticleProfile = () => {
  const fromArticle = getQueryStringParams(useLocation())?.fromArticle;

  return <ArticleProfile fromArticle={ fromArticle } />;
};

export default CreateArticleProfile;
