import { ButtonVariantType, Icon, IconType } from '@dealroadshow/uikit';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

import styles from './styles.scss';

const sendButtonTitle = (isSendEmail: boolean) => (
  <span>
    <Icon
      type={ isSendEmail ? IconType.sendEmail : IconType.emailChecked }
      className={ styles.icon }
    />
    { isSendEmail ? 'Send Email' : 'Resend Email' }
  </span>
);

export default {
  [ActionModalType.DELETE]: {
    variant: ButtonVariantType.warning,
    title: 'Delete',
    dataTest: 'articlesModalDeleteButton',
  },
  [ActionModalType.SEND_EMAIL]: {
    variant: ButtonVariantType.action,
    title: sendButtonTitle(true),
    dataTest: 'articlesModalSendEmailButton',
  },
  [ActionModalType.RESEND_EMAIL]: {
    variant: ButtonVariantType.action,
    title: sendButtonTitle(false),
    dataTest: 'articlesModalResendEmailButton',
  },
  [ActionModalType.UNPUBLISH]: {
    variant: ButtonVariantType.warning,
    title: 'Unpublish',
    dataTest: 'articlesModalUnpublishButton',
  },
};
