import { createContext, ReactNode, useContext } from 'react';
import useUnpublishModal from './useUnpublishModal';

type UnpublishModalContextType = ReturnType<typeof useUnpublishModal>;

const UnpublishModalContext = createContext<UnpublishModalContextType>(null);

export const useUnpublishModalContext = () => {
  const context = useContext(UnpublishModalContext);

  if (!context) {
    throw new Error('useUnpublishModalContext must be used within the UnpublishModalContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
}

export const UnpublishModalContextProvider = ({ children }: IProps) => (
  <UnpublishModalContext.Provider value={ useUnpublishModal() }>
    { children }
  </UnpublishModalContext.Provider>
);
