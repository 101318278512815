import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { IPreviewData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/PreviewData';
import { IIndustry, ISubindustry } from '@/finsight/domain/vo/IDeal';
import ISector from '@/finsight/domain/vo/ISector';
import { ABS_PRODUCT_ID } from '@/finsight/domain/Product';
import ISubsector from '@/finsight/domain/vo/ISubsector';
import { getValues, getIds, getAttachments } from '../helpers';
import { mapSectorsIndustries, addSectorIndustryToData } from '@/condor/application/Finsight/CreditFlowResearch/Articles/helpers';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';

export interface IMappedPreviewData {
  headline: string,
  description: string,
  themes: string[],
  sectorOrIndustry: ISector | IIndustry,
  subsectorOrSubindustry: ISubindustry | ISubsector,
  author: string,
  date: string,
  attachments: IAttachment[],
}

const getParentFromList = (values: ArticleFormData): string[] => {
  const parentsList = values.parentAndIssuer?.filter((parent) => parent.isParent);
  if (!parentsList || parentsList.length === 0) {
    return [];
  }
  return getIds(parentsList);
};

export const getPreviewPayload = (values: ArticleFormData) => ({
  themes: getValues(values.themes),
  deals: getValues(values.deals),
  parents: getParentFromList(values),
});

export const getArticleDataForPreview = (
  previewData: IPreviewData,
  values: ArticleFormData,
  industries: IIndustry[],
  sectors: ISector[],
): IMappedPreviewData => {
  const data = {
    headline: values.headline,
    description: values.description,
    themes: values.themes.map((theme) => theme.label),
    sectorOrIndustry: null,
    subsectorOrSubindustry: null,
    author: '',
    date: '',
    attachments: getAttachments(values.attachments || []),
  };

  const isAbsOnly = previewData.products.length === 1 && previewData.products[0] === ABS_PRODUCT_ID;
  const isCorporate = !previewData.products.find((product) => product === ABS_PRODUCT_ID);

  const {
    sectorsIndustries,
    subsectorsSubindustries,
  } = mapSectorsIndustries(previewData, sectors, industries, isAbsOnly, isCorporate);

  return addSectorIndustryToData(data, sectorsIndustries, subsectorsSubindustries);
};
