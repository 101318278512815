import React, { ElementType, memo } from 'react';
import { ICellProps, dataTableStyles, Spinner, IconType } from '@dealroadshow/uikit';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import TableWrapper, { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { getCellDisplayDateFromString, getCellDisplayDateFromNumber } from '@/condor/ui/common/Layout/helpers';

import styles from './blacklist.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

interface IProps {
  pageLabel: string,
  tableLabel: string,
  onMount: () => void,
  onUnmount: () => void,
  onDeleteIconClick: (cell: ICellProps) => void,
  deleteModalWarningText: string,
  components: {
    addItemsInput: ElementType,
    dataTable: ElementType,
    paginator: ElementType,
    deleteModal: ElementType,
    searchInput: ElementType,
  },
}

const Blacklist = (props: IProps) => {
  const {
    pageLabel,
    tableLabel,
    onDeleteIconClick,
    onMount,
    onUnmount,
    deleteModalWarningText,
    components: {
      addItemsInput: AddItemsInputComponent,
      dataTable: DataTableComponent,
      paginator: PaginatorComponent,
      deleteModal: DeleteModalComponent,
      searchInput: SearchInputComponent,
    },
  } = props;

  const columns = [
    {
      name: 'domain',
      title: 'Domain',
      sortable: true,
    },
    {
      name: 'createdAt',
      title: 'Created At',
      className: alignStyles.alignRight,
      width: 145,
      sortable: true,
      cellCallback: (cellProps) => (
        <LastTableCell
          childrenToShowOnHover={ (
            <TableRowActions
              actions={ [
                {
                  tooltipContent: 'Delete',
                  iconType: IconType.trash,
                  onClick: () => onDeleteIconClick(cellProps),
                },
              ] }
            />
          ) }
        >
          { typeof cellProps.row.createdAt === 'number'
              ? getCellDisplayDateFromNumber(cellProps.row.createdAt)
              : getCellDisplayDateFromString(cellProps.row.createdAt) }
        </LastTableCell>
      ),
    },
  ];

  return (
    <PageWrapper
      label={ pageLabel }
      onMount={ onMount }
      onUnmount={ onUnmount }
      labelCls={ styles.pageLabel }
      headerComponent={ <AddItemsInputComponent /> }
    >
      <TableWrapper
        label={ tableLabel }
        filterComponent={ (
          <SearchInputComponent
            className={ styles.filterInput }
            placeholder="Filter by Domain"
            isNarrow
            isClearable
          />
        ) }
      >
        <DataTableComponent
          columns={ columns }
          dataTest="blackListDataTable"
          className={ dataTableStyles.isHoverable }
          loadingComponent={ Spinner }
        />
        <PaginatorComponent
          className={ cardStyles.cardInner }
          dataTest="blackList"
        />
        <DeleteModalComponent warningText={ deleteModalWarningText } />
      </TableWrapper>
    </PageWrapper>
  );
};

export default memo(Blacklist);
