import React, { useMemo } from 'react';
import { TaggingStateTypes } from '@/condor/domain/vo/EnhancedTagging/TaggingState';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import Button from '@/Framework/UI/Atoms/Button';

import styles from './taggingState.scss';

interface IProps {
  status: TaggingStateTypes,
  onChange: (newStatus: TaggingStateTypes) => void,
}

const TaggingState = ({ status, onChange }: IProps) => {
  const { iconType, hoverIconType, hoverTooltip, nextStatus } = useMemo<{
    iconType: IconType,
    hoverIconType: IconType,
    hoverTooltip: string,
    nextStatus: TaggingStateTypes,
  }>(() => {
    switch (status) {
      case TaggingStateTypes.FULLY_TAGGED: {
        return {
          iconType: IconType.tag,
          hoverIconType: IconType.notTagged,
          hoverTooltip: 'Mark as Untagged',
          nextStatus: TaggingStateTypes.NOT_TAGGED,
        };
      }

      case TaggingStateTypes.PARTIALLY_TAGGED: {
        return {
          iconType: IconType.partiallyTagged,
          hoverIconType: IconType.tag,
          hoverTooltip: 'Mark as Fully Tagged',
          nextStatus: TaggingStateTypes.FULLY_TAGGED,
        };
      }

      case TaggingStateTypes.NOT_TAGGED:
      default: {
        return {
          iconType: IconType.notTagged,
          hoverIconType: IconType.partiallyTagged,
          hoverTooltip: 'Mark as Partially Tagged',
          nextStatus: TaggingStateTypes.PARTIALLY_TAGGED,
        };
      }
    }
  }, [status]);

  const onClickHandler = () => onChange(nextStatus);

  return (
    <Tooltip content={ hoverTooltip }>
      <Button
        onClick={ onClickHandler }
        dataTest={ `setTaggingState-${ nextStatus }` }
        variant="text"
        className={ styles.button }
      >
        <Icon type={ iconType } data-test={ `taggingState-${ status }` } className={ styles.taggedCallback } />
        <Icon type={ hoverIconType } className={ styles.taggedCallback } />
      </Button>
    </Tooltip>
  );
};

export default TaggingState;
