export const isTabKey = (event: KeyboardEvent): boolean => (event.keyCode === 9);

export const isEnterKey = (event: KeyboardEvent): boolean => event.key === 'Enter' || event.which === 13 || event.keyCode === 13;

export const isSpaceKey = (event: KeyboardEvent): boolean => event.keyCode === 32;

export const isEscapeKey = (event: KeyboardEvent): boolean => {
  let isEscape = false;

  if (event.key !== undefined) {
    isEscape = (event.key === 'Escape' || event.key === 'Esc');
  } else {
    isEscape = (event.keyCode === 27);
  }
  return isEscape;
};

export const isPrintKeysCombination = (event: KeyboardEvent): boolean => (event.ctrlKey || event.metaKey) && (event.key === 'p' || event.charCode === 16 || event.charCode === 112 || event.keyCode === 80);

export const isArrowLeftKey = (event: KeyboardEvent): boolean => event.key === 'ArrowLeft' || event.keyCode === 37;
export const isArrowUpKey = (event: KeyboardEvent): boolean => event.key === 'ArrowUp' || event.keyCode === 38;
export const isArrowRightKey = (event: KeyboardEvent): boolean => event.key === 'ArrowRight' || event.keyCode === 39;
export const isArrowDownKey = (event: KeyboardEvent): boolean => event.key === 'ArrowDown' || event.keyCode === 40;
