import React from 'react';
import Link from '@/Framework/Router/ReactRouter/Link';
import { CONDOR_DRS_ROADSHOW_URL } from '@/condor/ui/common/constants';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';

export const dealNameCellCallback = (cellProps) => {
  if (cellProps.row.platform !== 'dealroadshow') {
    return commonCellCallback(cellProps);
  }
  return (
    <Link to={ `${ CONDOR_DRS_ROADSHOW_URL }/${ cellProps.row.id }/deal-settings` }>
      { cellProps.cellData }
    </Link>
  );
};
