import React from 'react';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import AddSubscribersForm from './AddSubscribersForm';
import { useAddSubscribersFormContext } from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm';

const AddSubscribersModal = () => {
  const { onSubmit } = useAddSubscribersFormContext();
  return (
    <FinalForm
      name="addSubscribersForm"
      onSubmit={ onSubmit }
      render={ () => <AddSubscribersForm /> }
      dataTest="addSubscribersForm"
      initialValues={ { isExportEnabled: false } }
    />
  );
};

export default AddSubscribersModal;
