import * as constants from '@/condor/ui/common/constants';
import Trades from '@/condor/ui/components/Finsight/TraceManager/Trades';
import { SubProductType } from '@/condor/domain/vo/types';

export const TRACE_TABLE_CELL_MAP = [
  {
    atsIndicator: 'Ats Indicator',
    agency: 'Agency',
    asOfIndicator: 'as of indicator',
    rdid: 'RDID',
    securityId: 'security Id',
    symbol: 'symbol',
    subProductType: 'subproduct Type',
    fitchRating: 'fitch rating',
    moodyRating: 'moody’s rating',
    standardAndPoorRating: 'S&P Rating',
  },
  {
    contraPartyType: 'Contra party type',
    reportingPartyType: 'Reporting Party type',
    reportingPartySide: 'Reporting party side',
    remuneration: 'Remuneration',
    commission: 'commission',
    saleCondition3: 'sale condition 3',
    saleCondition4: 'sale condition 4',
    specialPrice: 'Special price',
    maturityDate: 'Maturity date',
    settlementDate: 'Settlement date',
  },
  {
    tradeSize: 'qty',
    stringTradeSize: 'qty as string',
    quantityIndicator: 'qty indicator',
    price: 'PRICE ($)',
    coupon: 'coupon',
    couponType: 'coupon type',
    factor: 'factor',
    poolNumber: 'pool number',
    lastTradeDate: 'last trade date',
    lastTradePrice: 'last trade PRICE ($)',
  },
];

export const TABS = [
  {
    title: 'ABS Trades',
    path: `${ constants.CONDOR_FINSIGHT_TRADES_BASE_URL }/${ SubProductType.ABS }`,
    components: {
      content: Trades,
    },
    dataTest: 'absTradesTab',
  },
  {
    title: 'CMO Trades',
    path: `${ constants.CONDOR_FINSIGHT_TRADES_BASE_URL }/${ SubProductType.CMO }`,
    components: {
      content: Trades,
    },
    dataTest: 'cmoTradesTab',
  },
  {
    title: 'Archived',
    path: constants.CONDOR_FINSIGHT_TRADES_ARCHIVED_BASE_URL,
    components: {
      content: Trades,
    },
    dataTest: 'archivedTradesTab',
  },
];
