import { BWICS } from '@/finsight/domain/BondType';

export default {
  getHomePage: () => ({
    title: 'FINSIGHT',
    description: 'Explore global fixed income new issue pricing or streamline your capital market and corporate finance workflows with our award winning services.',
  }),
  getAbout: () => ({
    title: 'About Us',
    description: "We're an award-winning technology platform for financial institutions that provides unparalleled visibility with actionable insights into capital markets.",
  }),
  getCareers: () => ({
    title: 'Careers With FINSIGHT | Explore Our Open Positions',
    description: "We're looking for creative, self-motivated people who value an entrepreneurial environment and are committed to excellence. Explore our open positions here.",
  }),
  getCompliance: () => ({
    title: 'Our Compliance Policy',
    description: 'An overview of our Compliance Policy.',
  }),
  getContactUs: () => ({
    title: 'Contact Us',
    description: 'Send us your questions or comments through this page or contact our fanatical 24x7 support via phone or email.',
  }),
  getFeatures: () => ({
    title: 'Features',
    description: 'Our features include global fixed income new issue pricing, intuitive deal mapping, granular search, ratings research, and market specific modules. Learn more.',
  }),
  getSubscriptions: () => ({
    title: 'Email Subscriptions',
    description: 'All your email subscriptions in one place, customize information that you want to receive from Finsight.com',
  }),
  getOurSolutions: () => ({
    title: 'Solutions By FINSIGHT | Digital Tools For Financial Insights',
    description: 'We offer a suite of best-in-class applications that streamline your workflow, reduce costs & provide unparalleled visibility into your transaction process.',
  }),
  getPrivacyPolicy: () => ({
    title: 'Privacy Policy',
    description: "We're committed to safeguarding your privacy online. Visit this page to view our Privacy Policy.",
  }),
  getSecurityPolicy: () => ({
    title: 'Our Security Policy',
    description: 'Visit this page to read our Security Policy.',
  }),
  getTermsOfUse: () => ({
    title: 'Terms of Use',
    description: 'By accessing or using our Sites, you agree to be bound by the Terms stated here. Please read these Terms carefully and contact us if you have any questions.',
  }),
  getABSBondScreener: () => ({
    title: 'Fixed Income ABS Bond Screener',
    description: 'Use our fixed income screener for global ABS bonds for free. Filter 40,000+ bonds across all sectors using dozens of criteria.',
  }),
  getCorporateBondscreener: () => ({
    title: 'Fixed Income Corporate Bond Screener',
    description: 'Use our fixed income screener for global corporate bonds for free. Filter 40,000+ bonds across all industries using dozens of criteria.',
  }),
  getBwicsInventory: (bondType, assetClassAbbreviation) => ({
    title: `Fixed Income ${ assetClassAbbreviation } ${ bondType === BWICS ? 'BWICS' : 'Inventories' }`,
    description: 'Supercharge your ABS BWIC & Inventory discovery process. Seamlessly explore BWICS & Inventory from participating dealers.',
  }),
  getBwicsInventorySummary: (bondType, assetClassAbbreviation) => ({
    title: `Fixed Income ${ assetClassAbbreviation } ${ bondType === BWICS ? 'BWICS' : 'Inventories' }`,
    description: 'Supercharge your ABS BWIC & Inventory discovery process. Seamlessly explore BWICS & Inventory from participating dealers.',
  }),
  getIndustryProfile: (industryName) => ({
    title: `${ industryName }  Industry Bond Issuance Overview `,
    description: `View recent and historical corporate bond new issue pricing, volume and ratings in the ${ industryName } industry.`,
  }),
  getIndustrySubindustryProfile: (industrySubindustryName) => ({
    title: ` ${ industrySubindustryName } Bond Issuance Overview`,
    description: `View recent and historical corporate bond new issue pricing, volume and ratings in the ${ industrySubindustryName } subindustry.`,
  }),

  getMarketOverviewHYC: () => ({
    title: 'High Yield Corporate Bond Issuance Overview',
    description: 'Recent and historical global high yield corporate bond new issue pricing, volume and ratings tracker.',
  }),
  getMarketOverviewIGC: () => ({
    title: 'Investment Grade Corporate Bond Issuance Overview',
    description: 'Recent and historical global investment grade corporate bond new issue pricing, volume and ratings tracker.',
  }),
  getMarketOverviewUsAbs: () => ({
    title: 'ABS Bond Issuance Overview',
    description: 'View recent and historical global ABS new issue pricing, volume and research across Auto, CLO, CMBS, Credit Card, Equipment, Esoteric, Student Loans and RMBS.',
  }),
  getMarketOverviewCustomer: () => ({
    title: 'ABS (ex CLO, CMBS & RMBS) Issuance Overview',
    description: 'View recent and historical global ABS new issue pricing, volume and research across Auto, Credit Card, Equipment, Esoteric and Student Loan ABS.',
  }),
  getResearch: () => ({
    title: 'Fixed Income Research & Presale Reports',
    description: 'Explore free fixed income research across corporate bonds and asset-backed securities from leading rating agencies and research providers here.',
  }),
  getSectorProfile: (sector) => ({
    title: `${ sector } Sector Bond Issuance Overview`,
    description: `View recent and historical ABS new issue pricing, volume and ratings in the ${ sector } sector here.`,
  }),
  getSectorSubsectorProfile: (sectorSubsectorName) => ({
    title: `${ sectorSubsectorName } ABS Bond Issuance Overview`,
    description: `View recent and historical ABS new issue pricing, volume and ratings in the ${ sectorSubsectorName } sector here.`,
  }),
  getParentProfile: (parentName) => ({
    title: `${ parentName } Bond Issuance Profile`,
    description: `View ${ parentName }'s historical fixed income issuance and pricing profile here.`,
  }),
  getIssuerProfile: (issuerName) => ({
    title: `${ issuerName } Bond Issuance Profile`,
    description: `View ${ issuerName }'s historical fixed income issuance and pricing profile here.`,
  }),
  getDealProfile: (dealProfileName) => ({
    title: `${ dealProfileName } Bond Profile`,
    description: `View the ${ dealProfileName } fixed income deal profile here.`,
  }),
  getLoanLevelData: () => ({
    title: 'US ABS Loan Level Data on EDGAR Tracker',
    description: 'View and track the latest ABS-EE loan level-data filings on EDGAR here.',
  }),
  getNewIssuePipeline: () => ({
    title: 'US ABS New Issue Pipeline & 15G Tracker',
    description: 'View and track the latest ABS-15G new issue filings on EDGAR here.',
  }),
  getStratMaker: (filingTitle) => ({
    title: 'US ABS Loan Level Data StratMaker',
    description: `View ${ filingTitle } loan-level data stratifications on FINSIGHT's stratmaker.`,
  }),
  leagueTable: () => ({
    title: 'Fixed Income Underwriter Rating Agency League Table',
    description: 'View and track the latest global fixed Income league table for underwriters and rating agencies here.',
  }),
  underwriterRatingAgency: (companyName) => ({
    title: `${ companyName } League Table Ranking`,
    description: `View ${ companyName } fixed income league table profile here.`,
  }),
  getCatalogIssuers: () => ({
    title: 'Global Fixed Income Issuers',
    description: 'View a list of all global fixed income issuers tracked by FINSIGHT.',
  }),
  getCatalogSponsors: () => ({
    title: 'Global Fixed Income Parents',
    description: 'View a list of all global fixed income parents tracked by FINSIGHT.',
  }),
  getPricing: () => ({
    title: 'Pricing and Plans',
    description: 'Subscribe for access to industry leading fixed income new issue and secondary market data, news and research.',
  }),
};
