import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import cn from 'classnames';
import ExportButtonWithSpinner from '@/Framework/UI/Atoms/Button/ExportButtonWithLoader';
import * as downloadExcelSelectors from '@/dealroadshow/application/actions/auditTrail/downloadExcel/selectors';
import { exportToExcelAuditTrail } from '@/dealroadshow/application/actions/auditTrail/downloadExcel/actions';
import styles from './exportToExcelButton.scss';

const mapStateToProps = (state, ownProps) => ({
  className: cn(styles.exportBtnWrapper, ownProps.wrapperClassName),
  tooltipContainerClassName: styles.exportBtn,
  spinnerClassName: cn(styles.exportSpinner, ownProps.spinnerClassName),
  isDisabled: false,
  isFetching: downloadExcelSelectors.isFetching(state, ownProps.isAdmin),
});

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({
    onClick: () => exportToExcelAuditTrail(ownProps.match.params.roadshowId, ownProps.isAdmin),
  }, dispatch);
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportButtonWithSpinner));
