import debounce from 'lodash/debounce';
import { connect, batch } from 'react-redux';
import {
  getGlobalBlacklistByFilter,
  paginate,
  setGlobalBlacklistSearchQuery,
} from '@/condor/application/actions/blacklists/global/actions';
import selectors from '@/condor/application/actions/blacklists/global/selectors';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
  }
);

const mapDispatchToProps = (dispatch) => {
  const getListDebounced = debounce(() => dispatch(getGlobalBlacklistByFilter()), 500);

  return {
    onChange: (e) => {
      batch(() => {
        dispatch(setGlobalBlacklistSearchQuery(e.target.value));
        dispatch(paginate(1));
      });
      getListDebounced();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
