import React, { createContext, useContext, useState } from 'react';
import {
  IManagePlansSubscriberItem,
  IManageSubscriptionForm,
  IManagePlansManageSubscriberPayload,
} from '@/condor/domain/vo/FinsightManager/ManagePlans';
import { useSubscribersContext } from '@/condor/application/FinsightManager/Subscribers';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { UserPlanType } from '@/users/domain/UserPlan';

const useManageSubscriptionForm = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmailFormVisible, setIsEmailFormVisible] = useState<boolean>(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState<boolean>(true);
  const [data, setData] = useState<Partial<IManagePlansSubscriberItem>>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { container } = useDIContext();

  const { getCollection } = useSubscribersContext();

  const reset = () => {
    setIsNotificationEnabled(true);
    setIsEmailFormVisible(false);
    setData({});
    setIsOpen(false);
  };

  const onSubmit = async (values: IManageSubscriptionForm) => {
    const contactRepository = container.get(ContactRepository);
    setIsSubmitting(true);
    try {
      const formattedPayload: IManagePlansManageSubscriberPayload = {
        id: data.id,
        plan: values.userPlan,
        teams: Array.isArray(values.teamName) ? values.teamName.map((team) => team.label) : [values.teamName.label],
        sendEmail: isNotificationEnabled,
        features: [
          {
            feature: 'xlsExport',
            isEnabled: values.userPlan === UserPlanType.PRO ? values.isExportEnabled : false,
          },
        ],
      };

      if (isNotificationEnabled) {
        formattedPayload.subject = values.subject;
        formattedPayload.emailText = values.emailText === '<p></p>' ? '' : values.emailText;
      }
      await contactRepository.updateUserSubscription(formattedPayload);
      NotificationManager.success(`<strong>${ data.email }</strong> was  successfully subscribed to the ${ values.userPlan } plan`);
      reset();
      getCollection();
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isOpen,
    data,
    isEmailFormVisible,
    isNotificationEnabled,
    isSubmitting,
    setIsNotificationEnabled,
    setIsEmailFormVisible,
    onOpenModal: () => setIsOpen(true),
    onSetData: (data: Partial<IManagePlansSubscriberItem>) => setData(data),
    onSubmit,
    reset,
  };
};

type ManageSubscriptionFormContextType = ReturnType<typeof useManageSubscriptionForm>;

const ManageSubscriptionFormContext = createContext<ManageSubscriptionFormContextType>(null);

export const useManageSubscriptionFormContext = () => {
  const context = useContext(ManageSubscriptionFormContext);

  if (!context) {
    throw new Error('useManageSubscriptionFormContext must be used within the ManageSubscriptionFormContext');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

export const ManageSubscriptionFormContextProvider = ({ children }: IProps) => (
  <ManageSubscriptionFormContext.Provider value={ useManageSubscriptionForm() }>
    { children }
  </ManageSubscriptionFormContext.Provider>
);
