import { useParams, useHistory } from 'react-router';
import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { IGroup, IGroupEvent } from '@/evercall/domain/vo/call/admin/Group';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';

interface IProps {
  setIsCreating: (payload: boolean) => void,
  setIsDeleting: (payload: boolean) => void,
  setIsUpdating: (payload: boolean) => void,
  setIsUpdateSucceeded: (payload: boolean) => void,
  setItem: (payload: IGroup) => void,
}

export default (
  {
    setIsCreating,
    setIsDeleting,
    setIsUpdating,
    setIsUpdateSucceeded,
    setItem,
  }: IProps,
) => {
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const { groupId: groupIdFromUrl } = useParams();
  const { push } = useHistory();

  const createGroup = async (payload: IGroup) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.createGroup>(
      {
        method: evercallAdminRepository.createGroup,
        callbacks: {
          onSuccess: () => {
            setIsCreating(false);
            push(CONDOR_EVERCALL_LIST);
          },
          onError: (error) => {
            setIsCreating(false);
            NotificationManager.error(getErrorMessage(error));
          },
        },
      },
    );
    setIsCreating(true);
    return request(payload);
  };

  const updateGroup = async (payload: IGroup) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.updateGroup>(
      {
        method: evercallAdminRepository.updateGroup,
        callbacks: {
          onSuccess: ({ response }) => {
            setItem(response);
            setIsUpdating(false);
            setIsUpdateSucceeded(true);
            NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
          },
          onError: (error) => {
            setIsUpdating(false);
            setIsUpdateSucceeded(false);
            NotificationManager.error(getErrorMessage(error));
          },
        },
      },
    );
    setIsUpdating(true);
    return request({ ...payload, id: groupIdFromUrl });
  };

  const deleteGroup = async () => {
    const request = createAsyncRequest<typeof evercallAdminRepository.deleteGroup>(
      {
        method: evercallAdminRepository.deleteGroup,
        callbacks: {
          onSuccess: () => {
            setIsDeleting(false);
            NotificationManager.success(getMessage(messageCodes.DELETE_EVERCALL_GROUP_SUCCESS));
            push(CONDOR_EVERCALL_LIST);
          },
          onError: (error) => {
            setIsDeleting(false);
            NotificationManager.error(getErrorMessage(error));
          },
        },
      },
    );
    setIsDeleting(true);
    return request({ id: groupIdFromUrl });
  };

  const fetchGroupEventsSuggestions = async (search: string): Promise<IGroupEvent[]> => {
    let filteredData: IGroupEvent[] = [];
    const request = createAsyncRequest<typeof evercallAdminRepository.getGroupEventsSuggestions>(
      {
        method: evercallAdminRepository.getGroupEventsSuggestions,
        callbacks: {
          onSuccess: ({ response }) => {
            filteredData = response;
          },
        },
      },
    );
    await request({ search });
    return filteredData;
  };

  return {
    createGroup,
    updateGroup,
    deleteGroup,
    fetchGroupEventsSuggestions,
  };
};
