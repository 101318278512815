import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateFieldsArrayForFinalForm } from '@/Framework/UI/Organisms/FinalForm/validators/validateFieldsArrayForFinalForm';
import { validateUnderwritersName } from '@/Framework/UI/Organisms/FinalForm/validators/company/validateUnderwritersName';
import { validateDuplicate } from '@/Framework/UI/Organisms/FinalForm/validators/validateDuplicate';
import { validateFormSection } from '@/Framework/UI/Organisms/FinalForm/validators/validateFormSection';

const underwritersParams = [
  {
    fieldName: 'name',
    fieldCaption: 'Underwriters Name',
    functionValidation: [
      {
        name: validateUnderwritersName,
        params: {
          length: {
            min: 3,
            max: 128,
          },
        },
      },
      {
        name: validateDuplicate,
        params: {
          listName: 'Underwriters / Advisor',
        },
      },
    ],
  },
];

export default (values) => groupValidators(
  // @ts-ignore
  validateFormSection(values, 'enhancedTagging', (enhancedTaggingValues) => ({
    ...(validateFieldsArrayForFinalForm(
      'underwriters',
      underwritersParams,
      { collection: enhancedTaggingValues.underwriters },
    )),
  })),
);
