import React from 'react';
import { getCondorDateCell } from '@/condor/ui/components/FinsightManager/helpers';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import { CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL } from '@/condor/ui/common/constants';

export default [
  {
    name: 'issuerName',
    title: 'Issuer Name',
    cellCallback: (cellProps) => {
      const issuerTicker = cellProps.row.primaryIssuer.issuerTicker
        ? ` (${ cellProps.row.primaryIssuer.issuerTicker })`
        : '';
      return (
        <a href={ `${ CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/${ cellProps.row.id }` }>
          {
            `${ cellProps.row.primaryIssuer.name }${ issuerTicker }`
          }
        </a>
      );
    },
    className: alignStyles.alignLeft,
    sortable: true,
    width: 200,
  },
  {
    name: 'seriesName',
    title: 'Series',
    cellCallback: (cellProps) => cellProps.row.seriesName,
    sortable: true,
    width: 100,
  },
  {
    name: 'createdAt',
    title: 'Created at Timestamp',
    cellCallback: ({ row }) => getCondorDateCell(row.createdAt),
    className: alignStyles.alignRight,
    sortable: true,
    width: 200,
  },
];
