import { SidebarWidth } from '@/dealroadshow/domain/SidebarWidth';

export enum VerificationState {
  BypassActivated = 'bypassActivated',
  BypassDeactivated = 'bypassDeactivated',
  PromptVerificationActivated = 'promptVerificationActivated',
}

export interface ISecurityAndDisclaimerFormValues {
  watermarkEnabled: boolean,
  pdfWatermarkEnabled: boolean,
  investorEmailVerification: VerificationState,
  caseSensitiveEntryCodes: boolean,
  mobileDisabled: boolean,
  corporateEmailChecked: boolean,
  sidebarWidth: SidebarWidth,
  disclaimer: string,
  watermarkOpacity: string,
  investorQuestionsEnabled: boolean,
  requestMeetingEnabled: boolean,
}
