import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';

@Dependencies(CondorRpcDispatcher)
class SidebarRepository {
  constructor(condorRpc) {
    /** @type {Dispatcher} */
    this.condorRpc = condorRpc;
  }

  getCondorNavigationItems = async () => {
    let req = new Request('condor.sidebar.contact.get_navigation_items');
    let response = await this.condorRpc.call(req);
    return response.getResult().payload;
  };
}

export default SidebarRepository;
