import React from 'react';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import LeisFieldMultiValue from './LeisField/LeisFieldMultiValue';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';

interface IProps {
  fieldComponent: React.ElementType,
  label: string,
  name: string,
  placeholder: string,
  className: string,
}

const LeisField = ({ fieldComponent: Field, ...otherProps }:IProps) => {
  const components = {
    DropdownIndicator: () => null,
    MultiValue: LeisFieldMultiValue,
  };

  const formatCreateLabel = (label) => `Create a "${ label }" by Enter`;

  const isValidNewOption = (newElement) => {
    const validMinLength = !validateMinLength({
      fieldName: 'name',
      value: newElement,
      length: 1,
    });

    const validMaxLength = !validateMaxLength({
      fieldName: 'name',
      value: newElement,
      length: 255,
    });

    return !!newElement && validMinLength && validMaxLength;
  };

  return (
    <Field
      component={ FinalFormSelect }
      creatable
      components={ components }
      isMulti
      backspaceRemovesValue={ false }
      options={ [] }
      noOptionsMessage={ () => null }
      dataTest="leis"
      formatCreateLabel={ formatCreateLabel }
      isValidNewOption={ isValidNewOption }
      { ...otherProps }
    />
  );
};

export default LeisField;
