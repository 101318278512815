import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';

import { useDIContext } from '@/Framework/DI/DIContext';
import ProfileRepository from '@/openMarket/infrastructure/repository/Condor/ProfileRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import { CONDOR_FINSIGHT_OPEN_MARKET_PROFILES } from '@/condor/ui/common/constants';

const useProfileData = () => {
  const history = useHistory();
  const profileId = useParams().profileId as string;
  const { container } = useDIContext();
  const profileRepository = container.get<ProfileRepository>(ProfileRepository);

  const [profile, setProfile] = useState<IProfile>(null);

  const redirectToList = () => history.push(CONDOR_FINSIGHT_OPEN_MARKET_PROFILES);

  const getProfileById = async () => {
    try {
      const response = await profileRepository.getProfileById(profileId);
      setProfile(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      redirectToList();
      throw error;
    }
  };

  useEffect(() => {
    if (profileId) {
      getProfileById();
    } else {
      setProfile(null);
    }
  }, [profileId]);

  return {
    redirectToList,
    profile,
    profileId,
  };
};

export const ProfileDataContext = createContext<ReturnType<typeof useProfileData>>(null);

export const useProfileDataContext = () => {
  const context = useContext(ProfileDataContext);
  if (!context) {
    throw new Error('useProfileDataContext must be used within a ProfileDataContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ProfileDataContextProvider = ({ children }: IProps) => (
  <ProfileDataContext.Provider value={ useProfileData() }>{ children }</ProfileDataContext.Provider>
);

export default ProfileDataContextProvider;
