import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';

import { useCallProfileContext } from '@/evercall/application/condor/call/profile/CallProfileContext';
import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import useFormReinitializeAfterSubmit from '../useFormReinitializeAfterSubmit';

import { Input, Icon, IconType, Tooltip, AsyncSelect } from '@dealroadshow/uikit';

import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { TGroupSuggestion } from '@/evercall/domain/vo/call/admin/Group';
import { ISettingsFormValues } from './interfaces';
import { IGroupEventOption } from '@/evercall/ui/components/condor/GroupProfile/interfaces';

import { PARTICIPANT_CONFERENCE_ID_LABEL } from '@/evercall/ui/components/condor/CallsList/constants';
import { PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP } from './constants';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';

import styles from './settings.scss';
import groupProfileStyles from '@/evercall/ui/components/condor/GroupProfile/groupProfile.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

interface IProps {
  isUpdating: boolean,
  isUpdateSucceeded: boolean,
  fetchGroupsSuggestions: (search: string) => Promise<TGroupSuggestion[]>,
}

const SettingsFormFields = ({ isUpdating, isUpdateSucceeded, fetchGroupsSuggestions }: IProps) => {
  const {
    state: {
      item,
    },
  } = useCallProfileContext();

  const form = useForm<ISettingsFormValues>();
  const { values } = form.getState();

  const getInitialValues = (item: IAdminCall): ISettingsFormValues => ({
    ...item,
    groupId: item.groupId ? {
      label: item.groupName,
      value: item.groupId,
      path: item.groupPath,
    } : null,
  });

  useEffect(
    () => {
      if (item.name) {
        form.initialize(getInitialValues(item));
      }
    },
    [item.id],
  );

  const loadGroupOptions = debounce(
    (
      query: string,
      callback: (options: IGroupEventOption[]) => void,
    ) => {
      if (query?.length) {
        fetchGroupsSuggestions(query).then((response) => {
          callback(response.map((d) => ({
            value: d.id,
            label: d.name,
            path: d.path,
          })));
        });
      }
    },
    500,
  );

  useFormReinitializeAfterSubmit(() => {
    if (isUpdateSucceeded) {
      form.restart(getInitialValues(item));
    }
  }, isUpdating);

  const getLabelWithExplainer = (label: string, tooltipContent: string) => (
    <>
      <span>{ label }</span>
      <span className={ styles.inputQuestionIconContainer }>
        <Tooltip content={ tooltipContent }>
          <Icon type={ IconType.question } />
        </Tooltip>
      </span>
    </>
  );

  const hasDashboardIdAlreadyAdded = !!item.dashboardId;

  return (
    <>
      <Field
        component={ Input }
        className={ layoutStyles.inputField }
        name="name"
        label="Call Name"
        placeholder="eg Acme Global Investor Call"
        dataTest="evercallProfileFormCallNameInput"
      />
      <div className={ styles.conferenceInputsContainer }>
        <Field
          name="participantConferenceId"
          component={ Input }
          isNarrow
          className={ styles.inputField }
          label={ getLabelWithExplainer(PARTICIPANT_CONFERENCE_ID_LABEL, PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP) }
          placeholder="12345"
          dataTest="evercallProfileFormParticipantConferenceIdInput"
        />
        <div className={ styles.linkIconContainer }>
          <Icon
            className={ styles.linkIcon }
            type={ IconType.link }
          />
        </div>
        <Field
          name="groupId"
          render={ ({ input, otherProps }) => (
            <div className={ groupProfileStyles.groupNameInputContainer }>
              <AsyncSelect
                { ...otherProps }
                isNarrow
                formFieldClassName={ styles.inputField }
                name={ input.name }
                value={ input.value }
                onBlur={ input.onBlur }
                onChange={ input.onChange }
                label="Group"
                placeholder="Select Group"
                loadOptions={ loadGroupOptions }
                dataTest="groupSelect"
              />
              { values.groupId && (
                <div className={ groupProfileStyles.urlLabel }>
                  { evercallUrl.getUrl(`/events/${ values.groupId.path }`) }
                </div>
              ) }
            </div>
          ) }
        />
      </div>
      <Field
        component={ Input }
        className={ layoutStyles.inputField }
        name="dashboardId"
        label="Speaker/Dashboard ID"
        dataTest="dashboardIdInput"
        disabled={ hasDashboardIdAlreadyAdded }
        placeholder="99123"
      />
      <div className={ styles.roadshowInputsContainer }>
        <Field
          component={ Input }
          formFieldClassName={ styles.roadshowInputFormField }
          isNarrow
          name="roadshowId"
          label={ getLabelWithExplainer('Roadshow ID', 'Link to display dial-ins in Roadshow') }
          placeholder="d38f43d38f43d38f43d38f"
          dataTest="roadshowId"
        />
        <Field
          component={ Input }
          formFieldClassName={ styles.roadshowInputFormField }
          isNarrow
          name="roadshowEntryCode"
          label="Entry Code"
          placeholder="Entry Code linked in event registration form"
          dataTest="roadshowEntryCodeInput"
        />
      </div>
    </>
  );
};

export default SettingsFormFields;
