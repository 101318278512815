import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';

import { IconType, Icon } from '@dealroadshow/uikit';
import nodeStringToStyleAttrObject from '@/Framework/dataHelpers/htmlCollection/nodeStringToStyleAttrObject';
import ColorPicker from '@/Framework/UI/Molecules/Form/ColorPicker';

import styles from '../toolbar.scss';

interface IProps {
  value: string,
  className?: string,
}

const TextColorPicker = ({ value, className }: IProps) => {
  const editor = useSlate();
  const { color: savedColor } = nodeStringToStyleAttrObject(value);
  const [open, toggle] = useState(false);
  const [pickerColor, setPickerColor] = useState(savedColor);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const onChange = (color) => {
    Editor.removeMark(editor, 'color');
    Editor.addMark(editor, 'color', color);
    setPickerColor(color);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!colorPickerRef.current?.contains(event.target)) {
      toggle(false);
    }
  };

  return (
    <div
      className={ cn(styles.pickerWrapper, className) }
      ref={ colorPickerRef }
      onClick={ () => toggle(!open) }
    >
      <ColorPicker
        withoutTextInput
        enableAutofill
        containerClassName={ styles.picker }
        input={ { value: pickerColor, onChange } }
        forceColorPickerVisible={ open }
      />
      <Icon type={ IconType.fontColor } />
    </div>
  );
};

export default TextColorPicker;
