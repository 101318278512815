import { useMemo } from 'react';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { IconType } from '@dealroadshow/uikit';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import {
  CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLE_PROFILE_BASE_URL,
  CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_ARTICLE_BASE_URL,
} from '@/condor/ui/common/constants';
import ArticlesActionsContextProvider, {
  useArticlesActionsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesList/ArticlesActionsContext';
import ArticlesTableModals from './ArticlesTableModals';
import { IArticleToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

import styles from './styles.scss';

const isRowActive = (row: IArticleToDisplay) => row.isActive;

const hasMailingRequests = (row: IArticleToDisplay) => row.mailingRequests?.length > 0;

const UpdatedAtCell = ({ cellData, row }: { cellData: string, row: IArticleToDisplay }) => {
  const { openModal } = useArticlesActionsContext();
  const editAndDuplicateActions = [
    {
      tooltipContent: 'Edit',
      iconType: IconType.pencil,
      className: styles.actionWrapper,
      url: `${ CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLE_PROFILE_BASE_URL }/${ row.id }`,
    },
    {
      tooltipContent: 'Duplicate',
      iconType: IconType.duplicate,
      className: styles.actionWrapper,
      url: `${ CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_ARTICLE_BASE_URL }?fromArticle=${ row.id }`,
    },
  ];

  const deleteAction = {
      tooltipContent: 'Delete',
      iconType: IconType.trash,
      onClick: () => openModal(row, ActionModalType.DELETE),
  };

  const sendEmailAction = {
    tooltipContent: 'Send Email Alert',
    iconType: IconType.sendEmail,
    className: styles.actionWrapper,
    onClick: () => openModal(row, ActionModalType.SEND_EMAIL),
  };

  const reSendEmailAction = {
    tooltipContent: 'Resend Email Alert',
    iconType: IconType.emailChecked,
    className: styles.actionWrapper,
    onClick: () => openModal(row, ActionModalType.RESEND_EMAIL),
  };

  const getEmailActionType = (row) => {
    if (!isRowActive(row)) return [];
    return hasMailingRequests(row) ? [reSendEmailAction] : [sendEmailAction];
  };

  const actions = useMemo(() => {
    return [
      ...editAndDuplicateActions,
      ...getEmailActionType(row),
      deleteAction,
    ];
  }, [row.id, row.mailingRequests.length]);

  return (
    <>
      <LastTableCell
        childrenToShowOnHover={ (
          <TableRowActions
            actions={ actions }
          />
      ) }
      >
        { getCellDisplayDateFromString(cellData) }
      </LastTableCell>
      <ArticlesTableModals />
    </>
  );
};

export default (props) => (
  <ArticlesActionsContextProvider>
    <UpdatedAtCell { ...props } />
  </ArticlesActionsContextProvider>
);
