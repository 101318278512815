import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { NotificationManager } from '@/Framework/Notification';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import * as userPermissionSelectors from '@/users/application/actions/permissions/selectors';
import * as actionTypes from '@/users/application/actions/permissions/actionTypes';
import UserRepository from '@/condor/infrastructure/repository/UserRepository';

const getUserRepository = (getState: () => any): UserRepository => getState().container.get(UserRepository);

export const getUserPermissionsModules = () => (dispatch, getState) => dispatch(createAsyncRequest(
  // @ts-ignore
  {
    method: getUserRepository(getState).getUserPermissionModules,
    types: [
      actionTypes.GET_USER_PERMISSIONS_MODULES,
      actionTypes.GET_USER_PERMISSIONS_MODULES_SUCCESS,
      actionTypes.GET_USER_PERMISSIONS_MODULES_FAILURE,
    ],
    callbacks: {
      onSuccess: ({ dispatch }) => {
        dispatch(getUserPermissionsGroup());
      },
    },
  },
));

export const getUserPermissionsGroup = () => (dispatch, getState) => dispatch(createAsyncRequest(
  // @ts-ignore
  {
    method: getUserRepository(getState).getUserPermissionGroup,
    types: [
      actionTypes.GET_USER_PERMISSIONS_GROUP,
      actionTypes.GET_USER_PERMISSIONS_GROUP_SUCCESS,
      actionTypes.GET_USER_PERMISSIONS_GROUP_FAILURE,
    ],
  },
));

/**
 * @param {Number} contactId
 */
export const getContactPermissions = (contactId) => (dispatch, getState) => dispatch(createAsyncRequest(
  {
    method: getUserRepository(getState).getContactPermissions,
    payload: contactId,
    types: [
      actionTypes.GET_CONTACT_PERMISSIONS,
      actionTypes.GET_CONTACT_PERMISSIONS_SUCCESS,
      actionTypes.GET_CONTACT_PERMISSIONS_FAILURE,
    ],
    callbacks: {
      onSuccess: ({ dispatch }) => {
        dispatch(getUserPermissionsModules());
      },
    },
  },
));

export const getCurrentUserPermissions = () => (dispatch, getState) => dispatch(createAsyncRequest(
  // @ts-ignore
  {
    method: getUserRepository(getState).getCurrentUserPermissions,
    types: [
      actionTypes.GET_CURRENT_USER_PERMISSIONS,
      actionTypes.GET_CURRENT_USER_PERMISSIONS_SUCCESS,
      actionTypes.GET_CURRENT_USER_PERMISSIONS_FAILURE,
    ],
  },
));

/**
 * @param payload
 * @param payload.status String
 * @param dispatch
 */
export const handleMessage = ({ status }, dispatch) => {
  if (status === 'error') {
    NotificationManager.error(getMessage(messageCodes.GENERAL_ERROR));
  } else NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
  dispatch(createAction(actionTypes.SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_SUCCESS));
};

/**
 * @param payload
 * @param payload.contactId Number
 * @param {Object[]} payload.userPermissions
 */
export const subScribeToUpdateContactPermissions = (payload) => async (
  dispatch,
  getState,
) => {
  dispatch(createAction(actionTypes.SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS));
  await getUserRepository(getState).subScribeToUpdateContactPermissions({
    payload,
    callback: (data) => handleMessage(data, dispatch),
  });
  await getUserRepository(getState).updateContactPermissions(payload);
};

/**
 * @param permissionObject
 * @param permissionObject.moduleKey String
 * @param permissionObject.permissionGroupKey String
 */
export const triggerUserPermissionGroup = (permissionObject) => async (
  dispatch,
  getState,
) => {
  let permissions = [];
  const state = getState();
  const { contactPermissions } = userPermissionSelectors.getUserPermissionsState(state);

  permissions = contactPermissions.find((item) => item.moduleKey === permissionObject.moduleKey)
    ? contactPermissions.filter((item) => item.moduleKey !== permissionObject.moduleKey)
    : contactPermissions.concat(permissionObject);

  dispatch({ type: actionTypes.TRIGGER_USER_PERMISSION_GROUP, permissions });
};
