import cn from 'classnames';
import {
  accountFieldRenderer,
  cellAccountCallback,
  cellCreatedAtCallback,
  cellEmailCallback,
  cellLastLoginAtCallback,
  cellStatusCallback,
  cellTitleCallback,
  phoneFieldRenderer,
  titleFieldRenderer,
} from './cellCallbacks';
import styles from './contactsTable.scss';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

const columns = [
  {
    name: 'status',
    title: 'Status',
    width: 38,
    cellCallback: cellStatusCallback,
    sortable: true,
  },
  {
    name: 'email',
    title: 'Email',
    cellCallback: cellEmailCallback,
    sortable: true,
  },
  {
    name: 'firstName',
    title: 'First Name',
    isEditable: true,
    cellCallback: commonCellCallback,
    minWidth: 90,
    sortable: true,
  },
  {
    name: 'lastName',
    title: 'Last Name',
    isEditable: true,
    cellCallback: commonCellCallback,
    minWidth: 115,
    sortable: true,
  },
  {
    name: 'titleId',
    title: 'Title',
    isEditable: true,
    minWidth: 130,
    editableFieldCallback: titleFieldRenderer,
    cellCallback: cellTitleCallback,
    sortable: true,
  },
  {
    name: 'workPhone',
    width: 220,
    minWidth: 220,
    isEditable: true,
    title: 'Phone',
    cellCallback: commonCellCallback,
    editableFieldCallback: phoneFieldRenderer,
    sortable: true,
  },
  {
    name: 'companyId',
    title: 'Account',
    isEditable: true,
    minWidth: 230,
    width: 230,
    editableFieldCallback: accountFieldRenderer,
    cellCallback: cellAccountCallback,
    sortable: true,
  },
  {
    name: 'createdAt',
    title: 'Created At',
    className: styles.dateCol,
    cellCallback: cellCreatedAtCallback,
    sortable: true,
  },
  {
    name: 'lastLoginAt',
    title: 'Last Login',
    cellCallback: cellLastLoginAtCallback,
    className: cn(styles.dateCol, alignStyles.alignRight),
    sortable: true,
  },
];

export default columns;
