import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';

const columns = [
  {
    name: 'rowId',
    title: '#',
    cellCallback: commonCellCallback,
    width: 48,
    maxWidth: 48,
  },
  {
    name: 'bondName',
    title: 'Bond',
    cellCallback: commonCellCallback,
    width: 240,
    maxWidth: 240,
  },
  {
    name: 'value',
    title: 'Value',
    cellCallback: commonCellCallback,
    width: 240,
    maxWidth: 240,
  },
  {
    name: 'validationError',
    title: 'Potential Error',
    cellCallback: commonCellCallback,
  },
];

export default columns;
