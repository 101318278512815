import ExternalRepository from '@/users/infrastructure/repository/ExternalRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import * as userSelectors from '@/users/application/actions/data/selectors';
import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';

export const getApiAccessKey = () => (
  async (dispatch, getState) => {
    const externalRepository: ExternalRepository = getState().container.get(ExternalRepository);
    const user = userSelectors.getUser(getState());

    try {
      const response = await externalRepository.getApiAccessKey(user.id);
      dispatch(createAction(actionTypes.GET_API_ACCESS_KEY, response));
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    }
  }
);

export const setApiAccessKey = () => (
  async (dispatch, getState) => {
    const user = userSelectors.getUser(getState());
    const externalRepository: ExternalRepository = getState().container.get(ExternalRepository);
    dispatch(createAction(actionTypes.IS_LOADING, true));

    try {
      const response = await externalRepository.setApiAccessKey(user.id);
      dispatch(createAction(actionTypes.SET_API_ACCESS_KEY, response));
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    }
  }
);
