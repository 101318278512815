import { change } from 'redux-form';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import PresentationMediaRepository from '@/dealroadshow/infrastructure/repository/PresentationMediaRepository';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';
import Upload from '@/Framework/UI/Organisms/Upload';
import * as actionTypes from './actionTypes';
import * as uploadActionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import * as processingTypes from '@/Framework/UI/Organisms/Upload/processingTypes';
import filterAudioVideoFromData from './AudioVideoForm/helpers/filterAudioVideoFromData';

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestAudioVideoSuccess(payload) {
  return {
    type: actionTypes.REQUEST_AUDIO_VIDEO_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestAudioVideoError(error) {
  return {
    type: actionTypes.REQUEST_AUDIO_VIDEO_ERROR,
    error,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function changeBackgroundProcessingStatus(payload) {
  return {
    type: uploadActionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE,
    payload,
  };
}

/**
 * @param {String} roadshowId
 */
export function getAudioVideo(roadshowId) {
  return async (dispatch, getState) => {
    const presentationMediaRepository = getState().container.get(PresentationMediaRepository);
    dispatch({ type: actionTypes.REQUEST_AUDIO_VIDEO });
    const roadshowRepository = getState().container.get(RoadshowRepository);
    try {
      // TODO: finalize after add action on backend
      let response = await roadshowRepository.getAdminAudioVideo(roadshowId);
      const { mediaVideo } = response.presentationMedia;

      const onProcessingStatusChange = (processingState) => {
        dispatch(changeBackgroundProcessingStatus(processingState.status));
      };

      if (mediaVideo && (mediaVideo.processingStatus === processingTypes.COMPLETED ||
                         mediaVideo.processingStatus === processingTypes.ERROR)) {
        dispatch(changeBackgroundProcessingStatus(mediaVideo.processingStatus));
      } else if (mediaVideo && mediaVideo.processingStatus === processingTypes.PROCESSING) {
        // eslint-disable-next-line max-len
        presentationMediaRepository.subscribeOnProcessingStatusChange(mediaVideo.id, onProcessingStatusChange);
      }

      const canBeChangedVideoType = await roadshowRepository.canBeChangedVideoType(roadshowId);

      dispatch(requestAudioVideoSuccess({
        initialValues: {
          ...response,
          canBeChangedVideoType,
        },
        data: response,
      }));
    } catch (err) {
      dispatch(requestAudioVideoError(err));
    }
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestAudioVideoUpdateSuccess(payload) {
  return {
    type: actionTypes.REQUEST_AUDIO_VIDEO_UPDATE_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestAudioVideoUpdateError(error) {
  return {
    type: actionTypes.REQUEST_AUDIO_VIDEO_UPDATE_ERROR,
    error,
  };
}

/**
 * @param {Object} formData
 */
export function updateAudioVideo(formData) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_AUDIO_VIDEO_UPDATE });
    try {
      formData.type = typeof formData.type === 'object'
        ? formData.type.value
        : formData.type;

      if ((!getState().upload.presentationMediaThumbnail.canSave
           && getState().upload.presentationMediaThumbnail.process.uuid !== '')
          || (!getState().upload.presentationMediaVideo.canSave
              && getState().upload.presentationMediaVideo.process.uuid !== '')
          || (!getState().upload.presentationMediaAudio.canSave
              && getState().upload.presentationMediaAudio.process.uuid !== '')) {
        dispatch(requestAudioVideoUpdateError('files uploading'));
        NotificationManager.warning(getMessage(messageCodes.CONDOR_FILES_UPLOADING));
      } else if (RoadshowType.isSlidesAndAudio(formData.type) &&
                 !getState().upload.presentationMediaAudio.canSave) {
        dispatch(requestAudioVideoUpdateError('files not add'));
        NotificationManager.warning(getMessage(messageCodes.CONDOR_NEED_ADD_AUDIO_FOR_SLIDES_AUDIO));
      } else if ((RoadshowType.isSlidesAndVideo(formData.type) || RoadshowType.isVideoOnly(formData.type)) &&
                 !getState().upload.presentationMediaVideo.canSave) {
        dispatch(requestAudioVideoUpdateError('files not add'));
        NotificationManager.warning(getMessage(messageCodes.CONDOR_NEED_ADD_VIDEO_FOR_SLIDES_VIDEO));
      } else {
        formData.upload = getState().upload;
        let saveData = filterAudioVideoFromData(formData);
        let response = await roadshowRepository.updateAdminAudioVideo(saveData);
        dispatch(requestAudioVideoUpdateSuccess(response));
        dispatch(uploadClear());
        NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
        dispatch(getAudioVideo(formData.id));
      }
    } catch (errorResponse) {
      dispatch(requestAudioVideoUpdateError(errorResponse));
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function uploadClearForForm() {
  return async (dispatch) => {
    dispatch(uploadClear());
  };
}

/**
 * @return {Object}
 */
function uploadClear() {
  return {
    type: Upload.actionTypes.UPLOAD_CLEAR,
  };
}

export function resetAudioVideo() {
  return async (dispatch) => {
    dispatch(uploadClear());
    dispatch({ type: actionTypes.AUDIO_VIDEO_RESET });
  };
}

export function shiftCuePoints(fields, removedIndex) {
  return (dispatch) => {
    // Shift slides that come after slide #removedIndex
    // all following slides' indexes are less by 1 since indexing will shift up after removal
    fields
      .map((_, index) => index)
      .filter((index) => index > removedIndex)
      .forEach((index) => {
        dispatch(change('audioVideoForm', `cuePoints[${ index - 1 }].slideNumber`, index));
      });
  };
}

export function shiftVideoOnlyCuePoints(fields, removedIndex) {
  return (dispatch) => {
    // Shift slides that come after slide #removedIndex
    // all following slides' indexes are less by 1 since indexing will shift up after removal
    fields
      .map((_, index) => index)
      .filter((index) => index > removedIndex)
      .forEach((index) => {
        dispatch(change('audioVideoForm', `videoOnlyCuePoints[${ index - 1 }].number`, index));
      });
  };
}
