import { useEffect, useState } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { Input, Paginator, IconType, SortOrder } from '@dealroadshow/uikit';
import AuditTrailTable from './AuditTrailTable';
import EventDetailsModal from './EventDetailsModal';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import styles from './auditTrail.scss';

interface IProps {
  className?: string,
  emptyMessageClassName?: string,
  dataTableContainerClassName?: string,
  searchInputClassName?: string,
  paginatorClassName?: string,
  totalCount: number,
  page: number,
  perPage: number,
  collection: { [key: string]: any }[],
  isCollectionFetching: boolean,
  sortBy: string,
  sortOrder: SortOrder,
  query: string,
  isAdmin: boolean,
  timeZone: ITimezone,
  label?: string,
  toolbar?: React.ReactNode,
  filterSectionToolbar?: React.ReactNode,
  tableWrpClassName?: string,
  tableWrpContentClassName?: string,
  labelClassName?: string,
  onMount?: () => void,
  onPageChange?: (page: { selected: number }) => void,
  onItemsPerPageChange?: (perPage: { value: number }) => void,
  onSortChange?: (sortBy: string, sortOrder: string) => void,
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onReset?: () => void,
  onChangeAccessType?: (id: string, accessType: string) => void,
  columnsSettings?: { [key: string]: any },
  iconType?: IconType,
  dataTest?: string,
  PaginatorComponent?: React.ElementType,
}

const AuditTrail = ({
  className,
  emptyMessageClassName,
  dataTableContainerClassName,
  searchInputClassName,
  paginatorClassName,
  totalCount,
  page,
  perPage,
  collection = [],
  isCollectionFetching,
  sortBy,
  sortOrder,
  query,
  isAdmin,
  timeZone,
  label = 'Audit Trail',
  toolbar,
  filterSectionToolbar,
  tableWrpClassName,
  tableWrpContentClassName,
  labelClassName,
  onMount = noop,
  onPageChange = noop,
  onItemsPerPageChange = noop,
  onSortChange = noop,
  onSearchChange = noop,
  onReset = noop,
  onChangeAccessType = noop,
  columnsSettings,
  iconType,
  dataTest,
  PaginatorComponent = Paginator,
}: IProps) => {
  useEffect(() => {
    onMount();
    return onReset;
  }, []);

  const [isEventDetailsModal, setIsEventDetailsModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleShowEventDetailsModal = (data) => {
    setModalData(data);
    setIsEventDetailsModal(true);
  };

  const handleHideEventDetailsModal = () => {
    setModalData(null);
    setIsEventDetailsModal(false);
  };

  const handleChangeAccessType = (id, accessType) => {
    onChangeAccessType(id, accessType);
  };

  return (
    <div className={ cn(cardStyles.cardContainer, className) }>
      <TableWrapper
        className={ tableWrpClassName }
        contentClassName={ tableWrpContentClassName }
        label={ label }
        labelClassName={ labelClassName }
        toolbar={ toolbar }
        filterComponent={ (
          <div className={ styles.filterSection }>
            { filterSectionToolbar }
            { /* @ts-ignore */ }
            <Input
              name="search"
              dataTest="auditTrailSearchInput"
              className={ cn(styles.searchInput, searchInputClassName) }
              formFieldClassName={ styles.searchInputWrapper }
              iconType={ iconType || IconType.search }
              value={ query }
              placeholder="Filter by Event, Email or IP"
              onChange={ onSearchChange }
              isNarrow
            />
          </div>
        ) }
      >
        <AuditTrailTable
          emptyMessageClassName={ emptyMessageClassName }
          dataTableContainerClassName={ dataTableContainerClassName }
          collection={ collection }
          isFetching={ isCollectionFetching }
          sortBy={ sortBy }
          sortOrder={ sortOrder }
          onSortChange={ onSortChange }
          onShowEventDetailModal={ handleShowEventDetailsModal }
          isAdmin={ isAdmin }
          timeZone={ timeZone }
          onChangeAccessType={ handleChangeAccessType }
          columnsSettings={ columnsSettings }
          dataTest={ dataTest }
        />
        <PaginatorComponent
          totalCount={ totalCount }
          page={ page }
          perPage={ perPage }
          onItemsPerPageChange={ onItemsPerPageChange }
          onPageChange={ onPageChange }
          className={ cn(cardStyles.cardInner, paginatorClassName) }
          dataTest={ dataTest }
        />
      </TableWrapper>
      { isEventDetailsModal && (
        <EventDetailsModal
          data={ modalData }
          onClose={ handleHideEventDetailsModal }
        />
      ) }
    </div>
  );
};

export default AuditTrail;
