import React from 'react';
import { useHistory } from 'react-router-dom';

import { Tabs, Tab } from '@dealroadshow/uikit';
import ArticlesTable from './ArticlesTable';
import { TABS } from './constants';

import styles from './articlesList.scss';
import tabsStyles from '@dealroadshow/uikit/dist/lib/styles/tabs.scss';

const ArticlesList = () => {
  const { push, location } = useHistory();

  const onClickTab = (path: string) => {
    if (location.pathname !== path) {
      push(path);
    }
  };

  const defaultTab = TABS.find(({ path }) => location.pathname === path)?.title || TABS[0].title;

  return (
    <>
      <Tabs
        dataTest="condorArticlesTabs"
        className={ styles.tabs }
        defaultTab={ defaultTab }
      >
        { TABS.map(({ title, path, dataTest }) => (
          <Tab
            key={ title }
            onClick={ () => onClickTab(path) }
            tabFor={ title }
            className={ tabsStyles.tab }
            activeClassName={ tabsStyles.isTabActive }
            name={ dataTest }
          >
            { title }
          </Tab>
          )) }
      </Tabs>
      <div className={ tabsStyles.tabsFooter } />
      <ArticlesTable />
    </>
  );
};

export default ArticlesList;
