import { connect } from 'react-redux';
import { Field } from 'redux-form';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import { validateDomain } from '@/Framework/UI/Organisms/FinalForm/validators/validateDomain';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';

function mapStateToProps(state, ownProps) {
  return {
    component: ReduxFormSelect,
    creatable: true,
    components: { DropdownIndicator: () => null },
    isMulti: true,
    backspaceRemovesValue: false,
    options: [],
    noOptionsMessage: () => null,
    ...ownProps,
  };
}

function normalizeValue(value = '') {
  if (value.indexOf('http://') !== -1) {
    value = value.replace('http://', '');
  }

  if (value.indexOf('https://') !== -1) {
    value = value.replace('https://', '');
  }

  if (value.indexOf('www.') !== -1) {
    value = value.replace('www.', '');
  }
  return value;
}

function mapDispatchToProps() {
  return {
    formatCreateLabel: (label) => `Create a "${ normalizeValue(label) }" by Enter`,
    isValidNewOption: (newElement) => {
      let label = normalizeValue(newElement);
      let validMinLength = !validateMinLength({
        fieldName: 'domain',
        value: label,
        length: 4,
      });
      let validMaxLength = !validateMaxLength({
        fieldName: 'domain',
        value: label,
        length: 253,
      });
      let validDomain = !validateDomain({
        fieldName: 'domain',
        value: label,
      });
      return !!label && validMinLength && validMaxLength && validDomain;
    },
    isOptionUnique: (value) => {
      let label = normalizeValue(value.option.domain);
      return value.options
        .filter((option) => option[value.labelKey] === label)
        .length === 0;
    },
    normalize: (value) => value?.map((domain) => ({
      ...domain,
      id: normalizeValue(domain.id),
      domain: normalizeValue(domain.domain || domain.value),
    })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Field);
