import { Icon } from '@dealroadshow/uikit';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';
import getFileExtension from '@/Framework/dataHelpers/string/getFileExtension';
import mapFileExtensionToIconType from '@/Framework/dataHelpers/mapFileExtensionToIconType';
import { mapExtensionToColor } from './helpers';

import styles from './styles.scss';

const AttachmentItem = ({ attachment }: { attachment: IAttachment }) => {
  const extension = getFileExtension(attachment.displayName);

  const icon = mapFileExtensionToIconType(extension);
  const color = mapExtensionToColor(extension);
  return (
    <p key={ attachment.uploadId } className={ styles.attachmentItem }>
      <Icon type={ icon } style={ { fill: color } } />
      <p className={ styles.attachmentItemName }>{ attachment.displayName }</p>
    </p>
  );
};

export default AttachmentItem;
