import React from 'react';
import cn from 'classnames';
import Status from '@/condor/ui/common/Layout/Status';
import { Spinner } from '@dealroadshow/uikit';

import styles from './profileWrapper.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';

interface IProps {
  isSpinnerVisible?: boolean,
  status?: {
    className: string,
    tooltipContent: string,
  },
  contentClassName?: string,
  containerClassName?: string,
  children: React.ReactNode,
}

const ProfileWrapper = ({
  isSpinnerVisible = false,
  status,
  contentClassName,
  containerClassName,
  children,
}: IProps) => (
  <div className={ cn(
    cardStyles.cardContent,
    cardStyles.cardInner,
    containerClassName,
  ) }
  >
    { status && (
      <Status
        status={ status }
        className={ styles.statusContainer }
        circleClassName={ styles.statusCircleBig }
      />
    ) }
    <Spinner
      isVisible={ isSpinnerVisible }
      overlay
      isFixed
    />
    <div className={ contentClassName }>
      { children }
    </div>
  </div>
);

export default ProfileWrapper;
