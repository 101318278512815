import React from 'react';
import cn from 'classnames';
import { BlockButton, Dropdown, LinkButton, MarkButton, TextColorPicker } from './components';
import { DEFAULT_FONT_SIZE_OPTIONS } from './constants';
import { IconType } from '@dealroadshow/uikit';
import { TToolbarPosition } from '@/Framework/UI/Molecules/Form/RichTextInput/interfaces';
import styles from './toolbar.scss';

interface IProps {
  value: string,
  position: TToolbarPosition,
  className?: string,
  fontSizeOptions?: string[],
  defaultFontSize?: string,
}

const Toolbar = (
  {
    value,
    position,
    className = '',
    fontSizeOptions = DEFAULT_FONT_SIZE_OPTIONS,
    defaultFontSize = DEFAULT_FONT_SIZE_OPTIONS[0],
  }: IProps,
) => {
  const toolbarIconsClassnames = position === 'popover' && {
    className: styles.popoverToolbarIcon,
    activeClassName: styles.popoverToolbarIconActive,
  };

  return (
    <div
      className={ cn(styles.toolbar, {
        [styles.isAtBottom]: position === 'bottom',
        [styles.popoverToolbarContainer]: position === 'popover',
      }, className) }
      data-test="toolbar"
    >
      <MarkButton format="bold" iconType={ IconType.bold } { ...toolbarIconsClassnames } />
      <MarkButton format="italic" iconType={ IconType.italic } { ...toolbarIconsClassnames } />
      <MarkButton format="underline" iconType={ IconType.underline } { ...toolbarIconsClassnames } />
      <BlockButton format="alignLeft" iconType={ IconType.alignLeft } { ...toolbarIconsClassnames } />
      <BlockButton format="alignCenter" iconType={ IconType.alignCenter } { ...toolbarIconsClassnames } />
      <BlockButton format="alignRight" iconType={ IconType.alignRight } { ...toolbarIconsClassnames } />
      <LinkButton { ...toolbarIconsClassnames } />
      <Dropdown
        format="fontSize"
        options={ fontSizeOptions }
        defaultValue={ defaultFontSize }
        { ...toolbarIconsClassnames }
      />
      <TextColorPicker value={ value } { ...toolbarIconsClassnames } />
    </div>
  );
};

export default Toolbar;
