export const DEFAULT_COLORS = {
  backgroundColor: '#F3F3F3',
  tileColor: '#FFFFFF',
  primaryTextColor: '#2D2D2D',
  secondaryTextColor: '#8C8C8C',
  linkColor: '#4A76B6',
  inputLabelColor: '#8C8C8C',
  buttonTextColor: '#FFFFFF',
  buttonColor: '#4A76B6',
};
