import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import PageWrapper from '@/evercall/ui/common/PageWrapper';
import { Spinner, Button, ButtonVariantType, Modal } from '@dealroadshow/uikit';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import Link from '@/Framework/Router/ReactRouter/Link';
import GroupProfileFormFields from './GroupProfileFormFields';
import GroupProfileContext, {
  useGroupProfileContext,
} from '@/evercall/application/condor/group/profile/GroupProfileContext';

import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';
import { GROUP_PROFILE_FORM_NAME } from './constants';
import { IGroupFormValues } from './interfaces';
import { ICreateGroupProps } from '@/evercall/application/condor/group/profile/interfaces';
import { IGroup } from '@/evercall/domain/vo/call/admin/Group';

import validate from './validate';
import isEmpty from 'lodash/isEmpty';

import styles from './groupProfile.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import headerStyles from '@dealroadshow/uikit/dist/lib/styles/headers.scss';
import modalStyles from '@/condor/ui/common/Layout/modal.scss';

const GroupProfile = () => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    state: {
      isInitialized,
      isUpdateSucceeded,
      isUpdating,
      isCreating,
      isDeleting,
      item,
      isCreateGroup,
    },
    initCallProfile,
    setIsInitialized,
    createGroup,
    updateGroup,
    deleteGroup,
  } = useGroupProfileContext();

  useEffect(() => {
    if (isCreateGroup) {
      setIsInitialized(true);
    } else {
      initCallProfile();
    }
  }, []);

  const submit = (values: IGroupFormValues) => {
    const payload: IGroup = {
      ...values,
      footerText: values.footerText || '',
      events: values.events.filter((e) => !!e.id).map((e) => ({
        id: e.id.value,
        useCustomization: e.useCustomization,
      })),
    };

    if (isCreateGroup) {
      createGroup(payload);
    } else {
      updateGroup(payload);
    }
  };

  return (
    <PageWrapper label={ isCreateGroup ? 'Create Group' : `Group: ${ item?.name || '' }` }>
      <ProfileWrapper>
        <div className={ cn(headerStyles.isH3, styles.panelHeaderLabel) }>Settings</div>
        <Spinner
          isVisible={ !isInitialized || isCreating || isUpdating || isDeleting }
          overlay
          isFixed
        />
        { isInitialized && (
          <FinalForm<IGroupFormValues>
            name={ GROUP_PROFILE_FORM_NAME }
            dataTest={ GROUP_PROFILE_FORM_NAME }
            validate={ validate }
            className={ formStyles.formWrp }
            onSubmit={ submit }
            render={ (
              {
                errors,
                pristine,
              },
            ) => {
              return (
                <>
                  <GroupProfileFormFields />
                  <div className={ styles.formButtonsContainer }>
                    <div>
                      <Button
                        type="submit"
                        variant={ ButtonVariantType.action }
                        title={ isCreateGroup ? 'Create' : 'Save' }
                        dataTest="saveButton"
                        disabled={ !isEmpty(errors) || pristine || isUpdating || isCreating }
                      />
                      <Link to={ CONDOR_EVERCALL_LIST }>
                        <Button
                          variant={ ButtonVariantType.text }
                          title="Cancel"
                          dataTest="cancelButton"
                        />
                      </Link>
                    </div>
                    <AreYouSureModal
                      when={ !pristine && !isCreating && !isUpdating && !isUpdateSucceeded }
                      disableNative={ false }
                    />
                    <Modal
                      onCloseClicked={ () => setIsDeleteModalVisible(false) }
                      isVisible={ isDeleteModalVisible }
                      title="Delete Group"
                      dataTest="deleteGroupModal"
                      footer={ (
                        <>
                          <Button
                            onClick={ deleteGroup }
                            variant={ ButtonVariantType.warning }
                            title="Delete"
                            dataTest="deleteButton"
                          />
                          <Button
                            variant={ ButtonVariantType.text }
                            onClick={ () => setIsDeleteModalVisible(false) }
                            title="Cancel"
                            dataTest="cancelButton"
                          />
                        </>
                      ) }
                    >
                      <div className={ modalStyles.areYouSureLabel }>
                        Are you sure you want to delete group <b>{ item?.name || '' }</b>?
                      </div>
                    </Modal>
                  </div>
                </>
              );
            } }
          />
        ) }
      </ProfileWrapper>
    </PageWrapper>
  );
};

export default (props: ICreateGroupProps) => (
  <GroupProfileContext { ...props }>
    <GroupProfile />
  </GroupProfileContext>
);
