import { StatusIndicator, StatusIndicatorType, Tooltip } from '@dealroadshow/uikit';
import UpdatedAtCell from './UpdatedAtCell';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import SectorIndustryLabel from '@/condor/ui/components/Finsight/CreditFlowResearch/common/SectorIndustryLabel';

import styles from './styles.scss';

export const textFromArray = ({ cellData }: { cellData: string[] }) => (
  <>{ cellData.length > 0 ? cellData.join(', ') : '-' }</>
);

export const statusCellCallback = ({ cellData }: { cellData: boolean }) => {
  return (
    <Tooltip
      content={ cellData ? 'Active' : 'Inactive' }
    >
      <StatusIndicator
        status={ cellData ? StatusIndicatorType.published : StatusIndicatorType.expired }
      />
    </Tooltip>
  );
};

export const sectorIndustryCellCallback = ({ cellData, row }) => (
  <SectorIndustryLabel sectorOrIndustry={ cellData } subsectorOrSubindustry={ row.subsectorOrSubindustry } />
);

// we need to add link to finsight post page here after implementation
export const headlineCellCallback = ({ cellData }: { cellData: string }) => (
  <span className={ styles.headline }>{ cellData || '-' }</span>
);

export const themesCellCallback = ({ cellData }: { cellData: string[] }) => {
  if (cellData.length > 1) {
    return (
      <Tooltip
        content={ (
          <div className={ styles.tooltipContent }>
            { cellData.map((theme: string) => (
              <p className={ styles.tooltipItem } key={ theme }>{ theme }</p>
          )) }
          </div>
        ) }
        className={ styles.themeTooltip }
      >
        <span className={ styles.themeItem }>{ cellData[0] }</span> <span className={ styles.themeItem }>...</span>
      </Tooltip>
    );
  }
  return (
    <span className={ styles.themeItem }>{ cellData[0] }</span>
  );
};

export const authorCellCallback = ({ cellData: { name } }) => (
  <>{ name }</>
);

export const regionCellCallback = ({ cellData }: { cellData: string[] }) => {
  if (cellData.length > 2) {
    return (
      <Tooltip
        content={ cellData.join(', ') }
      >
        { cellData.slice(0, 2).join(', ') }, ...
      </Tooltip>
    );
  }
  return textFromArray({ cellData });
};

export const createdAtCellCallback = ({ cellData }: { cellData: string }) => {
  return (
    <>
      { getCellDisplayDateFromString(cellData) }
    </>
  );
};

export const updatedAtCellCallback = (props) => (
  <UpdatedAtCell { ...props } />
);
