import { useEffect, useState } from 'react';
import cn from 'classnames';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import Company from '@/Framework/UI/Organisms/EnhancedTagging/Field/Company';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import { CheckboxInput, Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import validate from './validator';
import styles from './rows.scss';

export const INPUT_MIN_LENGTH = 3;

interface IProps {
  fields: any,
  fieldComponent: React.ElementType,
  companies: object,
  rowClassName?: string,
  disabled?: boolean,
  label?: string,
  placeholder?: string,
  modalCall?: boolean,
  modalCallText?: string,
  modalShow: (payload: any) => void,
  clearOnBlur?: boolean,
  onSuggestionSelected?: (val: string, fieldName: string) => void,
}

const Rows = ({
  fields,
  fieldComponent: Field,
  companies,
  rowClassName,
  disabled,
  label,
  placeholder,
  modalCall,
  modalCallText,
  modalShow,
  clearOnBlur = false,
  onSuggestionSelected,
}: IProps) => {
  const [emptyField, setEmptyField] = useState(false);

  const handleFieldRemove = (index) => {
    if (fields.length > 1) {
      fields.remove(index);
    } else {
      fields.update(index, { name: '' });
    }
  };

  // method fields.getAll() refers to redux-form, and fields.value refers to final-form
  useEffect(() => {
    const fieldsValue = fields.getAll?.() || fields?.value;
    if (fieldsValue?.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fieldsValue) {
        if (field?.name?.trim().length >= INPUT_MIN_LENGTH) {
          setEmptyField(false);
        } else {
          setEmptyField(true);
          break;
        }
      }
    }
  }, [JSON.stringify(fields.getAll?.() || fields?.value)]);

  const rows = (
    <div className={ styles.inputsWrp } data-test="underwritersRows">
      { fields.map((field, index) => (
        <div
          key={ field }
          className={ cn(styles.row, rowClassName) }
          data-test="underwriterRow"
        >
          <div className={ styles.inputWrapper }>
            <TextField
              name={ `${ field }.name` }
              dataTest="underwriterName"
              placeholder={ placeholder || 'Name' }
              // @ts-ignore
              validate={ validate }
              component={ Company }
              fieldComponent={ Field }
              formFieldClassName={ styles.inputField }
              companies={ companies }
              disabled={ disabled }
              modalCall={ modalCall }
              modalCallText={ modalCallText }
              modalShow={ modalShow }
              clearOnBlur={ clearOnBlur }
              onSuggestionSelected={ onSuggestionSelected }
            />
          </div>
          <div className={ styles.checkboxContainer }>
            <Field
              component={ CheckboxInput }
              type="checkbox"
              name={ `${ field }.lead` }
              disabled={ disabled }
              dataTest="underwriterLead"
            />
          </div>
          { !disabled && (
            <Button
              variant={ variantTypes.text }
              onClick={ () => handleFieldRemove(index) }
              className={ styles.deleteButton }
              dataTest="underwriterDelete"
              disabled={ disabled }
            >
              <Tooltip
                placement="bottom"
                content="Delete underwriter"
              >
                <Icon
                  type={ IconType.trash }
                  className={ styles.deleteButtonIcon }
                />
              </Tooltip>
            </Button>
          ) }
        </div>
      )) }
    </div>
  );

  return (
    <>
      { rows }
      { !disabled && (
        <Button
          variant={ variantTypes.text }
          className={ cn(styles.addMoreButton, { [styles.addMoreButtonDisabled]: emptyField }) }
          onClick={ () => fields.push({ name: '' }) }
          dataTest="underwritersAdd"
          disabled={ emptyField }
        >
          <Icon
            type={ IconType.add }
            className={ styles.addMoreIcon }
          />
          { label || 'Add Underwriter' }
        </Button>
      ) }
    </>
  );
};

export default Rows;
