import React from 'react';
import { ICellProps, IconType } from '@dealroadshow/uikit';
import { CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { getCellDisplayDateFromNumber } from '@/condor/ui/common/Layout/helpers';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';

interface IProps {
  cellProps: ICellProps,
}

const UpdatedAtCell = ({ cellProps: { row: { id, updatedAt } } }: IProps) => (
  <LastTableCell
    childrenToShowOnHover={ (
      <TableRowActions
        actions={
          [
            {
              url: `${ CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL }/${ id }`,
              tooltipContent: 'Profile',
              iconType: IconType.settings,
            },
          ]
        }
      />
    ) }
  >
    { getCellDisplayDateFromNumber(updatedAt) }
  </LastTableCell>
);

export default UpdatedAtCell;
