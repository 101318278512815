import React from 'react';
import AuditTrailComponent from '@/Framework/UI/Organisms/AuditTrail';
import ExportToExcelButton from './ExportToExcelButton';
import styles from './auditTrail.scss';

const AuditTrail = (props) => (
  <AuditTrailComponent
    { ...props }
    tableWrpClassName={ styles.auditTrailTableWrapper }
    filterSectionToolbar={ (
      <ExportToExcelButton
        isAdmin
        wrapperClassName={ styles.exportButton }
        iconClassName={ styles.exportButtonIcon }
      />
    ) }
  />
);

export default AuditTrail;
