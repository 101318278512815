import { useEffect, useRef } from 'react';

/**
 * useEffect hook that only runs on updates
 * Easy way to replace componentDidUpdate
 * @param effect Callback function to be executed
 * @param deps dependencies
 */
const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      effect();
    } else {
      mounted.current = true;
    }
  }, deps);
};

export default useUpdateEffect;
