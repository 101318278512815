import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { DataTable, dataTableStyles, Paginator, Spinner } from '@dealroadshow/uikit';
import SearchInput from '@/Framework/UI/Organisms/FinalForm/Fields/SearchInput';
import { useUsersContext } from '@/openMarket/application/Condor/UsersContext';
import { useWhitelistContext } from '../WhitelistContext';
import { columns } from './columns';

import styles from './whitelistTable.scss';

const ITEMS_PER_PAGE = 10;

const WhitelistList = () => {
  const { whitelist } = useWhitelistContext();
  const { isFetching } = useUsersContext();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const onSearch = (search: string) => {
    setSearch(search);
    setPage(1);
  };

  const filteredBySearchData = useMemo(() => {
    const searchString = search.trim().toLowerCase();

    if (searchString.length === 0) {
      return whitelist;
    }

    return whitelist.filter(({ emailOrDomain }) => {
      return emailOrDomain.toLowerCase().includes(searchString);
    });
  }, [whitelist, search]);

  const visibleData = useMemo(() => {
    return filteredBySearchData.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  }, [filteredBySearchData, page]);

  useEffect(() => {
    /**
     * Decrease the number of page in case user delete last record on current page
     */
    if (visibleData.length === 0 && page !== 1) {
      setPage((page) => page - 1);
    }
  }, [visibleData, page]);

  return (
    <>
      <SearchInput
        className={ styles.search }
        dataTest="whitelistSearch"
        value={ search }
        onChange={ onSearch }
        placeholder="Filter by email or domain"
      />
      <DataTable
        dataTest="whitelistTable"
        isFetching={ isFetching }
        loadingComponent={ Spinner }
        containerClassName={ cn(styles.dataTableContainer, dataTableStyles.dataTableContainer) }
        data={ visibleData }
        columns={ columns }
        showEmptyStateMessage
        showEmptyStateMessageFirst
        emptyMessage={ search ? `There is no email/domain contains "${ search }"` : 'There is no email/domain added' }
      />
      <Paginator
        className={ styles.paginator }
        dataTest="whitelistPaginator"
        totalCount={ filteredBySearchData.length }
        page={ page }
        perPage={ ITEMS_PER_PAGE }
        onPageChange={ ({ selected }) => setPage(selected) }
        isItemsPerPageVisible={ false }
      />
    </>
  );
};

export default WhitelistList;
