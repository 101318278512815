import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

export const defaultInitialFormData: ArticleFormData = {
  headline: '',
  description: '<p class="EditorTheme__paragraph"><br></p>',
  isActive: false,
  sendEmail: false,
  themes: [],
  deals: [],
  parentAndIssuer: [],
  attachments: [],
};
