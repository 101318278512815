import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { ArticleFormData, Attachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { UploadTable } from '@/Framework/UI/Organisms/Upload/components';
import { Field, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import UploadAttachmentsContextProvider, {
  useUploadAttachmentsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/UploadAttachmentsContext';
import {
  UPLOAD_ATTACHMENTS_ALLOWED_FILES,
  UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT,
  UPLOAD_TABLE_COLUMNS,
  UPLOAD_TABLE_WIDTHS,
} from '@/Framework/UI/Organisms/Upload/constants';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

type TUploadAttachmentsFields = IFinalFormFieldArrayInputComponentProps<Attachment>['fields'];

interface IUploadAttachmentsFields {
  fields: TUploadAttachmentsFields,
}

const UploadAttachments = ({ fields }: IUploadAttachmentsFields) => {
  const formState = useFormState<ArticleFormData>();

  const {
    files,
    uploadDocuments,
    cancelDocumentUpload,
    changeDocumentsOrder,
  } = useUploadAttachmentsContext();

  return (
    <>
      <label className={ formStyles.formLabel }>Attachments</label>
      <UploadTable
        fieldComponent={ Field }
        uploadFiles={ files }
        uploadList={ formState.values.attachments || [] }
        formFieldName={ 'attachments' }
        fields={ fields }
        columns={ UPLOAD_TABLE_COLUMNS }
        widths={ UPLOAD_TABLE_WIDTHS }
        allowedFileTypes={ UPLOAD_ATTACHMENTS_ALLOWED_FILES }
        allowedFileText={ UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT }
        limit={ 20 }
        uploadName=""
        onUpload={ uploadDocuments }
        onUploadCancel={ cancelDocumentUpload }
        onUploadOrder={ changeDocumentsOrder }
      />
    </>
  );
};

export default (props: IUploadAttachmentsFields) => (
  <UploadAttachmentsContextProvider>
    <UploadAttachments { ...props } />
  </UploadAttachmentsContextProvider>
);
