import React, { useCallback } from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import styles from './deleteTrancheButton.scss';

interface IProps {
  index: number,
  isExcluded: boolean,
  excludeTranche: (index: number) => void,
  undoExcludeTranche: (index: number) => void,
}

const DeleteTrancheButton = ({ index, isExcluded, excludeTranche, undoExcludeTranche }: IProps) => {
  const onClick = useCallback(
    () => (!isExcluded ? excludeTranche(index) : undoExcludeTranche(index)),
    [isExcluded],
  );

  return (
    <Tooltip content={ `${ isExcluded ? 'Undo ' : '' }Delete` }>
      <Button
        variant={ variantTypes.text }
        className={ styles.deleteTrancheBtn }
        onClick={ onClick }
        dataTest={ `deleteTrancheButton${ index }` }
      >
        <Icon
          className={ styles.deleteTrancheIcon }
          type={ isExcluded ? IconType.rotate : IconType.trash }
        />
      </Button>
    </Tooltip>
  );
};

export default DeleteTrancheButton;
