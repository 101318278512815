import { Dispatch } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import * as correctionTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/correctionTypes';
import { TableBodyInterface } from '@/condor/domain/vo/types';

export const calculateBodyErrors = (body: Record<string, TableBodyInterface>) => {
  let count = 0;
  Object.keys(body).forEach((rowIndex) => Object.keys(body[rowIndex]).forEach((columnIndex) => {
    let cell = body[rowIndex][columnIndex];
    if (cell.correctionType && !cell.corrected) {
      count++;
    }
  }));
  return count;
};

export const checkUiCorrectionOnly = (
    body: Record<string, TableBodyInterface>,
    header: string,
    changeUiCorrectionOnlyAction: Function,
) => (dispatch: Dispatch) => {
  let uiCorrectionOnly = true;
  Object.keys(body).find((rowIndex: string) => Object.keys(body[rowIndex]).find((columnIndex: string) => {
    let cell = body[rowIndex][columnIndex];
    let headerCell = header[columnIndex];
    if (cell.correctionType && !headerCell.canUiCorrection) {
      uiCorrectionOnly = false;
      return true;
    }
    return false;
  }));
  dispatch(changeUiCorrectionOnlyAction(uiCorrectionOnly));
};

export const modifyCompanyDataAndBody = (selectCompany, state, selectors) => {
  let body = cloneDeep(selectors.getTableBody(state));
  let correctionData = cloneDeep(selectors.getCorrectionData(state));
  let activeValidateCell = cloneDeep(selectors.getActiveValidateCell(state));
  const activeCellRowIndex = activeValidateCell.position.rowIndex;
  const activeCellColumnIndex = activeValidateCell.position.columnIndex;
  const companySelectModalCollection = selectors.getCompanySelectModalCollection(state);
  const selectCompanyId = selectCompany.value ? selectCompany.value : selectCompany.id;
  const selectCompanyData = selectCompany.value
      ? companySelectModalCollection.find((company) => company.id === selectCompany.value)
      : selectCompany;

  if (!correctionData[activeCellRowIndex]) {
    correctionData[activeCellRowIndex] = {};
  }

  correctionData[activeCellRowIndex][activeCellColumnIndex] = selectCompanyId;

  body[activeCellRowIndex][activeCellColumnIndex] = {
    ...body[activeCellRowIndex][activeCellColumnIndex],
    corrected: true,
  };

  Object.keys(body).forEach((rowIndex) => {
    let cell = body[rowIndex][activeCellColumnIndex];
    if (cell.correctionType) {
      switch (cell.correctionType) {
        case correctionTypes.companyLead:
          if (activeValidateCell.value === cell.value) {
            body[rowIndex][activeCellColumnIndex] = {
              ...body[rowIndex][activeCellColumnIndex],
              corrected: true,
              value: selectCompanyData.abbreviation,
            };
            if (!correctionData[rowIndex]) {
              correctionData[rowIndex] = {};
            }
            correctionData[rowIndex][activeCellColumnIndex] = selectCompanyId;
          }
          break;
        case correctionTypes.companyParent:
        case correctionTypes.companySelect:
          if (
              activeValidateCell.data.name === cell.data.name
              && activeValidateCell.data.issuerTicker === cell.data.issuerTicker
          ) {
            body[rowIndex][activeCellColumnIndex] = {
              ...body[rowIndex][activeCellColumnIndex],
              corrected: true,
              value: selectCompanyData.name,
            };
            if (!correctionData[rowIndex]) {
              correctionData[rowIndex] = {};
            }
            correctionData[rowIndex][activeCellColumnIndex] = selectCompanyId;
          }
          break;
        default:
          break;
      }
    }
  });

  return {
    body,
    correctionData,
  };
};

export const getValidationSelectors = (getStateByPath) => ({
  getCorrectionData: (state) => getStateByPath(state).correctionData,
  isUiCorrectionOnly: (state) => getStateByPath(state).uiCorrectionOnly,
  isValidationTableVisible: (state) => getStateByPath(state).validationTableVisible,
  isCompanySelectModalVisible: (state) => getStateByPath(state).companySelectModalVisible,
  getCompanySelectModalCollection: (state) => getStateByPath(state).companySelectModalCollection,
  isCompanyCreateModalVisible: (state) => getStateByPath(state).companyCreateModalVisible,
  getCompanyCreateModalCompany: (state) => getStateByPath(state).companyCreateModalCompany,
  getActiveValidateCell: (state) => getStateByPath(state).activeValidateCell,
  isCanReimportFile: (state) => getStateByPath(state).canReimportFile,
  getValidationProcess: (state) => getStateByPath(state).process,
  getTableHeader: (state) => getStateByPath(state).table.header,
  getTableBody: (state) => getStateByPath(state).table.body,
  getTableBodyExistsColumn: (state) => getStateByPath(state).bodyExistsColumn,
  getPotentialErrors: (state) => getStateByPath(state).potentialErrorsList,
});
