import { isInvalid, isPristine, isSubmitting, submit } from 'redux-form';
import { batch, useDispatch } from 'react-redux';
import { CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import ActionsCell from './ActionsCell';
import {
  setCondorFinsightCompanyCurrentDeal,
  resetCondorFinsightCompanyDealItem,
  setCondorFinsightCompanyDealIsInlineEditing,
  setCondorFinsightCompanyDealIsDeleteModalVisible,
  exportDealToExcel,
} from '@/condor/application/actions/finsight/company/deals/item/actions';
import selectors from '@/condor/application/actions/finsight/company/deals/item/selectors';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';

interface IReduxProps {
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  isDownloading: boolean,
  inlineEditFormState: {
    pristine: boolean,
    invalid: boolean,
    submitting: boolean,
  },
}

const stateSelector = (row) => (state): IReduxProps => ({
  isSelectedForEditing: selectors.getItemData(state).id === row?.id,
  isUpdating: selectors.isUpdating(state),
  isDownloading: selectors.dealIdDownloading(state) === row?.id,
  inlineEditFormState: {
    pristine: isPristine(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
    invalid: isInvalid(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
    submitting: isSubmitting(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
  },
});

const ActionsCellContainer = (props) => {
  const dispatch = useDispatch();
  const {
    isSelectedForEditing,
    isUpdating,
    isDownloading,
    inlineEditFormState,
  } = useReduxSelector(stateSelector(props.row));

  const onSubmit = () => dispatch(submit(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME));

  const onCancel = () => dispatch(resetCondorFinsightCompanyDealItem());

  const onEditClick = () => batch(() => {
    dispatch(setCondorFinsightCompanyDealIsInlineEditing(true));
    dispatch(setCondorFinsightCompanyCurrentDeal(props.row));
  });

  const onDeleteClick = () => batch(() => {
    dispatch(setCondorFinsightCompanyDealIsDeleteModalVisible(true));
    dispatch(setCondorFinsightCompanyCurrentDeal(props.row));
  });

  const exportToExcel = () => dispatch(exportDealToExcel(props.row?.id));

  return (
    <ActionsCell
      { ...props }
      isSelectedForEditing={ isSelectedForEditing }
      isUpdating={ isUpdating }
      isDownloading={ isDownloading }
      inlineEditFormState={ inlineEditFormState }
      onSubmit={ onSubmit }
      onCancel={ onCancel }
      onEditClick={ onEditClick }
      onDeleteClick={ onDeleteClick }
      exportToExcel={ exportToExcel }
    />
  );
};

export default ActionsCellContainer;
