import createAction from '@/Framework/State/Redux/createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import { NotificationManager } from '@/Framework/Notification';
import * as actionTypes from './actionTypes';

/**
 * @param {Object} payload
 */
export function getCompanies(payload) {
  return async (dispatch, getState) => {
    const companiesRepository = getState().container.get(CompaniesRepository);

    dispatch(createAction(actionTypes.GET_COMPANIES));

    try {
      const response = await companiesRepository.list({ ...payload, verified: true, limit: payload.limit || 50 });
      dispatch(createAction(actionTypes.GET_COMPANIES_SUCCESS, response));
      return response;
    } catch (e) {
      dispatch(createAction(actionTypes.GET_COMPANIES_FAILURE, e));
      NotificationManager.error(getErrorMessage(e));
      return e;
    }
  };
}

export const resetCompaniesData = () => createAction(actionTypes.RESET_COMPANIES_DATA);
