import React from 'react';
import { CheckAll as BaseCheckAll } from '@/Framework/UI/Molecules/CheckBoxArray';
import styles from './checkAll.scss';

interface IProps {
  dataTest: string,
  name?: string,
  disabled?: boolean,
}

const CheckAll = ({ name, dataTest, disabled }: IProps) => (
  <BaseCheckAll
    name={ name }
    className={ styles.checkAll }
    dataTest={ dataTest }
    disabled={ disabled }
  />
);

export default CheckAll;
