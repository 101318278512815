interface IToSlugOptions {
  trim?: boolean,
  separator?: string,
  replaceRegex?: RegExp,
}

export const toSlug = (
  str: string,
  {
    replaceRegex = /[^A-Z0-9]+/ig,
    trim = true,
    separator = '-',
  }: IToSlugOptions = {},
): string => {
  let slug = str.toLowerCase().replace(replaceRegex, ' ');

  if (trim) {
    slug = slug.trim();
  }

  return slug.replace(/ /g, separator);
};
