import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import { useUploadSsoCertificateContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';

const CellAction = ({ id, name }: ISsoCertificate) => {
  const { setCertificateForDelete } = useUploadSsoCertificateContext();

  const onClick = () => {
    setCertificateForDelete({ id, name });
  };

  return (
    <LastTableCell
      className={ styles.cellActions }
      childrenToShowOnHover={ (
        <Icon className={ styles.buttonRemove } type={ IconType.trash } onClick={ onClick } data-test="deleteCertificate" />
      ) }
    />
  );
};

export default CellAction;
