import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import GroupSelectWithToolbar, { useToolbarSelectSync } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import { USOA_REGION_ID } from '@/finsight/domain/Region';
import {
  ThemesContextProvider,
  useThemesContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';

const ThemeSelect = ({
  input,
  ...otherProps
}: IFinalFormFieldInputComponentProps) => {
  const { themeOptions } = useThemesContext();

  return (
    <GroupSelectWithToolbar
      { ...otherProps }
      name={ input.name }
      value={ input.value }
      onBlur={ input.onBlur }
      onChange={ input.onChange }
      label="Themes"
      dataTest="themes"
      placeholder="Select themes"
      { ...useToolbarSelectSync({
        options: themeOptions,
        defaultOptionValue: USOA_REGION_ID,
        dataTest: 'themesFilters',
      }) }
      formatValue={ (option) => `${ option.regionName } ${ option.label }` }
    />
  );
};

export default (props: IFinalFormFieldInputComponentProps) => (
  <ThemesContextProvider>
    <ThemeSelect { ...props } />
  </ThemesContextProvider>
);
