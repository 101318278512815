import React from 'react';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { useEnhancedTaggingCondorContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { TruncateTextWithTooltip, ICellProps } from '@dealroadshow/uikit';

import spaces from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

const CountryCallback = ({ row: { countryId, id }, rowIndex }: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();
  const { countriesOptions } = useEnhancedTaggingCondorContext();

  if (editableRowId === id) {
    return (
      <Field
        name={ `${ fieldRowName }.countryId` }
        placeholder="Select Country"
        formFieldClassName={ spaces.mbn }
        // @ts-ignore
        component={ FinalFormSelect }
        simpleValue
        asterisk
        options={ countriesOptions }
        dataTest={ `country[${ rowIndex }]` }
      />
    );
  }

  if (!countryId) {
    return '-';
  }

  const countryName = countriesOptions.find((item) => item.value === countryId)?.label;

  return <TruncateTextWithTooltip value={ countryName } />;
};

export default CountryCallback;
