import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateNumberMaxMinAmount } from '@/Framework/UI/Organisms/FinalForm/validators/validateNumberMaxMinAmount';
import { IFormValues } from '@/openMarket/ui/components/Condor/Events/EventForm/interfaces';

import { FieldsLabel, FieldsNames } from './constants';

const validatePricingDate = (values: IFormValues) => {
  const pricingDate = values[FieldsNames.EXPECTED_PRICING_DATE];

  if (!pricingDate) {
    return null;
  }

  const isBefore = getUnixTimestamp() > getUnixTimestamp(pricingDate);

  if (isBefore) {
    return {
      [FieldsNames.EXPECTED_PRICING_DATE]: 'Pricing date must be in the future',
    };
  }

  return null;
};

const validateDisplayOnFields = (values: IFormValues) => {
  const displayOn = values[FieldsNames.DISPLAY_ON];

  const isSomeDisplayOn = Object.values(displayOn).some(Boolean);

  if (!isSomeDisplayOn) {
    return {
      [FieldsNames.DISPLAY_ON]: 'At least one Display Option should be selected',
    };
  }

  return null;
};

export default (values: IFormValues) => {
  return groupValidators(
    // LINK TO ROADSHOW
    validateRequiredField({
      fieldName: FieldsNames.ROADSHOW_LINK,
      fieldCaption: FieldsLabel.ROADSHOW_LINK,
      value: values[FieldsNames.ROADSHOW_ID],
    }),
    // EVENT NAME
    validateRequiredField({
      fieldName: FieldsNames.NAME,
      fieldCaption: FieldsLabel.NAME,
      value: values[FieldsNames.NAME],
    }),
    validateMinLength({
      fieldName: FieldsNames.NAME,
      fieldCaption: FieldsLabel.NAME,
      value: values[FieldsNames.NAME],
      length: 3,
    }),
    validateMaxLength({
      fieldName: FieldsNames.NAME,
      fieldCaption: FieldsLabel.NAME,
      value: values[FieldsNames.NAME],
      length: 128,
    }),
    // DESCRIPTION
    validateRequiredField({
      fieldName: FieldsNames.DESCRIPTION,
      fieldCaption: FieldsLabel.DESCRIPTION,
      value: values[FieldsNames.DESCRIPTION],
    }),
    validateMinLength({
      fieldName: FieldsNames.DESCRIPTION,
      fieldCaption: FieldsLabel.DESCRIPTION,
      value: values[FieldsNames.DESCRIPTION],
      length: 3,
    }),
    // TENOR
    validateNumberMaxMinAmount({
      fieldName: FieldsNames.TENOR,
      fieldCaption: FieldsLabel.TENOR,
      value: Number(values[FieldsNames.TENOR]),
      minAmount: 0,
      maxAmount: 999999.999,
    }),
    // EQUITY TICKER
    validateMinLength({
      fieldName: FieldsNames.EQUITY_TICKER,
      fieldCaption: FieldsLabel.EQUITY_TICKER,
      value: values[FieldsNames.EQUITY_TICKER],
      length: 3,
    }),
    validateMaxLength({
      fieldName: FieldsNames.EQUITY_TICKER,
      fieldCaption: FieldsLabel.EQUITY_TICKER,
      value: values[FieldsNames.EQUITY_TICKER],
      length: 128,
    }),
    validateDisplayOnFields(values),
    validatePricingDate(values),
  );
};
