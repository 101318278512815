import ArticleActionModal from '../ArticleActionModal';
import {
  useUnpublishModalContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/UnpublishModalContext';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

const UnpublishActionModal = () => {
  const { isOpenModal, setIsOpenModal, article, onUnpublish, isUnpublishing } = useUnpublishModalContext();

  return (
    <ArticleActionModal
      isModalOpen={ isOpenModal }
      modalType={ ActionModalType.UNPUBLISH }
      closeModal={ () => setIsOpenModal(false) }
      article={ article }
      handleAction={ onUnpublish }
      isRequestPerforming={ isUnpublishing }
    />
  );
};

export default UnpublishActionModal;
