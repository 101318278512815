import { validateEmail } from './user/validateEmail';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';

export const validateEmailDomain = (params: {
  value: string,
  fieldName: string,
  expectedDomain: string,
  emailName: string,
}): IValidationError => {
  if (validateEmail(params) || (params.value && params.value.split('@')[1] !== params.expectedDomain)) {
    return { [params.fieldName]: `Invalid ${ params.emailName } Email` };
  }
  return null;
};
