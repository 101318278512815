import React from 'react';
import cn from 'classnames';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Icon, IconType } from '@dealroadshow/uikit';

import styles from './addBannerButton.scss';

interface IProps {
  isDisabled?: boolean,
  onClick: () => void,
}

const AddBannerButton = ({ isDisabled, onClick }: IProps) => (
  <>
    <div className={ cn(styles.addBannerHeader) }>
      <div className={ styles.addBannerBtnContainer }>
        <Button
          disabled={ isDisabled }
          className={ cn(styles.addBannerBtn, { [styles.disabled]: isDisabled }) }
          variant={ variantTypes.text }
          onClick={ onClick }
          dataTest="addBannerButton"
        >
          <Icon
            type={ IconType.add }
            className={ styles.addBannerIcon }
          />
          <span className={ styles.addBannerLabel }>Add Banner</span>
        </Button>
      </div>
    </div>
  </>
);

export default AddBannerButton;
