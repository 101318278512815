import React from 'react';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import styles from './invalidatePasswordModal.scss';
import modalStyles from '@/condor/ui/common/Layout/modal.scss';

interface IProps {
  onCloseClicked: () => void,
  isVisible: boolean,
  userEmail: string,
  invalidatePassword: () => void,
}

const InvalidatePasswordModal = (props: IProps) => (
  <Modal
    title="Invalidate Password"
    onCloseClicked={ props.onCloseClicked }
    isVisible={ props.isVisible }
    dataTest="invalidatePasswordModal"
    footer={ (
      <>
        <Button
          variant={ variantTypes.action }
          onClick={ props.invalidatePassword }
          title="Invalidate Password"
          dataTest="invalidatePasswordButton"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ props.onCloseClicked }
          title="Cancel"
          dataTest="invalidatePasswordCancelButton"
        />
      </>
    ) }
  >
    <div className={ styles.warning }>
      Invalidating password will block this contact from logging into all Finsight applications. Invalidating
      password will NOT generate an automated systems email notifying the Contact of this action. Support must
      manually notify Contact to change their password.
    </div>
    <div className={ modalStyles.areYouSureLabel }>
      Are you sure you want to Invalidate the password for <b>{ props.userEmail }</b>?
    </div>
  </Modal>
);

export default InvalidatePasswordModal;
