import omit from 'lodash/omit';

import { mapSectorsIndustries, addSectorIndustryToData } from '../helpers';

import { IArticle, IArticleToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import ISector from '@/finsight/domain/vo/ISector';
import IIndustry from '@/finsight/domain/vo/IIndustry';
import { ProductAbbreviations } from '@/finsight/domain/Product';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';
import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';

export const mapArticleToDisplay = (
  article: IArticle,
  themesCollection: ITheme[],
  products: IProduct[],
  regions: IRegion[],
  sectors: ISector[],
  industries: IIndustry[],
  productType: string,
) => {
  const mappedItem: IArticleToDisplay = {
    ...omit(article, ['sectors', 'industries']),
    products: [],
    regions: [],
    sectorOrIndustry: null,
    subsectorOrSubindustry: null,
  };

  const {
    themes,
    productsList,
    regionsList,
  } = mapThemesProductsRegions(article, themesCollection, products, regions);
  mappedItem.themes = themes;
  mappedItem.products = productsList;
  mappedItem.regions = regionsList;

  const isAbsOnlyArticle = productsList.includes(ProductAbbreviations.ABS) && productsList.length === 1;
  const isAbsExcluded = !productsList.includes(ProductAbbreviations.ABS);

  const lowercasedProductType = productType.toLowerCase();
  const isAbsProductTab = lowercasedProductType === ProductAbbreviations.ABS.toLowerCase();
  const isCorporateTab = lowercasedProductType === ProductAbbreviations.HYC.toLowerCase()
    || lowercasedProductType === ProductAbbreviations.IGC.toLowerCase();
  const isAllProductTab = productType.toLowerCase() === 'all';

  const showSector = isAbsProductTab || (isAllProductTab && isAbsOnlyArticle);
  const showIndustry = isCorporateTab || (isAllProductTab && isAbsExcluded);

  const {
    sectorsIndustries,
    subsectorsSubindustries,
  } = mapSectorsIndustries(
    article,
    sectors,
    industries,
    showSector,
    showIndustry,
  );
  return addSectorIndustryToData(mappedItem, sectorsIndustries, subsectorsSubindustries);
};

const mapThemesProductsRegions = (
  article: IArticle,
  themesCollection: ITheme[],
  products: IProduct[],
  regions: IRegion[],
) => {
  const regionAbbreviations = new Set<string>();
  const productAbbreviations = new Set<string>();
  const themes = article.themes.map((themeId: string) => {
    const currentTheme = themesCollection.find(({ id }) => id === themeId);
    productAbbreviations.add(products.find((product) => product.id === currentTheme.productId)?.abbreviation);
    const themeRegion = regions.find((region) => region.id === currentTheme.regionId);
    regionAbbreviations.add(themeRegion.abbreviation);
    return `${ themeRegion.abbreviation } ${ currentTheme.themeName }`;
  });
  return {
    themes,
    productsList: Array.from(productAbbreviations),
    regionsList: Array.from(regionAbbreviations),
  };
};
