import React from 'react';
import Head from 'next/head';
import isEqual from 'lodash/isEqual';
import baseSchema from './baseSchema';

interface IProps {
  schema?: any,
  isWithoutMerging?: boolean,
}

const MetaSchema = ({ schema, isWithoutMerging = false }: IProps) => {
  let convertedSchema;
  if (!schema) {
    convertedSchema = JSON.stringify(baseSchema);
  } else {
    convertedSchema = JSON.stringify(!isWithoutMerging ? { ...baseSchema, ...schema } : schema);
  }
  return (
    <Head>
      <script id="metaSchema" type="application/ld+json">
        { convertedSchema }
      </script>
    </Head>
  );
};

export default React.memo(MetaSchema, isEqual);
