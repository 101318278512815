import { injectable, inject } from 'inversify';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { IGroupThemeData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ThemesData';
import {
  IArticlePayload,
  IArticleUpdatePayload,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';
import { IDealPayload, IDealResponse, IDealInfoPayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Deal';
import { IArticle, ISingleArticle } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import {
  IPreviewData,
  IPreviewRequestPayload,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/PreviewData';
import { IArticlesFilters } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';

@injectable()
class CreditFlowResearchRepository {
  constructor(@inject(FinsightRpcDispatcher) protected readonly finsightRpc: typeof FinsightRpcDispatcher) {
  }

  getGroupThemesData = async(): Promise<IGroupThemeData[]> => {
    const request = new Request('finsight_web.research.theme.get_all', { isGrouped: true });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  createArticle = async(payload: IArticlePayload): Promise<null> => {
    const request = new Request('finsight_web.research.article.create', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getArticleCategories = async (payload: IPreviewRequestPayload): Promise<IPreviewData> => {
    const request = new Request('finsight_web.research.article.get_categories', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  updateArticle = async(payload: IArticleUpdatePayload): Promise<null> => {
    const request = new Request('finsight_web.research.article.update', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDeals = async(payload: IDealPayload): Promise<IDealResponse> => {
    const request = new Request('finsight_web.research.article.search_deals', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getArticlesList = async (payload: IArticlesFilters): Promise<{
    collection: IArticle[],
    totalCount: number,
  }> => {
    let request = new Request('finsight_web.research.article.get_all', payload);
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticlesThemes = async (): Promise<ITheme[]> => {
    let request = new Request('finsight_web.research.theme.get_all', {});
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  deleteArticleById = async (id: string): Promise<void> => {
    let request = new Request('finsight_web.research.article.delete', { id });
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  sendEmailNotification = async (id: string): Promise<void> => {
    let request = new Request('finsight_web.research.article.request_mailing', { id });
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticleById = async (id: string): Promise<ISingleArticle> => {
    const request = new Request('finsight_web.research.article.get_by_id', { id });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  unpublishArticleById = async (id: string): Promise<ISingleArticle> => {
    const request = new Request('finsight_web.research.article.unpublish', { id });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDealInfoList = async (payload: IDealInfoPayload): Promise<IDealResponse> => {
    const request = new Request('finsight_web.deal_transaction.deal.get_info', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default CreditFlowResearchRepository;
