import isEmpty from 'lodash/isEmpty';
import { Radio, RadioGroup } from '@dealroadshow/uikit';
import { ToolbarOption } from './interfaces';

import styles from './styles.scss';

interface IRadioButtonsToolbarProps {
  options: ToolbarOption[],
  dataTest: string,
  selectedValue: string | number,
  onChange: (value: string | number) => void,
}

const RadioButtonsToolbar = ({
  options,
  dataTest,
  selectedValue,
  onChange,
}: IRadioButtonsToolbarProps) => {
  if (isEmpty(options)) {
    return null;
  }

  return (
    <RadioGroup
      name="radioButtonsToolbar"
      dataTest={ `${ dataTest }Group` }
      selectedValue={ selectedValue }
      onChange={ onChange }
      isNarrow
      className={ styles.toolbarContainer }
    >
      {
        options.map(({ label, value, isDisable = false }) => (
          <Radio
            key={ value }
            value={ value }
            label={ label }
            dataTest={ `${ dataTest }Option_${ value }` }
            disabled={ isDisable }
          />
        ))
      }
    </RadioGroup>
  );
};

export default RadioButtonsToolbar;
