import { useDispatch } from 'react-redux';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import {
  subscribeUsersByAdmin,
} from '@/condor/application/actions/finsight/subscriptions/metadata/actions';
import selectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';
import SubscriptionsForm from '@/condor/ui/common/SubscriptionsForm';
import validate from './validate';
import { SUBSCRIBE_USERS_FORM_NAME } from '@/condor/ui/components/Finsight/Subscribers/constants';

const stateSelector = (state) => ({
  isSubmitting: selectors.isSubmitting(state),
  initialValues: selectors.getSubscriptionsFormValues(state),
  subscriptionsData: selectors.getSubscriptionsData(state),
});

const AddSubscribersFormContainer = (props) => {
  const dispatch = useDispatch();
  const { isSubmitting, initialValues, subscriptionsData } = useReduxSelector(stateSelector);

  const submitForm = (values) => dispatch(subscribeUsersByAdmin(values));

  return (
    <SubscriptionsForm
      { ...props }
      isSubmitting={ isSubmitting }
      initialValues={ initialValues }
      subscriptionsData={ subscriptionsData }
      validate={ validate }
      formName={ SUBSCRIBE_USERS_FORM_NAME }
      submitForm={ submitForm }
    />
  );
};

export default AddSubscribersFormContainer;
