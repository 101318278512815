import { connect } from 'react-redux';

import selectors from '@/condor/application/actions/accounts/mappings/notes/selectors';
import {
  itemsPerPage,
  getMappingsNotesByFilter,
  paginate,
  sort,
  resetMappingsNotesCollection,
} from '@/condor/application/actions/accounts/mappings/notes/actions';

import BaseMappingsPanel from '../BaseMappingsPanel';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

const columns = [
  {
    name: 'fullName',
    title: 'Name',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 320,
  },
  {
    name: 'email',
    title: 'Email',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 310,
  },
  {
    name: 'noteCount',
    title: '# Notes',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
  },
];

const mapStateToProps = (state) => (
  {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    totalCount: selectors.getTotalCount(state),
    columns,
    dataTableDataTest: 'notesMappingsTable',
    paginatorDataTest: 'notesMappingsTablePaginator',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    getCollection: () => {
      dispatch(getMappingsNotesByFilter());
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getMappingsNotesByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getMappingsNotesByFilter());
    },
    reset: () => {
      dispatch(resetMappingsNotesCollection());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getMappingsNotesByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseMappingsPanel);
