import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import actionButtonConfig from './actionButtonConfig';

interface IModalFooterProps {
  modalType: ActionModalType,
  isRequestPerforming: boolean,
  closeModal: () => void,
  handleAction: () => void,
}

const ModalFooter = ({
  modalType,
  isRequestPerforming,
  closeModal,
  handleAction,
}: IModalFooterProps) => (
  <div>
    <Button
      { ...actionButtonConfig[modalType] }
      onClick={ handleAction }
    />
    <Button
      variant={ variantTypes.text }
      onClick={ closeModal }
      title="Cancel"
      dataTest={ `${ modalType }ModalCancelButton` }
      disabled={ isRequestPerforming }
    />
  </div>
);

export default ModalFooter;
