import { connect } from 'react-redux';
import Button from '@/Framework/UI/Atoms/Button';
import { hideModal } from '@/condor/application/actions/finsight/filings/collection/actions';

const mapStateToProps = () => ({
  variant: 'text',
  title: 'Cancel',
  dataTest: 'cancelModalButton',
});

const mapDispatchToProps = {
  onClick: hideModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Button);
