import { IField } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/Field';
import { IValidationError } from './interfaces/ValidationError';

/**
 * Fields which already have stored data applied will be required
 */
export const validateRequiredFieldsWithInitialValues = (
  requiredFields: IField[],
  values: { [key: string] : string },
  initialValues: typeof values,
): IValidationError => {
  const errors = {};
  requiredFields.forEach((f) => {
    if (!values[f.fieldName] && initialValues && !!initialValues[f.fieldName]) {
      errors[f.fieldName] = `${ f.fieldCaption || 'Field' } ${ (f.isPlural && f.fieldCaption) ? 'are' : 'is' } required`;
    }
  });

  if (Object.keys(errors).length) {
    return errors;
  }
  return null;
};
