import React, { memo } from 'react';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { AsyncCreatableSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';

import styles from '../Subscribers/ManageSubscriptionModal/styles.scss';

interface IOption {
 label: string,
  value: string,
}

interface IProps {
  labelText: string,
  name: string,
  dataTest: string,
  isDisabled?: boolean,
  isMulti?: boolean,
}

interface IResponseItem {
  id: string,
  name: string,
}

const TeamPanel = ({ labelText, name, dataTest, isDisabled = false, isMulti = false }: IProps) => {
  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);

  const loadOptions = (text: string) => {
    if (text.length > 2) {
      return contactRepository.getTeamsByName({ name: text })
        .then((response: IResponseItem[]) => response.map((item) : IOption => (
          { label: item.name, value: item.id }
        )));
    }
    return Promise.resolve([]);
  };

  const isValidNewOption = (text: string, _value: IOption[], options: IOption[]) => {
    return text.length > 2 && !options.some((option) => option.label === text);
  };

  const formatCreateLabel = (label: string) => (
    label.length > 2 ? (<div data-test="createTeam">{ `Create a "${ label }" by Enter` }</div>) : null
  );

  return (
    <div className={ styles.teamContainer }>
      <div className={ styles.teamContainerTitle }>{ labelText }</div>
      <Field
        name={ name }
        dataTest={ dataTest }
        placeholder="Team Name"
        component={ AsyncCreatableSelect }
        components={ { DropdownIndicator: () => null } }
        isDisabled={ isDisabled }
        isMulti={ isMulti }
        isNarrow
        isClearable
        backspaceRemovesValue
        allowCreateWhileLoading={ false }
        formatCreateLabel={ formatCreateLabel }
        loadOptions={ loadOptions }
        isValidNewOption={ isValidNewOption }
        isSupportHighlighting={ false }
        noOptionsMessage={ () => 'You need to enter at least 3 letters' }
      />
    </div>
  );
};

export default memo(TeamPanel);
