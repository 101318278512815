import React from 'react';
import debounce from 'lodash/debounce';
import { AsyncSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';

import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import { IGroupEventOption, IGroupEventTableRowItem, IGroupFormValues } from '../interfaces';
import { useGroupProfileContext } from '@/evercall/application/condor/group/profile/GroupProfileContext';

import groupProfileStyles from '../groupProfile.scss';

interface IProps {
  row: IGroupEventTableRowItem,
}

const EventField = ({ row }: IProps) => {
  const form = useForm<IGroupFormValues>();
  const { values } = form.getState();

  const {
    fetchGroupEventsSuggestions,
  } = useGroupProfileContext();

  const loadOptions = debounce(
    (
      query: string,
      callback: (options: IGroupEventOption[]) => void,
    ) => {
      if (query?.length) {
        fetchGroupEventsSuggestions(query).then((response) => {
          callback(response.map((d) => ({
            value: d.id,
            label: d.name,
            disabled: values.events.some((e) => e.id?.value === d.id),
          })));
        });
      }
    },
    500,
  );

  return (
    <Field
      isNarrow
      name={ `${ row.fieldName }.id` }
      placeholder="Select Event"
      dataTest="eventSelect"
      // empty options fix a bug with async select's empty value drag and drop or delete
      options={ [] }
      loadOptions={ loadOptions }
      formFieldClassName={ groupProfileStyles.inputField }
      component={ AsyncSelect }
      isOptionDisabled={ (option) => option.disabled }
    />
  );
};

export default EventField;
