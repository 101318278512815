import { IValidationError } from './interfaces/ValidationError';
import stripTags from '@/Framework/dom/stripTags';

interface IParams {
  value: string,
  length: number,
  fieldName: string,
  fieldCaption?: string,
}

export const validateMinLengthWithStrippedTags = (params: IParams): IValidationError => {
  if (stripTags(params.value) && stripTags(params.value?.trim()).length < params.length) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Length' } must contain at least ${ params.length } characters` };
  }
  return null;
};
