import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import condor from '@/condor/infrastructure/condorUrl';
import { Filter, RadioGroups, Search } from '@/condor/ui/common/FilterGroup';

import { Paginator } from '@dealroadshow/uikit';
import { setBreadcrumb, removeBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import RoadshowTable from '@/condor/ui/components/DealRoadshows/Roadshows/RoadshowTable';
import { getFilterStateToRequest } from './helpers';
import { useRoadshowTable } from './useRoadshowTable';
import { roadShowsTableFilterConfig } from '@/condor/ui/components/DealRoadshows/constants';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';

const Roadshows = () => {
  const dispatch = useDispatch();
  const searchNode: any = useRef(null);
  const {
    tableFilter,
    totalCount,
    getRoadshows,
    resetRoadshows,
    setRoadshowsTableFilterRadio,
    setRoadshowsTableFilterSearch,
    setRoadshowsTableFilterPagination,
    setRoadshowsTableFilterPerPage,
  } = useRoadshowTable();
  const getRoadshowList = (filterState) => getRoadshows(getFilterStateToRequest(filterState));

  useEffect(() => {
    dispatch(setBreadcrumb({
      name: 'Roadshows',
      url: condor.getUrlPathname('/roadshows'),
    }));
    return () => {
      dispatch(removeBreadcrumb(condor.getUrlPathname('/roadshows')));
      resetRoadshows();
    };
  }, []);

  useEffect(() => {
    getRoadshowList(tableFilter);
  }, [tableFilter]);

  const handleFilterChange = (type, value, name = '') => {
    switch (type) {
      case 'radio':
        setRoadshowsTableFilterRadio({ value, group: name });
        searchNode.current.clearSearch();
        break;
      case 'search':
        if (value === '' || value.length >= 3) {
          setRoadshowsTableFilterSearch({ value });
        }
        break;
      default:
        break;
    }
  };

  /**
   * @param {Object} page
   */
  const handlePageChange = (page) => setRoadshowsTableFilterPagination({ value: page.selected });

  /**
   * @param {Object} perPage
   */
  const handleItemsPerPageChange = (perPage) => setRoadshowsTableFilterPerPage({ value: perPage.value });

  /**
   * @return {ReactElement}
   */

  return (
    <PageWrapper>
      <TableWrapper
        label="Deal Roadshow"
        filterComponent={ (
          <Filter
            config={ roadShowsTableFilterConfig }
            state={ tableFilter }
            onChange={ handleFilterChange }
          >
            <RadioGroups />
            <Search ref={ searchNode } />
          </Filter>
        ) }
      >
        <RoadshowTable />
        <Paginator
          className={ cardStyles.cardInner }
          totalCount={ totalCount }
          page={ tableFilter.paginationGroup.activePage }
          perPage={ tableFilter.paginationGroup.perPage }
          onItemsPerPageChange={ handleItemsPerPageChange }
          onPageChange={ handlePageChange }
          dataTest="condorRoadShows"
        />
      </TableWrapper>
    </PageWrapper>
  );
};

export default Roadshows;
