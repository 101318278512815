import { Link } from 'react-router-dom';

import { DataTable, Paginator, dataTableStyles, Spinner } from '@dealroadshow/uikit';
import ArticlesListContextProvider,
  { useArticlesListContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesList';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import ArticlesThemesContextProvider from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesThemes';
import columns from './columns';
import { IArticleToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import TableFilters from '@/condor/ui/components/Finsight/CreditFlowResearch/common/TableFilters';
import { CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_ARTICLE_BASE_URL } from '@/condor/ui/common/constants';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import buttonStyles from '@/Framework/UI/Atoms/Button/button.scss';
import styles from './styles.scss';

const ArticleTable = () => {
  const {
    collection,
    totalCount,
    page,
    perPage,
    paginate,
    sort,
    setItemsPerPage,
    sortBy,
    sortOrder,
    isFetching,
  } = useArticlesListContext();

    return (
      <>
        <TableWrapper
          filterComponent={ (
            <div className={ styles.tableFilters }>
              <TableFilters />
              <Link
                to={ CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_ARTICLE_BASE_URL }
                className={ buttonStyles.action }
              >
                Add Post
              </Link>
            </div>
      ) }
        >
          <DataTable
            columns={ columns }
            dataTest="articlesTable"
            data={ collection as IArticleToDisplay[] }
            onSortChange={ sort }
            sortBy={ sortBy }
            sortOrder={ sortOrder }
            isFetching={ isFetching }
            className={ dataTableStyles.isHoverable }
            loadingComponent={ Spinner }
          />
          <Paginator
            totalCount={ totalCount }
            page={ page }
            perPage={ perPage }
            onItemsPerPageChange={ ({ value }) => setItemsPerPage(value) }
            onPageChange={ ({ selected }) => paginate(selected) }
            className={ cardStyles.cardInner }
            dataTest="articlesTablePaginator"
          />
        </TableWrapper>
      </>
  );
};

export default () => (
  <ArticlesThemesContextProvider>
    <ArticlesListContextProvider>
      <ArticleTable />
    </ArticlesListContextProvider>
  </ArticlesThemesContextProvider>
);
