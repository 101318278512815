import { Checkbox, Tooltip, Button, ButtonVariantType } from '@dealroadshow/uikit';
import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { useArticlePreviewContext }
  from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/ArticlePreviewContext';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import { TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';
import { getAlertsHistory } from './helpers';

import styles from './styles.scss';

const PreviewModalFooter = () => {
  const { closePreviewModal, publishArticle, isFetching, onWithEmailChange } = useArticlePreviewContext();
  const { initialArticle, isEdit } = useArticleProfileContext();
  const { values } = useFormState<ArticleFormData>();
  const isResendEmail = initialArticle?.mailingRequests.length > 0 && isEdit;
  return (
    <div className={ styles.previewModalFooter }>
      <div>
        <Button
          variant={ ButtonVariantType.action }
          onClick={ publishArticle }
          title="Publish"
          dataTest="PreviewModalPublishButton"
          disabled={ isFetching }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ closePreviewModal }
          title="Cancel"
          dataTest="PreviewModalCancelButton"
          disabled={ isFetching }
        />
      </div>
      <div className={ styles.sendEmailBlock }>
        { isResendEmail && (
          <Tooltip
            content={ getAlertsHistory(initialArticle.mailingRequests) }
          >
            <p className={ styles.emailAlertsHistory }>
              Email Alert sent at: { getFormatInTimeZoneDate(initialArticle.mailingRequests[0], TIMEZONE_DEFAULT) } ET
            </p>
          </Tooltip>
        ) }
        <Checkbox
          dataTest="withEmailNotification"
          label={ isResendEmail ? 'Resend Email' : 'Send Email Alert' }
          onChange={ onWithEmailChange }
          checked={ values.sendEmail }
        />
      </div>
    </div>
  );
};

export default PreviewModalFooter;
