import React from 'react';
import cn from 'classnames';
import Clipboard from 'react-clipboard.js';
import { differenceInHours, format, getTime, isSameDay } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

import { Tooltip, Icon, IconType, Label } from '@dealroadshow/uikit';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import { formatNumberWithDecimals } from '@/finsight/ui/components/deals/helpers';
import { getRatingValue, getBondsDate } from '@/finsight/ui/components/bonds/helpers';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';

import config from '@/Framework/config';
import {
  BWIC_DATE_FORMAT_EXPIRED,
  BWIC_DATE_FORMAT,
  INVENTORY_DATE_FORMAT, LABEL_COLOR_NEW,
} from '@/finsight/ui/components/bonds/constants';

import styles from '@/finsight/ui/components/bonds/common/BondTables/bondsTables.scss';
import linkStyles from '@dealroadshow/uikit/dist/lib/styles/links.scss';

export const getOriginalFaceCell = (cellProps) => {
  if (cellProps.cellData) {
    return formatNumber(cellProps.cellData);
  }

  return '-';
};

export const getCurrentFaceCell = (cellProps) => {
  if (cellProps.cellData) {
    return formatNumber(cellProps.cellData);
  }

  return '-';
};

export const getWalCell = (cellProps) => {
  if (cellProps.cellData) {
    return formatNumberWithDecimals(cellProps.cellData, 2);
  }

  return '-';
};

export const getRatingCell = (cellProps) => getRatingValue(cellProps);

export const getOtherRatingCell = (cellProps) => {
  const value = getRatingValue(cellProps);
  const isValueTooLong = value.length > 6;

  return (
    <Tooltip
      content={ value }
      disabled={ !isValueTooLong }
    >
      { isValueTooLong ? `${ value.slice(0, 4) }...` : value }
    </Tooltip>
  );
};

export const getSpreadCell = (cellProps) => {
  const { row } = cellProps;
  let cell = '';

  if (row.benchmark) {
    cell = row.benchmark.abbreviation;
  }

  if (row.benchmark && row.spread !== null) {
    cell += '+';
  }

  if (row.spread !== null) {
    cell += row.spread;
  }

  return cell || '-';
};

export const getSpeedCell = (cellProps) => {
  const { row } = cellProps;
  if (!row.speedType && !row.speed) {
    return '-';
  }

  let cell = '';

  if (row.speed !== null) {
    // no decimals for zero value
    let isWholeNumber = row.speed % 1 !== 0;
    let decimals = (row.speed === 0) ? 0 : 2;
    cell = isWholeNumber ? `${ formatNumberWithDecimals(row.speed, decimals) } ` : `${ row.speed } `;
  }

  if (row.speedType && row.speedType.abbreviation) {
    cell += row.speedType.abbreviation;
  }

  return cell || '-';
};

export const getCeCell = (cellProps) => {
  if (cellProps.cellData) {
    return formatNumberWithDecimals(cellProps.cellData, 2);
  }

  return '-';
};

export const getPriceTalkCell = (cellProps) => {
  if (Number(cellProps.cellData) === 0) {
    return '-';
  }

  return cellProps.cellData;
};

export const getDealerCell = (cellProps) => {
  const { name, abbreviation } = cellProps.cellData;
  const { lastImportAt } = cellProps.row;

  return (
    <Tooltip
      placement="bottom"
      content={ getTooltip(name, lastImportAt) }
    >
      { abbreviation }
    </Tooltip>
  );
};

/**
 * @param {String} name
 * @param {Object} lastImportAt
 * @returns {JSX.Element}
 */
const getTooltip = (name, lastImportAt) => (
  <div className={ styles.tooltipContainer }>
    <div><span className={ styles.tooltipLabel }>Dealer Name </span> { name } </div>
    <div><span className={ styles.tooltipLabel }>Last Update </span> { getBondsDate(lastImportAt) } </div>
  </div>
);

/**
 * CUSIP / ISIN cell
 * Due to issues with setting row state as hovered after onmouseenter and setting unhovered state with omouseleave
 * (when you moved your cursor super fast - onmouselave was registered on another row or wasn't registered
 * at all - that's why this trick with checkbox+label was used
 */
export const getIdentifierCell = (cellProps) => {
  let cellData = cellProps.row.cusip || cellProps.row.isin;
  let identifier = cellProps.row.cusip ? 'CUSIP' : 'ISIN';

  return (
    <>
      <input
        type="checkbox"
        id={ `identifier${ cellProps.row?.id || '' }${ cellProps.rowIndex }` }
        className={ cn('togglerCheckbox', styles.togglerCheckbox) }
      />

      <label
        htmlFor={ `identifier${ cellProps.row?.id || '' }${ cellProps.rowIndex }` }
        className={ styles.copy }
        data-test="identifierLabel"
      >
        { cellProps.row.deal?.investorSetAvailable && (
          <a
            target="_blank"
            className={ cn(linkStyles.link, styles.investorSetIconContainer) }
            href={ dataroomUrl(config.tenant.tenantInvestorSet.code).getExploreSetsABSUrl() }
            data-test="investorSetEntryLink"
          >
            <Tooltip content="Transaction documentation is available on InvestorSet">
              <Icon
                className={ styles.investorSetIcon }
                type={ IconType.investorSet }
              />
            </Tooltip>
          </a>
        ) }
        <Clipboard
          component="span"
          data-clipboard-text={ cellData }
        >
          <span className={ linkStyles.link }>
            <Icon
              className={ styles.iconCopy }
              type={ IconType.copy }
            />
            { cellData }
          </span>
        </Clipboard>
      </label>

      <span className={ styles.copied }>
        <Icon
          className={ styles.iconCopied }
          type={ IconType.check }
        />
        { identifier } Copied
      </span>
    </>
  );
};

export const getListIdCell = (cellProps, onListIdClick) => {
  if (cellProps.cellData) {
    return (
      <Tooltip content="View BWIC List">
        <a
          className={ linkStyles.link }
          onClick={ () => onListIdClick(cellProps.cellData) }
        >
          { cellProps.cellData }
        </a>
      </Tooltip>
    );
  }

  return '-';
};

export const getDueByCell = ({ row }) => {
  if (!row.dueBy) {
    return '-';
  }
  const { timezone, dateTime } = row.dueBy;

  const now = getTime(utcToZonedDate(timezone.timeZone));
  const dueBy = getTime(utcToZonedDate(timezone.timeZone, new Date(dateTime)));

  // if bond has expired more than 24 hours ago
  if (differenceInHours(now, dueBy) >= 24) {
    return format(dueBy, BWIC_DATE_FORMAT_EXPIRED);
  }

  return format(dueBy, BWIC_DATE_FORMAT);
};

export const getPriceCell = (cellProps) => {
  if (Number(cellProps.cellData) === 0) {
    return '-';
  }

  return cellProps.cellData;
};

export const getAddedAtCell = ({ row }) => {
  const date = row.addedAt;
  const { timezone, dateTime } = date;
  const time = utcToZonedDate(timezone.timeZone, new Date(dateTime));

  const isToday = isSameDay(time, utcToZonedDate(timezone.timeZone));

  if (isToday) {
    return 'Today';
  }

  return format(time, INVENTORY_DATE_FORMAT);
};

export const getIsNewCell = (cellProps) => {
  if (cellProps.cellData) {
    return (
      <Label backgroundColor={ LABEL_COLOR_NEW }>
        NEW
      </Label>
    );
  }

  return undefined;
};
