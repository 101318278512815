import { getIssuerNameCell, getStatusCell, updateStatusButtonsCellCallback } from './helpers';
import { getDealCell } from '../helpers';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

export default [
  {
    name: 'status',
    title: 'Status',
    cellCallback: getStatusCell,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 30,
  },
  {
    name: 'finsightDeal',
    title: 'Finsight Deal',
    cellCallback: getDealCell,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 200,
  },
  {
    name: 'issuerName',
    title: 'SEC Issuer Name',
    cellCallback: getIssuerNameCell,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 400,
  },
  {
    name: 'assetClass',
    title: 'SEC Asset Class',
    cellCallback: ({ row }) => row.data['SEC-HEADER']['ABS-ASSET-CLASS'],
    className: alignStyles.alignLeft,
    sortable: true,
    width: 300,
  },
  {
    name: 'acceptanceTimestamp',
    title: 'Acceptance Timestamp',
    cellCallback: updateStatusButtonsCellCallback,
    className: alignStyles.alignRight,
    sortable: true,
    width: 200,
  },
];
