import React, { useEffect } from 'react';
import cn from 'classnames';
import DataroomForm from '@/dataroom/ui/components/Condor/EditDataroom/DataroomForm/DataroomForm';
import { Spinner } from '@dealroadshow/uikit';
import DataroomCondorActionsContext, { useDataroomCondorActionsContext } from '@/dataroom/application/condor/DataroomCondorActionsContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import headersStyles from '@dealroadshow/uikit/dist/lib/styles/headers.scss';
import styles from './editDataroom.scss';

const EditDataroom = () => {
  const { tenant } = useDataroomTenantContext();
  const { details: { getDataroomDetails, isFetching, dataroomDetails } } = useDataroomCondorActionsContext();

  useEffect(() => {
    getDataroomDetails();
  }, []);

  return (
    <>
      { !isFetching && dataroomDetails && (
        <div>
          <div className={ pageStyles.pageHeader }>
            <div
              className={ cn(
                pageStyles.pageHeaderTitle,
                headersStyles.isH2,
                styles.pageHeaderTitle,
              ) }
              data-test="pageHeaderTitle"
            >
              { dataroomDetails?.displayName }
            </div>
          </div>
          <div className={ styles.pageDescription }>
            Settings
          </div>
          <DataroomForm
            tenant={ tenant }
            dataroom={ dataroomDetails }
            isFetching={ isFetching }
          />
        </div>
      ) }
      <Spinner
        isVisible={ isFetching || !dataroomDetails }
      />
    </>
);
};

export default (props) => (
  <DataroomCondorActionsContext dataroomId={ props.match.params.dataroomId }>
    <EditDataroom />
  </DataroomCondorActionsContext>
);
