export enum UserPlanType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  STARTER = 'STARTER',
  PREMIUM = 'PREMIUM',
  PRO = 'PRO',
  PROFESSIONAL = 'PROFESSIONAL',
}

export const UserPlanTypePrice = {
  [UserPlanType.STARTER]: 'FREE',
  [UserPlanType.PREMIUM]: '125',
  [UserPlanType.PRO]: '325',
};

class UserPlan {
  static isUnauthorized(plan: UserPlanType): boolean {
    return plan === UserPlanType.UNAUTHORIZED;
  }

  static isStarter(plan: UserPlanType): boolean {
    return plan === UserPlanType.STARTER;
  }

  static isPremium(plan: UserPlanType): boolean {
    return plan === UserPlanType.PREMIUM;
  }

  static isSatisfiedStarterPlan(plan: UserPlanType): boolean {
    return [UserPlanType.STARTER, UserPlanType.PREMIUM, UserPlanType.PRO].includes(plan);
  }

  static isSatisfiedPremiumPlan(plan: UserPlanType): boolean {
    return [UserPlanType.PREMIUM, UserPlanType.PRO].includes(plan);
  }

  static isPro(plan: UserPlanType): boolean {
    return plan === UserPlanType.PRO;
  }
}
export default UserPlan;
