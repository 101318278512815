import createAction from '@/Framework/State/Redux/createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { NotificationManager } from '@/Framework/Notification';
import * as actionTypes from './actionTypes';
import * as userSelectors from '@/users/application/actions/data/selectors';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import TwoFactorRepository from '@/users/infrastructure/repository/TwoFactorRepository';

/**
 * Get user by id
 *
 * @param {Number} id
 * @return {Promise<function(*, *)>}
 */
export function getUserById(id) {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypes.GET_USER));
    try {
      const userRepository = getState().container.get(UserRepository);
      const user = await userRepository.getUserById(id, true);
      dispatch(createAction(actionTypes.GET_USER_SUCCESS, user));
    } catch (e) {
      dispatch(createAction(actionTypes.GET_USER_ERROR));
      NotificationManager.error(getErrorMessage(e));
    }
  };
}

/**
 * Get user by activation code
 *
 * @param {String} email
 * @param {String} activationCode
 * @param {?Boolean} aggregateCompany
 *
 * @return {Promise<function(*, *)>}
 */
export function getUserByActivationCode(email, activationCode, aggregateCompany = false) {
  return async (dispatch, getState) => {
    const userRepository = getState().container.get(UserRepository);
    dispatch(createAction(actionTypes.GET_USER));

    try {
      let user = await userRepository.getUserByActivationCode(email, activationCode, aggregateCompany);
      dispatch(createAction(actionTypes.GET_USER_SUCCESS, user));
    } catch (e) {
      dispatch(createAction(actionTypes.GET_USER_ERROR));
    }
  };
}

export const resetUserData = () => createAction(actionTypes.RESET_USER_DATA);

export function regenerateUserActivationCode() {
  return async (dispatch, getState) => {
    const state = getState();
    const userRepository = state.container.get(UserRepository);
    const user = userSelectors.getUser(state);

    dispatch(createAction(actionTypes.REGENERATE_USER_ACTIVATION_CODE));
    try {
      const response = await userRepository.regenerateActivationCode(user.email);

      dispatch(createAction(actionTypes.REGENERATE_USER_ACTIVATION_CODE_SUCCESS, response));
    } catch (e) {
      dispatch(createAction(actionTypes.REGENERATE_USER_ACTIVATION_CODE_FAILURE, e));
      NotificationManager.error(getErrorMessage(e));
    }
  };
}

/**
 * @param {Object} payload
 * @param callbacks
 */
export const editUser = (payload, callbacks = {}) => (dispatch, getState) => createAsyncRequest(
  {
    payload,
    method: getState().container.get(UserRepository).edit,
    types: [actionTypes.EDIT_USER, actionTypes.EDIT_USER_SUCCESS, actionTypes.EDIT_USER_ERROR],
    callbacks,
  },
);

/**
 * @param {Object} payload
 */
export const generateTwoFactorCode = (payload) => (dispatch, getState) => createAsyncRequest(
  {
    payload,
    method: getState().container.get(TwoFactorRepository).generateTwoFactorCode,
    types: [
      actionTypes.GENERATE_TWO_FACTOR_CODE,
      actionTypes.GENERATE_TWO_FACTOR_CODE_SUCCESS,
      actionTypes.GENERATE_TWO_FACTOR_CODE_FAILURE,
    ],
  },
);
