import Head from 'next/head';
import cn from 'classnames';
import { Spinner, Paginator, DataTable, dataTableStyles } from '@dealroadshow/uikit';
import ProfileListContextProvider, { useProfilesListContext } from './ProfileListContext';
import { columns } from './columns';
import DeleteModal from '@/openMarket/ui/common/Condor/DeleteModal';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import styles from './profilesList.scss';

const ProfileList = () => {
  const {
    activity: {
      collection,
      isFetching,
      sort,
      sortBy,
      sortOrder,
      totalCount,
      page,
      perPage,
      paginate,
      setItemsPerPage,
    },
    deletingProfile,
    deleteProfile,
    isDeleting,
    closeDeleteModal,
  } = useProfilesListContext();

  const handlePageChange = (page: { selected: number }) => {
    paginate(page.selected);
  };

  const handleItemsPerPageChange = (perPage: { value: number }) => {
    setItemsPerPage(perPage.value);
  };

  return (
    <>
      <Head>
        <title>Profiles | OpenMarket</title>
      </Head>
      <DataTable
        containerClassName={ cn(styles.dataTableContainer, dataTableStyles.dataTableContainer) }
        className={ dataTableStyles.isHoverable }
        rowClassNameCallback={ () => styles.tableRow }
        columns={ columns }
        data={ collection }
        isFetching={ isFetching }
        loadingComponent={ Spinner }
        onSortChange={ sort }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
        dataTest="openMarketTable"
        emptyMessage="Profiles not found"
      />
      <Paginator
        dataTest="openMarketPaginator"
        className={ cardStyles.cardInner }
        totalCount={ totalCount }
        page={ page }
        perPage={ perPage }
        onItemsPerPageChange={ handleItemsPerPageChange }
        onPageChange={ handlePageChange }
      />
      { deletingProfile && (
        <DeleteModal
          title="Delete Profile"
          disclaimer={ (
            <>
              Please confirm you would like to delete
              the <b>{ decodeSymbols(deletingProfile.name) }</b> Profile
              from OpenMarket and Finsight.com.
            </>
          ) }
          isLoading={ isDeleting }
          confirm={ () => deleteProfile(deletingProfile.id) }
          close={ closeDeleteModal }
        />
      ) }
    </>
  );
};

export default () => (
  <ProfileListContextProvider>
    <ProfileList />
  </ProfileListContextProvider>
);
