import cn from 'classnames';
import noop from 'lodash/noop';
import { useHistory } from 'react-router-dom';

import { useProfileDataContext } from '@/openMarket/application/Condor/ProfileDataContext';
import ProfileFormHeaderButtons from '@/openMarket/ui/common/Condor/ProfileFormHeaderButtons';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Input, Icon, IconType } from '@dealroadshow/uikit';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import EventsList from '@/openMarket/ui/common/Condor/EventsList';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { columns } from './columns';

import * as constants from '@/condor/ui/common/constants';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import btnStyles from '@/Framework/UI/Atoms/Button/button.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import styles from './profileEvents.scss';

const ProfileEvents = () => {
  const { profile } = useProfileDataContext();

  const history = useHistory();

  const handleNewEvent = () => {
    history.push({
      pathname: constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE,
      state: { profile: { id: profile?.id, name: profile?.name } },
    });
  };

  return (
    <>
      <PageWrapper
        label={ decodeSymbols(profile?.name ?? 'Profile Name') }
        contentCls={ cn(cardStyles.cardContainer, styles.cardContainer) }
        headerCls={ styles.cardContainerHeader }
        labelCls={ styles.cardTitle }
        headerComponent={ (
          <>
            <div className={ cn(pageStyles.pageHeaderActions, styles.pageHeaderActions) }>
              <Button
                dataTest="createEvent"
                variant={ variantTypes.action }
                className={ cn(btnStyles.btnIcon, btnStyles.btnIconRight, btnStyles.btnIconMobile) }
                onClick={ handleNewEvent }
                disabled={ !profile }
              >
                Add Event
                <Icon type={ IconType.plus } className={ styles.buttonIcon } />
              </Button>
            </div>
            <ProfileFormHeaderButtons className={ styles.headerButtons } />
          </>
        ) }
      >
        <div className={ spacesStyles.pbn }>
          <div className={ styles.cardHeader }>
            <div className={ styles.cardHeaderTitle }>
              <h3>Events</h3>
            </div>
            <div className={ styles.searchInputWrapper }>
              { /* @ts-ignore */ }
              <Input
                name="search"
                // @ts-ignore
                iconType={ IconType.filtering }
                placeholder="Filter by Event Name, Country, Region or Tab"
                isNarrow
                dataTest="searchInput"
                value=""
                onChange={ (event) => noop(event.target.value) }
                disabled
              />
            </div>
          </div>
        </div>
        <EventsList columns={ columns } />
      </PageWrapper>
    </>
  );
};

export default ProfileEvents;
