import React, { useEffect } from 'react';

import { Paginator, PAGINATOR_ITEMS_PER_PAGE_OPTIONS, DataTable, IColumn, IRowData } from '@dealroadshow/uikit';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import styles from './baseMappingsPanel.scss';

const paginatorOptions = [
  { value: 10, label: 'Show 10' },
  ...PAGINATOR_ITEMS_PER_PAGE_OPTIONS,
];

interface IProps {
  getCollection: () => void,
  reset: () => void,
  columns: IColumn[],
  data: IRowData[],
  isFetching: boolean,
  sortBy: string,
  sortOrder: string,
  onSortChange: () => void,
  totalCount: number,
  page: number,
  perPage: number,
  onItemsPerPageChange: () => void,
  onPageChange: () => void,
  dataTableDataTest: string,
  paginatorDataTest: string,
}

const BaseMappingsPanel = (props: IProps) => {
  useEffect(() => {
    props.getCollection();
    return props.reset;
  }, []);

  return (
    <TableWrapper className={ styles.mappingsTableLabel }>
      <DataTable
        columns={ props.columns }
        data={ props.data }
        isFetching={ props.isFetching }
        sortBy={ props.sortBy }
        sortOrder={ props.sortOrder }
        onSortChange={ props.onSortChange }
        containerClassName={ styles.mappingsTable }
        dataTest={ props.dataTableDataTest }
        emptyMessageClassName={ styles.emptyMessage }
        emptyMessage="No records found"
      />
      <Paginator
        className={ cardStyles.cardInner }
        totalCount={ props.totalCount }
        page={ props.page }
        perPage={ props.perPage }
        onItemsPerPageChange={ props.onItemsPerPageChange }
        onPageChange={ props.onPageChange }
        customOptions={ paginatorOptions }
        minPerPageValue={ 10 }
        dataTest={ props.paginatorDataTest }
      />
    </TableWrapper>
  );
};

export default BaseMappingsPanel;
