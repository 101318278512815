import stripTags from '@/Framework/dom/stripTags';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import {
  validateRequiredFieldWithArrayValue,
} from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFieldWithArrayValue';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

export default (values: ArticleFormData) => (
  groupValidators(
    validateRequiredField({ fieldName: 'headline', fieldCaption: 'Headline', value: values.headline }),
    validateRequiredField({
      fieldName: 'description',
      fieldCaption: 'Description',
      value: values.description ? stripTags(values.description) : undefined,
    }),
    validateMinLength({ fieldName: 'headline', fieldCaption: 'Headline', value: values.headline, length: 3 }),
    validateMaxLength({ fieldName: 'headline', fieldCaption: 'Headline', value: values.headline, length: 255 }),
    validateMaxLength(
      {
        fieldName: 'description',
        fieldCaption: 'Description',
        value: stripTags(values.description),
        length: 65000,
      },
    ),
    // @ts-ignore
    validateRequiredFieldWithArrayValue({ fieldName: 'themes', fieldCaption: 'Themes', value: values.themes }),
  )
);
