import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoProduct } from '@dealroadshow/uikit';
import Favicon from '@/condor/ui/common/Favicon';
import NavigationItems from './NavigationItems';
import AppNavigation from '@/Framework/UI/Templates/AppNavigation';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { getCondorNavigationItems } from '@/condor/application/actions/sidebar/actions';
import * as sidebarSelectors from '@/condor/application/actions/sidebar/selectors';
import PagePreloader from '@/dmPortal/ui/components/PagePreloader';

interface IProps {
  children: React.ReactNode,
}

const Navigation = ({ children }: IProps) => {
  const { currentUser, logout } = useSessionContext();
  const dispatch = useDispatch();

  const isSidebarFetching = useSelector(sidebarSelectors.isFetching);

  useEffect(() => {
    dispatch(getCondorNavigationItems());
  }, []);

  return (
    <>
      { isSidebarFetching && <PagePreloader /> }
      { !isSidebarFetching && (
        <>
          <Favicon />
          <AppNavigation
            currentUser={ currentUser }
            logoProduct={ LogoProduct.CONDOR }
            contentComponent={ NavigationItems }
            logoutAction={ () => logout({ redirect: false }) }
            isNext={ false }
          >
            { children }
          </AppNavigation>
        </>
      ) }
    </>
  );
};

export default Navigation;
