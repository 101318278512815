import { createContext, ReactNode, useContext } from 'react';
import useParentIssuer from './useParentIssuer';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

type ParentIssuerContextType = ReturnType<typeof useParentIssuer>;

const ParentIssuerContext = createContext<ParentIssuerContextType>(null);

export const useParentIssuerContext = () => {
  const context = useContext(ParentIssuerContext);

  if (!context) {
    throw new Error('useParentIssuerContext must be used within the ParentIssuerContext');
  }

  return context;
};

interface IProps {
  initialFormData: ArticleFormData,
  children: ReactNode,
}

export const ParentIssuerContextProvider = ({ children, initialFormData }: IProps) => (
  <ParentIssuerContext.Provider value={ useParentIssuer(initialFormData) }>
    { children }
  </ParentIssuerContext.Provider>
);
