import TenantConfig from '@/Framework/Tenant/TenantConfig';
import dataroomConfig from '@/dataroom/application/config/config';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/condor/application/config/featureToggle';

export const platformCellCallback = ({ cellData }) => {
  if (TenantConfig.fromCode(cellData).code === dataroomConfig.tenant.tenantResearchRoom.code) {
    return (
      <FeatureToggle
        featureName={ RESEARCHROOM_FEATURE }
        defaultComponent={ '-' }
      >
        { TenantConfig.fromCode(cellData).name }
      </FeatureToggle>
    );
  }

  return (TenantConfig.fromCode(cellData).name || '-');
};
