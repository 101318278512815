import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import PermissionRow from './PermissionRow';
import { HeaderWrapper, HeaderRow, BodyWrapper, dataTableStyles } from '@dealroadshow/uikit';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';

import permissionStyles from './userPermissions.scss';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import dataroomConfig from '@/dataroom/application/config/config';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/condor/application/config/featureToggle';

interface IPermissionTableProps {
  activeTab: number,
  hasPermissionToUpdate: boolean,
  updateOwnContact: boolean,
}

const headerColumns = [
  {
    name: 'Functionality',
    title: 'Functionality',
    width: 200,
    className: alignStyles.alignLeft,
  },
  {
    name: 'Description',
    title: 'Description',
    className: alignStyles.alignLeft,
  },
  {
    name: 'Admin',
    title: 'Admin',
    width: 105,
    className: alignStyles.alignCenter,
  },
];

const PermissionTable: FC<IPermissionTableProps> = ({
  activeTab, hasPermissionToUpdate, updateOwnContact,
}) => {
  const { modules } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const activeModule = Object.keys(modules)[activeTab];
  const moduleContent = modules[activeModule];
  const tableClass = cn(
    dataTableStyles.dataTable,
    dataTableStyles.isHoverable,
    dataTableStyles.nowrapCells,
    permissionStyles.tableContainer,
  );

  return (
    <div className={ permissionStyles.tableWrapper }>
      <table className={ tableClass }>
        <HeaderWrapper>
          <HeaderRow columns={ headerColumns } />
        </HeaderWrapper>
        <BodyWrapper>
          { moduleContent.map((item, index) => {
            if (item.key === dataroomConfig.tenant.tenantResearchRoom.code) {
              return (
                <FeatureToggle
                  featureName={ RESEARCHROOM_FEATURE }
                  key={ item.description }
                >
                  <PermissionRow
                    hasPermissionToUpdate={ hasPermissionToUpdate }
                    updateOwnContact={ updateOwnContact }
                    id={ item.key }
                    rowIndex={ index }
                    { ...item }
                  />
                </FeatureToggle>
              );
            }

            return (
              <PermissionRow
                key={ item.description }
                hasPermissionToUpdate={ hasPermissionToUpdate }
                updateOwnContact={ updateOwnContact }
                id={ item.key }
                rowIndex={ index }
                { ...item }
              />
          );
          }) }
        </BodyWrapper>
      </table>
    </div>
  );
};

export default PermissionTable;
