import styles from './styles.scss';

export const getStatus = (isActive: boolean) => {
  if (isActive) {
    return {
      id: 'active',
      tooltipContent: 'Active',
      className: styles.activeProfile,
    };
  }

  return {
    id: 'inactive',
    tooltipContent: 'Inactive',
    className: styles.inactiveProfile,
  };
};
