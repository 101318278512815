import {
  transactionTypes,
  TransactionType,
  isSectorAvailableForTransactionType,
} from '@/dealroadshow/domain/TransactionTypes';

const transactionTypeDisplayNames: Record<TransactionType, string | null> = {
  [transactionTypes.Convertible]: 'Convertible',
  [transactionTypes.BondHighYield]: 'High Yield Corporate',
  [transactionTypes.BondInvestmentGrade]: 'Investment Grade Corporate',
  [transactionTypes.BondMunicipal]: 'Muncipal',
  [transactionTypes.BondPrivatePlacement]: 'Private Placement',
  [transactionTypes.BondSecuritization]: 'Asset Backed Securities',
  [transactionTypes.EquityEarlyStage]: 'Early Stage Equity',
  [transactionTypes.EquityFollowOn]: 'Follow-On',
  [transactionTypes.EquityGDR]: 'Global Depositary Receipt (GDR)',
  [transactionTypes.EquityIPO]: 'Initial Public Offering (IPO)',
  [transactionTypes.EquityPDIE]: 'Pre-Deal Investor Education (PDIE)',
  [transactionTypes.EquityPilotFishing]: 'Pilot Fishing',
  [transactionTypes.EquityWallCross]: 'Wall Cross',
  [transactionTypes.EquityPrivate]: 'Private Equity',
  [transactionTypes.EquitySPAC]: 'SPAC',
  [transactionTypes.LeveragedLoans]: 'Leveraged Loan',
  [transactionTypes.MaCimTeaserDistribution]: 'M&A',
  [transactionTypes.MaSiteTourVideoSiteTourVide]: 'M&A',
  [transactionTypes.MaFiresideChatManagementPresentation]: 'M&A',
  [transactionTypes.MaOther]: 'M&A',
  [transactionTypes.SyndicatedLoan]: 'Syndicated Loan',
  [transactionTypes.NonDealRoadshow]: 'Non-Deal Roadshow',
  [transactionTypes.OtherTestingTheWaters]: 'Testing the Waters',
  [transactionTypes.Other]: null,
  [transactionTypes.OtherExcludedAnalytics]: null,
};

export class EventTransactionTypes {
  static getTemplate(transactionType: string) {
    const asbDebtTransactionTypes: TransactionType[] = [transactionTypes.BondSecuritization, transactionTypes.Other];

    if (asbDebtTransactionTypes.includes(transactionType as TransactionType)) {
      return 'absDebt';
    }

    const equityTransactionTypes: TransactionType[] = [
      transactionTypes.EquityFollowOn,
      transactionTypes.EquityGDR,
      transactionTypes.EquityIPO,
      transactionTypes.EquityPDIE,
      transactionTypes.EquityPrivate,
      transactionTypes.EquitySPAC,
      transactionTypes.EquityEarlyStage,
      transactionTypes.EquityPilotFishing,
      transactionTypes.EquityWallCross,
    ];

    if (equityTransactionTypes.includes(transactionType as TransactionType)) {
      return 'equity';
    }

    const maTransactionTypes: TransactionType[] = [
      transactionTypes.MaCimTeaserDistribution,
      transactionTypes.MaSiteTourVideoSiteTourVide,
      transactionTypes.MaFiresideChatManagementPresentation,
      transactionTypes.MaOther,
    ];

    if (maTransactionTypes.includes(transactionType as TransactionType)) {
      return 'ma';
    }

    return 'debt';
  }

  static getProductType(transactionType: string): string | null {
    return transactionTypeDisplayNames[transactionType] ?? null;
  }

  static hasSubsector(transactionType: string): boolean {
    return isSectorAvailableForTransactionType(transactionType);
  }
}
