import {
  seriesNameCallback,
  regionCellCallback,
  tickerCellCallback,
  productCellCallback,
  parentCell,
  issuerCell,
  industryOrSectorCell,
  actionsCell,
  industrySectorEditableFieldCallback,
  pricingDateCellCallback,
  pricingDateEditableFieldCallback,
  parentSelect,
  issuerSelect,
  productEditableFieldCallback,
  regionEditableFieldCallback,
  editableTickerCallback,
} from './cellCallbacks';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

const columns = [
  {
    name: 'seriesName',
    title: 'Series',
    sortable: true,
    isEditable: true,
    isFieldClearable: false,
    cellCallback: seriesNameCallback,
    width: 80,
  },
  {
    name: 'product',
    title: 'Product',
    sortable: true,
    isEditable: true,
    width: 70,
    cellCallback: productCellCallback,
    editableFieldCallback: productEditableFieldCallback,
  },
  {
    name: 'primaryRegion',
    title: 'Region',
    sortable: true,
    isEditable: true,
    width: 80,
    cellCallback: regionCellCallback,
    editableFieldCallback: regionEditableFieldCallback,
  },
  {
    name: 'issuerTicker',
    title: 'Ticker',
    sortable: true,
    isEditable: true,
    width: 80,
    cellCallback: tickerCellCallback,
    editableFieldCallback: editableTickerCallback,
  },
  {
    name: 'issuerName',
    title: 'Issuer',
    isEditable: true,
    sortable: true,
    width: 330,
    cellCallback: issuerCell,
    editableFieldCallback: issuerSelect,
  },
  {
    name: 'parentName',
    title: 'Parent',
    isEditable: true,
    sortable: true,
    width: 240,
    cellCallback: parentCell,
    editableFieldCallback: parentSelect,
  },
  {
    name: 'industrySector',
    title: 'Sector / Industry',
    width: 200,
    isEditable: true,
    editableFieldCallback: industrySectorEditableFieldCallback,
    cellCallback: industryOrSectorCell,
  },
  {
    name: 'pricingDate',
    title: 'Pricing date',
    isEditable: true,
    sortable: true,
    width: 125,
    minWidth: 125,
    editableFieldCallback: pricingDateEditableFieldCallback,
    cellCallback: pricingDateCellCallback,
  },
  {
    name: 'actions',
    title: '',
    width: 120,
    minWidth: 120,
    className: alignStyles.alignRight,
    cellCallback: actionsCell,
  },
];

export default columns;
