import React from 'react';
import { Redirect, Switch } from 'react-router';

import Route from '@/Framework/Router/ReactRouter/Route';
import * as constants from '@/condor/ui/common/constants';

import ProfileDataContextProvider from '@/openMarket/application/Condor/ProfileDataContext';
import OpenMarketList from '@/openMarket/ui/components/Condor/OpenMarketList';
import ProfileEvents from '@/openMarket/ui/components/Condor/Profiles/ProfileEvents';
import CreateProfile from '@/openMarket/ui/components/Condor/Profiles/CreateProfile';
import EditProfile from '@/openMarket/ui/components/Condor/Profiles/EditProfile';
import CreateEvent from '@/openMarket/ui/components/Condor/Events/CreateEvent';
import EditEvent from '@/openMarket/ui/components/Condor/Events/EditEvent';
import UsersContextProvider from '@/openMarket/application/Condor/UsersContext';

const CondorRouter = () => {
  return (
    <UsersContextProvider>
      <Switch>
        <Redirect
          exact
          from={ constants.CONDOR_FINSIGHT_OPEN_MARKET }
          to={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }
        />
        <Route
          exact
          path={ [constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES, constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS] }
          component={ OpenMarketList }
        />
        <Route
          exact
          path={ [
            constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_CREATE,
            constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_EDIT,
            constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILE_EVENTS_LIST,
          ] }
          render={ () => (
            <ProfileDataContextProvider>
              <Switch>
                <Route
                  exact
                  path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_CREATE }
                  component={ CreateProfile }
                />
                <Route
                  exact
                  path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_EDIT }
                  component={ EditProfile }
                />
                <Route
                  exact
                  path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILE_EVENTS_LIST }
                  component={ ProfileEvents }
                />
              </Switch>
            </ProfileDataContextProvider>
          ) }
        />
        <Route
          exact
          path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE }
          component={ CreateEvent }
        />
        <Route
          exact
          path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_EDIT }
          component={ EditEvent }
        />
      </Switch>
    </UsersContextProvider>
  );
};

export default CondorRouter;
