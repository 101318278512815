import omit from 'lodash/omit';

import { IArticle, ISimpleIndustry, ISimpleSector } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { IPreviewData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/PreviewData';
import ISector from '@/finsight/domain/vo/ISector';
import IIndustry from '@/finsight/domain/vo/IIndustry';
import ISubsector from '@/finsight/domain/vo/ISubsector';
import ISubindustry from '@/finsight/domain/vo/ISubindustry';

const processEntities = (
  entities: ISimpleSector[] | ISimpleIndustry[],
  entityList: ISector[] | IIndustry[],
  entityKey: 'sectorId' | 'industryId',
  subentityKey: 'subsectorId' | 'subindustryId',
  subentityList: 'subsectorList' | 'subindustryList',
) => {
  const sectorsIndustries = [];
  const subsectorsSubindustries = [];
  entities.forEach((entity) => {
    const { [entityKey]: entityId, [subentityKey]: subentityId } = entity;
    const currentEntity = entityList.find(({ id }) => id === entityId);
    const articleEntity = omit(currentEntity, [subentityList]);
    const articleSubentity = currentEntity[subentityList].find(({ id }) => id === subentityId);
    if (!sectorsIndustries.find(({ id }) => id === articleEntity.id)) {
      sectorsIndustries.push(articleEntity);
    }
    if (articleSubentity && !subsectorsSubindustries.find(({ id }) => id === articleSubentity.id)) {
      subsectorsSubindustries.push(articleSubentity);
    }
  });
  return { sectorsIndustries, subsectorsSubindustries };
};

export const mapSectorsIndustries = (
  article: IArticle | IPreviewData,
  sectors: ISector[],
  industries: IIndustry[],
  showSector: boolean,
  showIndustry: boolean,
) => {
  if (showSector) {
    return processEntities(article.sectors, sectors, 'sectorId', 'subsectorId', 'subsectorList');
  }

  if (showIndustry) {
    return processEntities(article.industries, industries, 'industryId', 'subindustryId', 'subindustryList');
  }
  return { sectorsIndustries: [], subsectorsSubindustries: [] };
};

export const addSectorIndustryToData = (
  data,
  sectorsIndustries: ISector[] | IIndustry[],
  subsectorsSubindustries: ISubsector[] | ISubindustry[],
) => {
  const updatedData = { ...data };
  if (sectorsIndustries.length === 1) {
    updatedData.sectorOrIndustry = sectorsIndustries[0] || null;
    if (subsectorsSubindustries.length === 1) {
      updatedData.subsectorOrSubindustry = subsectorsSubindustries[0] || null;
    }
  }
  return updatedData;
};
