import { createContext, ReactNode, useContext } from 'react';
import useDeals from './useDeals';

type DealsContextType = ReturnType<typeof useDeals>;

const DealsContext = createContext<DealsContextType>(null);

export const useDealsContext = () => {
  const context = useContext(DealsContext);

  if (!context) {
    throw new Error('useDealsContext must be used within the DealsContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
}

export const DealsContextProvider = ({ children }: IProps) => (
  <DealsContext.Provider value={ useDeals() }>
    { children }
  </DealsContext.Provider>
);
