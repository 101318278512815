export const USOA_REGION_ID = '77fdfc56-826b-4cab-976c-175acb54e97a';

export enum RegionAbbreviations {
  USOA = 'USOA',
  APAC = 'APAC',
  EMEA = 'EMEA',
  LATM = 'LATM',
}

class Region {
  /**
   * @return {string}
   */
  static getDefault() {
    return RegionAbbreviations.USOA;
  }

  /**
   * @return {boolean}
   */
  static isUsoaRegionId(regionId) {
    return regionId === USOA_REGION_ID;
  }

  /**
   * @return {boolean}
   */
  static isUsoaRegionOnly(regionIds) {
    return regionIds.length === 1 && regionIds.includes(USOA_REGION_ID);
  }

  /**
   * @param {Array<Object>} regions collection
   * @param {Array<String>|String} abbreviations
   * @param {Boolean} idsOnly
   */
  static getByAbbreviations(regions, abbreviations, idsOnly) {
    let results = regions.filter((region) => abbreviations.includes(region.abbreviation));

    return idsOnly
      ? results.map((result) => result.id)
      : results;
  }
}

export default Region;
