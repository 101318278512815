import { createContext, ReactNode, useContext } from 'react';
import useArticleProfile from './useArticleProfile';

type ArticleProfileContextType = ReturnType<typeof useArticleProfile>;

const ArticleProfileContext = createContext<ArticleProfileContextType>(null);

export const useArticleProfileContext = () => {
  const context = useContext(ArticleProfileContext);

  if (!context) {
    throw new Error('useArticleProfileContext must be used within the ArticleProfileContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  fromArticle?: string,
  articleId?: string,
  setTitle: (title: string) => void,
}

export const ArticleProfileContextProvider = ({ children, ...otherProps }: IProps) => (
  <ArticleProfileContext.Provider value={ useArticleProfile(otherProps) }>
    { children }
  </ArticleProfileContext.Provider>
);
