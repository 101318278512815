import { createContext, useContext, useState, useMemo, PropsWithChildren } from 'react';

const useUploadInputsStatus = () => {
  const [canSaveState, setCanSaveState] = useState<Record<string, boolean>>({});
  const [processStatusState, setProcessStatusState] = useState<Record<string, string>>({});

  const isAllUploadCanSave = useMemo(() => {
    const allLoaders = Object.values(canSaveState);

    return allLoaders.length === 0 || allLoaders.every(Boolean);
  }, [canSaveState]);

  const setCanSave = (key: string, value: boolean) => {
    setCanSaveState((canSaveState) => ({
      ...canSaveState,
      [key]: value,
    }));
  };

  const setProcessStatus = (key: string, value: string) => {
    setProcessStatusState((canSaveState) => ({
      ...canSaveState,
      [key]: value,
    }));
  };

  return {
    isAllUploadCanSave,
    canSaveState,
    processStatusState,
    setCanSave,
    setProcessStatus,
  };
};

type UploadInputsStatusContextType = ReturnType<typeof useUploadInputsStatus>;

export const UploadInputsStatusContext = createContext<UploadInputsStatusContextType>(null);

export const useUploadInputsStatusContext = () => {
  const context = useContext(UploadInputsStatusContext);

  if (!context) {
    throw new Error('useUploadInputsStatusContext must be used within the UploadInputsStatusContext');
  }

  return context;
};

const UploadInputsStatusContextProvider = ({ children }: PropsWithChildren) => (
  <UploadInputsStatusContext.Provider value={ useUploadInputsStatus() }>
    { children }
  </UploadInputsStatusContext.Provider>
);

export default UploadInputsStatusContextProvider;
