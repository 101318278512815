import React from 'react';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import { useFilterContext } from '../Filter';

import styles from './styles.scss';

interface IProps {
  dataTest: string,
  formFieldClassName?: string,
}

const FilterSelect = ({ dataTest, formFieldClassName = '' }: IProps) => {
  const context = useFilterContext();
  return (
    <>{ Object.entries(context.config.radio)
      .map(([key, { group, placeholder }]: any) => {
        const handleChange = ({ value }) => context.onChange('radio', value, key);

        return (
          <Select
            key={ key }
            dataTest={ `${ dataTest }${ key }` }
            options={ group }
            value={ context.state.radio[key] }
            onChange={ handleChange }
            className={ styles.select }
            formFieldClassName={ formFieldClassName }
            placeholder={ placeholder }
            selectedPlaceholderWhenOpen={ placeholder }
          />
        );
      }) }
    </>
  );
};

export default FilterSelect;
