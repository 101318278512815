import React, { memo, useMemo } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import cn from 'classnames';
import Route from '@/Framework/Router/ReactRouter/Route';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import ProfileForm from './ProfileForm';
import Links from './Links';
import Advanced from './Advanced';
import UserPermissions from './UserPermissions';
import { getStatus } from '@/condor/ui/components/AccountsAndContacts/Contacts/helpers';
import {
  CONDOR_CONTACT_ADVANCED_BASE_URL,
  CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL,
  CONDOR_CONTACT_LINKS_BASE_URL,
  CONDOR_CONTACT_PROFILE_BASE_URL,
} from '@/condor/ui/common/constants';
import { IUser } from '@/users/domain/vo/User';

import floatStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/floats.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

interface IProps {
  isFetching: boolean,
  contact: IUser,
  init: () => void,
  reset: () => void,
}

const ContactProfile = (props: IProps) => {
  const {
    isFetching,
    contact: {
      id,
      email,
      firstName,
      lastName,
      activated,
      registered,
      emailVerified,
      banned,
    },
  } = props;

  const label = useMemo(
    () => {
      if (firstName || lastName) {
        return `${ firstName ? `${ firstName } ` : '' }${ lastName || '' }`;
      }
      return email;
    },
    [email, firstName, lastName],
  );

  return (
    <PageWrapper
      label={ label }
      onMount={ props.init }
      onUnmount={ props.reset }
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ id }` }
            >
              Profile
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_CONTACT_LINKS_BASE_URL }/${ id }` }
            >
              Links
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_CONTACT_ADVANCED_BASE_URL }/${ id }` }
            >
              Advanced
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL }/${ id }` }
            >
              Permissions
            </NavLink>
          </div>
        </div>
      ) }
    >
      <ProfileWrapper
        isSpinnerVisible={ isFetching }
        status={ getStatus({
          activated,
          registered,
          emailVerified,
          banned,
        }) }
      >
        <Switch>
          <Route
            path={ `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ id }` }
            component={ ProfileForm }
          />
          <Route
            path={ `${ CONDOR_CONTACT_LINKS_BASE_URL }/${ id }` }
            component={ Links }
          />
          <Route
            path={ `${ CONDOR_CONTACT_ADVANCED_BASE_URL }/${ id }` }
            component={ Advanced }
          />
          <Route
            path={ `${ CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL }/${ id }` }
            component={ UserPermissions }
          />
        </Switch>
      </ProfileWrapper>
    </PageWrapper>
  );
};

export default memo(ContactProfile);
