import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import memoize from '@/Framework/dataHelpers/memoize';
import keyResolver from '@/Framework/dataHelpers/keyResolver';
import Filing from '@/finsight/domain/Filing';
import {
  getFilingProfileUrl,
  getFilingCreateUrl,
} from '@/condor/ui/components/Finsight/Filings/helpers';
import { Tooltip } from '@dealroadshow/uikit';
import { IFilling } from '@/finsight/domain/vo/Filings/Filings';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT } from '@/Framework/DateTime/dateFormats';

interface IProps {
  row: IFilling,
}

export const getAcceptanceTimestampCell = ({ row: { acceptanceDatetime } }: IProps): string => {
  const { dateTime, timezone } = acceptanceDatetime;
  return `${ format(new Date(dateTime), MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT) } ${ timezone.abbr }`;
};

export const getDealCell = memoize(({ row: { type, id, deal = null } }: IProps) => {
  const filingType = Filing.getFilingType(type);

  if (deal) {
    return (
      <Tooltip content="Edit deal">
        <Link to={ getFilingProfileUrl(filingType, id) }>
          { `${ deal.primaryIssuer.issuerTicker || '' } ${ deal.seriesName }` }
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip content="Map deal">
      <Link to={ getFilingCreateUrl(filingType, id) }>
        (unmapped)
      </Link>
    </Tooltip>
  );
}, keyResolver);
