import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';

import OpenMarketListContextProvider from './OpenMarketListContext';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import OpenMarketHeader from './OpenMarketHeader';
import OpenMarketBody from './OpenMarketBody';

import * as constants from '@/condor/ui/common/constants';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import btnStyles from '@/Framework/UI/Atoms/Button/button.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './openMarket.scss';

const OpenMarketList = () => {
  return (
    <PageWrapper
      label="OpenMarket"
      contentCls={ cn(cardStyles.cardContainer, styles.cardContainer) }
      headerComponent={ (
        <div className={ pageStyles.pageHeaderActions }>
          <Link to={ constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE }>
            <Button
              dataTest="createEvent"
              variant={ variantTypes.action }
              className={ cn(btnStyles.btnIcon, btnStyles.btnIconRight, btnStyles.btnIconMobile) }
            >
              Create Event
              <Icon type={ IconType.plus } className={ styles.buttonIcon } />
            </Button>
          </Link>
          <Link to={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_CREATE }>
            <Button
              dataTest="createProfile"
              variant={ variantTypes.action }
              className={ cn(spacesStyles.mrn, btnStyles.btnIcon, btnStyles.btnIconRight, btnStyles.btnIconMobile) }
            >
              Create Profile
              <Icon type={ IconType.plus } className={ styles.buttonIcon } />
            </Button>
          </Link>
        </div>
      ) }
    >
      <OpenMarketHeader />
      <OpenMarketBody />
    </PageWrapper>
  );
};

export default () => (
  <OpenMarketListContextProvider>
    <OpenMarketList />
  </OpenMarketListContextProvider>
);
