import URL from '@/finsight/Router/urlConstants';

export const ABS_PRODUCT_ID = 'df146e90-5edc-4182-9328-98cee1bd403b';

export enum ProductAbbreviations {
  ABS = 'ABS',
  HYC = 'HYC',
  IGC = 'IGC',
}

class Product {
  static isAbsProductId(productId) {
    return productId === ABS_PRODUCT_ID;
  }

  static isAbsProductOnly(productIds) {
    return productIds.length === 1 && productIds.includes(ABS_PRODUCT_ID);
  }

  /**
   * @param {Array<Object>} products collection
   * @param {Array<String>|String} abbreviations
   * @param {Boolean} idsOnly
   */
  static getByAbbreviations(products, abbreviations, idsOnly) {
    let results = products.filter((product) => abbreviations.includes(product.abbreviation));

    return idsOnly
      ? results.map((result) => result.id)
      : results;
  }

  /**
   * @return {string}
   */
  static getDefault() {
    return ProductAbbreviations.ABS;
  }

  /**
   * @param {String} pathname
   * @return {string}
   */
  static getDefaultProductByPath(pathname) {
    if (pathname === URL.MARKET_OVERVIEW_HYC) {
      return ProductAbbreviations.HYC;
    }
    if (pathname === URL.MARKET_OVERVIEW_IGC) {
      return ProductAbbreviations.IGC;
    }
    return ProductAbbreviations.ABS;
  }
}

export default Product;
