import React, { FC, memo, useRef } from 'react';
import cn from 'classnames';
import config from '@/Framework/config';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import FINSIGHT_URLS from '@/finsight/Router/urlConstants';
import usersUrl from '@/users/infrastructure/usersUrl';
import { Link } from '@/Framework/Router/Next/Link';
import getLogoutTarget from '@/users/application/Session/getLogoutTarget';
import ContactSupport from '@/Framework/UI/Molecules/ContactSupport';
import { Icon, IconType, Popover, PlacementTypes } from '@dealroadshow/uikit';
import ExternalLink from '@/Framework/Router/Next/ExternalLink';
import isServer from '@/Framework/Router/Next/isServer';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';

import menuStyles from './menu.scss';
import spaceStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import commonIconStyles from '@dealroadshow/uikit/dist/lib/components/Icons/styles.scss';

interface IProps {
  children?: any,
  logoutUrl?: string,
  placement?: PlacementTypes,
  logoutAction?: (e: React.SyntheticEvent) => void,
  popoverContentClassName?: string,
  arrowClassName?: string,
  isNext?: boolean,
}

const Menu: FC<IProps> = ({
  children,
  placement = 'bottom',
  logoutUrl,
  logoutAction = null,
  popoverContentClassName,
  arrowClassName,
  isNext,
}) => {
  const listRef = useRef<any>();
  const { setIsUserProfileMenuOpen } = useHeaderMenuContext();
  const prefixIconStyles = cn(menuStyles.prefixIcon, commonIconStyles.smallIcon);

  const getLogoutUrl = () => {
    if (isServer()) {
      return null;
    }

    return logoutUrl || (
        usersUrl.getLogoutUrl(getLogoutTarget({
          callbackUrl: window.location.href,
          // @ts-ignore
          tenant: TenantConfig.fromHostname().code,
          isAutoLogout: false,
        }))
      );
  };

  const getLogoutBtn = () => {
    return (
      <a
        onClick={ logoutAction }
        href={ getLogoutUrl() }
        className={ menuStyles.logoutLink }
        data-test="logOutButton"
      >
        <Icon className={ prefixIconStyles } type={ IconType.exit } />
        Log Out
      </a>
    );
  };

  const getSubscriptionsLink = () => (
    !isServer() && window.location.hostname === config.tenant.finsight.hostname && (
      <Link
        name="emailSubscriptionsLink"
        to={ FINSIGHT_URLS.SUBSCRIPTIONS }
        onClick={ () => listRef.current.hide() }
      >
        <Icon className={ prefixIconStyles } type={ IconType.emailAlerts } />
        Email Subscriptions
      </Link>
    )
  );

  const getMenuList = () => (
    <div
      data-test="dropdownUserProfileMenu"
      className={ menuStyles.profileDropdownContent }
    >
      <div className={ menuStyles.section }>
        <ExternalLink
          name="myProfileLink"
          to={ usersUrl.getProfileUrl(TenantConfig.fromHostname().code) }
          isNext={ isNext }
        >
          <Icon className={ prefixIconStyles } type={ IconType.user } />
          My Profile
        </ExternalLink>
        { getSubscriptionsLink() }
      </div>
      <div className={ menuStyles.section }>
        <a
          data-test="termsOfUseLink"
          href={ finsightWebUrl.getTermsOfUseUrl() }
          target="_blank"
        >
          <Icon className={ prefixIconStyles } type={ IconType.document } />
          Terms of Use
        </a>
        <a
          data-test="privacyPolicyLink"
          href={ finsightWebUrl.getPrivacyPolicyUrl() }
          target="_blank"
        >
          <Icon className={ prefixIconStyles } type={ IconType.document } />
          Privacy Policy
        </a>
        <ContactSupport>
          <a>
            <Icon className={ prefixIconStyles } type={ IconType.chatSquare } />
            Live Tech Support
          </a>
        </ContactSupport>
      </div>
      <div className={ menuStyles.section }>
        { getLogoutBtn() }
      </div>
    </div>
  );

  return (
    <Popover
      ref={ listRef }
      content={ getMenuList() }
      wrapperClassName={ menuStyles.dropdownWrapper }
      placement={ placement }
      minPadding={ 0 }
      contentClassName={ cn(spaceStyles.pn, popoverContentClassName) }
      arrowClassName={ arrowClassName }
      usePortal={ false }
      renderOnlyVisible
      afterOpen={ () => setIsUserProfileMenuOpen(true) }
      afterClose={ () => setIsUserProfileMenuOpen(false) }
    >
      { children }
    </Popover>
  );
};

export default memo(Menu);
