import React, { Component } from 'react';
import { format } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import cn from 'classnames';
import { Field } from 'redux-form';
import isEqual from 'lodash/isEqual';
import { Input, Paginator, DataTable } from '@dealroadshow/uikit';
import { ROTATING_ENTRY_CODE_TYPE, SINGLE_ENTRY_CODE_TYPE } from '@/dealroadshow/domain/EntryCode';
import * as constants from '@/condor/ui/common/constants';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import hText from '@dealroadshow/uikit/dist/lib/styles/helpers/text.scss';
import styles from './entryCodesTable.scss';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

interface IProps {
  rotatingEntryCodes: any[],
  loginLimitValues: any[],
  changeEntryCodeLoginLimit: (payload: { id: string, loginsMaxNumber: number }) => void,
  tableName: string,
  loginLimitsAvailable: boolean,
  timeZone: ITimezone,
  sortEntryCodes: (entryCodes: any[]) => void,
}

class RotatingEntryCodes extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'startedAt',
      sortOrder: 'asc',
      page: 1,
      perPage: 25,
    };
    this.handleTableSortChange = this.handleTableSortChange.bind(this);
    this.cellActionCallback = this.cellActionCallback.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    this.getFieldSubNameFromCellProps = this.getFieldSubNameFromCellProps.bind(this);
    this.getFieldSubNameFromString = this.getFieldSubNameFromString.bind(this);
    this.loginsTimeLimitCallback = this.loginsTimeLimitCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.rotatingEntryCodes.length > 0) {
      this.sortEntryCodes(this.props.rotatingEntryCodes, this.state.sortBy, this.state.sortOrder);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.rotatingEntryCodes, this.props.rotatingEntryCodes)) {
      this.sortEntryCodes(this.props.rotatingEntryCodes, this.state.sortBy, this.state.sortOrder);
    }
  }

  /**
   * @param {Object} cellProps
   * @param {String} cellName
   * @return {string}
   */
  getFieldSubNameFromCellProps(cellProps) {
    return this.getFieldSubNameFromString(cellProps.rowIndex, cellProps.cellName);
  }

  /**
   * @param {Number} positionIndex
   * @param {String} cellName
   * @return {string}
   */
  getFieldSubNameFromString(positionIndex, cellName) {
    return `entryCodes[${ positionIndex }].${ cellName }`;
  }

  /**
   * @param {Object} page
   */
  handlePageChange(page) {
    this.setState({ page: page.selected });
  }

  /**
   * @param {Object} perPage
   */
  handleItemsPerPageChange(perPage) {
    this.setState({
      perPage: perPage.value,
      page: 1,
    });
  }

  handleTableSortChange(sortBy, sortOrder) {
    this.sortEntryCodes(this.props.rotatingEntryCodes, sortBy, sortOrder);

    this.setState({
      sortBy,
      sortOrder,
      page: 1,
    });
  }

  sortEntryCodes(sortArray, sortBy, sortOrder) {
    // The id`s comparison is needed if items are equal and sorts on the same set of items produces different results
    // If compared items (for example by name) are equal sort may produce different results, so added additional sort by
    // id in this case

    sortArray = this.removeStartAndEndDateFromDeletedSingleCodes(sortArray);

    if (sortOrder === 'asc') {
      // eslint-disable-next-line no-nested-ternary
      sortArray.sort((prev, next) => (prev[sortBy] !== next[sortBy] ? prev[sortBy] < next[sortBy] ? -1 : 1 :
        (prev.id < next.id ? -1 : 1)));
    } else {
      // eslint-disable-next-line no-nested-ternary
      sortArray.sort((prev, next) => (prev[sortBy] !== next[sortBy] ? prev[sortBy] < next[sortBy] ? 1 : -1 :
        (prev.id < next.id ? 1 : -1)));
    }

    this.props.sortEntryCodes(sortArray);
  }

  // Single entry codes need to be sorted and shown without start and end date and login limits will be None
  removeStartAndEndDateFromDeletedSingleCodes(codes) {
    return codes.map((code) => {
      if (code.type === SINGLE_ENTRY_CODE_TYPE) {
        code.expiredAt = null;
        code.startedAt = null;
        code.loginsMaxNumber = this.props.loginLimitValues[0].value;
      }

      return code;
    });
  }

  /**
   * @param {Array} collection
   * @param {Number }page
   * @param {Number} perPage
   * @return {Object}
   */
  paginator(collection, page, perPage) {
    if (collection && collection.length) {
      const offsetFrom = ((page - 1) * perPage);
      return {
        data: collection.slice(offsetFrom).slice(0, perPage),
      };
    }
    return {
      data: [],
    };
  }

  cellActionCallback(cellProps) {
    let currentVal = this.props.loginLimitValues.find((item) => item.value === cellProps.row.loginsMaxNumber);
    if (!currentVal) {
      currentVal = this.props.loginLimitValues[0].value;
    }

    return (
      <div className="login-limits-actions">
        <Field
          name={ this.getFieldSubNameFromCellProps(cellProps) }
          component={ (fieldProps) => (
            <Select
              isNarrow
              selectClassName={ (
                !!cellProps.row.deletedAt ||
                !this.props.loginLimitsAvailable ||
                (cellProps.row.type === SINGLE_ENTRY_CODE_TYPE)
              ) ? 'form-select login-limit-disabled' : 'form-select' }
              name={ fieldProps.input.name }
              onChange={
                (loginLimit) => this.props.changeEntryCodeLoginLimit({
                  id: cellProps.row.id,
                  loginsMaxNumber: loginLimit.value,
                })
              }
              value={ currentVal }
              options={ this.props.loginLimitValues }
              placeholder="Select"
              // @ts-ignore
              asterisk
              disabled={
                !!cellProps.row.deletedAt ||
                !this.props.loginLimitsAvailable ||
                (cellProps.row.type === SINGLE_ENTRY_CODE_TYPE)
              }
              className={ styles.rotatingEntryCodeWrapper }
              dataTest="rotatingEntryCodeSelect"
            />
          ) }
        />
      </div>
    );
  }

  loginsTimeLimitCallback(cellProps) {
    return (
      <Field
        isNarrow
        name={ this.getFieldSubNameFromCellProps(cellProps) }
        component={ Input }
        dataTest={ `dealSetting${ this.getFieldSubNameFromCellProps(cellProps) }Input` }
        disabled={
          !!cellProps.row.deletedAt ||
          !cellProps.row.loginsMaxNumber ||
          !this.props.loginLimitsAvailable ||
          (cellProps.row.type === SINGLE_ENTRY_CODE_TYPE)
        }
      />
    );
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const timeZoneObj = this.props.timeZone;

    const tableColumns = [
      {
        name: 'id',
        title: 'Unique ID',
        sortable: true,
        className: cn('wrapper-id', hText.nowrap),
        cellCallback: (cellProps) => (
          <div className="roadshow-id-clip ">
            { cellProps.cellData }
          </div>
        ),
      },
      {
        name: 'code',
        title: 'Entry Code',
        sortable: true,
        className: alignStyles.alignCenter,
      },
      {
        name: 'startedAt',
        title: 'Start',
        sortable: true,
        cellCallback: ({ row }) => (
          row.type === ROTATING_ENTRY_CODE_TYPE ?
            format(
              utcToZonedDate(timeZoneObj.timeZone, getDateFromUnixTime(row.startedAt)),
              constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT,
            ) : ''
        ),
      },
      {
        name: 'expiredAt',
        title: 'End',
        sortable: true,
        cellCallback: ({ row }) => (
          row.type === ROTATING_ENTRY_CODE_TYPE ?
            format(
              utcToZonedDate(timeZoneObj.timeZone, getDateFromUnixTime(row.expiredAt)),
              constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT,
            ) : ''
        ),
      },
      {
        name: 'deletedAt',
        title: 'Deleted',
        sortable: true,
        cellCallback: ({ row }) => (
          row.deletedAt ?
            format(
              utcToZonedDate(timeZoneObj.timeZone, getDateFromUnixTime(row.deletedAt)),
              constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT,
            ) : ''
        ),
      },
      {
        name: 'loginsMaxNumber',
        title: 'Login Limits',
        sortable: true,
        cellCallback: (cellProps) => this.cellActionCallback(cellProps),
      },
      {
        name: 'loginsTimeLimit',
        title: 'Minutes Per Login',
        width: '145',
        sortable: true,
        cellCallback: (cellProps) => this.loginsTimeLimitCallback(cellProps),
      },
    ];

    const totalEntryCodesCount = this.props.rotatingEntryCodes.length;
    const { page, perPage } = this.state;
    const entryCodespPaginatorCollection = this.paginator(this.props.rotatingEntryCodes, page, perPage);
    const { tableName } = this.props;
    return (
      <>
        <div className={ cn(formStyles.formLabel, styles.tableLablel) }>{ tableName }</div>
        { this.props.rotatingEntryCodes && timeZoneObj && (
          <>
            <DataTable
              columns={ tableColumns }
              dataTest="dealSettingsEntryCodesDataTable"
              sortBy={ this.state.sortBy }
              sortOrder={ this.state.sortOrder }
              onSortChange={ this.handleTableSortChange }
              data={ entryCodespPaginatorCollection.data }
              containerClassName={ styles.dataTableContainer }
            />
            <Paginator
              className={ cn(cardStyles.cardInner, spacesStyles.pln) }
              totalCount={ totalEntryCodesCount }
              page={ page }
              perPage={ perPage }
              onItemsPerPageChange={ this.handleItemsPerPageChange }
              onPageChange={ this.handlePageChange }
              dataTest="dealSettingEntryCodes"
            />
          </>
        ) }
      </>
    );
  }
}

export default RotatingEntryCodes;
