import { useCallback } from 'react';
import { Editor, Range, Transforms } from 'slate';
import { serialize } from '@/Framework/UI/Molecules/Form/RichTextInput/helpers/serialize';
import { isLinkActive } from '@/Framework/UI/Molecules/Form/RichTextInput/helpers/links';

const useHandlers = (
  onInputChange: (value: string) => void,
  editor: Editor,
  symbolsLeft: number,
  hasLineBreaks: boolean,
) => {
  const onChange = useCallback(
    (value) => {
      let html = serialize({ children: value });
      if (hasLineBreaks) {
        html = html.split(/\n{1,2}/)
          .map((line) => `<p>${ line }</p>`)
          .join('')
          .replace(/<p><p>(.*?)<\/p><\/p>/g, '<p>$1</p>');
      }

      onInputChange(html);
    },
    [],
  );

  const onKeyDown = useCallback((e) => {
    const { selection } = editor;

    const isLinkRemove = ['Backspace', 'Delete'].includes(e.key) && isLinkActive(editor);

    if (selection && Range.isCollapsed(selection)) {
      if (isLinkRemove) {
        Transforms.delete(editor, { unit: 'block' });
        return;
      }

      if (e.key === 'ArrowLeft') {
        e.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }

      if (e.key === 'ArrowRight') {
        e.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }
    }

    if (symbolsLeft <= 0 && !['Backspace', 'Delete', 'Meta'].includes(e.key)) {
      e.preventDefault();
    }
  }, [symbolsLeft]);

  const onPaste = useCallback((e) => {
    e.preventDefault();
    const textFromBuffer = e.clipboardData.getData('Text');
    const availableText = textFromBuffer.substr(0, symbolsLeft);
    if (symbolsLeft > availableText.length) {
      editor.insertText(availableText, {});
    }
  }, [symbolsLeft]);

  return {
    onChange,
    onKeyDown,
    onPaste,
  };
};

export default useHandlers;
