import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { CONDOR_ACCOUNT_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { getStatus } from '@/condor/ui/components/AccountsAndContacts/Accounts/helpers';
import {
  openProfileCellWrapper,
  statusCellWrapper,
  commonCellCallback,
  getCellDisplayDateFromString,
} from '@/condor/ui/common/Layout/helpers';
import { Checkbox, CheckboxInput } from '@dealroadshow/uikit';
import DomainField from '@/condor/ui/components/AccountsAndContacts/Accounts/DomainFieldContainer';
import TypeField from '@/condor/ui/components/AccountsAndContacts/Accounts/TypeFieldContainer';
import { APPLICATION_WRP_ID } from '@/Framework/UI/Templates/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';
import UpdatedAtCell from './UpdatedAtCell';
import BulkDeleteCell from './BulkDeleteCell';
import BulkDeleteHeadCell from './BulkDeleteHeadCell';
import styles from './accountsTable.scss';

/**
 * @param {Object} cellProps
 */
export const commonCellCallbackWithWrapper = (cellProps) => (
  <span className={ cn({
    [styles.deletedAccountField]: cellProps.row.deletedAt,
  }) }
  >
    { commonCellCallback(cellProps) }
  </span>
);

/**
 * @param {Object} cellProps
 */

export const bulkDeleteCellCallback = (cellProps) => <BulkDeleteCell cellProps={ cellProps } />;

export const bulkDeleteHeadCellCallback = () => <BulkDeleteHeadCell />;

/**
 * @param {Object}  cellProps
 */
export const statusCellCallback = (cellProps) => statusCellWrapper(
  getStatus(cellProps.row.verified, cellProps.row.deletedAt),
);

/**
 * @param {Object} cellProps
 */
export const nameCellCallback = (cellProps) => {
  if (!cellProps.row.deletedAt) {
    return openProfileCellWrapper({
      href: `${ CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ cellProps.row.id }`,
      className: cn(styles.nameCell, {
        [styles.isVerified]: cellProps.row.verified,
      }),
      children: cellProps.row.name,
    });
  }
  return commonCellCallbackWithWrapper(cellProps);
};

/**
 * @param {Object} cellProps
 */
export const webDomainsEditableFieldCallback = (cellProps) => (
  <DomainField
    name="webDomains"
    placeholder="Web Domains"
    dataTest="webDomain"
    isNarrow
    usePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);

/**
 * @param {Object} cellProps
 */
export const emailDomainsEditableFieldCallback = (cellProps) => (
  <DomainField
    name="emailDomains"
    placeholder="Email Domains"
    dataTest="emailDomain"
    isNarrow
    usePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);

/**
 * @param {Object} cellProps
 */
export const domainsCellCallback = (cellProps) => (
  <span className={ cn({
    [styles.deletedAccountField]: cellProps.row.deletedAt,
  }) }
  >
    { cellProps.cellData?.length ? cellProps.cellData.map((emailDomain) => emailDomain.domain).join(', ') : '-' }
  </span>
);

/**
 * @param {Object} cellProps
 */
export const typeEditableFieldCallback = (cellProps) => (
  <TypeField
    isNarrow
    isUsePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);

/**
 * @param {Object} cellProps
 */
export const accountTypeCellCallback = (cellProps) => (
  <span className={ cn({
    [styles.deletedAccountField]: cellProps.row.deletedAt,
  }) }
  >
    { cellProps.cellData ? cellProps.row.accountType.name : '-' }
  </span>
);

/**
 * @param {Object} cellProps
 */
export const verifiedCellCallback = (cellProps) => (
  <Checkbox
    dataTest="verifiedAccountDisabled"
    disabled
    checked={ cellProps.row.verified }
  />
);

export const verifiedEditableFieldCallback = () => (
  <Field
    name="verified"
    dataTest="verifiedAccountEnabled"
    component={ CheckboxInput }
    type="checkbox"
  />
);

/**
 * @param {Object} cellProps
 */
export const createdAtCellCallback = (cellProps) => (
  <span className={ cn({
    [styles.deletedAccountField]: cellProps.row.deletedAt,
  }) }
  >
    { getCellDisplayDateFromString(cellProps.row.createdAt) }
  </span>
);

/**
 * @param {Object} cellProps
 */
export const updatedAtCellCallback = (cellProps) => <UpdatedAtCell cellProps={ cellProps } />;
