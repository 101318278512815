import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { mapOptionsMemoized } from '@/condor/ui/common/Layout/helpers';
import * as companyActions from '@/users/application/actions/companies/data/actions';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import styles from './contacts.scss';

/**
 * @param {Object} company
 */
const createOption = (company) => ({
  label: company.name,
  value: company.id,
  className: cn({ [styles.verifiedCompany]: company.verified }),
});

const mapStateToProps = (state, ownProps) => (
  {
    name: 'companyId',
    component: ReduxFormSelect,
    placeholder: 'Select Account',
    backspaceRemovesValue: false,
    options: mapOptionsMemoized(state.companies.data.collection, createOption),
    ...ownProps,
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    handleInputChange: (query) => companyActions.getCompanies({ limit: 50, query }),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Field);
