import cn from 'classnames';
import { NavLink, Switch } from 'react-router-dom';
import { RadioGroup, Radio } from '@dealroadshow/uikit';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Route from '@/Framework/Router/ReactRouter/Route';
import CreditFlowResearchListsFiltersContextProvider, {
  useCreditFlowResearchListsFilters,
} from '@/condor/application/Finsight/CreditFlowResearch/Filters';
import ArticlesList from './Articles/ArticlesList';
import {
  CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST,
} from '@/condor/ui/common/constants';
import { EIncludedStatuses } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';

import pageStyles from '@/Framework/GlobalStyles/page.scss';
import floatStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/floats.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './styles.scss';

const pageHeaderRadio = [
  {
    label: 'All',
    dataTest: 'allResearches',
    value: EIncludedStatuses.ALL,
  },
  {
    label: 'Active',
    dataTest: 'activeResearches',
    value: EIncludedStatuses.ACTIVE,
  },
  {
    label: 'Inactive',
    dataTest: 'inactiveResearches',
    value: EIncludedStatuses.INACTIVE,
  },
];

const CreditFlowResearch = () => {
  const { setIncludedStatus, listFilters: { includedStatus }, productType } = useCreditFlowResearchListsFilters();
  return (
    <PageWrapper
      label="Posts & Stats"
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ styles.pageHeaderRow }>
            <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.ptn) }>
              <NavLink
                activeClassName={ pageStyles.isActiveNavLink }
                to={ `${ CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/${ productType }` }
                data-test="creditFlowResearchArticles"
              >
                Commentary & Deal Flow
              </NavLink>
            </div>
            <div className={ styles.pageHeaderRowCheckboxes }>
              <RadioGroup
                selectedValue={ includedStatus }
                onChange={ setIncludedStatus }
                fieldClassName={ spacesStyles.mbn }
                dataTest="researchesStatusesRadioGroup"
              >
                { pageHeaderRadio.map(({ label, value, dataTest }) => (
                  <Radio
                    key={ value }
                    label={ label }
                    value={ value }
                    dataTest={ dataTest }
                  />
                )) }
              </RadioGroup>
            </div>
          </div>
        </div>
      ) }
    >
      <TableWrapper
        className={ spacesStyles.pl }
        contentClassName={ styles.tableWrapper }
      >
        <Switch>
          <Route
            path={ `${ CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/:productType` }
            component={ ArticlesList }
          />
        </Switch>
      </TableWrapper>
    </PageWrapper>
  );
};

export default () => (
  <CreditFlowResearchListsFiltersContextProvider>
    <CreditFlowResearch />
  </CreditFlowResearchListsFiltersContextProvider>
);
