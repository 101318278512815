import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { RpcError, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import IDeal, { IDealsPayload, IDealsWithSubscriptionPayload } from '@/finsight/domain/vo/IDeal';
import { ISubscriptionStatus } from '@/finsight/application/Deal/helpers';
import { v4 as uuid } from 'uuid';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import SocketPush from '@/finsight/application/DI/Socket/Client';

@Dependencies(RpcDispatcher, SocketPush)
class DealRepository {
  exportDealExcelSubscription: Subscription;

  constructor(protected rpc: typeof RpcDispatcher, protected socketPush: typeof SocketPush) {
    this.exportDealExcelSubscription = null;
  }

  /**
   * Gets deals for public product(Finsight)
   * Do not use this method in Condor!
   */
  getDeals = async (payload: IDealsPayload): Promise<{
    collection: IDeal[],
    totalCount: number,
  }> => {
      const request = new Request('finsight_web.market.deal_transaction.deal.get_deals', payload);
      const response = await this.rpc.call<RpcSuccess>(request);

      return response.getResult().payload;
  };

  /**
   * Gets deals with subscription object for public product(Finsight)
   * Do not use this method in Condor!
   */
  getDealsWithSubscriptionStatus = async (payload: IDealsWithSubscriptionPayload): Promise<{
    deals: {
      collection: IDeal[],
      totalCount: number,
    },
    subscriptionStatus: ISubscriptionStatus,
  }> => {
    const request = new Request('finsight_web.market.deal_transaction.deal.get_deals_with_subscription', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  /**
   * Gets the most recently priced deals by given filter criteria for Condor
   * Use this method to get deals in Condor!
   *
   * @param {Object} payload
   * @return {Promise}
   */
  getDealsForCondorAdmin = async (payload) => {
    let response = await this.rpc.request(new Request('finsight_web.deal_transaction.deal.get_deals', payload));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   */
  createDeal = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.deal.create', payload));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   */
  updateDeal = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.deal.update', payload));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   */
  partialDealUpdate = async (payload) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.deal.partial_update', payload));
    return response.getResult().payload;
  };

  /**
   * @param {String} id
   */
  deleteDealById = async (id = null) => {
    const response = await this.rpc.request(
      new Request('finsight_web.deal_transaction.deal.delete', { id }),
    );
    return response.getResult().payload;
  };

  /**
   * @return {Array}
   */
  getRankingsList = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.get_rankings', payload));
    return response.getResult().payload;
  };

  /**
   * @return {Array}
   */
  getCouponTypesList = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.get_coupon_types', payload));
    return response.getResult().payload;
  };

  /**
   * @return {Array}
   */
  getRegistrationsList = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.deal_transaction.get_registrations', payload));
    return response.getResult().payload;
  };

  /**
   *
   * @param {Object} payload
   * @return {Promise<*>}
   */
  getBenchmarksList = async (payload = {}) => {
    const response = await this.rpc.call(
      new Request('finsight_web.deal_transaction.benchmark.get_benchmarks', payload),
    );
    return response.getResult().payload;
  };

  exportDealToExcel = async (dealId: string) => {
    const exportId = uuid();
    const request = new Request(
      'finsight_web.deal_transaction.deal.generate_deal_card_export',
      { dealId, exportId },
    );

    const socketSubscribeReq = new Request('finsight_web.socket.notifications', {
      entityId: exportId,
    });

    await this.rpc.call(request);
    this.exportDealExcelSubscription = await this.socketPush.subscribe(socketSubscribeReq);

    return new Promise((resolve, reject) => {
      this.exportDealExcelSubscription.on('finsight_web.export_generation',
        ({ params: { payload: { status, data: { url: exportUrl, error } } } }) => {
          this.exportDealExcelSubscription.cancel();
          if (status === 'SUCCESS') {
            resolve(exportUrl);
          } else {
            reject(new RpcError({ id: request.id, error }));
          }
      });
    });
  };
}

export default DealRepository;
