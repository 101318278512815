import React from 'react';
import isEqual from 'lodash/isEqual';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import styles from './sidebar.scss';

interface IProps {
  className?: string,
  innerRef?: React.ReactNode,
  filters?: {},
  appliedFilters?: {},
  initialFilters?: {},
  onApply?: () => void,
  onReset?: () => void,
  onTagRemove?: (fieldName: string, value: any) => void,
  tagsComponent?: React.ReactNode | {} | (() => React.ReactNode),
}

const Footer = React.forwardRef(({
  tagsComponent: TagsComponent,
  className = styles.footerContent,
  initialFilters,
  filters,
  appliedFilters,
  innerRef,
  onTagRemove,
  onApply,
  onReset,
}: IProps, ref) => {
  const isApplyDisabled = isEqual(filters, appliedFilters) ||
      (isEqual(initialFilters, appliedFilters)
          && isEqual(initialFilters, filters));
  const isResetDisabled =
      (isEqual(initialFilters, appliedFilters)
          && isEqual(initialFilters, filters));

  return (
    <div
      className={ className }
      // @ts-ignore
      ref={ ref || innerRef }
    >
      <div className={ styles.footerTags }>
        { /* @ts-ignore */ }
        <TagsComponent
          filters={ filters }
          onTagRemove={ onTagRemove }
        />
      </div>
      <div className={ styles.footerActions }>
        <Button
          variant={ variantTypes.action }
          onClick={ isApplyDisabled ? () => {} : onApply }
          disabled={ isApplyDisabled }
          title="Apply"
          dataTest="applyFiltersButton"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ isResetDisabled ? () => {} : onReset }
          disabled={ isResetDisabled }
          title="Reset"
          dataTest="resetFiltersButton"
        />
      </div>
    </div>
  );
});

export default Footer;
