import CheckboxWithFilters from '@/finsight/ui/common/components/CheckboxWithFilters';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { SubscriptionsSubGroup } from '@/condor/domain/vo/Finsight/Subscriptions/SubscriptionsData';

import styles from '../SubscriptionsForm.scss';

const SubscriptionSubGroup = ({ subgroup, isDisabled }: { subgroup: SubscriptionsSubGroup, isDisabled: boolean }) => (
  <div
    key={ subgroup.subgroupId || subgroup.id }
    className={ styles.tileRow }
  >
    <Field
      name={ subgroup.fieldName }
      label={ subgroup.subgroupName || subgroup.name }
      isDisabled={ isDisabled }
      component={ CheckboxWithFilters }
      filterSet={ {
          key: subgroup.subgroupName || subgroup.name,
          items: subgroup.subscriptions,
        } }
    />
    { !!subgroup.accessMessage && (
      <span className={ styles.tileInfo }>{ subgroup.accessMessage }</span>
    ) }
  </div>
);

export default SubscriptionSubGroup;
