import React, { memo, useEffect, useMemo } from 'react';

import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';

import DealsTable from './DealsTable';
import DealsTablePaginator from './DealsTable/DealsTablePaginatorContainer';
import SearchInputContainer from './SearchInputContainer';
import DeleteDealModal from './DeleteDealModal';
import ProductsFilter from './ProductsFilter';
import { getDealInitialValues } from '@/condor/ui/components/Finsight/Companies/helpers';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import styles from './dealsList.scss';

interface IProps {
  reset: () => void,
  init: () => void,
  isDeleteModalVisible: boolean,
  currentItem: any,
}

const DealsList = ({ reset, init, isDeleteModalVisible, currentItem }: IProps) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  const initialValues = useMemo(() => getDealInitialValues(currentItem), [currentItem]);

  return (
    <TableWrapper
      label="Deals"
      filterComponent={ (
        <div className={ styles.filtersContainer }>
          <ProductsFilter />
          <SearchInputContainer
            className={ styles.searchFilterInput }
            inputClassName={ styles.inputClassName }
            placeholder="Filter by series, country, region, product, sec/ind, parent"
            isNarrow
            isClearable
            dataTest="dealsListSearchInput"
          />
        </div>
      ) }
    >
      <DealsTable initialValues={ initialValues } />
      <DealsTablePaginator
        className={ cardStyles.cardInner }
        dataTest="dealsList"
      />
      { isDeleteModalVisible && (
        <DeleteDealModal />
      ) }
    </TableWrapper>
  );
};

export default memo(DealsList);
