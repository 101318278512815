import lodashMemoize from 'lodash/memoize';
import isServer from '@/Framework/Router/Next/isServer';

const memoize = (fn: any, keyResolver?: (...args: any[]) => string) => {
  if (isServer()) {
    return fn;
  }

  return keyResolver ? lodashMemoize(fn, keyResolver) : lodashMemoize(fn);
};

export default memoize;
