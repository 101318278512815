import { format } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { Modal, Spinner } from '@dealroadshow/uikit';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT, TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import { IArticleActionModal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import ModalFooter from './ModalFooter';

import styles from './styles.scss';

const mapTypeToDescription = {
  [ActionModalType.DELETE]: 'You are going to delete this Post. Please confirm.',
  [ActionModalType.SEND_EMAIL]: 'You are going to send email alert to all subscribed users for this Post. Please confirm.',
  [ActionModalType.RESEND_EMAIL]: 'You are going to send email alert to all subscribed users for this Post. Please confirm.',
  [ActionModalType.UNPUBLISH]: 'You are going to unpublish this Post. Please confirm.',
};

interface IArticleActionModalProps {
  isModalOpen: boolean,
  modalType: ActionModalType,
  article: IArticleActionModal,
  isNeedToShowEmailHistory?: boolean,
  isRequestPerforming?: boolean,
  closeModal: () => void,
  handleAction: () => void,
}

const ArticleActionModal = ({
  isModalOpen,
  modalType,
  article,
  isNeedToShowEmailHistory = false,
  isRequestPerforming = false,
  closeModal,
  handleAction,
}: IArticleActionModalProps) => {
  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isVisible={ isModalOpen && !!modalType }
      onCloseClicked={ closeModal }
      title="Are you sure?"
      closeOnEsc
      footer={ (
        <ModalFooter
          modalType={ modalType }
          isRequestPerforming={ isRequestPerforming }
          closeModal={ closeModal }
          handleAction={ handleAction }
        />
      ) }
      className={ styles.articlesModal }
      dataTest={ `${ modalType }ArticleModal` }
    >
      <p className={ styles.modalDescription }>{ mapTypeToDescription[modalType] }</p>
      <div className={ styles.modalTable }>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>Headline</p>
          <p className={ styles.modalTableHeadline }>{ article.headline }</p>
        </div>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>Author</p>
          <p>{ article.author.name }</p>
        </div>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>Themes</p>
          <p className={ styles.modalTableThemes }>
            { article.themes.map((theme) => <span key={ theme } className={ styles.modalTableTheme }>{ theme }</span>) }
          </p>
        </div>
      </div>
      { isNeedToShowEmailHistory && (
        <div className={ styles.emailHistory }>{
          article.mailingRequests.map((requestTime) => (
            <div key={ requestTime } className={ styles.emailHistoryRow }>
              <p className={ styles.emailHistoryRowTitle }>Email Alert sent at:</p>
              <p className={ styles.emailHistoryRowDate }>
                { format(utcToZonedDate(TIMEZONE_DEFAULT, new Date(requestTime)),
                  MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT,
                ) } ET
              </p>
            </div>
          ))
        }
        </div>
      ) }
      <Spinner isVisible={ isRequestPerforming } overlay isAbsolute />
    </Modal>
  );
};

export default ArticleActionModal;
