import React, { useMemo } from 'react';

import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import columns from './columns';
import columnsBulkTable from './columnsBulkTable';
import CheckboxArrayContext from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';
import { allItems, defaultCheckboxGroupName } from '@/Framework/UI/Molecules/CheckBoxArray/constants';
import EditableTable from '@/condor/ui/common/EnhancedTagging/EditableTable';
import BulkEditingModal from '@/condor/ui/common/EnhancedTagging/BulkEditingModal';

const RoadshowsTable = () => {
  const {
    totalCount,
    page,
    perPage,
    isFetching,
    sort,
    sortBy,
    sortOrder,
    paginate,
    setItemsPerPage,
    collection,
    checkedRoadshows,
    setCheckedRoadshows,
    editableRowId,
    isBulkModalVisible,
    setIsBulkModalVisible,
    getBulkEditingCollection,
    bulkUpdateStatus,
    bulkUpdateRoadshows,
    clearUpdateStatus,
  } = useEnhancedTaggingRoadshowsContext();

  const { allIdsOnCurrentPage, checkedOnCurrentPage, checkedOnAnotherPages } = useMemo(() => {
    const allIdsOnCurrentPage = collection.map(({ id }) => id);
    const checkedOnCurrentPage = checkedRoadshows.filter((id) => allIdsOnCurrentPage.includes(id));
    const checkedOnAnotherPages = checkedRoadshows.filter((id) => !allIdsOnCurrentPage.includes(id));

    return {
      allIdsOnCurrentPage,
      checkedOnCurrentPage,
      checkedOnAnotherPages,
    };
  }, [collection, isBulkModalVisible]);

  const initialValues = useMemo(
    () => ({
      [allItems]: allIdsOnCurrentPage,
      [defaultCheckboxGroupName]: checkedOnCurrentPage,
    }),
    [allIdsOnCurrentPage, checkedOnCurrentPage],
  );

  const onCheckboxOnChange = ({ selectedItems }) => {
    setCheckedRoadshows([...checkedOnAnotherPages, ...selectedItems]);
  };

  return (
    <CheckboxArrayContext initialValues={ initialValues } onChange={ onCheckboxOnChange }>
      <EditableTable
        stickyTable
        name="editableTableForm"
        checkedRoadshows={ checkedRoadshows }
        editableRowId={ editableRowId }
        collection={ collection }
        columns={ columns }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
        sort={ sort }
        isFetching={ isFetching }
        totalCount={ totalCount }
        pagination={ {
          page,
          perPage,
          paginate,
          setItemsPerPage,
        } }
      />
      { isBulkModalVisible && (
        <BulkEditingModal
          isBulkModalVisible={ isBulkModalVisible }
          setIsBulkModalVisible={ setIsBulkModalVisible }
          getCollection={ getBulkEditingCollection }
          columns={ columnsBulkTable }
          updateCollection={ bulkUpdateRoadshows }
          updateStatus={ bulkUpdateStatus }
          clearUpdateStatus={ clearUpdateStatus }
          checkedRoadshows={ checkedRoadshows }
          setCheckedRoadshows={ setCheckedRoadshows }
        />
      ) }
    </CheckboxArrayContext>
  );
};

export default RoadshowsTable;
