import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';
import { IconType, Input } from '@dealroadshow/uikit';
import styles from './search-input.scss';

interface IProps {
  debounceWaitTime?: number,
  value: string,
  placeholder: string,
  isNarrow?: boolean,
  isClearable?: boolean,
  inputClassName?: string,
  iconType?: IconType,
  formFieldClassName?: string,
  dataTest?: string,
  disabled?: boolean,
  className?: string,
  onInit?: () => void,
  onChange: (value: any) => void,
  reset?: () => void,
}

const SearchInput = (props: IProps) => {
  const {
    value,
    isNarrow = true,
    isClearable = true,
    iconType = IconType.filtering,
    debounceWaitTime = 500,
    onInit = noop,
    onChange = noop,
    reset = noop,
  } = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    return () => {
      reset();
      setLocalValue(null);
    };
  }, []);

  /**
   * @param {SyntheticEvent} e
   */

  const handleSearchChange = useCallback((e) => {
    const debounced = debounce(onChange, debounceWaitTime);
    setLocalValue(e.target.value);
    debounced(e.target.value);
  }, [debounceWaitTime]);

  return (
    // @ts-ignore
    <Input
      { ...props }
      className={ cn(styles.searchInput, props.className) }
      onChange={ handleSearchChange }
      value={ localValue }
      dataTest="formSearchInput"
      isNarrow={ isNarrow }
      isClearable={ isClearable }
      iconType={ iconType }
      onInit={ onInit }
    />
  );
};

export default SearchInput;
