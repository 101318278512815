import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { IAdminCallListItem } from '@/evercall/domain/vo/call/admin/AdminCallListItem';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';
import { IAuditTrailItem, IAuditTrailPayload } from '@/evercall/domain/vo/AuditTrail';
import { IGroup, IGroupEvent, TGroupSuggestion } from '@/evercall/domain/vo/call/admin/Group';

@Dependencies(RpcDispatcher)
class EvercallRepository {
  constructor(private rpc: typeof RpcDispatcher) {}

  getCallsList = async (
    payload: IFetchCollectionPayload,
  ): Promise<IFetchCollectionResponse<IAdminCallListItem>> => {
    const req = new Request(
      'evercall.admin.get_calls',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  toggleCallIsHidden = async (payload: { id: string }): Promise<null> => {
    const req = new Request(
      'evercall.admin.toggle_hide_call',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  toggleCallIsDeleted = async (payload: { id: string }): Promise<null> => {
    const req = new Request(
      'evercall.admin.toggle_delete_call',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  toggleCallIsSupported = async (payload: { id: string }): Promise<null> => {
    const req = new Request(
      'evercall.admin.toggle_delete_call_manager_support',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  getCallData = async (payload: { id: string }): Promise<IAdminCall> => {
    const req = new Request(
      'evercall.admin.get_call_settings',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  updateCallData = async (payload: IAdminCall): Promise<IAdminCall> => {
    const req = new Request(
      'evercall.admin.update_call_settings',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  getCallCustomRegistrationSettings = async (payload: { callId: string }): Promise<ICustomRegistration> => {
    const req = new Request('evercall.admin.get_call_appearance_settings', payload);
    const response = await this.rpc.request(req);
    return response.getResult().payload;
  };

  getCallCustomRegistrationDefaultSettings = async (): Promise<ICustomRegistration> => {
    const req = new Request('evercall.admin.get_default_call_appearance_settings');
    const response = await this.rpc.request(req);
    return response.getResult().payload;
  };

  updateCallCustomRegistrationSettings = async (
    payload: ICustomRegistration & { callId: string },
  ): Promise<ICustomRegistration> => {
    const req = new Request('evercall.admin.update_call_appearance_settings', payload);
    const response = await this.rpc.request(req);
    return response.getResult().payload;
  };

  getAuditTrail = async (payload: IAuditTrailPayload): Promise<IFetchCollectionResponse<IAuditTrailItem>> => {
    const req = new Request('evercall.admin.get_call_audit_trail', payload);
    const response = await this.rpc.request(req);
    return response.getResult().payload;
  };

  getGroupEventsSuggestions = async (payload: { search: string }): Promise<IGroupEvent[]> => {
    const req = new Request(
      'evercall.admin.get_group_events_suggestion',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  getGroupsSuggestions = async (payload: { search: string }): Promise<TGroupSuggestion[]> => {
    const req = new Request(
      'evercall.admin.get_call_groups_suggestion',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  getGroupData = async (payload: { id: string }): Promise<IGroup> => {
    const req = new Request(
      'evercall.admin.get_group',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  createGroup = async (payload: IGroup): Promise<IGroup> => {
    const req = new Request(
      'evercall.admin.create_group',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  updateGroup = async (payload: IGroup): Promise<IGroup> => {
    const req = new Request(
      'evercall.admin.update_group',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  deleteGroup = async (payload: { id: string }): Promise<null> => {
    const req = new Request(
      'evercall.admin.update_call_settings',
      payload,
    );
    const response = await this.rpc.request(req);

    return response.getResult().payload;
  };
}

export default EvercallRepository;
