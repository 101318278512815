import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import { validateName } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateName';
import { validatePhoneNumber } from '@/Framework/UI/Organisms/FinalForm/validators/user/validatePhoneNumber';
import { validateRequiredFieldsWithInitialValues } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFieldsWithInitialValues';
import { validateEmailDomain } from '@/Framework/UI/Organisms/FinalForm/validators/validateEmailDomain';
import { validateLinkedinLink } from '@/Framework/UI/Organisms/FinalForm/validators/validateLinkedinLink';

const requiredFields = [
  {
    fieldName: 'firstName',
    fieldCaption: 'First Name',
  },
  {
    fieldName: 'lastName',
    fieldCaption: 'Last Name',
  },
  {
    fieldName: 'title',
    fieldCaption: 'Title',
  },
  {
    fieldName: 'jurisdiction',
    fieldCaption: 'Jurisdiction',
  },
];

export default (values, formState) => groupValidators(
  validateName({ fieldName: 'firstName', fieldCaption: 'First Name', value: values.firstName }),
  validateName({ fieldName: 'lastName', fieldCaption: 'Last Name', value: values.lastName }),
  validateEmail({ fieldName: 'email', value: values.email }),
  validatePhoneNumber({
    fieldName: 'officePhone',
    fieldCaption: 'Office Phone',
    value: values.officePhone,
    initialValue: formState.initialValues.officePhone?.value,
    isRequired: true,
    isRequirementDependsOnInitialValue: true,
  }),
  validatePhoneNumber({
    fieldName: 'personalPhone',
    fieldCaption: 'Mobile Phone',
    value: values.personalPhone,
    initialValue: formState.initialValues.personalPhone?.value,
  }),
  validatePhoneNumber({
    fieldName: 'mobilePhone',
    fieldCaption: 'Mobile Phone',
    value: values.mobilePhone,
    initialValue: formState.initialValues.mobilePhone?.value,
  }),
  validateRequiredFieldsWithInitialValues(requiredFields, values, formState.initialValues),
  validateEmailDomain({
    fieldName: 'bloombergEmail',
    expectedDomain: 'bloomberg.net',
    value: values.bloombergEmail?.trim(),
    emailName: 'Bloomberg',
  }),
  validateLinkedinLink({
    fieldName: 'linkedinProfile',
    value: values.linkedinProfile?.trim(),
  }),
);
