import { connect } from 'react-redux';
import CondorAsyncSelect from '@/Framework/UI/Molecules/Form/Select/legacy/CondorAsyncSelect/CondorAsyncSelect';
import { fetchCompaniesOptions } from '@/condor/application/actions/finsight/company/filteredCompaniesOptionsByFields/actions';
import * as selectors from '@/condor/application/actions/finsight/company/filteredCompaniesOptionsByFields/selectors';

const mapStateToProps = (state, ownProps) => ({
  filteredOptions: selectors.getFilteredCompaniesOptionsByFields(state)[ownProps.input.name] || [],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchOptions: (query, callback) => dispatch(fetchCompaniesOptions(
    {
      query,
      fieldName: ownProps.input.name,
      includedRole: ownProps.role,
      withoutExcludedIds: ownProps.withoutExcludedIds,
      callback,
    },
  )),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondorAsyncSelect);
