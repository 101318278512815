import { IDefaultSelectOption } from '@dealroadshow/uikit';
import { IDialInCountryFormValue } from './DialInCountriesFieldArray/interfaces';
import { ISpeakerArrayItem } from '@/evercall/domain/vo/call/SpeakerArrayItem';

export interface ITimeZoneOption extends IDefaultSelectOption {
  timeZone: string,
}

export type TQuestionLabel = { [index: string]: string } | string;

export interface IRadioButtonAnswer {
  value: string,
  label: TQuestionLabel,
  dataTest: string,
}

export enum QuestionType {
  CheckboxOnly = 'CheckboxOnly',
  RadioOnly = 'RadioOnly',
  // PseudoRadio looks as 2-option radio button on Evercall and as checkbox on DM Portal except registrationAheadWebinar
  PseudoRadio = 'PseudoRadio',
}

export interface IQuestion {
  type: QuestionType,
  name: string,
  explanation?: TQuestionLabel,
  title: TQuestionLabel,
  radioButtonAnswers?: IRadioButtonAnswer[],
  dataTest?: string,
}

export interface IOaccQuestions {
  DRS_WITH_EMBEDDED_DIAL_INS: IQuestion,
  INSTANT_CHECK_IN: IQuestion,
  LIVE_OPERATOR: IQuestion,
  REGISTRATION_AHEAD: IQuestion,
  CUSTOM_SCRIPT: IQuestion,
  ROADSHOW_SHARED_PRESENTATION: IQuestion,
  QA_SESSION: IQuestion,
  SPEAKER_PRESENTING_PROCESS: IQuestion,
  SEPARATED_CONFERENCE_LINES: IQuestion,
  QA_DASHBOARD: IQuestion,
  ROADSHOW_ASK_QUESTION: IQuestion,
  CALL_RECORDING: IQuestion,
  RECORDED_REPLAY: IQuestion,
  CALL_TRANSCRIPTION: IQuestion,
}

export interface IWebinarQuestions {
  JOIN_VIA_LINK: IQuestion,
  CUSTOM_SCRIPT: IQuestion,
  QUESTION_VERBAL: IQuestion,
  QUESTION_WRITTEN: IQuestion,
  SPEAKER_PRESENTING_PROCESS: IQuestion,
  SEPARATED_SESSION_LINES: IQuestion,
  CALL_TRANSCRIPTION: IQuestion,
  MP4: IQuestion,
  REPLAY_STREAM: IQuestion,
  REPLAY_PLAYER: IQuestion,
  ADDITIONAL_REPLAY: IQuestion,
}

export interface IMeetingQuestions {
  REGISTRATION_AHEAD: IQuestion,
  CUSTOMIZE_REGISTRATION: IQuestion,
  SLIDES_ONLY_ROADSHOW_REQUIRED: IQuestion,
  MODERATION: IQuestion,
  CALL_TRANSCRIPTION: IQuestion,
  CALL_RECORDING: IQuestion,
}

export interface ICallDetailsFormValues {
  name: string,
  dateAt: number,
  timeAt: number,
  timeZoneId: ITimeZoneOption | null,
  transactionTypeId: IDefaultSelectOption | null,
  eventTypeId: IDefaultSelectOption | null,
  estimatedDuration: string,
  approximateNumberOfSpeakers: string,
  approximateNumberOfParticipants: string,
  dialInCountries: IDialInCountryFormValue[],
}

export interface IOaccConfigurationsFormValues<TBinaryQuestionAnswer> { // Binary question is a Yes/No question
  participantCheckInProcess: string[],
  customScript: TBinaryQuestionAnswer,
  registrationAhead: TBinaryQuestionAnswer,
  roadshowSharedPresentation: TBinaryQuestionAnswer,
  qaSession: TBinaryQuestionAnswer,
  speakerPresentingProcess: string,
  separatedConferenceLines: TBinaryQuestionAnswer,
  qaDashboard: TBinaryQuestionAnswer,
  roadshowAskQuestion: TBinaryQuestionAnswer,
  callRecording: TBinaryQuestionAnswer,
  transcriptionServices: TBinaryQuestionAnswer,
  recordedReplay: string,
}

export interface IWebinarConfigurationsFormValues<TBinaryQuestionAnswer> { // Binary question is a Yes/No question
  /* webinarPlatformId input field is located in Call Details section
   but as an object property it is related to configurations */
  webinarPlatformId: IDefaultSelectOption,
  speakers: ISpeakerArrayItem[],
  customScriptWebinar: TBinaryQuestionAnswer,
  speakerPresentingProcessWebinar: string,
  transcriptionServicesWebinar: TBinaryQuestionAnswer,
  verbalQaSession: TBinaryQuestionAnswer,
  writtenQaSession: TBinaryQuestionAnswer,
  separatedSessions: TBinaryQuestionAnswer,
  callRecordingProcess: string,
  uneditedReplayHostedByThirdParty: TBinaryQuestionAnswer,
  editedReplayHostedOnDrs: TBinaryQuestionAnswer,
  additionalPremiumReplay: TBinaryQuestionAnswer,
}

export interface IMeetingCallConfigurationsFormValues<TBinaryQuestionAnswer> { // Binary question is a Yes/No question
  /* managedMeetingPlatformId input field is located in Call Details section
   but as an object property it is related to configurations */
  managedMeetingPlatformId: IDefaultSelectOption,
  registrationAheadMeeting: TBinaryQuestionAnswer,
  moderation: TBinaryQuestionAnswer,
  moderationHostName: string,
  moderationHostEmail: string,
  slidesOnlyRoadshowRequired: TBinaryQuestionAnswer,
  slidesOnlyRoadshowAdditionalNotes: string,
  recording: TBinaryQuestionAnswer,
  transcription: TBinaryQuestionAnswer,
  registrationCustomization: TBinaryQuestionAnswer,
  registrationCustomizationCompanyLogo: {
    name: string,
    url: string,
  } | null,
  registrationCustomizationBackgroundImage: {
    name: string,
    url: string,
  } | null,
}
