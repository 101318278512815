import cn from 'classnames';

import {
  textFromArray,
  statusCellCallback,
  sectorIndustryCellCallback,
  headlineCellCallback,
  themesCellCallback,
  authorCellCallback,
  regionCellCallback,
  createdAtCellCallback,
  updatedAtCellCallback,
} from './cellCallbacks';

import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

export default [
  {
    name: 'isActive',
    title: 'Status',
    minWidth: 70,
    maxWidth: 70,
    width: 70,
    cellCallback: statusCellCallback,
    className: alignStyles.alignCenter,
  },
  {
    name: 'sectorOrIndustry',
    title: 'Sec/Ind',
    minWidth: 66,
    maxWidth: 66,
    width: 66,
    cellCallback: sectorIndustryCellCallback,
  },
  {
    name: 'headline',
    title: 'Headline',
    maxWidth: 757,
    width: 757,
    cellCallback: headlineCellCallback,
  },
  {
    name: 'themes',
    title: 'Themes',
    maxWidth: 250,
    minWidth: 250,
    cellCallback: themesCellCallback,
    className: alignStyles.alignRight,
  },
  {
    name: 'author',
    title: 'Author',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: authorCellCallback,
  },
  {
    name: 'products',
    title: 'Products',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: textFromArray,
  },
  {
    name: 'regions',
    title: 'Regions',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: regionCellCallback,
  },
  {
    name: 'createdAt',
    title: 'Created at',
    maxWidth: 109,
    minWidth: 109,
    sortable: true,
    cellCallback: createdAtCellCallback,
  },
  {
    name: 'updatedAt',
    title: 'Last Updated',
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
    className: cn(spacesStyles.pln, alignStyles.alignRight),
    cellCallback: updatedAtCellCallback,
  },
];
