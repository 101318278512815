import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { IFormValues } from '../../interfaces';
import { FormFieldName } from '../../constants';
import { DISCLAIMER_LENGTH } from './constants';

const getCustomizedValidators = (values: IFormValues) => {
  if (!values.isCustomized) return [];

  const fieldsToValidate = [
    { name: FormFieldName.BACKGROUND_COLOR, caption: 'Background Color' },
    { name: FormFieldName.TILE_COLOR, caption: 'Tile Color' },
    { name: FormFieldName.PRIMARY_TEXT_COLOR, caption: 'Primary Text Color' },
    { name: FormFieldName.SECONDARY_TEXT_COLOR, caption: 'Secondary Text Color' },
    { name: FormFieldName.LINK_COLOR, caption: 'Link Color' },
    { name: FormFieldName.INPUT_LABEL_COLOR, caption: 'Input Label Text Color' },
    { name: FormFieldName.BUTTON_TEXT_COLOR, caption: 'Button Text Color' },
    { name: FormFieldName.BUTTON_COLOR, caption: 'Button Color' },
  ];

  return fieldsToValidate.map(({ name, caption }) => (
    validateRequiredField({
      fieldName: name,
      fieldCaption: caption,
      // @ts-ignore
      value: values[name],
    })
  ));
};

export default (values: IFormValues) => {
  return groupValidators(
    validateMinLength({
      fieldName: FormFieldName.DISCLAIMER,
      fieldCaption: 'Profile disclaimer',
      value: values.disclaimer,
      length: 3,
    }),
    validateMaxLength({
      fieldName: FormFieldName.DISCLAIMER,
      fieldCaption: 'Profile disclaimer',
      value: values.disclaimer,
      length: DISCLAIMER_LENGTH,
    }),
    ...getCustomizedValidators(values),
  );
};
