import * as constants from '@/condor/ui/common/constants';

export const TABS = [
  {
    title: 'All',
    path: `${ constants.CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/all`,
    dataTest: 'allArticlesTab',
  },
  {
    title: 'ABS',
    path: `${ constants.CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/abs`,
    dataTest: 'absArticlesTab',
  },
  {
    title: 'HYC',
    path: `${ constants.CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/hyc`,
    dataTest: 'hycArticlesTab',
  },
  {
    title: 'IGC',
    path: `${ constants.CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST }/igc`,
    dataTest: 'igcArticlesTab',
  },
];
