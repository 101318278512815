import React, { memo } from 'react';
import { Spinner, ProgressBar } from '@dealroadshow/uikit';
import * as uploadTypes from '@/Framework/UI/Organisms/Upload/uploadTypes';
import * as validationTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/validationTypes';
import uploadOneFileStyle from '@/condor/ui/components/Finsight/Import/modules/Upload/uploadOneFile.scss';
import {
  convertUploadTypesToString,
  isShowProgressByUploadType,
} from '@/Framework/UI/Organisms/Upload/helpers/convertUploadTypes';
import { convertValidationTypesToSting } from '@/condor/ui/components/Finsight/Import/modules/Validation/helpers/convertUploadTypes';

interface IProps {
  uploadProcess: any,
  validationProcess: any,
}

const UploadProcess = (
  {
    uploadProcess,
    validationProcess,
  }: IProps,
) => {
  const isUploaded = uploadProcess.status === uploadTypes.UPLOAD_DONE;
  const isValidated = validationProcess.status === validationTypes.VALIDATION_COMPLETE;

  if (!uploadProcess.uuid || (isUploaded && isValidated)) {
    return null;
  }

  return (
    <>
      <Spinner
        isVisible={
          !isUploaded ||
          !isValidated
        }
        overlay
      />
      { !isUploaded && (
        <span className={ uploadOneFileStyle.formFileUploadOneFileStatus }>
          Upload: { convertUploadTypesToString(uploadProcess.status) }
        </span>
      ) }
      { isUploaded && !isValidated && (
        <span className={ uploadOneFileStyle.formFileUploadOneFileStatus }>
          Validate: { convertValidationTypesToSting(validationProcess.status, validationProcess.payload) }
        </span>
      ) }
      { isShowProgressByUploadType(uploadProcess.status) && (
        <ProgressBar
          value={ uploadProcess.progress }
          className={ uploadOneFileStyle.progressBar }
          filledClassName={ uploadOneFileStyle.progressBarFilled }
        />
      ) }
      { isShowProgressByUploadType(uploadProcess.status) && (
        <span
          className={ uploadOneFileStyle.formFileUploadOneFilePercent }
        >
          { uploadProcess.progress }%
        </span>
      ) }
    </>
  );
};

export default memo(UploadProcess);
