import React, { useState } from 'react';
import cn from 'classnames';
import { Input, Spinner, IconType } from '@dealroadshow/uikit';
import DataroomCondorListingContext, { useDataroomCondorListingContext } from '@/dataroom/application/condor/DataroomCondorListingContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import DataroomsList from './DataroomsList';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import headersStyles from '@dealroadshow/uikit/dist/lib/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './datarooms.scss';

const Datarooms = () => {
  const [searchString, setSearchString] = useState('');

  const { tenant } = useDataroomTenantContext();

  const { name } = DataroomTenantConfig.fromCode(tenant);

  const {
    isFetching,
    isInitialized,
    debouncedSearch,
  } = useDataroomCondorListingContext();

  const isInitialDataFetching = isFetching && !isInitialized;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
    debouncedSearch(value);
  };

  return (
    <div>
      <div className={ pageStyles.pageHeader }>
        <div
          className={ cn(pageStyles.pageHeaderTitle, headersStyles.isH2) }
          data-test="pageHeaderTitle"
        >
          { name }
        </div>
      </div>
      <div className={ cardStyles.cardContainer }>
        { !isInitialDataFetching && (
          <div className={ cn(cardStyles.cardInner, spacesStyles.pbn) }>
            <div className={ styles.header }>
              <div className={ styles.headerTitle }>
                <h3>{ name } Data Rooms</h3>
              </div>
              <div className={ styles.searchWrp }>
                <div
                  className={ styles.searchInput }
                  data-test={ `${ tenant }SearchTable` }
                >
                  { /* @ts-ignore */ }
                  <Input
                    name="search"
                    iconType={ IconType.filtering }
                    value={ searchString }
                    placeholder="Filter by Data Room Name"
                    onChange={ handleSearchChange }
                    dataTest="dataroomSearchInput"
                    isNarrow
                  />
                </div>
              </div>
            </div>
          </div>
        ) }
        { isInitialDataFetching && (
          <div className={ styles.initialPreloaderWrp }>
            <Spinner />
          </div>
        ) }
        { !isInitialDataFetching && (<DataroomsList />) }
      </div>
    </div>
  );
};

export default () => (
  <DataroomCondorListingContext>
    <Datarooms />
  </DataroomCondorListingContext>
);
