import { useCreditFlowResearchListsFilters } from '@/condor/application/Finsight/CreditFlowResearch/Filters';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';
import { Select } from '@dealroadshow/uikit';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { getRegionsByIds, mapRegionsToOptions } from './helpers';

import styles from './styles.scss';

const TableFilters = () => {
  const {
    listFilters: { includedRegionIds },
    setIncludedRegions,
    searchString,
    onSearchChange,
  } = useCreditFlowResearchListsFilters();

  const { regions } = useRegionsProductsContext();

  return (
    <>
      <Select
        value={ getRegionsByIds(regions, includedRegionIds) }
        onChange={ setIncludedRegions }
        dataTest="creditFlowRegionsSelect"
        options={ mapRegionsToOptions(regions) }
        isMulti
        isSearchable={ false }
        className={ styles.select }
        placeholder="Select Region"
        isNarrow
      />
      <SearchInput
        value={ searchString }
        onChange={ onSearchChange }
        placeholder="Filter by Headline, Description or Theme"
        className={ styles.searchInput }
        isNarrow
        dataTest="creditFlowSearchInput"
      />
    </>
  );
};

export default TableFilters;
