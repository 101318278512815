import React, { useEffect } from 'react';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';

import DealNoPricingDateList from './DealNoPricingDateTable/DealNoPricingDateListContainer';
import DealNoPricingDatePaginator from './DealNoPricingDateTable/DealNoPricingDatePaginatorContainer';
import { Spinner, dataTableStyles } from '@dealroadshow/uikit';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const DealNoPricingDatePage = ({ init, reset }: IProps) => {
  useEffect(() => {
    init();

    return reset;
  }, []);

  return (
    <PageWrapper label="Deals - Missing Pricing Date">
      <TableWrapper>
        { /* @ts-ignore */ }
        <DealNoPricingDateList
          className={ dataTableStyles.isHoverable }
          loadingComponent={ Spinner }
        />
        { /* @ts-ignore */ }
        <DealNoPricingDatePaginator className={ cardStyles.cardInner } />
      </TableWrapper>
    </PageWrapper>
  );
};

export default DealNoPricingDatePage;
