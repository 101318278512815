import React from 'react';
import { Checkbox as BaseCheckbox } from '@/Framework/UI/Molecules/CheckBoxArray';
import styles from './checkbox.scss';

interface IProps {
  value: string | number | {},
  dataTest: string,
  name?: string,
  checked?: boolean,
  disabled?: boolean,
}

const Checkbox = ({ value, name, dataTest, checked, disabled }: IProps) => (
  <BaseCheckbox
    className={ styles.checkbox }
    value={ value }
    name={ name }
    dataTest={ dataTest }
    checked={ checked }
    disabled={ disabled }
  />
);

export default Checkbox;
