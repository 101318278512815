import { Button, ButtonVariantType, Modal } from '@dealroadshow/uikit';
import NavigationPrompt from 'react-router-navigation-prompt';

import styles from './styles.scss';

interface IAreYouSureModalProps {
  when: boolean,
  disableNative?: boolean,
}

const AreYouSureModal = ({ when, disableNative = true }: IAreYouSureModalProps) => (
  <NavigationPrompt
    when={ when }
    disableNative={ disableNative }
  >
    { ({ onConfirm, onCancel, isActive }) => (
      <Modal
        onCloseClicked={ onCancel }
        isVisible={ isActive }
        className={ styles.areYouSureModal }
        title="Are You Sure?"
        footer={ (
          <>
            <Button
              onClick={ onConfirm }
              variant={ ButtonVariantType.action }
              title="Yes"
              dataTest="roadshowFormYesButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              onClick={ onCancel }
              title="Cancel"
              dataTest="roadshowFormNoButton"
            />
          </>
        ) }
      >
        Are you sure you want to leave this form? Any changes you made will be lost.
      </Modal>
    ) }
  </NavigationPrompt>
);

export default AreYouSureModal;
