import React, { useMemo } from 'react';
import {
  initialSubmitData,
  useApproveDenyFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ApproveDenyFormContext';
import FinalForm, { Field } from '@/Framework/UI/Organisms/FinalForm';
import {
  REQUEST_DEFAULT_SUBJECT,
  RequestSubmitType,
} from '@/condor/application/FinsightManager/ManagePlans/constants';
import NoNotificationOverlay from '../../common/NoNotificationOverlay';
import InputTags from '@/Framework/UI/Molecules/Form/InputTags';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';
import { Spinner, Modal } from '@dealroadshow/uikit';
import { PreviewText } from '../../common/PreviewText';
import FooterModal from './FooterModal';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from './styles.scss';
import commonStyles from '../../common/common.scss';

const getInitialValues = (email: string, submitType: RequestSubmitType) => ({
  to: [{ value: email, isValid: true }],
  subject: REQUEST_DEFAULT_SUBJECT[submitType],
  emailText: '',
  messagePreview: '',
});

const ApproveDenyModal = () => {
  const {
    isNotificationEnabled,
    selectedSubmitData: { typeSubmit, cellData },
    setSelectedSubmitData,
    onSubmit,
    isSubmitting,
  } = useApproveDenyFormContext();
  const { email, firstName, currentPlan, requestedPlan } = cellData;

  const onResetForm = (restart) => {
    setSelectedSubmitData(initialSubmitData);
    restart();
  };

  const renderForm: TFinalFormRenderProp = ({ values, handleSubmit, form }) => (
    <Modal
      title={ `${ typeSubmit } Subscription Request` }
      isVisible={ typeSubmit !== null }
      dataTest={ `${ typeSubmit }UserPlanRequestModal` }
      handleCloseClick={ () => onResetForm(form.reset) }
      className={ styles.modalContainer }
      footer={ <FooterModal handleSubmit={ handleSubmit } /> }
    >
      { isSubmitting && <Spinner isAbsolute overlay /> }
      <div className={ styles.modalBody }>
        <NoNotificationOverlay isNotificationEnabled={ isNotificationEnabled } />
        <div className={ commonStyles.fieldWrapper }>
          <label className={ commonStyles.fromFieldLabel }>From:</label>
          <div className={ commonStyles.fromField }>
            Finsight Support<span className={ commonStyles.fromFieldEmail }>{ ' <support@finsight.com>' }</span>
          </div>
        </div>
        <div className={ commonStyles.fieldWrapper }>
          <label className={ commonStyles.topFieldLabel }>To:</label>
          <InputTags
            wrapperClassName={ commonStyles.topInput }
            tags={ values.to }
            isDisabled
            dataTest={ `${ typeSubmit }UserPlanRequestFormEmailField` }
          />
        </div>
        <div className={ commonStyles.fieldWrapper }>
          <label className={ commonStyles.topFieldLabel }>Subject:</label>
          <Field
            name="subject"
            formFieldClassName={ commonStyles.topInput }
            component={ Input }
            disabled={ isSubmitting }
            dataTest={ `${ typeSubmit }UserPlanRequestFormSubjectField` }
          />
        </div>
        <Field
          isFocused
          name="emailText"
          component={ RichTextInput }
          containerCls={ commonStyles.editor }
          /* Please do not do this. Instead, use Textarea and apply 'white-space: pre-line' to the div where
           * the submitted text is being inserted via dangerouslySetInnerHTML tag. */
          toolbarComponent={ () => null }
          placeholder="Type text here..."
          label="custom message text:"
          dataTest={ `${ typeSubmit }UserPlanRequestFormMessageField` }
          disabled={ isSubmitting }
        />
        <PreviewText
          name={ firstName }
          type={ typeSubmit }
          emailText={ values.emailText === '<p></p>' ? '' : values.emailText }
          planName={ typeSubmit === RequestSubmitType.APPROVE ? requestedPlan : currentPlan }
        />
      </div>
    </Modal>
  );

  const initialValues = useMemo(() => getInitialValues(email, typeSubmit), [email, typeSubmit]);

  return (
    <FinalForm
      name="grandDenyAccessForm"
      onSubmit={ onSubmit }
      initialValues={ initialValues }
      render={ renderForm }
      dataTest={ `${ typeSubmit }UserPlanRequestForm` }
    />
  );
};

export default ApproveDenyModal;
