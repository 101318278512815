import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import config from '@/Framework/config';

const baseSchema = {
  '@context': 'https://schema.org/',
  publisher: {
    '@type': 'Organization',
    name: 'Finsight',
    url: finsightWebUrl.getUrl(),
    telephone: config.tenant.finsight.supportPhoneNumber,
    email: config.tenant.finsight.supportEmail,
  },
};

export default baseSchema;
