import React from 'react';
import { Field } from 'redux-form';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';

interface IProps {
  options: any[],
}

const RegionField = (props: IProps) => (
  <Field
    isNarrow
    name="region"
    disabled
    component={ ReduxFormSelect }
    options={ props.options }
    components={ { DropdownIndicator: () => null } }
    dataTest="regionFieldReduxFormSelect"
  />
);

export default RegionField;
