import { createContext, useContext, useEffect } from 'react';
import useCountries from '@/Framework/hooks/useCountries';
import useTitles from '@/users/application/useTitles';

const useProfileContext = () => {
  const { getCountriesList, collection: countries, isFetching: isCountriesFetching } = useCountries();
  const { isCollectionFetching: isTitlesFetching, collection: titles, getTitles } = useTitles();

  useEffect(() => {
    getCountriesList();
    getTitles();
  }, []);

  return {
    isCountriesFetching,
    isTitlesFetching,
    countries,
    titles,
  };
};

export type CommonProfileContextType = ReturnType<typeof useProfileContext>;

export const CommonProfileContext = createContext<CommonProfileContextType>(undefined);

export const useCommonProfileContext = () => {
  const context = useContext(CommonProfileContext);
  if (!context) {
    throw new Error('useCommonProfileContext must be used within a CommonProfileContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CommonProfileContextProvider = ({ children }: IProps) => (
  <CommonProfileContext.Provider value={ useProfileContext() }>
    { children }
  </CommonProfileContext.Provider>
);

export default CommonProfileContextProvider;
