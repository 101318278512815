import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import {
  ArticleFormData,
  IGroupParentIssuer,
  IOptionDeal,
  IOptionParentIssuer,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { getIssuerOptions, getNewParentIssuerIds, getParentIssuerGroupMapper, getParentOptions } from '../helpers';
import { useForm, useField } from '@/Framework/UI/Organisms/FinalForm';
import useCompany from '../useCompany';

const MIN_LENGTH_QUERY = 3;
const MAX_LENGTH_OPTIONS = 50;

const FIELD_DEALS_NAME = 'deals';
const FIELD_PARENT_ISSUER_NAME = 'parentAndIssuer';

const useParentIssuer = (initialFormData: ArticleFormData) => {
  const { loadCompanies, loadParentByIds, loadIssuerByIds } = useCompany();
  const { change } = useForm<ArticleFormData>();
  const { input: { value: deals = [] } } = useField<IOptionDeal[]>(FIELD_DEALS_NAME);
  const { input: { value: parentAndIssuer = [] } } = useField<IOptionParentIssuer[]>(FIELD_PARENT_ISSUER_NAME);
  const [isFullField, setIsFullField] = useState(false);

  const [currentOptions, setCurrentOptions] = useState<IGroupParentIssuer[]>();

  const loadParentIssuer = async (
    query: string,
    callback: (options: IGroupParentIssuer[]) => void,
  ) => {
    if (query.length < MIN_LENGTH_QUERY) {
      callback([]);

      return;
    }

    const parentFilter = { includedRole: 'parent' };
    const issuerFilter = { includedRole: 'issuer' };
    const payload = { page: 1, perPage: MAX_LENGTH_OPTIONS, query };

    setCurrentOptions([]);

    const [parents, issuers] = await Promise.all([
      loadCompanies({ ...payload, filter: parentFilter }),
      loadCompanies({ ...payload, filter: issuerFilter }),
    ]);

    const options = getParentIssuerGroupMapper(parents, issuers);

    setCurrentOptions(options);
    callback(options);
  };

  const loadParentIssuerOptions = debounce(loadParentIssuer, 500);

  const getNewParentIssuerOptionsFromDeals = async (deals: IOptionDeal[]) => {
    const parentIssuersIds = parentAndIssuer.map(({ value }) => value);
    const { parentIds, issuerIds } = getNewParentIssuerIds(deals, parentIssuersIds);

    const parents = await loadParentByIds(parentIds);
    const issuers = await loadIssuerByIds(issuerIds);

    change(FIELD_PARENT_ISSUER_NAME, [...parentAndIssuer, ...getParentOptions(parents), ...getIssuerOptions(issuers)]);
  };

  useEffect(() => {
    const isInitData = JSON.stringify(initialFormData.deals) === JSON.stringify(deals);

    if ((isFullField || !isInitData) && deals.length > 0) {
      getNewParentIssuerOptionsFromDeals(deals);
      setIsFullField(true);
    }
  }, [JSON.stringify(deals)]);

  return {
    currentParentIssuerOptions: currentOptions,
    loadParentIssuerOptions,
  };
};

export default useParentIssuer;
