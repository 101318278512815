import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import * as actionTypes from './actionTypes';
import selectors from './selectors';
import { resetValidation } from '../validation/actions';
import {
  unsubscribeToFileImportingChange,
  subscribeToFileImportingChange,
  bondImport,
} from '@/condor/application/actions/finsight/import/bond/validation/actions';
import createAction from '@/Framework/State/Redux/createAction';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadStart = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_ADD,
  payload,
);

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadChunkSuccess = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_CHUNK_SUCCESS,
  payload,
);

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadSuccess = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_SUCCESS,
  payload,
);

/**
 * chunk or combination
 * @param {Object} payload
 * @return {Object}
 */
const uploadError = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_ERROR,
  payload,
);

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadProcessing = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_PROCESSING,
  payload,
);

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadDone = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_DONE,
  payload,
);

/**
 * @param {Object} payload
 * @return {Object}
 */
const uploadCancelSuccess = (payload) => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL_SUCCESS,
  payload,
);

/**
 * @return {Object}
 */
const uploadReset = () => createAction(
  actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_RESET,
);

/**
 * @param {String} uuid
 */
export const uploadCancel = (uuid) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL,
    payload: {
      uuid,
    },
  });

  if (selectors.getUploadFileCancelCallback(getState())
      && typeof selectors.getUploadFileCancelCallback(getState()).cancel === 'function') {
    selectors.getUploadFileCancelCallback(getState()).cancel();
  } else if (selectors.getUploadFileProcessId(getState())) {
    dispatch(uploadCancelSuccess({
      uuid: selectors.getUploadFileProcessId(getState()),
    }));
  }
};

/**
 * @param {Array.<File>} files
 */
export const upload = (files) => async (dispatch, getState) => {
  if (selectors.getUploadFileProcessId(getState())) {
    uploadCancel(selectors.getUploadFileProcessId(getState()));
  }

  const importRepository = getState().container.get(ImportRepository);
  const upload: IUploadProcessing = await importRepository.bondUpload(files[0]);
  dispatch(uploadStart({
    uploadFile: files[0],
    callback: upload,
    uuid: upload.getUuid(),
  }));

  upload
    .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
    .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
    .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
    .on(ProcessingEvent.processingDone, (data) => {
      dispatch(uploadDone(data));
      dispatch(subscribeToFileImportingChange(reset, data));
      dispatch(bondImport({}));
    })
    .on(UploadEvent.error, (data) => {
      dispatch(uploadError(data));
      dispatch(unsubscribeToFileImportingChange());
    })
    .on(UploadEvent.cancel, (data) => {
      dispatch(uploadCancelSuccess(data));
      dispatch(unsubscribeToFileImportingChange());
    });
};

export const reset = () => (dispatch, getState) => {
  const uuid = selectors.getUploadFileProcessId(getState());
  if (uuid) {
    dispatch(uploadCancel(uuid));
  }
  dispatch(unsubscribeToFileImportingChange());
  dispatch(uploadReset());
  dispatch(resetValidation());
};
